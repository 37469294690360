import React from 'react';

import { Row, Col, Spin, Result } from 'antd';

import { Link, withRouter } from 'react-router-dom';

import JobDescriptionCard from '../../Cards/JobDescriptionCard';
import LanguagesCard from '../../Cards/LanguagesCard';
import EducationLevelCard from '../../Cards/EducationLevelCard';
import YearsOfExperienceCard from '../../Cards/YearsOfExperienceCard';
import PieChart from '../../Charts/PieChart';
import HardSoftSkillsCard from '../../Cards/HardSoftSkillsCard';
import Axios from 'axios';
import errorRouter from '../../errors/Error403';
import Translate from 'react-translate-component';

class JobDescriptionPerCandidate extends React.Component {

  state = {
    softSkills: [],
    hardSkills: [],
    languages: [],
    jobDescription: undefined,
    //Booleano che notifica se c'è caricamento dei dati o meno, in modo da gestire lo spinner
    isLoadingData: true,
    error403: false
  }

  // Vengono caricate le soft e le hard skills della job description. skill_Category specifica cosa si sta cercando: 1=soft skill, 2=hardskill, 3=computerskill, 4=language
  componentDidMount() {
    Axios.get(`/api/jobDescriptionLighter/${this.props.selectedJobDescription._embedded.jobDescription.id}`)
    .then((response) => {
      this.setState({
        error403: false,
        jobDescription: response.data,
      })
      Axios.all([
        Axios.get(`/api/cvJob/search/findByJobDescriptionIdOrderByScoreDesc?id_job=${this.props.selectedJobDescription._embedded.jobDescription.id}&size=9&page=0`),
        Axios.get(`/api/job/${this.props.selectedJobDescription._embedded.jobDescription.id}/jobSkills`)
      ])
        .then((response) => {
          let tempHardSkills = [];
          let tempSoftSkills = [];
          let tempLanguages = [];
          response[1].data._embedded.jobSkills.forEach(jobSkill => {
            if (jobSkill.skillTk) {

              if (jobSkill.skillTk.category === 1) {
                tempSoftSkills.push(
                  {
                    id: jobSkill.skillTk.id,
                    name: jobSkill.skillTk.descriptions,
                    duration: jobSkill.weight,
                    code_id: jobSkill.skillTk.code_id
                  }
                )
              } else if (jobSkill.skillTk.category === 2 || jobSkill.skillTk.category === 3) {
                tempHardSkills.push(
                  {
                    id: jobSkill.skillTk.id,
                    name: jobSkill.skillTk.descriptions,
                    duration: jobSkill.weight,
                    code_id: jobSkill.skillTk.code_id
                  }
                )
              } else if (jobSkill.skillTk.category === 4) {
                tempLanguages.push(
                  {
                    id: jobSkill.skillTk.id,
                    languageCodeDescription: jobSkill.skillTk.descriptions,
                    proficiencyCodeDescription: jobSkill.weight,
                    code_id: jobSkill.skillTk.languageCode
                  }
                )
              }
            }
          });
          this.setState((prevState) => ({
            cvJobs: response[0].data._embedded.cvJobs,
            totalData: response[0].data.page.totalElements,
            hardSkills: tempHardSkills,
            softSkills: tempSoftSkills,
            languages: tempLanguages,
            isLoadingData: false,
            pagination: {
              ...prevState.pagination,
              total: response[0].data.page.totalElements,
            }
          }))
        })
        .catch((error) => {
          console.log(error)
        })
    })
    .catch((error) => {
        if (error.response) {
          if(error.response.status === 403){
            this.setState(() => ({
              error403: true,
            }))
          }
          else{
            errorRouter(error.response.status, this.props.history);
          }
          
        }
      })
  }

  render() {
    if(this.state.error403 === true){
      return (
        <Result
          status="403"
          title={<Translate content='errorMessages.notAllowed' />}
          subTitle={<Translate content='errorMessages.notAllowedJobDescription' />}
        />
      )
    }
    else if (!this.state.isLoadingData) {
      return (
        <Row gutter={[16, 16]} >
          <Col
            xl={{ span: 8 }}
            xxl={{ span: 6 }}
          >
            <Row gutter={[16, 16]} >
              <Col span={24}>
                <Link
                  to={{
                    pathname: `/singlejobdescription/${this.props.selectedJobDescription._embedded.jobDescription.id}`,
                    query: { candidate: this.props.selectedJobDescription._embedded.jobDescription, prevPage: `/singlejobdescription/${this.props.referrerJobDescriptionID}` }
                  }}
                >
                  <JobDescriptionCard
                    jobDescription={this.props.selectedJobDescription._embedded.jobDescription}
                    onJobEditClick={this.props.onJobEditClick}
                  />
                </Link>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <HardSoftSkillsCard isSoftSkill={true} skills={this.state.softSkills} isSingleCandidate={false} />
                  </Col>
                  <Col span={24}>
                    <HardSoftSkillsCard isSoftSkill={false} skills={this.state.hardSkills} isSingleCandidate={false} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col
            xl={{ span: 16 }}
            xxl={{ span: 18 }}
          >
            <Row>
              <Col
                span={24}
              >
                <PieChart
                  jobSoftSkills={this.state.softSkills}
                  jobHardSkills={this.state.hardSkills}
                  jobLanguages={this.state.languages}
                  jobEducationLevelScore={this.state.jobDescription.weightLivelloIstruzione}
                  jobYearsOfExperienceScore={this.state.jobDescription.weightAnniEsperienza}
                  jobDegreesCoursesScore={this.state.jobDescription.weightCorsoLaurea}
                  candidateSoftSkills={this.props.softSkills}
                  candidateHardSkills={this.props.hardSkills}
                  candidateLanguages={this.props.languageSkills}
                  candidateYearsOfExperienceScore={this.props.selectedJobDescription.scoreAnniEsperienza}
                  candidateEducationLevelScore={this.props.selectedJobDescription.scoreLivelloIstruzione}
                  candidateDegreesCoursesScore={this.props.selectedJobDescription.scoreCorsoLaurea}
                  candidateYearsOfExperienceExplanation={this.props.selectedJobDescription.yearsOfExperienceExplanation}
                  selectedCandidateId={this.props.referrerCandidateID}
                  selectedCandidateScore={this.props.selectedJobDescription.score}
                />
              </Col>
            </Row>
            <Row
              gutter={[
                {
                  xs: 8,
                  sm: 8,
                  md: 8,
                  lg: 8,
                  xl: 8,
                  xxl: 32
                },
                16
              ]}
            >
              <Col
                xs={{ span: 8 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 8 }}
              >
                <LanguagesCard
                  languages={this.state.languages}
                  isCandidate={false}
                />
              </Col>
              <Col
                xs={{ span: 8 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 8 }}
              >
                <EducationLevelCard
                  jobEducationLevel={this.state.jobDescription.livelloIstruzione}
                  jobEducationLevelScore={this.state.jobDescription.weightLivelloIstruzione}
                  jobCoursesScore={this.state.jobDescription.weightCorsoLaurea}
                  courses={this.state.jobDescription._embedded.courses}
                  isCandidate={false}
                />
              </Col>
              <Col
                xs={{ span: 8 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
                xxl={{ span: 8 }}
              >
                <YearsOfExperienceCard
                  yearsOfExperience={this.state.jobDescription.anniEsperienza}
                  yearsOfExperienceScore={this.state.jobDescription.weightAnniEsperienza}
                  isCandidate={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )
    } else {
      return (
        <Row type='flex' justify='center' align='middle' style={{ height: '100%', width: '100%' }}>
          <Col span={2}>
            <Spin size='large' />
          </Col>
        </Row>
      )
    }
  }
} export default withRouter(JobDescriptionPerCandidate);