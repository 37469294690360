import React from "react";
//Componenti ant design utilizzati
import {
  Card,
  Row,
  Col,
  Typography,
  Modal,
  Timeline,
  Icon,
  Tooltip,
  Comment,
  Popover,
  List,
} from "antd";
//Librerie per il multilingua
import Translate from "react-translate-component";
import Moment from "react-moment";
import { withRouter } from "react-router-dom";

//import graduationCapSolid from '../../resources/icons/graduationCapSolid.png';
//Componenti Title e Text di Ant Design, fanno parte della libreria Typography
const { Title, Text } = Typography;

//Contiene i possibili livelli d'istruzione, per poterli utilizzare nel cambiamento del valore del livello d'istruzione
const educationLevelMapping =
  localStorage.getItem("Language") === "it"
    ? [
        "Qualsiasi",
        "Diploma",
        "Formazione Professionale",
        "Laurea Triennale",
        "Laurea Magistrale",
        "Master",
        "Dottorato",
        "Laurea Associata",
      ]
    : [
        "Any",
        "Diploma",
        "Professional Training",
        "Bachelor Degree",
        "Master Degree",
        "Master",
        "PhD",
        "Associate Degree",
      ];

/**
 * I props utilizzati sono:
 - onEducationLevelCardClick: permette di intercettare l'evento di click della card in questione, per aprire
 un modal che mostra la timeline di tutto il percorso scolastico seguito dal candidato
 - educationLevel: contiene tutti i dati riguardanti il livello di istruzione del candidato
 - isEducationLevelModalVisible: booleano che consente l'apertura/chiusura del modal con la timeline
 - handleCancelEducationLevelModal: metodo lanciato alla chiusura del modal
 - institues: contiene tutti i dati riguardanti il percorso scolastico del candidato, utilizzato per creare la timeline
 */
class EducationLevelCard extends React.Component {
  onEducationLevelChange = (updatedEducationLevel) => {
    this.props.onEducationLevelChange(updatedEducationLevel);
  };

  render() {
    return (
      <div>
        <Card hoverable id="personalCard" style={{ height: "100%" }}>
          <Row justify="center" align="middle">
            {!this.props.isCandidate ? (
              <Tooltip
                title={
                  localStorage.getItem("Language") === "it"
                    ? this.props.jobEducationLevelScore
                      ? this.props.jobEducationLevelScore + "%"
                      : this.props.educationLevel
                      ? this.props.educationLevel.highestEducation
                        ? this.props.educationLevel.highestEducation
                            .levelCodeDescription.length !== 0
                          ? this.props.educationLevel.highestEducation
                              .levelCodeDescription
                          : "Mancante"
                        : educationLevelMapping[this.props.jobEducationLevel]
                      : "Mancante"
                    : this.props.jobEducationLevelScore
                    ? this.props.jobEducationLevelScore + "%"
                    : this.props.educationLevel
                    ? this.props.educationLevel.highestEducation
                      ? this.props.educationLevel.highestEducation
                          .levelCodeDescription.length !== 0
                        ? this.props.educationLevel.highestEducation
                            .levelCodeDescription
                        : "Absent"
                      : educationLevelMapping[this.props.jobEducationLevel]
                    : "Absent"
                }
              >
                <Popover
                  content={
                    <List
                      header={
                        <h3>
                          <Translate content="educationCard.weightText" />{" "}
                          {this.props.jobCoursesScore}%
                        </h3>
                      }
                      itemLayout="horizontal"
                      dataSource={this.props.courses}
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta title={item.nome} />
                        </List.Item>
                      )}
                    />
                  }
                  placement="leftTop"
                >
                  <Col
                    span={20}
                    style={{
                      height: "25px",
                      textAlign: "left",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Row>
                      <Title
                        level={3}
                        ellipsis={true}
                        editable={
                          this.props.isCandidate
                            ? { onChange: this.onEducationLevelChange }
                            : false
                        }
                        className="ant-editable-icon"
                      >
                        {
                          /**
                           * Fa un controllo sulla presenza di dati che riguardano il livello d'istruzione. Il dato potrebbe
                           * risultare undefined
                           */
                          localStorage.getItem("Language") === "it"
                            ? this.props.highestEducationLevel
                              ? this.props.highestEducationLevel
                                  .levelCodeDescription.length !== 0
                                ? this.props.highestEducationLevel
                                    .levelCodeDescription
                                : "Mancante"
                              : educationLevelMapping[
                                  this.props.jobEducationLevel
                                ]
                            : this.props.highestEducationLevel
                            ? this.props.highestEducationLevel
                                .levelCodeDescription.length !== 0
                              ? this.props.highestEducationLevel
                                  .levelCodeDescription
                              : "Absent"
                            : educationLevelMapping[
                                this.props.jobEducationLevel
                              ]
                        }
                      </Title>
                    </Row>

                    <Row style={{ marginTop: "-3px" }}>
                      <Text
                        style={{
                          color: "#3f88c5",
                          textAlign: "center",
                          fontSize: 12,
                        }}
                      >
                        <Translate content="cards.educationLevelJob" />
                      </Text>
                    </Row>
                  </Col>
                </Popover>
              </Tooltip>
            ) : (
              <Col
                span={20}
                style={{
                  height: "25px",
                  textAlign: "left",
                  justifyContent: "start",
                  alignItems: "center",
                }}
                onClick={
                  this.props.educationHistory
                    ? this.props.onEducationLevelCardClick
                    : null
                }
              >
                <Row>
                  <Title
                    level={3}
                    ellipsis={
                      true
                    } /* editable={this.props.isCandidate ? { onChange: this.onEducationLevelChange } : false} */
                    className="ant-editable-icon"
                  >
                    {
                      /**
                       * Fa un controllo sulla presenza di dati che riguardano il livello d'istruzione. Il dato potrebbe
                       * risultare undefined
                       */
                      localStorage.getItem("Language") === "it"
                        ? this.props.highestEducationLevel
                          ? this.props.highestEducationLevel
                              .levelCodeDescription.length !== 0
                            ? this.props.highestEducationLevel
                                .levelCodeDescription
                            : "Mancante"
                          : educationLevelMapping[this.props.jobEducationLevel]
                        : this.props.highestEducationLevel
                        ? this.props.highestEducationLevel.levelCodeDescription
                            .length !== 0
                          ? this.props.highestEducationLevel
                              .levelCodeDescription
                          : "Absent"
                        : educationLevelMapping[this.props.jobEducationLevel]
                    }
                  </Title>
                </Row>
                <Row style={{ marginTop: "-3px" }}>
                  <Text
                    style={{
                      color: "#3f88c5",
                      textAlign: "center",
                      fontSize: 12,
                    }}
                  >
                    <Translate content="cards.educationLevelCandidate" />
                  </Text>
                </Row>
              </Col>
            )}
            <Col
              span={4}
              style={{ textAlign: "right" }}
              onClick={
                this.props.educationHistory
                  ? this.props.onEducationLevelCardClick
                  : null
              }
            >
              <i
                className="fa-regular fa-graduation-cap iconsCandidate"
                style={{ marginRight: "0.5rem", fontSize: "2rem" }}
              ></i>
            </Col>
          </Row>
        </Card>
        {this.props.educationHistory ? (
          <Modal
            title={<Translate content="educationCard.titleText" />}
            visible={this.props.isEducationLevelModalVisible}
            onCancel={this.props.handleCancelEducationLevelModal}
            onOk={this.props.handleCancelEducationLevelModal}
            width="70vw"
          >
            <Timeline mode="left">
              {
                /**
                 * Cicla tutti gli elementi presenti in institues in modo da poter creare la timeline
                 */
                this.props.educationHistory.map((institute) => {
                  return (
                    <Timeline.Item
                      dot={
                        institute.endDate === "present" ? (
                          <Tooltip
                            title={
                              <Translate content="educationCard.completingText" />
                            }
                          >
                            <Icon
                              type="clock-circle-o"
                              style={{ fontSize: "16px" }}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={
                              institute.endDate ? (
                                <span>
                                  <Translate content="educationCard.endedText" />{" "}
                                  <Moment
                                    format="DD/MM/YYYY"
                                    parse="YYYY/MM/DD"
                                    date={institute.endDate}
                                  />
                                </span>
                              ) : (
                                <Translate content="educationCard.missingDateText" />
                              )
                            }
                          >
                            <Icon
                              type="check-circle-o"
                              style={{ fontSize: "16px" }}
                            />
                          </Tooltip>
                        )
                      }
                      key={institute.id}
                    >
                      <Comment
                        author={(institute.degreeDirection.length !== 0 &&
                        (institute.instituteName.length !== 0 ||
                          institute.institutePlace.length !== 0)
                          ? `${institute.degreeDirection},`
                          : `${institute.degreeDirection}`
                        )
                          .concat(
                            institute.instituteName.length !== 0 &&
                              institute.institutePlace.length !== 0
                              ? ` ${institute.instituteName},`
                              : ` ${institute.instituteName}`
                          )
                          .concat(
                            institute.institutePlace.length !== 0
                              ? ` ${institute.institutePlace}`
                              : ""
                          )}
                        datetime={
                          institute.startDate ? (
                            <Moment
                              format="DD/MM/YYYY"
                              parse="YYYY/MM/DD"
                              date={institute.startDate}
                            />
                          ) : (
                            <Translate content="educationCard.noDataText" />
                          )
                        }
                        content={
                          institute.LevelCodeDescription ? (
                            institute.LevelCodeDescription
                          ) : (
                            <Translate content="educationCard.noDescriptionText" />
                          )
                        }
                      />
                    </Timeline.Item>
                  );
                })
              }
            </Timeline>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default withRouter(EducationLevelCard);
