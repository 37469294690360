import React from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);



class PieChartDistribution extends React.Component  {
  state = {
    pieChartData: [],
    values:[],
    labels:[]
  }
  options = {
    responsive: true,
    maintainAspectRatio: false,
    layout:{
      autoPadding: false
    },
    scales: {
      r: {
        pointLabels: {
          font: {
            size: 14
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'bottom',
        display: false,
        labels: {
          // This more specific font property overrides the global property
          font: {
              size: 14
          }
        }
      }
    },
  };
  

  componentDidMount() {
    let employment = []
    if (this.props.employment){
       employment = this.props.employment;
    }

    let values = [];
    let labels = [];
   
    //Viene fatto un matching tra skill del candidato e skill della job description, e i dati filtrati sono aggiunti in pieChartData
    if (employment.length !== 0) {
      //console.log(employment)
      employment.forEach(emp => {
        values.push(emp.jobGroupDescription)
        labels.push(emp.count)
      })
  
    let pieChartData={
        labels: values,
        datasets: [
          {
            label: '# Candidati',
            data:labels,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
          },
        ],
    }
  
    this.setState({
      pieChartData: pieChartData,
      })
  
}}

  render() {
    //console.log(this.options)
    return(this.state.pieChartData.datasets === undefined ? null: <Radar data={this.state.pieChartData} options={this.options}/>)
  } 
}export default PieChartDistribution;