import React from "react";

import { Row, Col, InputNumber, Select, Typography, Button } from "antd";
import { withRouter } from "react-router-dom";

import Translate from "react-translate-component";

import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";

const { Option, OptGroup } = Select;

const { Text } = Typography;

//Contiene i possibili livelli d'istruzione, per poterli utilizzare nel cambiamento del valore del livello d'istruzione

const operations = [
  { operation: "<", value: 0 },
  { operation: "<=", value: 1 },
  { operation: "=", value: 2 },
  { operation: ">", value: 3 },
  { operation: ">=", value: 4 },
  { operation: "Range", value: 5 },
];

let onlyOperations = [];

class RequirementsStep extends React.Component {
  state = {
    maxYearsActive: false,

    isExperienceYearsMandatory: false,
    isEducationLevelMandatory: false,
    isThematicMandatory: false,

    operationType: "=",
  };

  constructor() {
    super();

    onlyOperations = operations.map((operation) => operation.operation);
  }

  //Lanciato al cambiamento del valore degli anni di esperienza
  onYearsOfExperienceChange = (yearsOfExperienceValue) => {
    this.props.onYearsOfExperienceChange(yearsOfExperienceValue);
  };

  onMaxYearsOfExperienceChange = (yearsOfExperienceValue) => {
    this.props.onMaxYearsOfExperienceChange(yearsOfExperienceValue);
  };

  //Lanciato al cambiamento del peso degli anni di esperienza
  onYearsOfExperienceWeightChange = (yearsOfExperienceWeight) => {
    this.props.onYearsOfExperienceWeightChange(yearsOfExperienceWeight);
  };

  //Lanciato al cambiamento del valore del livello d'istruzione
  onEducationLevelChange = (educationLevelValue) => {
    this.props.onEducationLevelChange(educationLevelValue);
  };

  //Lanciato al cambiamento del peso del livello d'istruzione
  onEducationLevelWeightChange = (educationLevelWeight) => {
    this.props.onEducationLevelWeightChange(educationLevelWeight);
  };

  //Lanciato al cambiamento del valore dell'area tematica
  onThemeAreaChange = (themeAreaValue) => {
    this.props.onThemeAreaChange(themeAreaValue);
  };

  onThemeAreaDeselect = (removedThemeArea) => {
    this.props.onThemeAreaDeselect(removedThemeArea);
  };

  //Lanciato al cambiamento del peso dell'area tematica
  onThemeAreaWeightChange = (themeAreaWeight) => {
    this.props.onThemeAreaWeightChange(themeAreaWeight);
  };

  /* onAziendaWorkCheckboxChange = (element) => {
    this.props.onAziendaWorkCheckboxChange(element.target.checked);
  }*/

  /* onAtLeastCheckboxChange = (element) => {
    this.props.onAtLeastCheckboxChange(element.target.checked);
  };

  onDisabilityStatusCheckboxChange = (element) => {
    this.props.onDisabilityStatusCheckboxChange(element.target.checked);
  }; */

  //Comunica a <NewJobDescription /> di aggiungere un nuovo oggetto all'array dei corsi
  addCourse = () => {
    this.props.addCourse();
  };

  //Comunica a <NewJobDescription /> di rimuovere un oggetto dall'array dei corsi
  removeCourse = (index) => {
    this.props.removeCourse(index);
  };

  activateMaxYearHandler = () => {
    this.setState((prevState) => ({
      maxYearsActive: !prevState.maxYearsActive,
    }));
  };

  onOperationChange = (event) => {
    event.preventDefault();
    this.setState({
      operationType: event.value,
    });
    this.props.onOperationChange(
      operations.findIndex((operation) => operation.operation === event.value)
    );
  };

  render() {
    //Contiene tutti i corsi selezionati, ricevuti da <NewJobDescription />

    /* const formItems = this.props.jobDescriptionData.coursesList
      ? this.props.jobDescriptionData.coursesList.map((courseElement, index) => (
        this.props.jobDescriptionData.coursesList.length !== 0 ?
          (<CourseRow
            removeCourse={this.removeCourse}
            courseIndex={index}
            courseValue={courseElement.nome}
            coursesLength={this.props.jobDescriptionData.coursesList.length}
            key={index}
          />) : null
      ))
      : []; */
    return (
      <Row gutter={[16, 16]}>
        <Row type="flex" justify="start" align="middle" gutter={[16, 32]}>
          <Col
            xs={{ span: 4, offset: 1 }}
            sm={{ span: 4, offset: 1 }}
            md={{ span: 4, offset: 1 }}
            lg={{ span: 4, offset: 1 }}
            xl={{ span: 4, offset: 1 }}
            xxl={{ span: 4, offset: 1 }}
            style={{ textAlign: "right" }}
          >
            <Text strong>
              <Translate content="newJobDescription.isRequiredText" />
            </Text>
            <Checkbox
              className="requirements"
              style={{ marginLeft: "0.5rem" }}
              onChange={this.props.onExperienceYearsMandatory}
              checked={this.props.jobDescriptionData.mandatoryAnniEsperienza}
            />
          </Col>
          <Col
            xs={{ span: 3 }}
            sm={{ span: 3 }}
            md={{ span: 3 }}
            lg={{ span: 3 }}
            xl={{ span: 3 }}
            xxl={{ span: 3 }}
            style={{ textAlign: "right" }}
          >
            <Text strong>
              <Translate content="newJobDescription.yearsOfExperienceText" />
            </Text>
          </Col>
          <Col
            xs={{ span: 6 }}
            sm={{ span: 6 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xl={{ span: 6 }}
            xxl={{ span: 4 }}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}
              xxl={{ span: 24 }}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Dropdown
                className="experience"
                options={onlyOperations}
                value={
                  onlyOperations[
                    this.props.jobDescriptionData.anniEsperienzaType
                  ]
                }
                onChange={this.onOperationChange}
              />
              <InputNumber
                className="experience"
                defaultValue={0}
                value={this.props.jobDescriptionData.anniEsperienza}
                min={0}
                max={100}
                onChange={this.onYearsOfExperienceChange}
              />
              {this.props.jobDescriptionData.anniEsperienzaType === 5 && (
                <InputNumber
                  className="experience"
                  value={this.props.jobDescriptionData.anniEsperienzaMax}
                  min={this.props.jobDescriptionData.anniEsperienza + 1}
                  max={100}
                  onChange={this.onMaxYearsOfExperienceChange}
                />
              )}
            </Col>
            <Col
              style={{ display: "flex", alignItems: "center" }}
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            ></Col>
          </Col>
          <Col
            xs={{ span: 1 }}
            sm={{ span: 1 }}
            md={{ span: 1 }}
            lg={{ span: 1, offset: 1 }}
            xl={{ span: 1, offset: 2 }}
            xxl={{ span: 1 }}
            style={{ textAlign: "right" }}
          >
            <Text strong>
              <Translate content="newJobDescription.weightText" />
            </Text>
          </Col>
          <Col
            xs={{ span: 4 }}
            sm={{ span: 4 }}
            md={{ span: 4 }}
            lg={{ span: 4 }}
            xl={{ span: 2 /*3*/ }}
            xxl={{ span: 4 }}
          >
            <InputNumber
              defaultValue={0}
              value={this.props.jobDescriptionData.weightAnniEsperienza}
              min={0}
              max={100}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
              onChange={this.onYearsOfExperienceWeightChange}
            />
          </Col>
          {this.props.errorMessage.error && (
            <small className="p-error-experience-years">
              {this.props.errorMessage.message}
            </small>
          )}
        </Row>
        {/* <Row type='flex' justify='start' align='middle' gutter={[16, 32]}>
          <Col offset={8}>
            <Checkbox
              onChange={this.onAtLeastCheckboxChange}
              checked={this.props.jobDescriptionData.anniEsperienzaAlmeno}
            >
              Considera {this.props.jobDescriptionData.anniEsperienza} anni come soglia minima richiesta
            </Checkbox>
          </Col>
        </Row> */}
        {/*<Row type='flex' justify='start' align='middle' gutter={[16, 32]}>
          <Col offset={8}>
            <Checkbox
              onChange={this.onAziendaWorkCheckboxChange}
              checked={this.props.jobDescriptionData.aziendaWork}
            >
              Considera bonus una precedente esperienza in azienda
            </Checkbox>
          </Col>
        </Row>
        <Row type='flex' justify='start' align='middle' gutter={[16, 32]}>
          <Col offset={8}>
            <Checkbox
              onChange={this.onDisabilityStatusCheckboxChange}
              checked={this.props.jobDescriptionData.categoriaProtetta}
            >
              Considera categoria protetta
            </Checkbox>
          </Col>
        </Row>*/}
        <Row type="flex" justify="start" align="middle" gutter={[16, 40]}>
          <Col
            xs={{ span: 4, offset: 1 }}
            sm={{ span: 4, offset: 1 }}
            md={{ span: 4, offset: 1 }}
            lg={{ span: 4, offset: 1 }}
            xl={{ span: 4, offset: 1 }}
            xxl={{ span: 4, offset: 1 }}
            style={{ textAlign: "right" }}
          >
            <Text strong>
              <Translate content="newJobDescription.isRequiredText" />
            </Text>
            <Checkbox
              className="requirements"
              style={{ marginLeft: "0.5rem" }}
              onChange={this.props.onEducationLevelMandatory}
              checked={this.props.jobDescriptionData.mandatoryLivelloIstruzione}
            />
          </Col>
          <Col
            xs={{ span: 3 }}
            sm={{ span: 3 }}
            md={{ span: 3 }}
            lg={{ span: 3 }}
            xl={{ span: 3 }}
            xxl={{ span: 3 }}
            style={{ textAlign: "right" }}
          >
            <Text strong>
              <Translate content="newJobDescription.educationLevelText" />{" "}
            </Text>
          </Col>
          <Col
            xs={{ span: 4 }}
            sm={{ span: 4 }}
            md={{ span: 4 }}
            lg={{ span: 6 }}
            xl={{ span: 4 }}
            xxl={{ span: 4 }}
          >
            <Select
              value={this.props.jobDescriptionData.livelloIstruzione}
              defaultValue={0}
              showSearch
              showArrow
              placeholder={
                localStorage.getItem("Language") === "it"
                  ? "Seleziona livello d'istruzione"
                  : "Select education level"
              }
              optionFilterProp="children"
              onChange={this.onEducationLevelChange}
              onSearch={this.onEducationLevelSearch}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              style={{
                width: 300,
                border: "1px solid #d9d9d9",
                borderRadius: "5px",
              }}
            >
              {this.props.educationLevelMapping.map((element, index) => (
                <Option
                  key={
                    element.charAt(0).toUpperCase() +
                    element.substring(1, element.length)
                  }
                  value={index}
                >
                  {element.charAt(0).toUpperCase() +
                    element.substring(1, element.length)}
                </Option>
              ))}
            </Select>
          </Col>
          <Col
            xs={{ span: 1, offset: 2 }}
            sm={{ span: 1, offset: 2 }}
            md={{ span: 1, offset: 2 }}
            lg={{ span: 1, offset: 3 }}
            xl={{ span: 1, offset: 4 }}
            xxl={{ span: 1, offset: 2 }}
            style={{ textAlign: "right" }}
          >
            <Text strong>
              <Translate content="newJobDescription.weightText" />
            </Text>
          </Col>
          <Col
            xs={{ span: 5 }}
            sm={{ span: 5 }}
            md={{ span: 5 }}
            lg={{ span: 3 }}
            xl={{ span: 5 }}
            xxl={{ span: 5 }}
          >
            <InputNumber
              defaultValue={0}
              value={this.props.jobDescriptionData.weightLivelloIstruzione}
              min={0}
              max={100}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
              onChange={this.onEducationLevelWeightChange}
              /* step={0.1}
              precision={2} */
            />
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle" gutter={[16, 40]}>
          <Col
            xs={{ span: 4, offset: 1 }}
            sm={{ span: 4, offset: 1 }}
            md={{ span: 4, offset: 1 }}
            lg={{ span: 4, offset: 1 }}
            xl={{ span: 4, offset: 1 }}
            xxl={{ span: 4, offset: 1 }}
            style={{ textAlign: "right" }}
          >
            <Text strong>
              <Translate content="newJobDescription.isRequiredText" />
            </Text>
            <Checkbox
              className="requirements"
              style={{ marginLeft: "0.5rem" }}
              onChange={this.props.onThematicMandatory}
              checked={this.props.jobDescriptionData.mandatoryCorsoLaurea}
            />
          </Col>
          <Col
            xs={{ span: 3 }}
            sm={{ span: 3 }}
            md={{ span: 3 }}
            lg={{ span: 3 }}
            xl={{ span: 3 }}
            xxl={{ span: 3 }}
            style={{ textAlign: "right" }}
          >
            <Text strong>
              <Translate content="newJobDescription.thematicText" />{" "}
            </Text>
          </Col>
          <Col
            xs={{ span: 4 }}
            sm={{ span: 4 }}
            md={{ span: 4 }}
            lg={{ span: 6 }}
            xl={{ span: 4 }}
            xxl={{ span: 4 }}
          >
            <Select
              value={this.props.jobDescriptionData.theme_area.concat(
                this.props.coursesNamedList
              )}
              showSearch
              showArrow
              allowClear
              mode="multiple"
              placeholder={
                localStorage.getItem("Language") === "it"
                  ? "Seleziona Area Tematica"
                  : "Select thematic area"
              }
              optionFilterProp="children"
              onSelect={this.onThemeAreaChange}
              onDeselect={this.onThemeAreaDeselect}
              onSearch={this.onThemeAreaSearch}
              /*filterOption={(input, option) =>
                //option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }*/
              disabled={
                this.props.jobDescriptionData.livelloIstruzione !== 3 &&
                this.props.jobDescriptionData.livelloIstruzione !== 4
                  ? true
                  : false
              }
              style={{
                width: 300,
                border: "1px solid #d9d9d9",
                borderRadius: "5px",
              }}
            >
              <OptGroup
                label={
                  <Translate content="newJobDescription.thematicAreasText" />
                }
              >
                {this.props.dbThemeAreas.map((themeArea) => {
                  return (
                    <Option key={themeArea.id} value={themeArea.id}>
                      {themeArea.name.substring(0, 1).toUpperCase() +
                        themeArea.name.substring(1, themeArea.name.length)}
                    </Option>
                  );
                })}
              </OptGroup>
              <OptGroup
                label={<Translate content="newJobDescription.coursesText" />}
              >
                {this.props.dbCourses
                  ? this.props.dbCourses.map((course) => {
                      return (
                        <Option
                          key={`${course.id} ${course.nome}`}
                          value={`${course.id} ${course.nome}`}
                        >
                          {course.nome.substring(0, 1).toUpperCase() +
                            course.nome.substring(1, course.nome.length)}
                        </Option>
                      );
                    })
                  : null}
              </OptGroup>
            </Select>
          </Col>
          <Col
            xs={{ span: 1, offset: 2 }}
            sm={{ span: 1, offset: 2 }}
            md={{ span: 1, offset: 2 }}
            lg={{ span: 1, offset: 3 }}
            xl={{ span: 1, offset: 4 }}
            xxl={{ span: 1, offset: 2 }}
            style={{ textAlign: "right" }}
          >
            <Text strong>
              <Translate content="newJobDescription.weightText" />
            </Text>
          </Col>
          <Col
            xs={{ span: 5 }}
            sm={{ span: 5 }}
            md={{ span: 5 }}
            lg={{ span: 3 }}
            xl={{ span: 5 }}
            xxl={{ span: 5 }}
          >
            <InputNumber
              defaultValue={0}
              value={this.props.jobDescriptionData.weightCorsoLaurea}
              min={0}
              max={100}
              disabled={
                this.props.jobDescriptionData.livelloIstruzione !== 3 &&
                this.props.jobDescriptionData.livelloIstruzione !== 4
                  ? true
                  : false
              }
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
              onChange={this.onThemeAreaWeightChange}
            />
          </Col>
        </Row>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ marginTop: "2rem" }}
        >
          <Col span={22} style={{ display: "flex", justifyContent: "end" }}>
            <Button
              disabled={this.props.errorMessage.error}
              type="primary"
              color="#3f88c5"
              onClick={() =>
                this.props.stepAvanti(
                  this.props.jobDescriptionData.anniEsperienza,
                  this.props.jobDescriptionData.anniEsperienzaMax
                )
              }
              minExperienceYears={this.state.minExperienceYears}
              maxExperienceYears={this.state.maxExperienceYears}
            >
              <Translate content="newJobDescription.continueButtonText" />
            </Button>
          </Col>
        </Row>
        {/* <Row type='flex' justify='center' align='middle' gutter={[16, 40]} >
          <Col span={12}>
            <Collapse>
              <Panel
                header="Corsi"
                key="1"
                disabled={
                  (this.props.jobDescriptionData.livelloIstruzione !== 3
                    && this.props.jobDescriptionData.livelloIstruzione !== 4)
                    ? true : false
                }
              >
                {/* <Row type='flex' justify='center' align='middle' gutter={[16, 32]}>
                  <Col span={12} offset={1}>
                    <Button type="dashed" onClick={this.addCourse} style={{ width: '60%' }}>
                      <Icon type="plus" /> Aggiungi Corso
                      </Button>
                  </Col>
                </Row> 
                {formItems}
                {/* {
                  this.props.jobDescriptionData.courses.length !== 0 ?
                    (<Row type='flex' justify='center' align='middle' gutter={[16, 32]}>
                      <Col span={12} offset={1}>
                        <Button type="dashed" onClick={this.addCourse} style={{ width: '60%' }}>
                          <Icon type="plus" /> Aggiungi Corso
                    </Button>
                      </Col>
                    </Row>) : null
                } 
              </Panel>
            </Collapse>
          </Col>
        </Row>  */}
      </Row>
    );
  }
}
export default withRouter(RequirementsStep);
