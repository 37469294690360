import React from 'react';
//Componenti ant design utilizzati
import { Row, Col, Divider, Pagination, Button, Typography, Icon, Switch } from 'antd';
//Libreria per il multilingua
import Translate from 'react-translate-component';

//Componente Title di Ant Design, fa parte della libreria Typography
const { Title } = Typography;

/**
 * Riceve 4 props:
 * - isJobDescriptions: Distingue la top bar per capire se fa parte della schermata <JobDescriptions /> o <Candidates />,
 *   utilizzato per cambiare i testi ed evitare di duplicare il componente per i due casi differenti.
 * - onShowSizeChange: metodo lanciato al cambiamento del numero di elementi per pagina nel <Pagination /> da parte dell'utente.
 * - onPageChange: metodo lanciato al cambiamento di pagina nel <Pagination /> da parte dell'utente. 
 * - showDrawer= metodo lanciato al click del tasto filtra, per permettere l'apertura del drawer con le impostazioni.
*/
class topBar extends React.Component {


  state = {
    current: 1,
    pageSize: 12,
  }

  componentDidMount(){
    let keySessionStorage = this.props.isJobDescriptions ? "PAGED-JOB-STATUS" : "PAGED-CANDIDATE-STATUS";
    const store = sessionStorage.getItem(keySessionStorage);
     let local = JSON.parse(store);
     if(local != null && local !== undefined){
        this.onPageChange(local.current, local.pageSize)
       }
  }
  //Lanciato al cambiamento di pagina nel <Pagination />
  onPageChange = (page, pageSize) => {
    let keySessionStorage = this.props.isJobDescriptions ? "PAGED-JOB-STATUS" : "PAGED-CANDIDATE-STATUS";
    this.props.onPageChange(page, pageSize)
    this.setState({
      current: page,
      pageSize: pageSize
    })
    let currentState = {
      current: page,
      pageSize: pageSize,
    }
    sessionStorage.setItem(keySessionStorage, JSON.stringify(currentState))
  }

  onFullTextSearchFilterChange = (checked) => {
    this.props.onFullTextSearchFilterChange(checked);
  }

  //Lanciato al cambiamento del numero di elementi per pagina nel <Pagination />
  onShowSizeChange = (current, pageSize) => {
    let keySessionStorage = this.props.isJobDescriptions ? "PAGED-JOB-STATUS" : "PAGED-CANDIDATE-STATUS";
    this.props.onShowSizeChange(current, pageSize)
    let currentState = {
      current: current,
      pageSize: pageSize,
    }
    this.setState({
      current: current,
      pageSize: pageSize
    })
    sessionStorage.setItem(keySessionStorage, JSON.stringify(currentState))
  }

  render() {
    return (
      <Row gutter={[32, 8]} style={{ textAlign: 'center' }}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 6 }}
          xl={{ span: 5 }}
          xxl={{ span: 4 }}
        >
          <Title level={3}>
            {
              this.props.isJobDescriptions
                ? <Translate content='jobDescriptions.jobDescriptionsText' />
                : <Translate content='candidates.candidatesText' />
            }
          </Title>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 17, offset: 0 }}
          md={{ span: 17, offset: 0 }}
          lg={{ span: 14, offset: 0 }}
          xl={{ span: 10, offset: 1 }}
          xxl={{ span: 10, offset: 3 }}
        >
          <Pagination
            current={this.state.current}
            showSizeChanger
            pageSizeOptions={['12', '24', '36']}
            //defaultPageSize={12}
            pageSize={this.state.pageSize}
            onShowSizeChange={this.onShowSizeChange}
            onChange={this.onPageChange}
            total={this.props.totalData}
          />
        </Col>
        {
          !this.props.isJobDescriptions ?
            <Col
              xs={{ span: 16, offset: 0 }}
              sm={{ span: 5, offset: 2 }}
              md={{ span: 4, offset: 3 }}
              lg={{ span: 3, offset: 1 }}
              xl={{ span: 4, offset: 1 }}
              xxl={{ span: 3, offset: 2 }}
              style={{ textAlign: 'right', paddingBottom: 24 }}
            >
              <span><Translate content='drawer.fullTextSearch' /></span>
              <Switch
                checkedChildren={<Icon type="file-search" />}
                unCheckedChildren={<Icon type="close" />}
                defaultChecked={false}
                onChange={this.onFullTextSearchFilterChange}
                style={{ marginLeft: 10 }}
              />
            </Col> : null
        }
        <Col
          xs={{ span: 16, offset: 0 }}
          sm={{ span: 5, offset: 2 }}
          md={{ span: 4, offset: 3 }}
          lg={{ span: 3, offset: 1 }}
          xl={this.props.isJobDescriptions ? { span: 3, offset: 2 } : { span: 3, offset: 0 }}
          xxl={this.props.isJobDescriptions ? { span: 2, offset: 5 } : { span: 2, offset: 0 }}
          style={{ textAlign: 'right', paddingBottom: 24 }}
        >
          <Button
            icon='filter'
            onClick={this.props.showDrawer}
            type='primary'
          >
            <Translate content='buttons.topBarFilter' />
          </Button>
        </Col>
        <Divider style={{ backgroundColor: '#3f88c5' }} />
      </Row>
    )
  }
} export default topBar;
