import React from 'react';

import { Row, Col, Empty } from 'antd';

import SingleCandidateCard from '../../Cards/SingleCandidateCard';
import { withRouter } from 'react-router-dom';
import Translate from 'react-translate-component';

export default withRouter(function CandidatesGrid(props) {
  
  return (
    <Row gutter={[32, 24]} >
      {
        props.cardsData.length !== 0
          ? props.cardsData.map((singleCandidate) => {
            return (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={props.isSearchFullText ? {span: 8, offset: 2} : { span: 6 }}
                xxl={ props.isSearchFullText ? {span: 6, offset: 1} : { span: 4 }}
                key={singleCandidate.id}
              >
                <SingleCandidateCard
                  candidate={singleCandidate}
                  onCandidateCardClick={props.onCandidateCardClick}
                  currentPage={props.currentPage}
                  loadCandidatesData={props.loadCandidatesData}
                />
              </Col>
            )
          })
          :
          <Empty
            description={<Translate content='candidates.noCandidatesText' />}
            image={Empty.PRESENTED_IMAGE_DEFAULT}
            style={{ paddingTop: '30vh' }}
          />
      }
    </Row>
  );
})