import React from "react";

import ReactDOM from "react-dom";
//Utilizzate per le Rotte
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
//Axios per le chiamate alle API Back End
import Axios from "axios";
//Componenti ant design utilizzati
import { Typography, Icon, Button, Form, Input, Col, Row } from "antd";
//Librerie per il multilingua
import counterpart from "counterpart";
import Translate from "react-translate-component";
//File JSON custom contenenti le traduzioni nelle lingue specificate: italiano, inglese
import it from "./translations/it";
import en from "./translations/en";

//Componenti Custom creati da noi
import App from "./App";
import Error403 from "./components/errors/Error403";
import Error503 from "./components/errors/Error503";
import logoDarkZarathustra from "./resources/logo/logo-dark.png";
import { Toast } from "primereact/toast";
//CSS NECESSARIO per far funzionare Ant Design
require("antd/dist/antd.css");

//APP THEME
require("./resources/config/result.css");
//CSS Custom
require("./resources/css/style.css");
require("./resources/css/login.css");
require("./resources/css/header.css");
require("./resources/css/pagination.css");
require("./resources/css/sider.css");
require("./resources/css/cards.css");
require("./resources/css/fabs.css");
require("./resources/css/forms.css");
require("./resources/css/all.min.css");
require("primereact/resources/themes/lara-light-indigo/theme.css");
require("primereact/resources/primereact.min.css");
require("primeicons/primeicons.css");
/**
 * Il metodo registerTranslations('Nome Lingua', fileLingua) è utilizzato per la registrazione delle lingue presenti nella cartella "translations",
 * in modo che possano poi essere utilizzate dal metodo setLocale('Nome Lingua') per il settaggio della lingua che si vuole utilizzare
 */
counterpart.registerTranslations("it", it);
counterpart.registerTranslations("en", en);
localStorage.getItem("Language") === null
  ? counterpart.setLocale("it") && localStorage.setItem("Language", "it")
  : counterpart.setLocale(localStorage.getItem("Language"));

//Componente Text di Ant Design, fa parte della libreria Typography
const { Text } = Typography;

/*
  Questa è una Route custom che contiene al suo interno una Route normale. Permette di 
  avere rotte protette: se non viene effettuato il login, NON è possibile accedere
  alle PrivateRoute. 
  - Prende in ingresso, come props, il componente che appartiene a quella Route, e tutti gli 
  altri props presenti.
  - Il render di Route prevede che se nel localStorage è presente il token di autenticazione,
  allora viene renderizzato il componente passato nei props di PrivateRoute, altrimenti viene
  effettuato un redirect alla pagina di Login
  N.B. "component: Component" sta solo ad indicare che il props "component" viene rinominato in
  "Component", mentre "...rest" indica alla funzione di prendersi tutti gli altri props
*/
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("Token") !== null &&
      localStorage.getItem("Token").length !== 0 ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

//Componente che renderizza la schermata di Login
class LoginForm extends React.Component {
  state = {
    /**
     * Dice all'applicazione in quale momento effettuare il redirect all'area protetta (<App />), e dipende dalla presenza o meno del token di
     * autenticazione. A login effettuato viene impostato a true, in modo da ailitare l'accesso all'applicazione
     */
    redirectToProtectedArea: false,
  };

  constructor(props) {
    super(props);
    this.showSuccess = this.showSuccess.bind(this);
    this.showInfo = this.showInfo.bind(this);
    this.showWarn = this.showWarn.bind(this);
    this.showError = this.showError.bind(this);
    this.clear = this.clear.bind(this);
  }

  showSuccess() {
    this.toast.show({
      severity: "success",
      summary: "Success Message",
      detail: "Message Content",
      life: 3000,
    });
  }

  showInfo() {
    this.toast.show({
      severity: "info",
      summary: "Info Message",
      detail: "Message Content",
      life: 3000,
    });
  }

  showWarn() {
    this.toast.show({
      severity: "warn",
      summary: "Warn Message",
      detail: "Message Content",
      life: 3000,
    });
  }

  showError(msg) {
    this.toast.show({
      severity: "error",
      summary: "Errore",
      detail: msg,
      life: 3000,
    });
  }
  clear() {
    this.toast.clear();
  }

  query = new URLSearchParams(this.props.location.search);
  token = this.query.get("status");

  /**
   * Viene effettuato un check del token. Se l'utente è già loggato, la richiesta va a buon fine e viene effettuato il redirect a /dashboard,
   * altrimenti l'utente deve effettuare di nuovo il login.
   */
  componentDidMount() {
    if (this.token === "auth") {
      this.onLoginAfterSAML();
    } else if (this.token === "logout") {
      this.onLoginAfterLogout();
    } else {
      Axios.get("/api/token/remaining")
        .then(() => {
          this.setState({
            redirectToProtectedArea: true,
          });
          this.props.history.push({
            pathname: "/dashboard",
            state: true,
          });
        })
        .catch((error) => {
          localStorage.setItem("Token", "");
          localStorage.setItem("Username", "");
          localStorage.setItem("Type", "");
        });
    }
  }
  loginWithSSO = () => {
    Axios.get("/saml/getIdp")
      .then((response) => {
        document.location.replace("/saml/login?idp=" + response.data.url);
        /* Axios.get('/saml/login',{ params: { idp: response.data.url } }).then((response2) =>
      {
        document.getElementById("root").innerHTML = response2.data;
        console.log(response2)
        document.location.replace(response2)
      })*/
      })
      .catch((error) => {
        //TODO
        alert("SSO Non Attivo o non disponibile");
      });
  };
  onLoginAfterLogout = () => {
    Axios.get("/saml/deleteSession").then((response) => {});
  };
  onLoginAfterSAML = () => {
    //AUTENTICAZIONE SAML
    Axios.get("/saml/getJwtToken")
      .then((response) => {
        /**
         * In caso di autenticazione effettuata con successo, la response conterrà, in authorization
         * negli headers, il token di autenticazione da salvare, in quanto utilizzato per tutte le chiamate
         * autenticate.
         * In questo caso viene salvato direttamente nella configurazione di Axios, negli headers, in authorization (Axios.default.header.common['Authorization']).
         * Tutte le successive chiamate, dal login in poi, saranno autenticate con il token ricevuto in questa response.
         */
        Axios.defaults.headers.common["Authorization"] = response.data.token;
        //Il token è salvato nel localStorage di modo che possa essere recuperato in caso di perdita.
        localStorage.setItem("Token", response.data.token);
        localStorage.setItem("Username", response.data.username);
        /**
         * In duration è contenuta la durata in millisecondi del token appena ricevuto dal Back End, in modo da poter
         * avviare un timer che permetta di capire il momento in cui scade (o sta per scadere). Vedere in <App /> il metodo
         * onTimerStart() per maggiori dettagli sul funzionamento del timer e sulle azioni in caso di scadenza (o quasi scadenza).
         */
        localStorage.setItem("tokenDuration", response.data.Duration);
        localStorage.setItem("Type", "SAML");

        Axios.get("/api/getInfoSamlUser").then((response2) => {
          localStorage.setItem("userLogged", JSON.stringify(response2.data));
          this.setState({
            redirectToProtectedArea: true,
          });
        });

        /** Effettuato il login, viene lanciato l'avviso di libero accesso all'area protetta */
        /* this.setState({
              redirectToProtectedArea: true,
            }) */
      })
      .catch((error) => {
        if (error.response && error.response.status === 503)
          this.props.history.push("/loginserviceunavailable");
      });
  };
  //Metodo chiamato in caso di submit del login
  onLoginSubmit = (loginEvent) => {
    loginEvent.preventDefault();
    /**
     * Metodo di ant design, del componente Form. Permette di validare i campi del login form:
     * - err: booleano che dice se c'è un errore o meno nei campi del form
     * - authParams: parametri del form, in formato json
     */
    this.props.form.validateFields((err, authParams) => {
      //In caso di dati corretti nel form
      if (!err) {
        /**
         * Set del baseURL a cui axios deve mandare le chiamate. Teoricamente non
         * necessario in produzione.
         */
        /* Axios.defaults.baseURL = '/zarathustra';  */
        /**
         * Chiamata di autenticazione. I parametri sono:
         * - username
         * - password
         */

        Axios.post(
          "/api/authenticate",
          {
            /*Ignora i parametri del body*/
          },
          {
            headers: authParams,
          }
        )
          .then((response) => {
            /**
             * In caso di autenticazione effettuata con successo, la response conterrà, in authorization
             * negli headers, il token di autenticazione da salvare, in quanto utilizzato per tutte le chiamate
             * autenticate.
             * In questo caso viene salvato direttamente nella configurazione di Axios, negli headers, in authorization (Axios.default.header.common['Authorization']).
             * Tutte le successive chiamate, dal login in poi, saranno autenticate con il token ricevuto in questa response.
             */
            Axios.defaults.headers.common["Authorization"] =
              response.headers.authorization;
            Axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
            //Il token è salvato nel localStorage di modo che possa essere recuperato in caso di perdita.
            localStorage.setItem("Token", response.headers.authorization);
            localStorage.setItem("Username", response.data.Username);
            /**
             * In duration è contenuta la durata in millisecondi del token appena ricevuto dal Back End, in modo da poter
             * avviare un timer che permetta di capire il momento in cui scade (o sta per scadere). Vedere in <App /> il metodo
             * onTimerStart() per maggiori dettagli sul funzionamento del timer e sulle azioni in caso di scadenza (o quasi scadenza).
             */
            localStorage.setItem("tokenDuration", response.data.Duration);
            localStorage.setItem("Type", "Form");
            /** Effettuato il login, viene lanciato l'avviso di libero accesso all'area protetta */
            Axios.get("/api/getInfoUser").then((response2) => {
              localStorage.setItem(
                "userLogged",
                JSON.stringify(response2.data)
              );
              this.setState({
                redirectToProtectedArea: true,
              });
            });
            this.setState({
              redirectToProtectedArea: true,
              isLoggedSaml: false,
            });
          })
          .catch((error) => {
            if (error.response && error.response.status === 503)
              this.props.history.push("/loginserviceunavailable");
            else if (error.response && error.response.status === 401)
              this.showError("Credenziali non valide o non attive");
          });
      }
    });
  };

  render() {
    /**
     * E' un metodo del componente Form di ant design che permette di definire come gestire ogni campo.
     * Nel nostro caso è utilizzato nel seguente modo:
     * {getFieldDecorator('username', {
                          rules: [{ required: true, message: <Translate content='login.usernameErrorMessage' /> }],
                        })(
                          <Input
                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder={usernamePlaceholder}
                            size='large'
                          />,
                        )}
     * - 'username' è l'id con cui viene riconosciuto quel field
     * - rules: permette di specificare tutte le regole che si vuole che il campo abbia. In questo caso required, per indicare
     * che il campo non può essere vuoto, e message, che invece specifica il messaggio che appare in caso di errore (il dato non è stato inserito).
     * Infine c'è il componente che il campo del form dovrà renderizzare, in questo caso un <Input /> (sempre di ant design)
    */
    const { getFieldDecorator } = this.props.form;

    /**
     * In caso di token non nullo, viene effettuato il redirect istantaneo alla pagina di dashboard, in quanto significa che il login è stato
     * effettuato con successo e che l'utente è già autenticato. Qui è presente il check di "redirectoToProtectedArea" specificato sopra:
     * - è true se l'utente ha effettuato l'autenticazione
     * - è false se l'utente non è stato autenticato o se i dati di accesso sono errati
     */
    if (
      localStorage.getItem("Token") !== null &&
      localStorage.getItem("Token").length !== 0 &&
      this.state.redirectToProtectedArea
    ) {
      return <Redirect to="/dashboard" />;
    }

    /**
     * Variabili contenenti il testo (nella lingua selezionata) dei placeholder dei campi del login. Il campo non accetta
     * l'oggetto <Translate /> utilizzato per le traduzioni, counterpart.translate('nomeTraduzioneNelJSON') è il metodo
     * alternativo per permette comunque l'utilizzo delle traduzioni anche in casi del genere, salvando il testo in variabili.
     * Non è quindi necessario utilizzare il componente <Translate />.
     */
    const usernamePlaceholder = counterpart.translate(
      "login.usernamePlaceholder"
    );
    const passwordPlaceholder = counterpart.translate(
      "login.passwordPlaceholder"
    );

    return (
      <div className="login-div">
        <Toast ref={(el) => (this.toast = el)} />
        <Row className="login-div">
          <Col span={14} className="login-div">
            <div className="login-background_image"></div>
          </Col>
          <Col span={10} className="login-div">
            <Row type="flex" align="middle" className="login-div">
              <Col span={24} style={{ marginBottom: 100 }}>
                <Row
                  type="flex"
                  justify="center"
                  className="login-div"
                  gutter={[0, 40]}
                >
                  <Col span={5}>
                    <img
                      src={logoDarkZarathustra}
                      alt="Zarathustra"
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="center"
                  className="login-div"
                  gutter={[0, 40]}
                >
                  <Col xl={{ span: 10 }} style={{ textAlign: "center" }}>
                    <Text type="secondary">
                      <Translate content="login.welcomeMessage" />
                    </Text>
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="center"
                  className="login-div"
                  gutter={[0, 40]}
                >
                  <Col xl={{ span: 12 }}>
                    <Form onSubmit={this.onLoginSubmit}>
                      <Form.Item>
                        {getFieldDecorator("username", {
                          rules: [
                            {
                              required: true,
                              message: (
                                <Translate content="login.usernameErrorMessage" />
                              ),
                            },
                          ],
                        })(
                          <Input
                            prefix={
                              <Icon
                                type="user"
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            placeholder={usernamePlaceholder}
                            size="large"
                          />
                        )}
                      </Form.Item>
                      <Form.Item>
                        {getFieldDecorator("password", {
                          rules: [
                            {
                              required: true,
                              message: (
                                <Translate content="login.passwordErrorMessage" />
                              ),
                            },
                          ],
                        })(
                          <Input.Password
                            prefix={
                              <Icon
                                type="lock"
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            size="large"
                            placeholder={passwordPlaceholder}
                          />
                        )}
                      </Form.Item>
                      <Form.Item style={{ textAlign: "center" }}>
                        <Button
                          type="primary"
                          size="large"
                          shape="round"
                          htmlType="submit"
                          className="login-form-button"
                        >
                          <Translate content="login.loginButtonText" />
                        </Button>
                      </Form.Item>
                      <Form.Item style={{ textAlign: "center" }}>
                        <Button
                          onClick={this.loginWithSSO}
                          type="secondary"
                          size="large"
                          shape="round"
                          htmlType="button"
                          className="login-form-button"
                        >
                          <Icon type="login" />
                          <Translate content="login.loginButtonWithSSOText" />
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
/**
 * Il Form.create({name: 'login_form' })(LoginForm) è sempre un metodo del componente Form di ant design, che permette di
 * prendere il form, dargli un id ('login-form') e di indicare qual'è il componente in cui è contenuto (LoginForm). Viene così
 * creato un nuovo componente (ZarathustraLoginForm) che verrà poi utilizzato per visualizzare la schermata di Login.
 */
const ZarathustraLoginForm = Form.create({ name: "login_form" })(LoginForm);

/**
 * Router più esterno permette di distinguere tra il login e il resto dell'applicazione. L'applicazione è contenuta in una
 * PrivateRoute, quindi l'utente dovrà passare per forza per il Login prima di poter visualizzare tutto il resto dell'app.
 */
ReactDOM.render(
  <Router>
    <Route path="/login" component={ZarathustraLoginForm} />

    <PrivateRoute path="/" component={App} />
    <Route path={"/forbiddenaccess"} component={() => <Error403 />} />
    <Route path={"/loginserviceunavailable"} component={() => <Error503 />} />
  </Router>,
  document.querySelector("#root")
);
