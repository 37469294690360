import React from "react";
//Componenti ant design utilizzati
import { Card, Badge, Tooltip, Empty } from "antd";

//import Translate from 'react-translate-component';

//Componente Meta di Ant Design, fa parte della libreria Card 
const { Meta } = Card;

/**
 * Utilizza i props:
 * - isSoftSkill: booleano che indica se la card ha il compito di mostrare le soft o le hard skills. Soft Skills se true, 
 *   Hard se false.
 * - skills: array contenente tutte le skill da mostrare nella card.
 * - isSingleCandidate: booleano che indica se questo componente è caricato per il singolo candidato (true) o per la singola
 *   job description (false), in modo da visualizzare il tooltip con le percentuali solo in caso di singola job description
*/
export default function HardSoftSkillsCard(props) {
  let skillsBadges = [];
  props.skills
    ? (props.skills.length !== 0 && props.skills[0].name.length !== 0
      ? props.skills.forEach(element => {
        if (element.name.length !== 0) {
          if (!props.isSingleCandidate) {
            skillsBadges.push(
              <Tooltip title={`${element.duration ? element.duration : 0}%`}>
                <Badge
                  key={element.name}
                  count={element.name.length > 30 ? element.name.slice(0, 31)+'...' : element.name}
                  style={{
                    backgroundColor: "#E0E0E0",
                    color: "#4D4F5C",
                    fontSize: 14,
                    height: 22,
                    marginRight: 5,
                    marginTop: 5
                  }}
                  title={element.name}
                />
              </Tooltip>
            );
          } else {
            skillsBadges.push(
              <Tooltip
                title={
                  `${localStorage.getItem("Language") === 'it'
                    ? element.duration
                      ? element.duration !== '0'
                        ? element.duration !== '1' ? `${element.duration} anni` : `${element.duration} anno`
                        : '< un anno'
                      : 'Assente'
                    : element.duration
                      ? element.duration !== '0'
                        ? element.duration !== '1' ? `${element.duration} years` : `${element.duration} year`
                        : '< 1 year'
                      : 'Absent'
                  }`
                }
              >
                <Badge
                  key={element.name}
                  count={element.description.length > 30 ? element.description.slice(0, 31)+'...' : element.description}
                  style={{
                    backgroundColor: "#E0E0E0",
                    color: "#4D4F5C",
                    fontSize: 14,
                    height: 22,
                    marginRight: 5,
                    marginTop: 5
                  }}
                  title={element.description}
                />
              </Tooltip>
            );
          }
        }
      })
      : skillsBadges.push(<Empty description={`${localStorage.getItem("Language") === 'it'
                                                  ? props.isSoftSkill ? 'Soft skill mancanti' : 'Hard skill mancanti'
                                                  : props.isSoftSkill ? 'No soft skill present' : 'No hard skill present'}`}
                                image={Empty.PRESENTED_IMAGE_SIMPLE} />)
    )
    : skillsBadges.push(<Empty description={`${localStorage.getItem("Language") === 'it'
                                                  ? props.isSoftSkill ? 'Soft skill mancanti' : 'Hard skill mancanti'
                                                  : props.isSoftSkill ? 'No soft skill present' : 'No hard skill present'}`}
                              image={Empty.PRESENTED_IMAGE_SIMPLE} />)
  return (
    <div style={{ paddingTop: 24 }}>
      <Card id="personalCard">
        <Meta title={props.isSoftSkill ? "Soft Skills" : "Hard Skills"} />
        <div style={{ marginTop: 13, textAlign: "center" }}>
          {
            skillsBadges.map((element) => {
              return element;
            })
          }
        </div>
      </Card>
    </div>
  );
}
