import React from "react";
//Utilizzato per poter ricavare i dati dall'URL
import {withRouter} from "react-router";
//Componenti ant design utilizzati
import {Alert, Button, Card, Col, Layout, Modal, Progress, Result, Row, Spin, Tooltip, Typography,} from "antd";
//Axios per le chiamate alle API Back End
import Axios from "axios";
import Translate from "react-translate-component";
//Componenti Custom creati da noi
import PrevPageBar from "../../topbars/PrevPageBar";
import EducationLevelCard from "../../Cards/EducationLevelCard";
import LanguagesCard from "../../Cards/LanguagesCard";
import YearsOfExperienceCard from "../../Cards/YearsOfExperienceCard";
import JobDescriptionCard from "../../Cards/JobDescriptionCard";
import HardSoftSkillsCard from "../../Cards/HardSoftSkillsCard";
import CandidatePerJobDescription from "../candidates/CandidatePerJobDescription";

import {FilterMatchMode} from "primereact/api";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Slider} from "primereact/slider";
import {Dropdown} from "primereact/dropdown";

import {errorRouter} from "../../errors/ErrorRouter";
import Moment from "react-moment";
import {Toast} from "primereact/toast";
import CityCard from "../../Cards/CityCard";
import counterpart from "counterpart";
import {MultiSelect} from "primereact/multiselect";

const {Title, Text} = Typography;

//Componenti di ant design presi dal componente <Layout />
const {Content, Sider} = Layout;

let application = [];
let multiUtility = [];
let disability = [];
let candidateState = [];
let geographicAreas = [];
let regions = [];
let provinces = [];

let applied;
let notApplied;
let detected;
let notDetected;
let unseen;
let inProgress;
let suitable;
let notSuitable;
let all;

/**
 * Prende come props:
 * - jobDescriptionID: contiene l'id della job description selezionata da <JobDescriptions />
 */
class SingleJobDescription extends React.Component {
    nameStateJob = `dt-singleJobDescription-state-session-${this.props.jobDescriptionID}`;

    state = {
        /**
         * Contiene il link alla pagina che ha chiamato questo componente, in modo da poter tornare,
         * con il bottone indietro, alla pagina precedente
         */
        isLoadingCsv: false,

        prevPage: "/jobdescriptions",
        //Contiene l'oggetto della job description che si deve visualizzare a schermo
        jobDescription: undefined,
        //Larghezza del sider di ant design, per definire il lato sinistro del layout
        siderWidth: 290,
        //Permette di capire se la tabella con tutti i candidati rispetto alla job descriptions deve essere sostituita dalla visualizzazione del singolo candidato
        isCandidateVisible: false,
        //Oggetto contenente i dati del candidato selezionato nella tabella con tutti i candidati in relazione alla job description
        selectedCandidate: {},
        //Booleano che indica se i dati sono stati caricati o meno, in modo da poter gestire uno spinner
        isLoadingData: true,
        //I due array successivi contengono soft e hard skills della job description visualizzata
        softSkills: [],
        hardSkills: [],
        languages: [],
        //Array con tutti i cv relazionati alla job description specifica visualizzata in questo componente
        cvJobs: [],
        //Totale dei curriculum relazionati alla job description
        totalData: 0,
        //Errore 403: true se l'utente non ha accesso alla job description
        error403: false,
        //Pagination della tabella: total contiene il totale di elementi, defaultPageSize il numero di elementi per pagina.
        pagination: {
            total: 0,
            defaultPageSize: 9,
            currentFirst: 0,
        },
        sortField: null,
        sortOrder: null,
        filters: {
            candidate: {
                operator: "and",
                constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}],
            },
            "_embedded.cv.eta": {
                operator: "and",
                constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}],
            },
            "_embedded.cv.disability": {
                operator: "and",
                constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}],
            },
            aziendaMultiUtility: {
                operator: "and",
                constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}],
            },
            "_embedded.cv.nomeComune": {
                operator: "and",
                constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}],
            },
            statoCandidato: {
                operator: "and",
                constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}],
            },
            score: {
                operator: "and",
                constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}],
            },
            candidateName: {
                operator: "and",
                constraints: [{value: null, matchMode: "startsWith"}],
            },
            zcreationDate: {
                operator: "and",
                constraints: [{value: null, matchMode: "startsWith"}],
            },
        },
        sorter: null,

        etaMin: null,
        etaMinInput: null,
        etaMax: null,
        etaMaxInput: null,

        selectedGeographicArea: [],
        selectedRegion: [],
        selectedProvince: [],
        selectedDistance: null,

        isSetFilterCategoria: false,
        valueDisability: false,
        isSetFilterMulti: false,
        valueMulti: false,

        isSorted: false,
        isAscendAge: 0,
        url: null,
        isModalVisible: false,
        elencoFile: [],

        loading: true,
    };

    constructor(props) {
        super(props);

        applied = localStorage.getItem("Language") === "it" ? "Applicato" : "Applied";
        notApplied =
            localStorage.getItem("Language") === "it" ? "Non Applicato" : "Not Applied";
        detected = localStorage.getItem("Language") === "it" ? "Rilevata" : "Detected";
        notDetected =
            localStorage.getItem("Language") === "it" ? "Non Rilevata" : "Not Detected";
        unseen = localStorage.getItem("Language") === "it" ? "Non Visionato" : "Unseen";
        inProgress =
            localStorage.getItem("Language") === "it" ? "In Progresso" : "In Progress";
        suitable = localStorage.getItem("Language") === "it" ? "Idoneo" : "Suitable";
        notSuitable =
            localStorage.getItem("Language") === "it" ? "Non Idoneo" : "Not Suitable";
        all = localStorage.getItem("Language") === "it" ? "Tutti" : "All";

        Axios.get('/api/getProvince')
            .then((response) => {
                provinces = response.data;
            })

        application = [
            {label: applied, value: 1},
            {label: notApplied, value: 0},
            {label: all, value: null},
        ];
        multiUtility = [
            {label: detected, value: 1},
            {label: notDetected, value: 0},
            {label: all, value: null},
        ];
        disability = [
            {label: detected, value: 1},
            {label: notDetected, value: 0},
            {label: all, value: null},
        ];
        candidateState = [
            {label: unseen, value: 0},
            {label: inProgress, value: 1},
            {label: suitable, value: 2},
            {label: notSuitable, value: 3},
            {label: all, value: null},
        ];
        regions = [
            "Abruzzo",
            "Basilicata",
            "Calabria",
            "Campania",
            "Emilia Romagna",
            "Friuli-Venezia Giulia",
            "Lazio",
            "Liguria",
            "Lombardia",
            "Marche",
            "Molise",
            "Piemonte",
            "Puglia",
            "Sardegna",
            "Sicilia",
            "Toscana",
            "Trentino-Alto Adige",
            "Umbria",
            "Valle d'Aosta",
            "Veneto",
        ];
        geographicAreas = [
            "Nord",
            "Centro",
            "Sud",
        ]
    }

    //Vengono caricati tutti i dati della job description, tutti i cv correlati ad essa e tutte le sue hard e soft skills
    componentDidMount() {
        this.etaFilterTemplate = this.etaFilterTemplate.bind(this);
        //this.getStateTable();
        Axios.get(`/api/getCSVList/${this.props.jobDescriptionID}`)
            .then((response) => {
                this.setState({
                    elencoFile: response.data,
                });
            })
            .catch((error) => {
                console.log(error)
            });
        Axios.get(`/api/jobDescriptionLighter/${this.props.jobDescriptionID}`).then(
            (response) => {
                //this.getStateTable();
                Axios.get(
                    `/api/userpreference/jobConfiguration${this.props.jobDescriptionID}`
                )
                    .then(() => {
                        //let urlToCall = `/api/cvJob/search/findPagedFilteredSorted?idJob=${this.props.jobDescriptionID}&candidate=true&&sort=score,desc&sort=id,asc&size=9&page=0`;

                        this.setState({
                            error403: false,
                            jobDescription: response.data,
                        });
                        Axios.all([
                            //Axios.get(urlToCall),
                            Axios.get(`/api/job/${this.props.jobDescriptionID}/jobSkills`),
                        ])
                            .then((response) => {
                                this.getPagedFilteredCvJob(null, false, false);

                                let tempHardSkills = [];
                                let tempSoftSkills = [];
                                let tempLanguages = [];
                                response[0].data._embedded.jobSkills.forEach((jobSkill) => {
                                    if (jobSkill.skillTk) {
                                        if (jobSkill.skillTk.category === 1) {
                                            tempSoftSkills.push({
                                                id: jobSkill.skillTk.id,
                                                name: jobSkill.skillTk.descriptions,
                                                duration: jobSkill.weight,
                                                code_id: jobSkill.skillTk.code_id,
                                            });
                                        } else if (
                                            jobSkill.skillTk.category === 2 ||
                                            jobSkill.skillTk.category === 3
                                        ) {
                                            tempHardSkills.push({
                                                id: jobSkill.skillTk.id,
                                                name: jobSkill.skillTk.descriptions,
                                                duration: jobSkill.weight,
                                                code_id: jobSkill.skillTk.code_id,
                                            });
                                        } else if (jobSkill.skillTk.category === 4) {
                                            tempLanguages.push({
                                                id: jobSkill.skillTk.id,
                                                languageCodeDescription: jobSkill.skillTk.descriptions,
                                                proficiencyCodeDescription: jobSkill.weight,
                                                code_id: jobSkill.skillTk.languageCode,
                                            });
                                        }
                                    }
                                });
                                this.setState({
                                    hardSkills: tempHardSkills,
                                    softSkills: tempSoftSkills,
                                    languages: tempLanguages,
                                    isLoadingData: false,
                                });
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 403) {
                                this.setState(() => ({
                                    error403: true,
                                }));
                            } else {
                                errorRouter(error.response.status, this.props.history);
                            }
                        }
                    });
            }
        );
    }

    refreshJobDescriptionData = () => {
        Axios.get(`/api/jobDescriptionLighter/${this.props.jobDescriptionID}`)
            .then((response) => {
                this.setState({
                    error403: false,
                    jobDescription: response.data,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        this.setState(() => ({
                            error403: true,
                        }));
                    } else {
                        errorRouter(error.response.status, this.props.history);
                    }
                }
            });
    };

    /**
     * Il sider ha il breakpoint settato a 'lg'. Nel momento in cui la larghezza del dispositivo
     * scende sotto il 'lg' (da 'md' in giù), questo metodo viene lanciato, prendendo i parametri:
     * - collapsed: true se sotto large, altrimenti false
     * - type: responsive ad indicare che il collapse è dovuto al cambiamento di dimensioni
     * e la larghezza del sider
     * viene settata al 100%, in modo che il layout si adatti al dispositivo
     */
    onSiderCollapse = (collapsed, type) => {
        if (collapsed && type === "responsive") {
            this.setState({
                siderWidth: "100%",
            });
        } else if (!collapsed && type === "responsive") {
            this.setState({
                siderWidth: "290",
            });
        }
    };

    setVisibleModal = () => {
        this.setState({
            isModalVisible: true,
        });
    };

    handleOk = (e) => {
        e.stopPropagation();
        this.setState({
            isModalVisible: false,
        });
    };

    handleCancel = (e) => {
        e.stopPropagation();
        this.setState({
            isModalVisible: false,
        });
    };

    //Lanciato nel momento di click della row del candidato. Salva i suoi dati e lo rende visibile, sostituendo la tabella con i suoi dati
    onCandidateRowClick = (record) => {
        //this.saveStateTable();

        this.setState({
            selectedCandidate: record.data,
            isCandidateVisible: true,
        });
    };

    /**
     * Una volta aperto il singolo candidato rispetto alla job description, sostituendo quindi alla tabella con tutti i candidati il singolo
     * record, è possibile tornare alla visualizzazione della tabella con la frecca indietro. Cliccando la freccia, viene lanciato questo metodo, che
     * inverte di nuovo la visualizzazione: viene rimosso il singolo candidato, e visualizzata di nuovo la tabella
     */
    onCandidateViewBack = () => {
        this.setState({
            selectedCandidate: {},
            isCandidateVisible: false,
        });
        //this.getStateTable();
    };

    dowloadFile = (nameFile) => {
        var axiosconfig = {
            responseType: "arraybuffer",
            headers: {
                Accept: "application/csv",
            },
        };

        /**
         * Viene lanciato il download del file in base al nome del file indicato dal back end, con la configurazione soprastante, aprendo
         * una finestra di conferma del download con il nome del curriculum
         */
        Axios.get(
            `/api/csv/${this.props.jobDescriptionID}/${nameFile}`,
            axiosconfig
        ).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", nameFile);
            document.body.appendChild(link);
            link.click();
        });
    };

    onPrevPageClick = () => {
        //this.saveStateTable()
        this.props.history.goBack();
    };

    /* candidateFilterTemplate(options) {
      return <Dropdown value={options.value} options={application} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={this.candidateBodyTemplate} placeholder="Seleziona tipologia" className="p-column-filter" showClear />;
    } */

    candidateNameBodyTemplate(rowData) {
        return rowData.candidateName && rowData.candidateName.trim().length !== 0
            ? rowData.candidateName
            : "Nome non presente";
    }

    candidateBodyTemplate(rowData) {
        return rowData.candidate ? (
            <span
                className={`customer-badge status-applicato`}
                style={{display: "flex", justifyContent: "center"}}
            >
        {applied}
      </span>
        ) : (
            <span
                className={`customer-badge status-nonapplicato`}
                style={{display: "flex", justifyContent: "center"}}
            >
        {notApplied}
      </span>
        );
        /* return (rowData.candidate
            ? <Icon type="check" style={{ color: '#4CAF50' }} />
            : <Icon type="close" style={{ color: '#F44336' }} />) */
    }

    candidateFilterTemplate(options) {
        return (
            <Dropdown
                value={options.value}
                options={application}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                itemTemplate={this.candidateItemTemplate}
                placeholder={<Translate content="filters.selectType"/>}
                className="p-column-filter"
            />
        );
    }

    candidateItemTemplate = (option) => {
        return (
            <span
                className={`customer-badge status-${option.label
                    .replace(" ", "")
                    .toLowerCase()}`}
            >
        {option.label}
      </span>
        );
    };

    etaFilterTemplate(options) {
        return (
            <React.Fragment>
                <div style={{padding: "1rem 1rem 0rem 1rem"}}>
                    <Slider
                        value={options.value}
                        onChange={(e) => options.filterCallback(e.value)}
                        range
                        className="m-3"
                    ></Slider>
                    <div
                        className="flex align-items-center justify-content-between px-2"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <span>{options.value ? options.value[0] : 0}</span>
                        <span>{options.value ? options.value[1] : 100}</span>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    categoryTypeBodyTemplate(rowData) {
        return rowData._embedded.cv.disability
            ? rowData._embedded.cv.disability
            : "-";
    }

    categoryTypeFilterTemplate(options) {
        return (
            <Dropdown
                value={options.value}
                options={disability}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                itemTemplate={this.multiUtilityItemTemplate}
                placeholder={<Translate content="filters.selectPresence"/>}
                className="p-column-filter"
            />
        );
    }

    categoryTypeItemTemplate = (option) => {
        return (
            <span
                className={`customer-badge status-${option.label
                    .replace(" ", "")
                    .toLowerCase()}`}
            >
        {option.label}
      </span>
        );
    };

    multiUtilityBodyTemplate(rowData) {
        return rowData.aziendaMultiUtility === null ? (
            <i className="fa-regular fa-xmark iconsDelete"/>
        ) : (
            <i className="fa-regular fa-check iconsCheck"/>
        );
    }

    multiUtilityFilterTemplate(options) {
        return (
            <Dropdown
                value={options.value}
                options={multiUtility}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                itemTemplate={this.multiUtilityItemTemplate}
                placeholder={<Translate content="filters.selectPresence"/>}
                className="p-column-filter"
            />
        );
    }

    multiUtilityItemTemplate = (option) => {
        return (
            <span
                className={`customer-badge status-${String(option.label)
                    .replace(" ", "")
                    .toLowerCase()}`}
            >
        {option.label}
      </span>
        );
    };

    dateBodyTemplate(rowData) {
        return <Moment format="DD/MM/YYYY" date={rowData.zcreationDate}/>;
    }

    distanceBodyTemplate(rowData) {
        return rowData._embedded.cv.nomeComune
    }

    onCleanProvince() {
        this.setState({
            selectedGeographicArea: [],
            selectedRegion: [],
            selectedProvince: []
        })
    }

    distanceFilterTemplate = () => {
        return (
            <React.Fragment>
                <MultiSelect value={this.state.selectedGeographicArea} options={geographicAreas}
                             onChange={(e) => this.setState({selectedGeographicArea: e.value})}
                             placeholder={<Translate content="filters.selectGeographicArea"/>} display="chip"
                             style={{marginBottom: "2rem", maxWidth: "20rem", minWidth: "20rem"}}
                             disabled={this.state.selectedRegion.length !== 0 || this.state.selectedProvince.length !== 0}/>

                <MultiSelect value={this.state.selectedRegion} options={regions}
                             onChange={(e) => this.setState({selectedRegion: e.value})}
                             placeholder={<Translate content="filters.selectRegion"/>} display="chip"
                             style={{marginBottom: "2rem", maxWidth: "20rem", minWidth: "20rem"}}
                             disabled={this.state.selectedGeographicArea.length !== 0 || this.state.selectedProvince.length !== 0}/>

                <MultiSelect value={this.state.selectedProvince} options={provinces}
                             onChange={(e) => this.setState({selectedProvince: e.value})}
                             placeholder={<Translate content="filters.selectProvince"/>} display="chip"
                             style={{maxWidth: "20rem", minWidth: "20rem"}}
                             disabled={this.state.selectedRegion.length !== 0 || this.state.selectedGeographicArea.length !== 0}/>
            </React.Fragment>
        );
    };

    statoBodyTemplate(rowData) {
        console.log(rowData);
        return rowData.statoCandidato === null || rowData.statoCandidato === 0 ? (
            <span
                className={`customer-badge status-unseen`}
                style={{display: "flex", justifyContent: "center"}}
            >
        {unseen}
      </span>
        ) : rowData.statoCandidato === 1 ? (
            <span
                className={`customer-badge status-inprogress`}
                style={{display: "flex", justifyContent: "center"}}
            >
        {inProgress}
      </span>
        ) : rowData.statoCandidato === 2 && rowData.esitoCandidato === 1 ? (
            <span
                className={`customer-badge status-suitable`}
                style={{display: "flex", justifyContent: "center"}}
            >
        {suitable}
      </span>
        ) : rowData.statoCandidato === 2 && rowData.esitoCandidato === 2 ? (
            <span
                className={`customer-badge status-notsuitable`}
                style={{display: "flex", justifyContent: "center"}}
            >
        {notSuitable}
      </span>
        ) : rowData.statoCandidato === 2 && rowData.esitoCandidato === 49 ? (
            <span
                className={`customer-badge status-suitable`}
                style={{display: "flex", justifyContent: "center"}}
            >
        {suitable}
      </span>
        ) : rowData.statoCandidato === 2 && rowData.esitoCandidato === 48 ? (
            <span
                className={`customer-badge status-notsuitable`}
                style={{display: "flex", justifyContent: "center"}}
            >
        {notSuitable}
      </span>
        )  : (
            <span
                className={`customer-badge status-notsuitable`}
                style={{display: "flex", justifyContent: "center"}}
            >
        {notSuitable}
      </span>
        );
    }

    statoFilterTemplate(options) {
        return (
            <Dropdown
                value={options.value}
                options={candidateState}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                itemTemplate={this.statoItemTemplate}
                placeholder={<Translate content="filters.selectPresence"/>}
                className="p-column-filter"
            />
        );
    }

    statoItemTemplate = (option) => {
        return (
            <span
                className={`customer-badge status-${String(option.label)
                    .replace(" ", "")
                    .toLowerCase()}`}
            >
        {option.label}
      </span>
        );
    };

    scoreBodyTemplate(rowData) {
        return (
            <span>
        <Progress steps={3} percent={rowData.score} size="medium"/>
      </span>
        );
    }

    scoreFilterTemplate(options) {
        return (
            <React.Fragment>
                <div style={{padding: "1rem 1rem 0rem 1rem"}}>
                    <Slider
                        value={options.value}
                        onChange={(e) => options.filterCallback(e.value)}
                        range
                        className="m-3"
                    ></Slider>
                    <div
                        className="flex align-items-center justify-content-between px-2"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <span>{options.value ? options.value[0] : 0}</span>
                        <span>{options.value ? options.value[1] : 100}</span>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    getPagedFilteredCvJob(event, changePage, sortCall, filterTotal) {
        this.setState({
            loading: true,
        });

        let geographicAreasToSend = '';
        let regionsToSend = '';
        let provincesToSend = '';

        this.state.selectedGeographicArea.forEach((area, index, geographicAreas) => {
            if (index === geographicAreas.length - 1) {
                geographicAreasToSend += area
            } else {
                geographicAreasToSend += area + ","
            }
        })
        this.state.selectedRegion.forEach((region, index, regions) => {
            if (index === regions.length - 1) {
                regionsToSend += region
            } else {
                regionsToSend += region + ","
            }
        })
        this.state.selectedProvince.forEach((province, index, provinces) => {
            if (index === provinces.length - 1) {
                provincesToSend += province
            } else {
                provincesToSend += province + ","
            }
        })

        const store = sessionStorage.getItem(this.nameStateJob);
        let local = JSON.parse(store);
        if (local === null) {
            local = this.setInitStore();
        }
        let sortQuery = "";
        let arrayFilters = [];
        let url = "";

        if (filterTotal) {
            local.filters = event.filters;
        }

        if (event != null) {
            if (sortCall) {
                local.sortOrder = event.sortOrder;
                local.sortField = event.sortField;
            }
            if (event.sortField != null) {
                local.sortField = event.sortField;
                if (event.sortOrder != null) {
                    local.sortOrder = event.sortOrder;
                    if (local.sortField !== undefined) {
                        sortQuery += `&sort=${local.sortField
                            .replace("_embedded", "")
                            .replace(".cv", "")
                            .replace(".", "")},${local.sortOrder === 1 ? "asc" : "desc"}`;
                    }
                }
            } else if (event.field != null) {
                local.filters[event.field].constraints = event.constraints.constraints;
            }
            if (event.first != null && changePage) {
                local.first = event.first;
            }
        }
        if (
            local != null &&
            local.sortField !== undefined &&
            local.sortField !== null
        ) {
            sortQuery += `&sort=${local.sortField
                .replace("_embedded", "")
                .replace(".cv", "")
                .replace(".", "")},${local.sortOrder === 1 ? "asc" : "desc"}`;
        }

        sortQuery += `&sort=score,desc&sort=id,asc`;
        let currentPage = 0;

        if ((event != null && false) || local == null) {
            arrayFilters = local == null ? [] : {...local.filters};
            if (this.state.selectedGeographicArea.length !== 0) {
                url = `/api/cvJob/search/findPagedFilteredSortedByAreaGeografica?idJob=${this.props.jobDescriptionID}`;
            } else if (this.state.selectedRegion.length !== 0) {
                url = `/api/cvJob/search/findPagedFilteredSortedByNomeRegione?idJob=${this.props.jobDescriptionID}`;
            } else if (this.state.selectedProvince.length !== 0) {
                url = `/api/cvJob/search/findPagedFilteredSortedByNomeProvincia?idJob=${this.props.jobDescriptionID}`;
            } else {
                url = `/api/cvJob/search/findPagedFilteredSorted?idJob=${this.props.jobDescriptionID}`;
            }
            local = {};
            local.sortField = "";
            local.sortOrder = "";
            local.first = 0;
        } else {
            if (local.filters.candidate != null) {
                arrayFilters = {...local.filters};
                if (local.filters.candidate.constraints[0].value == null) {
                    local.filters.candidate.constraints[0].value = null;
                } else if (local.filters.candidate.constraints[0].value != null) {
                    /* if(local.filters.candidate.constraints[0].value === "Tutti" || local.filters.candidate.constraints[0].value === undefined) {
                      local.filters.candidate.constraints[0].value = null
                    } else if(local.filters.candidate.constraints[0].value === "Applicato" ) {
                      local.filters.candidate.constraints[0].value = 1
                    } else if(local.filters.candidate.constraints[0].value === "Non Applicato") {
                      local.filters.candidate.constraints[0].value = 0
                    }*/
                }
            }
            if (this.state.selectedGeographicArea.length !== 0) {
                url =
                    `/api/cvJob/search/findPagedFilteredSortedByAreaGeografica?idJob=${this.props.jobDescriptionID}` +
                    `&candidate=${
                        local.filters.candidate.constraints[0].value === null
                            ? ""
                            : local.filters.candidate.constraints[0].value
                    }` +
                    `&disability=${
                        local.filters["_embedded.cv.disability"].constraints[0].value === null
                            ? ""
                            : local.filters["_embedded.cv.disability"].constraints[0].value
                    }` +
                    `&multiUtility=${
                        local.filters.aziendaMultiUtility.constraints[0].value === null
                            ? ""
                            : local.filters.aziendaMultiUtility.constraints[0].value
                    }` +
                    (local.filters["_embedded.cv.eta"].constraints[0].value === null
                        ? ""
                        : `&etaMin=${local.filters["_embedded.cv.eta"].constraints[0].value[0]}`) +
                    (local.filters["_embedded.cv.eta"].constraints[0].value === null
                        ? ""
                        : `&etaMax=${local.filters["_embedded.cv.eta"].constraints[0].value[1]}`) +
                    (local.filters["score"].constraints[0].value === null
                        ? ""
                        : `&scoreMin=${local.filters["score"].constraints[0].value[0]}`) +
                    (local.filters["score"].constraints[0].value === null
                        ? ""
                        : `&scoreMax=${local.filters["score"].constraints[0].value[1]}`) +
                    `&statoCandidato=${
                        local.filters.statoCandidato.constraints[0].value === 0 ||
                        local.filters.statoCandidato.constraints[0].value === 1
                            ? local.filters.statoCandidato.constraints[0].value
                            : ""
                    }` +
                    `&esitoCandidato=${
                        local.filters.statoCandidato.constraints[0].value === 2
                            ? 1
                            : local.filters.statoCandidato.constraints[0].value === 3
                                ? 0
                                : ""
                    }` +
                    `&areaGeografica=${
                        geographicAreasToSend
                    }`
            } else if (this.state.selectedRegion.length !== 0) {
                url =
                    `/api/cvJob/search/findPagedFilteredSortedByNomeRegione?idJob=${this.props.jobDescriptionID}` +
                    `&candidate=${
                        local.filters.candidate.constraints[0].value === null
                            ? ""
                            : local.filters.candidate.constraints[0].value
                    }` +
                    `&disability=${
                        local.filters["_embedded.cv.disability"].constraints[0].value === null
                            ? ""
                            : local.filters["_embedded.cv.disability"].constraints[0].value
                    }` +
                    `&multiUtility=${
                        local.filters.aziendaMultiUtility.constraints[0].value === null
                            ? ""
                            : local.filters.aziendaMultiUtility.constraints[0].value
                    }` +
                    (local.filters["_embedded.cv.eta"].constraints[0].value === null
                        ? ""
                        : `&etaMin=${local.filters["_embedded.cv.eta"].constraints[0].value[0]}`) +
                    (local.filters["_embedded.cv.eta"].constraints[0].value === null
                        ? ""
                        : `&etaMax=${local.filters["_embedded.cv.eta"].constraints[0].value[1]}`) +
                    (local.filters["score"].constraints[0].value === null
                        ? ""
                        : `&scoreMin=${local.filters["score"].constraints[0].value[0]}`) +
                    (local.filters["score"].constraints[0].value === null
                        ? ""
                        : `&scoreMax=${local.filters["score"].constraints[0].value[1]}`) +
                    `&statoCandidato=${
                        local.filters.statoCandidato.constraints[0].value === 0 ||
                        local.filters.statoCandidato.constraints[0].value === 1
                            ? local.filters.statoCandidato.constraints[0].value
                            : ""
                    }` +
                    `&esitoCandidato=${
                        local.filters.statoCandidato.constraints[0].value === 2
                            ? 1
                            : local.filters.statoCandidato.constraints[0].value === 3
                                ? 0
                                : ""
                    }` +
                    `&nomeRegione=${
                        regionsToSend
                    }`;
            } else if (this.state.selectedProvince.length !== 0) {
                url =
                    `/api/cvJob/search/findPagedFilteredSortedByNomeProvincia?idJob=${this.props.jobDescriptionID}` +
                    `&candidate=${
                        local.filters.candidate.constraints[0].value === null
                            ? ""
                            : local.filters.candidate.constraints[0].value
                    }` +
                    `&disability=${
                        local.filters["_embedded.cv.disability"].constraints[0].value === null
                            ? ""
                            : local.filters["_embedded.cv.disability"].constraints[0].value
                    }` +
                    `&multiUtility=${
                        local.filters.aziendaMultiUtility.constraints[0].value === null
                            ? ""
                            : local.filters.aziendaMultiUtility.constraints[0].value
                    }` +
                    (local.filters["_embedded.cv.eta"].constraints[0].value === null
                        ? ""
                        : `&etaMin=${local.filters["_embedded.cv.eta"].constraints[0].value[0]}`) +
                    (local.filters["_embedded.cv.eta"].constraints[0].value === null
                        ? ""
                        : `&etaMax=${local.filters["_embedded.cv.eta"].constraints[0].value[1]}`) +
                    (local.filters["score"].constraints[0].value === null
                        ? ""
                        : `&scoreMin=${local.filters["score"].constraints[0].value[0]}`) +
                    (local.filters["score"].constraints[0].value === null
                        ? ""
                        : `&scoreMax=${local.filters["score"].constraints[0].value[1]}`) +
                    `&statoCandidato=${
                        local.filters.statoCandidato.constraints[0].value === 0 ||
                        local.filters.statoCandidato.constraints[0].value === 1
                            ? local.filters.statoCandidato.constraints[0].value
                            : ""
                    }` +
                    `&esitoCandidato=${
                        local.filters.statoCandidato.constraints[0].value === 2
                            ? 1
                            : local.filters.statoCandidato.constraints[0].value === 3
                                ? 0
                                : ""
                    }` +
                    `&nomeProvincia=${
                        provincesToSend
                    }`;
            } else {
                url =
                    `/api/cvJob/search/findPagedFilteredSorted?idJob=${this.props.jobDescriptionID}` +
                    `&candidate=${
                        local.filters.candidate.constraints[0].value === null
                            ? ""
                            : local.filters.candidate.constraints[0].value
                    }` +
                    `&disability=${
                        local.filters["_embedded.cv.disability"].constraints[0].value === null
                            ? ""
                            : local.filters["_embedded.cv.disability"].constraints[0].value
                    }` +
                    `&multiUtility=${
                        local.filters.aziendaMultiUtility.constraints[0].value === null
                            ? ""
                            : local.filters.aziendaMultiUtility.constraints[0].value
                    }` +
                    (local.filters["_embedded.cv.eta"].constraints[0].value === null
                        ? ""
                        : `&etaMin=${local.filters["_embedded.cv.eta"].constraints[0].value[0]}`) +
                    (local.filters["_embedded.cv.eta"].constraints[0].value === null
                        ? ""
                        : `&etaMax=${local.filters["_embedded.cv.eta"].constraints[0].value[1]}`) +
                    (local.filters["score"].constraints[0].value === null
                        ? ""
                        : `&scoreMin=${local.filters["score"].constraints[0].value[0]}`) +
                    (local.filters["score"].constraints[0].value === null
                        ? ""
                        : `&scoreMax=${local.filters["score"].constraints[0].value[1]}`) +
                    `&statoCandidato=${
                        local.filters.statoCandidato.constraints[0].value === 0 ||
                        local.filters.statoCandidato.constraints[0].value === 1
                            ? local.filters.statoCandidato.constraints[0].value
                            : ""
                    }` +
                    `&esitoCandidato=${
                        local.filters.statoCandidato.constraints[0].value === 2
                            ? 1
                            : local.filters.statoCandidato.constraints[0].value === 3
                                ? 0
                                : ""
                    }` +
                    `&areaGeografica=${
                        geographicAreasToSend
                    }` +
                    `&nomeRegione=${
                        regionsToSend
                    }` +
                    `&nomeProvincia=${
                        provincesToSend
                    }`;
            }
            currentPage = local.first / local.rows;
        }

        url += sortQuery + `&size=9&page=${currentPage}`;
        Axios.get(url)
            .then((response) => {
                if (
                    response.data.page.totalElements !== 0 &&
                    response.data._embedded.cvJobs.length === 0
                ) {
                    let url = "";
                    if (this.state.selectedGeographicArea.length !== 0) {
                        url =
                            `/api/cvJob/search/findPagedFilteredSortedByAreaGeografica?idJob=${this.props.jobDescriptionID}` +
                            `&candidate=${
                                local.filters.candidate.constraints[0].value === null
                                    ? ""
                                    : local.filters.candidate.constraints[0].value
                            }` +
                            `&disability=${
                                local.filters["_embedded.cv.disability"].constraints[0].value === null
                                    ? ""
                                    : local.filters["_embedded.cv.disability"].constraints[0].value
                            }` +
                            `&multiUtility=${
                                local.filters.aziendaMultiUtility.constraints[0].value === null
                                    ? ""
                                    : local.filters.aziendaMultiUtility.constraints[0].value
                            }` +
                            (local.filters["_embedded.cv.eta"].constraints[0].value === null
                                ? ""
                                : `&etaMin=${local.filters["_embedded.cv.eta"].constraints[0].value[0]}`) +
                            (local.filters["_embedded.cv.eta"].constraints[0].value === null
                                ? ""
                                : `&etaMax=${local.filters["_embedded.cv.eta"].constraints[0].value[1]}`) +
                            (local.filters["score"].constraints[0].value === null
                                ? ""
                                : `&scoreMin=${local.filters["score"].constraints[0].value[0]}`) +
                            (local.filters["score"].constraints[0].value === null
                                ? ""
                                : `&scoreMax=${local.filters["score"].constraints[0].value[1]}`) +
                            `&statoCandidato=${
                                local.filters.statoCandidato.constraints[0].value === 0 ||
                                local.filters.statoCandidato.constraints[0].value === 1
                                    ? local.filters.statoCandidato.constraints[0].value
                                    : ""
                            }` +
                            `&esitoCandidato=${
                                local.filters.statoCandidato.constraints[0].value === 2
                                    ? 1
                                    : local.filters.statoCandidato.constraints[0].value === 3
                                        ? 0
                                        : ""
                            }` +
                            `&areaGeografica=${
                                geographicAreasToSend
                            }` +
                            sortQuery +
                            `&size=9&page=0`;
                    } else if (this.state.selectedRegion.length !== 0) {
                        url =
                            `/api/cvJob/search/findPagedFilteredSortedByNomeRegione?idJob=${this.props.jobDescriptionID}` +
                            `&candidate=${
                                local.filters.candidate.constraints[0].value === null
                                    ? ""
                                    : local.filters.candidate.constraints[0].value
                            }` +
                            `&disability=${
                                local.filters["_embedded.cv.disability"].constraints[0].value === null
                                    ? ""
                                    : local.filters["_embedded.cv.disability"].constraints[0].value
                            }` +
                            `&multiUtility=${
                                local.filters.aziendaMultiUtility.constraints[0].value === null
                                    ? ""
                                    : local.filters.aziendaMultiUtility.constraints[0].value
                            }` +
                            (local.filters["_embedded.cv.eta"].constraints[0].value === null
                                ? ""
                                : `&etaMin=${local.filters["_embedded.cv.eta"].constraints[0].value[0]}`) +
                            (local.filters["_embedded.cv.eta"].constraints[0].value === null
                                ? ""
                                : `&etaMax=${local.filters["_embedded.cv.eta"].constraints[0].value[1]}`) +
                            (local.filters["score"].constraints[0].value === null
                                ? ""
                                : `&scoreMin=${local.filters["score"].constraints[0].value[0]}`) +
                            (local.filters["score"].constraints[0].value === null
                                ? ""
                                : `&scoreMax=${local.filters["score"].constraints[0].value[1]}`) +
                            `&statoCandidato=${
                                local.filters.statoCandidato.constraints[0].value === 0 ||
                                local.filters.statoCandidato.constraints[0].value === 1
                                    ? local.filters.statoCandidato.constraints[0].value
                                    : ""
                            }` +
                            `&esitoCandidato=${
                                local.filters.statoCandidato.constraints[0].value === 2
                                    ? 1
                                    : local.filters.statoCandidato.constraints[0].value === 3
                                        ? 0
                                        : ""
                            }` +
                            `&nomeRegione=${
                                regionsToSend
                            }` +
                            sortQuery +
                            `&size=9&page=0`;
                    } else if (this.state.selectedProvince.length !== 0) {
                        url =
                            `/api/cvJob/search/findPagedFilteredSortedByNomeProvincia?idJob=${this.props.jobDescriptionID}` +
                            `&candidate=${
                                local.filters.candidate.constraints[0].value === null
                                    ? ""
                                    : local.filters.candidate.constraints[0].value
                            }` +
                            `&disability=${
                                local.filters["_embedded.cv.disability"].constraints[0].value === null
                                    ? ""
                                    : local.filters["_embedded.cv.disability"].constraints[0].value
                            }` +
                            `&multiUtility=${
                                local.filters.aziendaMultiUtility.constraints[0].value === null
                                    ? ""
                                    : local.filters.aziendaMultiUtility.constraints[0].value
                            }` +
                            (local.filters["_embedded.cv.eta"].constraints[0].value === null
                                ? ""
                                : `&etaMin=${local.filters["_embedded.cv.eta"].constraints[0].value[0]}`) +
                            (local.filters["_embedded.cv.eta"].constraints[0].value === null
                                ? ""
                                : `&etaMax=${local.filters["_embedded.cv.eta"].constraints[0].value[1]}`) +
                            (local.filters["score"].constraints[0].value === null
                                ? ""
                                : `&scoreMin=${local.filters["score"].constraints[0].value[0]}`) +
                            (local.filters["score"].constraints[0].value === null
                                ? ""
                                : `&scoreMax=${local.filters["score"].constraints[0].value[1]}`) +
                            `&statoCandidato=${
                                local.filters.statoCandidato.constraints[0].value === 0 ||
                                local.filters.statoCandidato.constraints[0].value === 1
                                    ? local.filters.statoCandidato.constraints[0].value
                                    : ""
                            }` +
                            `&esitoCandidato=${
                                local.filters.statoCandidato.constraints[0].value === 2
                                    ? 1
                                    : local.filters.statoCandidato.constraints[0].value === 3
                                        ? 0
                                        : ""
                            }` +
                            `&nomeProvincia=${
                                provincesToSend
                            }` +
                            sortQuery +
                            `&size=9&page=0`;
                    } else {
                        url =
                            `/api/cvJob/search/findPagedFilteredSorted?idJob=${this.props.jobDescriptionID}` +
                            `&candidate=${
                                local.filters.candidate.constraints[0].value === null
                                    ? ""
                                    : local.filters.candidate.constraints[0].value
                            }` +
                            `&disability=${
                                local.filters["_embedded.cv.disability"].constraints[0].value ===
                                null
                                    ? ""
                                    : local.filters["_embedded.cv.disability"].constraints[0].value
                            }` +
                            `&multiUtility=${
                                local.filters.aziendaMultiUtility.constraints[0].value === null
                                    ? ""
                                    : local.filters.aziendaMultiUtility.constraints[0].value
                            }` +
                            (local.filters["_embedded.cv.eta"].constraints[0].value === null
                                ? ""
                                : `&etaMin=${local.filters["_embedded.cv.eta"].constraints[0].value[0]}`) +
                            (local.filters["_embedded.cv.eta"].constraints[0].value === null
                                ? ""
                                : `&etaMax=${local.filters["_embedded.cv.eta"].constraints[0].value[1]}`) +
                            (local.filters.distanzaDalComune.constraints[0].value === null
                                ? ""
                                : `&distanzaMax=${local.filters.distanzaDalComune.constraints[0].value}`) +
                            `&statoCandidato=${
                                local.filters.statoCandidato.constraints[0].value === 0 ||
                                local.filters.statoCandidato.constraints[0].value === 1
                                    ? local.filters.statoCandidato.constraints[0].value
                                    : ""
                            }` +
                            `&esitoCandidato=${
                                local.filters.statoCandidato.constraints[0].value === 2
                                    ? 1
                                    : local.filters.statoCandidato.constraints[0].value === 3
                                        ? 0
                                        : ""
                            }` +
                            sortQuery +
                            `&size=9&page=0`;
                    }
                    Axios.get(url)
                        .then((response2) => {
                            this.setState((prevState) => ({
                                cvJobs: response2.data._embedded.cvJobs,
                                pagination: {
                                    total: response2.data.page.totalElements,
                                    currentFirst: 0,
                                },
                                sortField: local.sortField,
                                sortOrder: local.sortOrder,
                                loading: false,
                                filters:
                                    Object.entries(arrayFilters).length !== 0
                                        ? arrayFilters
                                        : {...prevState},
                            }));
                        })
                        .catch((error) => {
                            if (error.response && error.response.status === 500)
                                this.resetStore();
                        });
                } else {
                    this.setState((prevState) => ({
                        cvJobs: response.data._embedded.cvJobs,
                        pagination: {
                            total: response.data.page.totalElements,
                            currentFirst: local.first,
                        },
                        sortField: local.sortField,
                        sortOrder: local.sortOrder,
                        loading: false,
                        filters:
                            Object.entries(arrayFilters).length !== 0
                                ? arrayFilters
                                : {...prevState},
                    }));
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 500) this.resetStore();
            });
    }

    resetStore() {
        sessionStorage.removeItem(this.nameStateJob);
        let obj = {
            first: 0,
            rows: 9,
            multiSortMeta: [],
            filters: {
                candidate: {
                    operator: "and",
                    constraints: [{value: null, matchMode: "equals"}],
                },
                "_embedded.cv.eta": {
                    operator: "and",
                    constraints: [{value: null, matchMode: "equals"}],
                },
                "_embedded.cv.disability": {
                    operator: "and",
                    constraints: [{value: null, matchMode: "equals"}],
                },
                aziendaMultiUtility: {
                    operator: "and",
                    constraints: [{value: null, matchMode: "equals"}],
                },
                distanzaDalComune: {
                    operator: "and",
                    constraints: [{value: null, matchMode: "equals"}],
                },
                statoCandidato: {
                    operator: "and",
                    constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}],
                },
                score: {
                    operator: "and",
                    constraints: [{value: null, matchMode: "equals"}],
                },
                candidateName: {
                    operator: "and",
                    constraints: [{value: null, matchMode: "startsWith"}],
                },
                zcreationDate: {
                    operator: "and",
                    constraints: [{value: null, matchMode: "startsWith"}],
                },
            },
        };
        sessionStorage.setItem(this.nameStateJob, JSON.stringify(obj));
    }

    setInitStore() {
        let obj = {
            first: 0,
            rows: 9,
            multiSortMeta: [],
            filters: {
                candidate: {
                    operator: "and",
                    constraints: [{value: null, matchMode: "equals"}],
                },
                "_embedded.cv.eta": {
                    operator: "and",
                    constraints: [{value: null, matchMode: "equals"}],
                },
                "_embedded.cv.disability": {
                    operator: "and",
                    constraints: [{value: null, matchMode: "equals"}],
                },
                aziendaMultiUtility: {
                    operator: "and",
                    constraints: [{value: null, matchMode: "equals"}],
                },
                distanzaDalComune: {
                    operator: "and",
                    constraints: [{value: null, matchMode: "equals"}],
                },
                statoCandidato: {
                    operator: "and",
                    constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}],
                },
                score: {
                    operator: "and",
                    constraints: [{value: null, matchMode: "equals"}],
                },
                candidateName: {
                    operator: "and",
                    constraints: [{value: null, matchMode: "startsWith"}],
                },
                zcreationDate: {
                    operator: "and",
                    constraints: [{value: null, matchMode: "startsWith"}],
                },
            },
        };
        sessionStorage.setItem(this.nameStateJob, JSON.stringify(obj));
        return obj;
    }

    onFilter(event) {
        this.getPagedFilteredCvJob(event, false, false, true);
    }

    onFilterClick(event) {
        //this.getPagedFilteredCvJob(event, false, false,false)
    }

    onPageChange(event) {
        this.getPagedFilteredCvJob(event, true, false, false);
    }

    onClean(event) {
        //this.getPagedFilteredCvJob(event, true, true,false)
    }

    onSort(event) {
        this.getPagedFilteredCvJob(event, false, true, false);
    }

    showWarn(msg) {
        this.toast.show({
            severity: "warn",
            summary: "Attenzione",
            detail: msg,
            life: 3000,
        });
    }

    showError(msg) {
        this.toast.show({
            severity: "error",
            summary: "Errore",
            detail: msg,
            life: 3000,
        });
    }

    showSuccess(msg) {
        this.toast.show({
            severity: "success",
            summary: "Bene",
            detail: msg,
            life: 3000,
        });
    }

    onClickExport = () => {
        this.setState({
            isLoadingCsv: true,
        });
        Axios.get(`/api/saveJobCsv/${this.props.jobDescriptionID}`).then(
            setTimeout(() => {
                Axios.get(`/api/getCSVList/${this.props.jobDescriptionID}`)
                    .then((response) => {
                        this.setState({
                            elencoFile: response.data,
                            isLoadingCsv: false,
                        });
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            }, 1000)
        );
    };

    render() {
        const loadingMessage = counterpart.translate(
            "infoMessages.loadingSingleJobDescriptionData"
        );
        if (this.state.error403) {
            return (
                <Result
                    status="403"
                    title="Accesso negato"
                    subTitle="Non hai accesso a questa job."
                />
            );
        } else {
            return (
                <div style={{padding: "30px 5vw 40px 5vw"}}>
                    <Toast ref={(el) => (this.toast = el)}/>
                    <Spin
                        spinning={this.state.isLoadingData}
                        size="large"
                        tip={loadingMessage}
                        style={{paddingTop: "70vh"}}
                    >
                        {this.state.jobDescription &&
                        this.state.jobDescription._embedded ? (
                            <>
                                <Row>
                                    <Col
                                        xl={{span: 2}}
                                        xxl={{span: 1}}
                                        style={{marginTop: 5}}
                                    >
                                        {/* eslint-disable-next-line */}
                                        <a
                                            href="javascript:void(0)"
                                            style={{padding: "0px 20px 0px 20px"}}
                                            onClick={this.onPrevPageClick}
                                        >
                                            <PrevPageBar/>
                                        </a>
                                    </Col>
                                    <Col span={17}>
                                        <Title level={3}>
                                            <Translate content="jobDescriptions.jobDescriptionData"/>
                                        </Title>
                                    </Col>
                                    <Col
                                        span={5}
                                        style={{display: "flex", justifyContent: "end"}}
                                    >
                                        <Button
                                            style={{
                                                backgroundColor: "rgb(0, 168, 176)",
                                                color: "white",
                                            }}
                                            onClick={this.setVisibleModal}
                                        >
                                            <Translate content="jobDescriptions.matchHistory"/>
                                        </Button>

                                        <Modal
                                            title={
                                                <div style={{display: "flex", alignItems: "center"}}>
                                                    <Translate
                                                        content="jobDescriptions.availableVersion"
                                                        style={{marginRight: "6rem"}}
                                                    />
                                                    <Button
                                                        loading={this.state.isLoadingCsv}
                                                        onClick={this.onClickExport}
                                                        style={{
                                                            color: "rgb(0, 168, 176)",
                                                            borderColor: "rgb(0, 168, 176)",
                                                        }}
                                                    >
                                                        <Translate content="jobDescriptions.exportText"/>
                                                    </Button>
                                                </div>
                                            }
                                            visible={this.state.isModalVisible}
                                            onOk={this.handleOk}
                                            onCancel={this.handleCancel}
                                            cancelButtonProps={{style: {display: "none"}}}
                                        >
                                            {this.state.elencoFile.length !== 0 ? (
                                                this.state.elencoFile.map((file) => (
                                                    <Row
                                                        gutter={8}
                                                        style={{display: "flex", alignItems: "center"}}
                                                    >
                                                        <Col span={15}>
                                                            <Tooltip title={file} placement="left">
                                                                <Text>{file}</Text>
                                                            </Tooltip>
                                                        </Col>
                                                        <Col
                                                            span={10}
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            {
                                                                <Button
                                                                    ghost
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        border: "1px",
                                                                        shape: "round",
                                                                        color: "green",
                                                                    }}
                                                                    onClick={() => this.dowloadFile(file)}
                                                                >
                                                                    <i
                                                                        className="fa-light fa-file-arrow-down"
                                                                        style={{
                                                                            marginRight: "0.5rem",
                                                                            fontSize: "1.1rem",
                                                                            marginLeft: "0.5rem",
                                                                        }}
                                                                    ></i>
                                                                    <Translate content="buttons.downloadText"/>
                                                                </Button>
                                                            }
                                                        </Col>
                                                    </Row>
                                                ))
                                            ) : (
                                                <Text
                                                    style={{display: "flex", justifyContent: "center"}}
                                                >
                                                    <Translate content="jobDescriptions.noHistoryFile"/>
                                                </Text>
                                            )}
                                        </Modal>
                                    </Col>
                                </Row>
                                {!this.state.isLoadingData ? (
                                    <Layout>
                                        <Sider
                                            width={this.state.siderWidth}
                                            breakpoint="lg"
                                            collapsedWidth="0"
                                            onCollapse={this.onSiderCollapse}
                                            theme="dark"
                                            style={{padding: 10}}
                                        >
                                            <Row>
                                                <JobDescriptionCard
                                                    jobDescription={this.state.jobDescription}
                                                    refreshJobDescriptionData={
                                                        this.refreshJobDescriptionData
                                                    }
                                                    onJobEditClick={this.props.onJobEditClick}
                                                />
                                            </Row>
                                            <Row>
                                                <HardSoftSkillsCard
                                                    isSoftSkill={true}
                                                    skills={this.state.softSkills}
                                                    isSingleCandidate={false}
                                                />
                                            </Row>
                                            <Row>
                                                <HardSoftSkillsCard
                                                    isSoftSkill={false}
                                                    skills={this.state.hardSkills}
                                                    isSingleCandidate={false}
                                                />
                                            </Row>
                                        </Sider>
                                        <Content
                                            style={{
                                                backgroundColor: "#F9F9FC",
                                                paddingLeft: "0.3vw",
                                            }}
                                        >
                                            <Row
                                                gutter={[
                                                    {
                                                        xs: 8,
                                                        sm: 8,
                                                        md: 8,
                                                        lg: 8,
                                                        xl: 8,
                                                        xxl: 32,
                                                    },
                                                    32,
                                                ]}
                                                style={{padding: 10, borderRadius: "0% 3% 3% 0%"}}
                                            >
                                                <Col
                                                    xs={{span: 6}}
                                                    sm={{span: 6}}
                                                    md={{span: 6}}
                                                    lg={{span: 12}}
                                                    xl={{span: 6}}
                                                    xxl={{span: 6}}
                                                >
                                                    <LanguagesCard
                                                        languages={this.state.languages}
                                                        isCandidate={false}
                                                    />
                                                </Col>
                                                <Col
                                                    xs={{span: 6}}
                                                    sm={{span: 6}}
                                                    md={{span: 6}}
                                                    lg={{span: 12}}
                                                    xl={{span: 9}}
                                                    xxl={{span: 6}}
                                                >
                                                    <YearsOfExperienceCard
                                                        yearsOfExperience={
                                                            this.state.jobDescription.anniEsperienza
                                                        }
                                                        yearsOfExperienceScore={
                                                            this.state.jobDescription.weightAnniEsperienza
                                                        }
                                                        isCandidate={false}
                                                    />
                                                </Col>
                                                <Col
                                                    xs={{span: 6}}
                                                    sm={{span: 6}}
                                                    md={{span: 6}}
                                                    lg={{span: 24}}
                                                    xl={{span: 9}}
                                                    xxl={{span: 6}}
                                                >
                                                    <EducationLevelCard
                                                        jobEducationLevel={
                                                            this.state.jobDescription.livelloIstruzione
                                                        }
                                                        jobEducationLevelScore={
                                                            this.state.jobDescription.weightLivelloIstruzione
                                                        }
                                                        jobCoursesScore={
                                                            this.state.jobDescription.weightCorsoLaurea
                                                        }
                                                        courses={
                                                            this.state.jobDescription._embedded.courses
                                                        }
                                                        isCandidate={false}
                                                    />
                                                </Col>
                                                <Col
                                                    xs={{span: 6}}
                                                    sm={{span: 6}}
                                                    md={{span: 6}}
                                                    lg={{span: 24}}
                                                    xl={{span: 9}}
                                                    xxl={{span: 6}}
                                                >
                                                    <CityCard
                                                        city={this.state.jobDescription.comune}
                                                        isCandidate={false}
                                                    />
                                                </Col>
                                            </Row>
                                            {!this.state.isCandidateVisible ? (
                                                <div className="datatable-doc-demo">
                                                    <Card
                                                        className="tables-container"
                                                        style={{
                                                            marginTop: "1rem",
                                                            height: "auto",
                                                            borderRadius: "0%",
                                                            backgroundColor: "#e5f2f6",
                                                        }}
                                                    >
                                                        <DataTable
                                                            value={this.state.cvJobs}
                                                            paginator
                                                            removableSort
                                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                                                            className="p-datatable-customers"
                                                            rows={9}
                                                            emptyMessage={
                                                                <Translate content="candidates.noCandidatesText"/>
                                                            }
                                                            responsiveLayout="scroll"
                                                            totalRecords={this.state.pagination.total}
                                                            currentPageReportTemplate={
                                                                localStorage.getItem("Language") === "it"
                                                                    ? "Candidato da {first} a {last} su {totalRecords} candidati"
                                                                    : "Candidate from {first} to {last} of {totalRecords} candidates"
                                                            }
                                                            lazy="true"
                                                            stateStorage="session"
                                                            stateKey={this.nameStateJob}
                                                            onPage={this.onPageChange.bind(this)}
                                                            first={this.state.pagination.currentFirst}
                                                            onSort={this.onSort.bind(this)}
                                                            sortField={this.state.sortField}
                                                            sortOrder={this.state.sortOrder}
                                                            onRowClick={this.onCandidateRowClick.bind(this)}
                                                            loading={this.state.loading}
                                                            filters={this.state.filters}
                                                            onFilter={this.onFilter.bind(this)}
                                                            filterDelay="1"
                                                        >
                                                            <Column
                                                                field="candidateName"
                                                                header={
                                                                    <Translate content="candidates.nameText"/>
                                                                }
                                                                body={this.candidateNameBodyTemplate}
                                                                style={{minWidth: "11rem"}}
                                                            ></Column>
                                                            <Column
                                                                field="candidate"
                                                                header={
                                                                    <Translate content="candidates.applicationForText"/>
                                                                }
                                                                style={{minWidth: "1rem"}}
                                                                body={this.candidateBodyTemplate}
                                                                filter
                                                                filterElement={this.candidateFilterTemplate.bind(
                                                                    this
                                                                )}
                                                                filterMatchMode="equals"
                                                                showFilterMenuOptions={false}
                                                                onFilterApplyClick={this.onFilterClick.bind(
                                                                    this
                                                                )}
                                                                onFilterClear={this.onClean.bind(this)}
                                                            ></Column>
                                                            <Column
                                                                field="_embedded.cv.eta"
                                                                header={
                                                                    <Translate content="candidates.ageText"/>
                                                                }
                                                                style={{minWidth: "3rem"}}
                                                                sortable
                                                                filter
                                                                filterElement={this.etaFilterTemplate}
                                                                filterMatchMode="contains"
                                                                showFilterMenuOptions={false}
                                                                onFilterApplyClick={this.onFilterClick.bind(
                                                                    this
                                                                )}
                                                                onFilterClear={this.onClean.bind(this)}
                                                            ></Column>
                                                            <Column
                                                                field="_embedded.cv.disability"
                                                                header={
                                                                    <Translate content="candidates.protectedText"/>
                                                                }
                                                                style={{minWidth: "7rem"}}
                                                                body={this.categoryTypeBodyTemplate}
                                                                filter
                                                                filterElement={this.categoryTypeFilterTemplate.bind(
                                                                    this
                                                                )}
                                                                filterMatchMode="contains"
                                                                showFilterMenuOptions={false}
                                                                onFilterApplyClick={this.onFilterClick.bind(
                                                                    this
                                                                )}
                                                                onFilterClear={this.onClean.bind(this)}
                                                            ></Column>
                                                            <Column
                                                                field="aziendaMultiUtility"
                                                                header={
                                                                    <Tooltip
                                                                        title={
                                                                            <Translate
                                                                                content="candidates.experienceText"/>
                                                                        }
                                                                    >
                                                                        <Translate content="candidates.experienceText"/>
                                                                    </Tooltip>
                                                                }
                                                                style={{minWidth: "1rem"}}
                                                                body={this.multiUtilityBodyTemplate}
                                                                filter
                                                                filterElement={this.multiUtilityFilterTemplate.bind(
                                                                    this
                                                                )}
                                                                filterMatchMode="contains"
                                                                showFilterMenuOptions={false}
                                                                onFilterApplyClick={this.onFilterClick.bind(
                                                                    this
                                                                )}
                                                                onFilterClear={this.onClean.bind(this)}
                                                            ></Column>
                                                            <Column
                                                                field="zcreationDate"
                                                                header={
                                                                    <Translate content="candidates.insertionDateText"/>
                                                                }
                                                                style={{minWidth: "5rem"}}
                                                                body={this.dateBodyTemplate}
                                                            ></Column>
                                                            <Column
                                                                field="_embedded.cv.eta"
                                                                header={
                                                                    <Translate content="candidates.provenanceText"/>
                                                                }
                                                                style={{minWidth: "6rem"}}
                                                                body={this.distanceBodyTemplate}
                                                                filter
                                                                filterElement={this.distanceFilterTemplate}
                                                                showFilterMenuOptions={false}
                                                                onFilterApplyClick={this.onFilterClick.bind(
                                                                    this
                                                                )}
                                                                onFilterClear={this.onCleanProvince.bind(this)}
                                                            ></Column>
                                                            <Column
                                                                field="statoCandidato"
                                                                header={
                                                                    <Translate content="candidates.statusText"/>
                                                                }
                                                                style={{width: "15rem"}}
                                                                body={this.statoBodyTemplate}
                                                                filter
                                                                filterElement={this.statoFilterTemplate.bind(
                                                                    this
                                                                )}
                                                                filterMatchMode="contains"
                                                                showFilterMenuOptions={false}
                                                                onFilterApplyClick={this.onFilterClick.bind(
                                                                    this
                                                                )}
                                                                onFilterClear={this.onClean.bind(this)}
                                                            ></Column>
                                                            <Column
                                                                field="score"
                                                                header={
                                                                    <Translate content="candidates.scoreText"/>
                                                                }
                                                                style={{width: "15rem"}}
                                                                body={this.scoreBodyTemplate}
                                                                filterElement={this.scoreFilterTemplate}
                                                                filter
                                                                filterMatchMode="contains"
                                                                showFilterMenuOptions={false}
                                                                onFilterApplyClick={this.onFilterClick.bind(
                                                                    this
                                                                )}
                                                                onFilterClear={this.onClean.bind(this)}
                                                            ></Column>
                                                        </DataTable>
                                                    </Card>
                                                </div>
                                            ) : (
                                                <Card
                                                    className="tables-container"
                                                    style={{
                                                        marginTop: "1rem",
                                                        height: "auto",
                                                        borderRadius: "0%",
                                                        backgroundColor: "#e5f2f6",
                                                    }}
                                                >
                                                    <div className="details-container">
                                                        <Row>
                                                            <Col
                                                                xl={{span: 2}}
                                                                xxl={{span: 1}}
                                                                style={{marginTop: 5}}
                                                            >
                                                                {/* eslint-disable-next-line */}
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    style={{padding: "0px 20px 0px 20px"}}
                                                                    onClick={this.onCandidateViewBack}
                                                                >
                                                                    <PrevPageBar/>
                                                                </a>
                                                            </Col>
                                                            <Col span={22}>
                                                                <Title level={3}>
                                                                    {<Translate content="cards.candidateData"/>}
                                                                </Title>
                                                            </Col>
                                                        </Row>
                                                        <CandidatePerJobDescription
                                                            softSkills={this.state.softSkills}
                                                            hardSkills={this.state.hardSkills}
                                                            languages={this.state.languages}
                                                            educationLevelScore={
                                                                this.state.jobDescription
                                                                    .weightLivelloIstruzione
                                                            }
                                                            yearsOfExperienceScore={
                                                                this.state.jobDescription.weightAnniEsperienza
                                                            }
                                                            degreesCoursesScore={
                                                                this.state.jobDescription.weightCorsoLaurea
                                                            }
                                                            selectedCandidate={this.state.selectedCandidate}
                                                            referrerJobDescriptionID={
                                                                this.props.jobDescriptionID
                                                            }
                                                        />
                                                    </div>
                                                </Card>
                                            )}
                                        </Content>
                                    </Layout>
                                ) : null}
                            </>
                        ) : !this.state.isLoadingData ? (
                            <>
                                <Row
                                    type="flex"
                                    justify="space-around"
                                    align="middle"
                                    style={{height: "60vh"}}
                                >
                                    <Col xl={{span: 10}} xxl={{span: 6}}>
                                        <Alert
                                            message="Dati in fase di Calcolo"
                                            description="La job description è appena stata modificata, i dati sono ancora in fase di calcolo. Riprova tra poco."
                                            type="warning"
                                        />
                                    </Col>
                                </Row>
                                <Row
                                    type="flex"
                                    justify="space-around"
                                    align="middle"
                                    style={{height: "60vh"}}
                                >
                                    <Col xl={{span: 10}} xxl={{span: 6}}>
                                        <Alert
                                            message="Dati in fase di Calcolo"
                                            description="La job description è appena stata modificata, i dati sono ancora in fase di calcolo. Riprova tra poco."
                                            type="warning"
                                        />
                                    </Col>
                                </Row>
                            </>
                        ) : null}
                    </Spin>
                </div>
            );
        }
    }
}

export default withRouter(SingleJobDescription);
