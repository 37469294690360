import React from 'react';

import { Result, Button } from 'antd';
import { Link, withRouter } from 'react-router-dom';

import Translate from 'react-translate-component';

/**
 * Utilizza i props: 
 * - hasError: utilizzato dall'app per rilevare eventuali errori
 * - errorHandling: metodo lanciato per notificare all'app l'avvenuto (o risolto) error
 */
class Error503 extends React.Component {

  /**
   * All'apertura della pagina d'errore, se this.props.hasError risulta false, viene lanciato l'errore in modo che 
   * l'app sia in grado di rilevarlo.
   */
  componentDidMount() {
    if(this.props.errorHandling)
      if (!this.props.hasError) {
        this.props.errorHandling();
      }
  }

  /**
   * Nel passaggio dalla schermata di errore a quella di dashboard, la segnalazione d'errore viene annullata e l'app torna
   * al suo funzionamento standard.
   */
  onBackToLoginClick = () => {
    if(this.props.errorHandling)
      this.props.errorHandling();
  }

  render() {
    return (
      <Result
        status="500"
        title={<Translate content='errorMessages.serviceUnavailable' />}
        subTitle={<Translate content='errorMessages.expiredDescription' />}
        extra={
          <Link to={this.props.errorHandling ? "/dashboard" : "/login"} >
            <Button type="primary" onClick={this.onBackToLoginClick}>
              <Translate content='errorMessages.retry' />
            </Button>
          </Link>
        }
      />
    )
  }
} export default withRouter(Error503);