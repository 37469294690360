import React from 'react';

import { notification, Spin } from 'antd';

import counterpart from 'counterpart';

import TopBar from '../../topbars/TopBar';

import { errorRouter } from '../../errors/ErrorRouter';

import CandidatesGrid from './CandidatesGrid';
import CandidatesTable from './CandidatesTable';
import FilterDrawer from '../../Drawers/FilterDrawer';
import Axios from 'axios';
import { withRouter } from 'react-router-dom';
import FullTextSearchCandidates from './FullTextSearchCandidates';
import Translate from 'react-translate-component';


/**
 * Utilizza i props: 
 * - userSettings: array contenente tutte le preferenze dell'utente
 * - updateUserSettings: metodo lanciato nel momento in cui l'utente cambia una delle sue preferenze. Il dato viene
 *   aggiornato direttamente senza dover ricaricare la pagina
 * - onCandidateCardClick: metodo lanciato nel momento in cui viene effettuato il click della card del candidato, effettuando
 *   il redirect al componente <SingleCandidate /> contenente tutti i dati sul candidato selezionato.
 */
class Candidates extends React.Component {

  state = {
    //Contiene i dati di tutti i candidati
    candidatesData: [],
    //Numero totale dei candidati ricevuti dal back end, utilizzato per la pagination di Spring
    totalCandidates: 0,
    //Pagina corrente della visualizzazione (tabellare o griglia)
    currentPage: this.props.location.state === undefined ? 1 : this.props.location.state.currentPage,
    //Numero di elementi per pagina visualizzata
    pageSize: 12,
    //Permette la visualizzazione del drawer con la filtratura scelta dall'utente
    drawerIsVisible: false,
    //Tipo di visualizzazione scelta dall'utente: 0 -> griglia, 1 -> tabella
    tableVisualizationValue: '1',
    //Booleano che controlla il caricamento dati da back end. Diventa false una volta ricevuti, true in caso contrario.
    isDataLoading: true,
    isFullTextSearchChecked: false,
    searchTablePage: 0,
    searchTableTotalElements: 0,
    searchCandidatesElements: 0,
    isFullTextLoading: false,
    selectedSearchId: 0,
  }

  /**
    * Nel costruttore sono impostati i vari filtri presenti, in base alla risposta mandata dal back end.
    * Se non sono presenti filtri, allora da dei valori di default specifici, altrimenti imposta il dato ricevuto
    */
  constructor(props) {
    super(props);
    if (this.props.userSettings.length !== 0) {
      let tableVisualizationValue = this.props.userSettings.filter((item) => { return item.name === 'candidatesVisualization' });
      this.state = {
        tableVisualizationValue: tableVisualizationValue[0] ? tableVisualizationValue[0].value : '1',
        drawerIsVisible: false,
        isDataLoading: true,
        candidatesData: [],
        totalCandidates: 0,
        currentPage: this.props.location.state === undefined ? 1 : this.props.location.state.currentPage,
        pageSize: 12,
        searchTablePage: 0,
        isFullTextSearchChecked: false,
        isFullTextLoading: false,
      }
    } else {
      this.state = {
        tableVisualizationValue: '1',
        drawerIsVisible: false,
        isDataLoading: true,
        candidatesData: [],
        totalCandidates: 0,
        currentPage: this.props.location.state === undefined ? 1 : this.props.location.state.currentPage,
        pageSize: 12,
        searchFullTextData: [],
        searchTablePage: 0,
        isFullTextSearchChecked: false,
        isFullTextLoading: false,
      }
    }
  }

  //Quando il componente viene montato, sono caricati tutti i dati della prima pagina, con una pagination di default di 12
  componentDidMount() {
    //console.log("STATE " + this.state.currentPage)
    //console.log("PROPS " + this.props.location.state === undefined ? '' : this.props.location.state.currentPage)
    this.loadCandidatesData(1, 12)
  }

  componentDidUpdate() {
    if (this.props.candidatesDataAreRefreshing) {
      this.loadCandidatesData(1, 12)
      this.props.refreshCandidatesData()
    }
  }

  /**
   * API che prende tutti i candidati dal back end, imposta l'array con i candidati, il numero totale di 
   * elementi per la pagination, e comunica che i dati sono stati caricati, in modo da interrompere lo spin 
   */
  loadCandidatesData = async () => {
    await this.setState({
      isDataLoading: true,
    })
    //console.log("PREV " + this.state.currentPage)
    await Axios.get(`/api/candidato?size=${this.state.pageSize}&page=${this.state.currentPage - 1}`)
      .then((response) => {
        this.setState({
          candidatesData: response.data._embedded.candidatoes,
          totalCandidates: response.data.page.totalElements,
          isDataLoading: false
        })
        //console.log("SUCC " + this.state.currentPage)
      })
      .catch((error) => {
        if (error.response) {
          errorRouter(error.response.status, this.props.history);
        }
      })
  }

  onCandidateSearch = async (searchingCandidate) => {
    await this.setState({
      isDataLoading: true,
    })
    await Axios.get(`/api/candidato/search/findByFirstNameContainingIgnoreCaseOrLastNameContainingIgnoreCase?name=${searchingCandidate}&lastname=${searchingCandidate}`)
      .then((response) => {
        this.setState({
          candidatesData: response.data._embedded.candidatoes,
          totalCandidates: response.data.page.totalElements,
          isDataLoading: false
        })
      })
  }

  //Viene lanciato al cambiamento del numero di elementi per pagina, ricaricando tutti i dati per la nuova visualizzazione
  onShowSizeChange = async (currentPage, pageSize) => {
    await this.setState({
      isDataLoading: !this.state.isDataLoading,
      currentPage,
      pageSize
    })
    await this.loadCandidatesData()
  }

  //Viene lanciato al cambiamento del numero di pagina, ricaricando tutti i dati per la nuova visualizzazione
  onPageChange = async (currentPage, pageSize) => {
    if (this.state.isFullTextSearchChecked) {
      await this.setState({
        currentPage,
        pageSize
      })
      await this.onSearchSelect(this.state.selectedSearchId)
    } else {
      await this.setState({
        isDataLoading: !this.state.isDataLoading,
        currentPage,
        pageSize
      })
      await this.loadCandidatesData()
    }
  }

  //Gestisce l'apertura del drawer per i filtri 
  showDrawer = () => {
    this.setState({
      drawerIsVisible: true,
    });
  };

  //Gestisce la chiusura del drawer per i filtri
  onDrawerClose = () => {
    this.setState({
      drawerIsVisible: false,
    });
  };

  /**
   * Metodo lanciato al cambiamento della visualizzazione griglia/tabella: permette il salvataggio della nuova
   * impostazione, e lancia un metodo che cambia in modo dinamico il dato anche in locale (updateUserSettings) 
   */
  onVisualizationSettingChange = (e) => {
    Axios.post(`/api/userpreference/add/candidatesVisualization/${e.target.value}`)
      .then(() => {
        this.setState({
          tableVisualizationValue: e.target.value,
        })
      })
      .catch((error) => {
        if (error.response) {
          errorRouter(error.response.status, this.props.history);
        }
      })
    this.props.updateUserSettings('candidatesVisualization', e.target.value);
  }

  onFullTextSearchFilterChange = async (checked) => {
    this.setState({
      isFullTextSearchChecked: checked
    })
    if (checked) {
      await this.setState({
        isFullTextLoading: true,
      })
      await Axios.get(`/api/scheduledSearch?page=${this.state.searchTablePage}&size=10`)
        .then((response) => {
          this.setState({
            searchFullTextData: response.data.content,
            searchTableTotalElements: response.data.totalElements,
            candidatesData: [],
            isFullTextLoading: false,
          })
        })
        .catch((error) => {
          //TODO GESTIONE ERRORE
        })
    } else {
      this.loadCandidatesData();
    }
  }

  onSearchSelect = async (id) => {
    //TODO gestione candidati in base alla ricerca selezionata
    await this.setState({
      isFullTextLoading: true,
      selectedSearchId: id,
    })
    await Axios.get(`/api/scheduledSearch/${id}/results?size=${this.state.pageSize}&page=${this.state.currentPage - 1}`)
      .then((response) => {
        console.log(response)
        this.setState({
          candidatesData: response.data.content,
          searchCandidatesElements: response.data.totalElements,
          isFullTextLoading: false
        })
      })
      .catch((error) => {
        //TODO GESTIONE ERRORE
      })
  }

  onSearchDelete = async (id) => {
    await this.setState({
      isFullTextLoading: true
    })
    await Axios.delete(`/api/scheduledSearch/${id}`)
      .then(() => {
        let searchFullTextData = this.state.searchFullTextData.slice();
        let indexes = searchFullTextData.map((element, index) => {
          if (element.id.toString() === id) {
            console.log(true)
            return index;
          } else return -1;
        });
        for (let i = 0; i < indexes.length; i++) {
          if (indexes[i] > -1) {
            searchFullTextData.splice(indexes[i], 1);
          }
        }
        this.setState({
          searchFullTextData: searchFullTextData,
          isFullTextLoading: searchFullTextData.length === 0 && this.state.searchTablePage > 1 ? true : false,
          searchTableTotalElements: searchFullTextData.length
        })
        if (searchFullTextData.length === 0 && this.state.searchTablePage > 1) {
          Axios.get(`/api/scheduledSearch?page=${this.state.searchTablePage - 2}&size=10`)
            .then((response) => {
              this.setState({
                searchFullTextData: response.data.content,
                searchTableTotalElements: response.data.totalElements,
                searchTablePage: this.state.searchTablePage - 1,
                candidatesData: [],
                isFullTextLoading: false,
              })
            })
            .catch((error) => {
              //TODO GESTIONE ERRORE
            })
        }
        notification.open({
          message: <Translate content='candidates.deleteSearchText' />,
          description:
            <Translate content='candidates.deleteSearchDescriptionText' />,
        });
      })
      .catch((error) => {
        //TODO GESTIONE ERRORE
      })
  }

  fullTextSearch = (keyword1, keyword2) => {
    Axios.post(`/api/scheduledSearch/add?keyword1=${keyword1}&keyword2=${keyword2}`)
      .then((response) => {
        console.log(response)
        console.log(this.state.searchFullTextData)
        //let searchFullTextData = this.state.searchFullTextData.slice();
        //searchFullTextData.push(response.data);
        
        this.setState({
          //searchFullTextData: searchFullTextData,
          //searchTableTotalElements: searchFullTextData.length,
          drawerIsVisible: false
        })
        notification.open({
          message: <Translate content='candidates.startSearchText' />,
          description:
            <Translate content='candidates.startSearchDescriptionText' />,
        });
      })
      .catch((error => {
        console.log(this.state.drawerIsVisible)
        if (error.response.status != null && error.response !== undefined && error.response.status === 400) {
          this.setState({
            drawerIsVisible: false
          })
          notification.open({
            message: <Translate content='candidates.existSearchText' />,
          description:
            <Translate content='candidates.existSearchDescriptionText' />,
          });
        }
      }))
  }

  onFullTextSearchTablePageChange = async (currentPage) => {
    await this.setState({
      isFullTextLoading: true,
      searchTablePage: currentPage
    })
    await Axios.get(`/api/scheduledSearch?page=${currentPage - 1}&size=10`)
      .then((response) => {
        this.setState({
          searchFullTextData: response.data.content,
          searchTableTotalElements: response.data.totalElements,
          candidatesData: [],
          isFullTextLoading: false,
        })
      })
      .catch((error) => {
        //TODO GESTIONE ERRORE
      })
  }

  render() {
    const loadingMessage = counterpart.translate('infoMessages.loadingCandidatesData');
    return (
      <div style={{ padding: '40px 5vw 40px 5vw' }}>
        <Spin
          spinning={this.state.isDataLoading}
          size='large'
          tip={loadingMessage}
          style={{ paddingTop: '70vh' }}
        >
          <TopBar
            isJobDescriptions={false}
            totalData={this.state.isFullTextSearchChecked ? this.state.searchCandidatesElements : this.state.totalCandidates}
            onShowSizeChange={this.onShowSizeChange}
            onPageChange={this.onPageChange}
            showDrawer={this.showDrawer}
            onFullTextSearchFilterChange={this.onFullTextSearchFilterChange}
          />
          {
            this.state.isFullTextSearchChecked
              ? <FullTextSearchCandidates
                onSearchSelect={this.onSearchSelect}
                cardsData={this.state.candidatesData}
                onSearchDelete={this.onSearchDelete}
                searchFullTextData={this.state.searchFullTextData}
                searchTableTotalElements={this.state.searchTableTotalElements}
                searchTablePage={this.state.searchTablePage}
                onCandidateCardClick={this.props.onCandidateCardClick}
                loadCandidatesData={this.loadCandidatesData}
                isFullTextLoading={this.state.isFullTextLoading}
                onFullTextSearchTablePageChange={this.onFullTextSearchTablePageChange}
              />
              : this.state.tableVisualizationValue === '1'
                ? <CandidatesGrid
                  cardsData={this.state.candidatesData}
                  onCandidateCardClick={this.props.onCandidateCardClick}
                  loadCandidatesData={this.loadCandidatesData}
                  currentPage={this.state.currentPage}
                />
                : <CandidatesTable
                  cardsData={this.state.candidatesData}
                  onCandidateCardClick={this.props.onCandidateCardClick}
                  isDataLoading={this.state.isDataLoading}
                />
              
          }
          <FilterDrawer
            isVisible={this.state.drawerIsVisible}
            onClose={this.onDrawerClose}
            onVisualizationSettingChange={this.onVisualizationSettingChange}
            tableVisualizationValue={this.state.tableVisualizationValue}
            onCandidateSearch={this.onCandidateSearch}
            loadCandidatesData={this.loadCandidatesData}
            fullTextSearch={this.fullTextSearch}
          />
        </Spin>
      </div>
    );
  }
} export default withRouter(Candidates);