import React from "react";
import {
  Steps,
  Layout,
  Typography,
  Divider,
  Button,
  Row,
  Col,
  Form,
  Spin,
  notification,
  Progress,
  InputNumber,
} from "antd";

import JobDescriptionStep from "../new-job_description/steps/JobDescriptionStep";
import RequirementsStep from "../new-job_description/steps/RequirementsStep";
import SkillsStep from "../new-job_description/steps/SkillsStep";
import Axios from "axios";

import { errorRouter } from "../errors/ErrorRouter";
import { withRouter } from "react-router-dom";
import ImportStep from "./steps/ImportStep";

import Translate from "react-translate-component";
//import SaveJobModal from './SaveJobModal';

import { Toast } from "primereact/toast";

import { uniqBy } from "lodash";

const { Step } = Steps;

const { Header, Content } = Layout;

const { Title } = Typography;

let educationLevelMapping = [];

class NewJobDescription extends React.Component {
  state = {
    //Tiene conto dello step corrente attivo nella creazione della job description (ci sono tre step)
    currentStep: 0,
    /**
     * Nome di default della job description. Se nuova, assume "Nuova Job Description", altrimenti,
     * in caso di modifica, prende il dato fornito.
     */
    jobDescriptionName:
      localStorage.getItem("Language") === "it"
        ? "Nuova posizione lavorativa"
        : "New job description",
    jobDescriptionOption: [],
    //Indicate lo stato dello step aperto in quel momento.
    importStepStatus: "finish",
    jobDescriptionStepStatus: "finish",
    requirementsStepStatus: "finish",
    skillsStepStatus: "finish",
    jobCodeStatus: "success",
    jobCodeHelpText:
      localStorage.getItem("Language") === "it"
        ? "Codice già utilizzato"
        : "Code already used",
    autoCompleteUsed: false,

    //Tiene conto della scansione della descrizione, nel primo step, se il tasto "Analizza Descrizione" viene cliccato
    isScanningDescription: false,
    //Soft e Hard Skills che arrivano dal backend: 1 - Soft Skills, 2/3 - Hard Skills, 4 Language Skills, 5 Certification
    dbSkills: [],
    //Permette il controllo sul caricamento di hard e soft skills da backend.
    areDataLoading: true,
    //Dati della job description: se in modifica job description, assume i dati ricevuti da backend
    jobDescriptionData: {
      title: "",
      jobCodeProfession: [],
      jobProfessionTitle: [],
      jobDescriptionCode: "",
      description: "",
      mandatoryAnniEsperienza: false,
      anniEsperienzaType: 2,
      anniEsperienza: 0,
      anniEsperienzaMax: 0,
      weightAnniEsperienza: 0,
      mandatoryLivelloIstruzione: false,
      livelloIstruzione: 0,
      weightLivelloIstruzione: 0,
      mandatoryCorsoLaurea: false,
      theme_area: [],
      weightCorsoLaurea: 0,
      coursesList: [],
      view: 0,
      active: 1,
      requirementsWeight: 0,
      skillsWeight: 0,
      anniEsperienzaAlmeno: false,
      aziendaWork: false,
      categoriaProtetta: false,
    },
    courses: [],
    coursesNamed: [],
    skills: [],
    requirementsStepTotalPercentage: 0,
    skillsStepTotalPercentage: 0,
    dbThemeAreas: [],
    dbCourses: [],
    spinText:
      localStorage.getItem("Language") === "it"
        ? "Caricamento dati in corso"
        : "Data loading",

    isStepsPercentageModalOpen: false,

    experienceYearsErrorMessage: {
      error: false,
      message: <Translate content="newJobDescription.maxYearErrorMessage" />,
    },
    //maxYearsActive: false,
  };

  constructor() {
    super();

    educationLevelMapping =
      localStorage.getItem("Language") === "it"
        ? [
            "Qualsiasi",
            "Diploma",
            "Formazione Professionale",
            "Laurea Triennale",
            "Laurea Magistrale",
            "Master",
            "Dottorato",
            "Laurea Associata",
          ]
        : [
            "Any",
            "Diploma",
            "Professional Training",
            "Bachelor Degree",
            "Master Degree",
            "Master",
            "PhD",
            "Associate Degree",
          ];
  }

  event = null;

  //Recupera Hard e Soft skills presenti nel db e interrompe il caricamento della pagina a dati ricevuti
  async componentDidMount() {
    if (this.props.isJobUpdating)
      await Axios.get(
        `/api/jobDescriptionLighter/${this.props.idUpdatingJob}`
      ).then((response) => {
        let previousSkills = [];
        let tempDbSkills = [];
        let previousSkillsTotalWeight = 0;
        let tempJobProfessionCodes = [];
        let tempJobProfessionTitles = [];
        if (response.data._embedded.jobSkills) {
          response.data._embedded.jobSkills.forEach((skill) => {
            previousSkillsTotalWeight += skill.weight;
            previousSkills.push({
              category: skill.skillTk.category,
              descriptions: skill.skillTk.descriptions,
              id_skill: skill.skillTk.id,
              weight: skill.weight,
              mandatory: skill.mandatory,
            });
            tempDbSkills.push({
              category: skill.skillTk.category,
              descriptions: skill.skillTk.descriptions,
              id: skill.skillTk.id,
              weight: skill.weight,
              mandatory: skill.mandatory,
            });
          });
        }

        if (response.data._embedded.jobProfessions) {
          response.data._embedded.jobProfessions.forEach((jobProfession) => {
            tempJobProfessionCodes.push(jobProfession.jobCode);
            tempJobProfessionTitles.push(jobProfession.jobTitle);
          });
        }

        let tempCoursesIds = [];
        let tempCoursesNamed = [];
        if (response.data._embedded.courses) {
          response.data._embedded.courses.forEach((course) => {
            tempCoursesIds.push(`${course.id}`);
            tempCoursesNamed.push(`${course.id} ${course.nome}`);
          });
        }
        this.props.form.setFieldsValue({
          title: response.data.title,
          titleOption: tempJobProfessionTitles,
          jobDescriptionCode: response.data.jobDescriptionCode,
          view: response.data.view,
          description: response.data.description,
        });
        this.setState({
          jobDescriptionName: response.data.title,
          dbSkills: tempDbSkills,
          jobDescriptionData: {
            title: response.data.title,
            jobCodeProfession: tempJobProfessionCodes,
            jobProfessionTitle: tempJobProfessionTitles,
            jobDescriptionCode: response.data.jobDescriptionCode,
            description: response.data.description,
            mandatoryAnniEsperienza: response.data.mandatoryAnniEsperienza,
            anniEsperienzaType: response.data.anniEsperienzaType,
            anniEsperienza: response.data.anniEsperienza,
            anniEsperienzaMax: response.data.anniEsperienzaMax,
            weightAnniEsperienza: response.data.weightAnniEsperienza,
            mandatoryLivelloIstruzione:
              response.data.mandatoryLivelloIstruzione,
            livelloIstruzione: response.data.livelloIstruzione,
            weightLivelloIstruzione: response.data.weightLivelloIstruzione,
            mandatoryCorsoLaurea: response.data.mandatoryCorsoLaurea,
            theme_area: [],
            weightCorsoLaurea: response.data.weightCorsoLaurea,
            coursesList: response.data._embedded.courses,
            view: response.data.view,
            active: response.data.active,
            requirementsWeight: 0,
            skillsWeight: 0,
            anniEsperienzaAlmeno: response.data.anniEsperienzaAlmeno,
            aziendaWork: response.data.aziendaWork,
            categoriaProtetta: response.data.categoriaProtetta,
          },
          courses: tempCoursesIds,
          coursesNamed: tempCoursesNamed,
          skills: previousSkills,
          requirementsStepTotalPercentage:
            response.data.weightLivelloIstruzione +
            response.data.weightCorsoLaurea +
            response.data.weightAnniEsperienza,
          skillsStepTotalPercentage: previousSkillsTotalWeight, //Math.round(previousSkillsTotalWeight),
          /* maxYearsActive:
            response.data.anniEsperienzaMax !== 0 &&
            response.data.anniEsperienzaMax >= response.data.anniEsperienza
              ? true
              : false, */
        });
      });
    await Axios.all([
      Axios.get("/api/area"),
      Axios.get("/api/skillTk/search/findByCategory?category=4"),
      Axios.get("/api/laurea?size=9999"),
    ])
      .then((response) => {
        this.setState({
          areDataLoading: false,
          dbThemeAreas: response[0].data._embedded.areas,
          dbLanguages: response[1].data._embedded.skillTks,
          dbCourses: response[2].data._embedded.laureas,
        });
      })
      .catch((error) => {
        if (error.response) {
          errorRouter(error.response.status, this.props.history);
        }
      });
  }

  componentWillUnmount() {
    if (this.props.isJobUpdating) this.props.onJobEditClick("saved");
  }

  //E' lanciato al cambiamento dello step nel form di creazione della job description
  onStepChange = (currentStep) => {
    if (currentStep === 1) {
      this.setState({
        requirementsStepStatus: "finish",
      });
    }
    if (currentStep === 2) {
      this.setState({
        skillsStepStatus: "finish",
      });
    }

    this.setState({
      currentStep,
    });
  };

  /* onAtLeastCheckboxChange = (isChecked) => {
    this.setState((prevState) => ({
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        anniEsperienzaAlmeno: isChecked,
      },
    }));
  }; */

  /*
  onAzeindaWorkCheckboxChange = (isChecked) => {
    this.setState((prevState) => ({
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        aziendaWork: isChecked,
      }
    }))
  } */

  /* onDisabilityStatusCheckboxChange = (isChecked) => {
    this.setState((prevState) => ({
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        categoriaProtetta: isChecked,
      },
    }));
  }; */

  onAnalizzaJob = (jobAn) => {
    console.log(jobAn);
    let nonDuplicateSkills = uniqBy(jobAn.skillTkList, "id");
    jobAn.skillTkList = nonDuplicateSkills;
    this.onJobTitleChange(jobAn.jobOverview.job.jobTitle);
    this.onProfessionDescriptionSelect(
      jobAn.jobOverview.job.jobTitle,
      jobAn.jobOverview.job.professionCode
    );

    const minimumIsNotPresent =
      jobAn.jobDetails.profile.yearsOfExperience.minimum === "" ||
      jobAn.jobDetails.profile.yearsOfExperience.minimum === undefined;

    const maximumIsNotPresent =
      jobAn.jobDetails.profile.yearsOfExperience.maximum === "" ||
      jobAn.jobDetails.profile.yearsOfExperience.maximum === undefined;

    if (minimumIsNotPresent && maximumIsNotPresent) {
      this.onYearsOfExperienceChange(0);
      this.onOperationChange(2);
    } else if (minimumIsNotPresent) {
      this.onYearsOfExperienceChange(
        +jobAn.jobDetails.profile.yearsOfExperience.maximum
      );
      this.onOperationChange(0);
    } else if (maximumIsNotPresent) {
      this.onYearsOfExperienceChange(
        +jobAn.jobDetails.profile.yearsOfExperience.minimum
      );
      this.onOperationChange(3);
    } else {
      this.onYearsOfExperienceChange(
        +jobAn.jobDetails.profile.yearsOfExperience.minimum
      );
      this.onMaxYearsOfExperienceChange(
        +jobAn.jobDetails.profile.yearsOfExperience.maximum
      );
      this.onOperationChange(5);
    }

    let previousSkillsTotalWeight = 0;
    let previuosrequirementsTotalWeigth = 50;
    let previousSkills = [];
    let tempDbSkills = [];
    if (jobAn.skillTkList) {
      previousSkillsTotalWeight = 50;
      let remaining = previousSkillsTotalWeight % jobAn.skillTkList.length;
      let weight = previousSkillsTotalWeight / jobAn.skillTkList.length;
      let loopTimes = remaining === 0 ? 1 : 2;

      for (let i = 0; i < loopTimes; i++) {
        jobAn.skillTkList.forEach((skill) => {
          if (i === 0) {
            previousSkills.push({
              category: skill.category,
              descriptions: skill.descriptions,
              id_skill: skill.id,
              weight: Math.trunc(weight),
            });
            tempDbSkills.push({
              category: skill.category,
              descriptions: skill.descriptions,
              id: skill.id,
              weight: Math.trunc(weight),
            });
          } else if (remaining !== 0) {
            let previousSkillIndex = previousSkills.findIndex(
              (skillToUpdate) => skillToUpdate.id_skill === skill.id
            );
            previousSkills[previousSkillIndex].weight = Math.trunc(weight) + 1;

            let tempSkillIndex = tempDbSkills.findIndex(
              (skillToUpdate) => skillToUpdate.id === skill.id
            );
            tempDbSkills[tempSkillIndex].weight = Math.trunc(weight) + 1;

            remaining -= 1;
          }
        });
      }
    }

    let educationLevelToSave = educationLevelMapping[0];

    if (
      jobAn.jobDetails.profile.education.internationalCode !== null ||
      jobAn.jobDetails.profile.education.internationalCode !== undefined
    ) {
      educationLevelToSave =
        educationLevelMapping[
          jobAn.jobDetails.profile.education.internationalCode
        ];
    }

    this.setState((prevState) => ({
      skills: previousSkills,
      dbSkills: tempDbSkills,
      skillsStepTotalPercentage: previousSkillsTotalWeight, //Math.round(previousSkillsTotalWeight),
      requirementsStepTotalPercentage: previuosrequirementsTotalWeigth,
      autoCompleteUsed: true,
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        weightAnniEsperienza: 25,
        livelloIstruzione: educationLevelToSave,
        weightLivelloIstruzione: 25,
        description: jobAn.documentText,
      },
    }));
  };

  /**
   * E' lanciato al cambiamento del titolo della job description:
   * - Il titolo non c'è: assume valore 'nessun titolo'
   * - Il titolo c'è: prende il valore specificato nel campo.
   * Inoltre aggiorna anche il dato della job description.
   * Il prevState permette di mantenere invariato lo stato e di modificare solo i campi
   * necessari, senza resettare tutto lo stato.
   */
  onJobTitleChange = (jobTitle) => {
    if (!jobTitle || jobTitle.length === 0) {
      this.setState({
        jobDescriptionName:
          localStorage.getItem("Language") === "it"
            ? "Nessun Titolo"
            : "No title",
      });
    } else {
      this.setState({
        jobDescriptionName: jobTitle,
      });
    }

    this.setState((prevState) => ({
      jobDescriptionStepStatus: "finish",
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        title: jobTitle,
      },
    }));

    //Salva il dato nel componente form di antd, in modo da poter gestire eventuale errori.
    this.props.form.setFieldsValue({
      title: jobTitle,
    });
  };

  onProfessionDescriptionSelect = (jobTitle, professionDescriptionCode) => {
    let tempProfessionDescriptionCode =
      this.state.jobDescriptionData.jobCodeProfession.slice();
    let tempProfessionDescriptionTitle =
      this.state.jobDescriptionData.jobProfessionTitle.slice();
    tempProfessionDescriptionCode.push(professionDescriptionCode);
    tempProfessionDescriptionTitle.push(jobTitle);
    this.setState((prevState) => ({
      jobDescriptionStepStatus: "finish",
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        jobCodeProfession: tempProfessionDescriptionCode,
        jobProfessionTitle: tempProfessionDescriptionTitle,
      },
    }));

    //Salva il dato nel componente form di antd, in modo da poter gestire eventuale errori.
    this.props.form.setFieldsValue({
      titleOption: tempProfessionDescriptionTitle,
    });
  };

  onProfessionDescriptionDeselect = (jobTitle, professionDescriptionCode) => {
    let tempProfessionDescriptionCode =
      this.state.jobDescriptionData.jobCodeProfession.slice();
    let tempProfessionDescriptionTitle =
      this.state.jobDescriptionData.jobProfessionTitle.slice();
    const indexCode = tempProfessionDescriptionCode.indexOf(
      professionDescriptionCode
    );
    tempProfessionDescriptionCode.splice(indexCode, 1);
    const indexTitle = tempProfessionDescriptionTitle.indexOf(jobTitle);
    tempProfessionDescriptionTitle.splice(indexTitle, 1);
    this.setState((prevState) => ({
      jobDescriptionStepStatus: "finish",
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        jobCodeProfession: tempProfessionDescriptionCode,
        jobProfessionTitle: tempProfessionDescriptionTitle,
      },
    }));

    //Salva il dato nel componente form di antd, in modo da poter gestire eventuale errori.
    this.props.form.setFieldsValue({
      titleOption: tempProfessionDescriptionTitle,
    });
  };

  /**
   * Lanciato al cambiamento del codice della job description.
   * Il prevState permette di mantenere invariato lo stato e di modificare solo i campi
   * necessari, senza resettare tutto lo stato.
   */
  onJobCodeChange = (jobCode) => {
    Axios.get(`/api/verifyJobCode/${jobCode}`)
      .then((response) => {
        if (response.data === "") {
          this.setState((prevState) => ({
            jobDescriptionStepStatus: "finish",
            jobCodeStatus: "success",
            jobCodeHelpText:
              localStorage.getItem("Language") === "it"
                ? "Codice già utilizzato"
                : "Code already used",
          }));
        } else {
          if (this.state.jobCodeHelpText !== "warning")
            this.setState((prevState) => ({
              jobDescriptionStepStatus: "error",
              jobCodeStatus: "warning",
              jobCodeHelpText:
                localStorage.getItem("Language") === "it"
                  ? "Codice già utilizzato"
                  : "Code already used",
            }));
        }
        this.setState((prevState) => ({
          jobDescriptionData: {
            ...prevState.jobDescriptionData,
            jobDescriptionCode: jobCode,
          },
        }));
      })
      .catch((error) => {
        //TODO GESTIONE ERRORE
      });

    //Salva il dato nel componente form di antd, in modo da poter gestire eventuale errori.
    this.props.form.setFieldsValue({
      jobDescriptionCode: jobCode,
    });
  };

  /**
   * Lanciato al cambiamento del tipo di visualizzazione dei candidati.
   * Il prevState permette di mantenere invariato lo stato e di modificare solo i campi
   * necessari, senza resettare tutto lo stato.
   */
  onViewChange = (value) => {
    this.setState((prevState) => ({
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        view: value,
      },
    }));

    //Salva il dato nel componente form di antd, in modo da poter gestire eventuale errori.
    this.props.form.setFieldsValue({
      view: value,
    });
  };

  /**
   * Lanciato al cambiamento della descrizione.
   * Il prevState permette di mantenere invariato lo stato e di modificare solo i campi
   * necessari, senza resettare tutto lo stato.
   */
  onJobDescriptionChange = (jobDescription) => {
    this.setState((prevState) => ({
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        description: jobDescription,
      },
    }));

    //Salva il dato nel componente form di antd, in modo da poter gestire eventuale errori.
    this.props.form.setFieldsValue({
      description: jobDescription,
    });
  };

  //Lanciato al cambiamento del valore degli anni di esperienza nel secondo step.
  onYearsOfExperienceChange = (yearsOfExperienceValue) => {
    //Aggiorna lo stato con il dato aggiornato
    this.setState((prevState) => ({
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        anniEsperienza: yearsOfExperienceValue,
      },
    }));

    if (
      this.state.jobDescriptionData.anniEsperienzaMax <
        yearsOfExperienceValue &&
      this.state.jobDescriptionData.anniEsperienzaType === 5
    ) {
      this.setState((prevState) => ({
        experienceYearsErrorMessage: {
          ...prevState.experienceYearsErrorMessage,
          error: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        experienceYearsErrorMessage: {
          ...prevState.experienceYearsErrorMessage,
          error: false,
        },
      }));
    }
  };

  onOperationChange = (value) => {
    this.setState((prevState) => ({
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        anniEsperienzaType: value,
      },
    }));
  };

  /* activateMaxYearHandler = (mandatory) => {
    this.setState({
      maxYearsActive: mandatory.checked,
    });

    if (!mandatory.checked) {
      this.setState((prevState) => ({
        experienceYearsErrorMessage: {
          ...prevState.experienceYearsErrorMessage,
          error: false,
        },
      }));
    } else if (
      this.state.jobDescriptionData.anniEsperienza >
      this.state.jobDescriptionData.anniEsperienzaMax
    ) {
      this.setState((prevState) => ({
        experienceYearsErrorMessage: {
          ...prevState.experienceYearsErrorMessage,
          error: true,
        },
      }));
    }
  }; */

  onMaxYearsOfExperienceChange = (yearsOfExperienceValue, isActive) => {
    //Aggiorna lo stato con il dato aggiornato
    this.setState((prevState) => ({
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        anniEsperienzaMax: yearsOfExperienceValue,
      },
    }));

    if (
      this.state.jobDescriptionData.anniEsperienza > yearsOfExperienceValue &&
      this.state.jobDescriptionData.anniEsperienzaType === 5
    ) {
      this.setState((prevState) => ({
        experienceYearsErrorMessage: {
          ...prevState.experienceYearsErrorMessage,
          error: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        experienceYearsErrorMessage: {
          ...prevState.experienceYearsErrorMessage,
          error: false,
        },
      }));
    }
  };

  /**
   * Lanciato al cambiamento del peso degli anni di esperienza. Funziona allo stesso modo
   * del metodo precedente, ma con il peso degli anni di esperienza piuttosto che col valore.
   */
  onYearsOfExperienceWeightChange = (yearsOfExperienceWeight) => {
    if (!isNaN(yearsOfExperienceWeight)) {
      let updatedRequirementsTotalPercentage =
        this.state.requirementsStepTotalPercentage +
        yearsOfExperienceWeight -
        this.state.jobDescriptionData.weightAnniEsperienza;
      this.setState((prevState) => ({
        jobDescriptionData: {
          ...prevState.jobDescriptionData,
          weightAnniEsperienza: yearsOfExperienceWeight,
        },
        requirementsStepTotalPercentage: updatedRequirementsTotalPercentage,
      }));
    }
  };

  onExperienceYearsMandatory = (mandatory) => {
    this.setState((prevState) => ({
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        mandatoryAnniEsperienza: mandatory.checked,
      },
    }));
  };

  onEducationLevelMandatory = (mandatory) => {
    this.setState((prevState) => ({
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        mandatoryLivelloIstruzione: mandatory.checked,
      },
    }));
  };

  onThematicMandatory = (mandatory) => {
    this.setState((prevState) => ({
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        mandatoryCorsoLaurea: mandatory.checked,
      },
    }));
  };

  /**
   * Funzionamento identico a quello degli anni di esperienza, ma per il livello d'istruzione.
   * In questo caso viene aggiornato il valore del livello d'istruzione. Il livello d'istruzione
   * si trova nella posizione [0] dell'array delle features
   */
  onEducationLevelChange = (educationLevelValue) => {
    this.setState((prevState) => ({
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        livelloIstruzione: educationLevelValue,
      },
    }));
  };

  /**
   * Funzionamento identico a quello degli anni di esperienza, ma per il livello d'istruzione.
   * In questo caso viene aggiornato il peso del livello d'istruzione. Il livello d'istruzione
   * si trova nella posizione [0] dell'array delle features
   */
  onEducationLevelWeightChange = (educationLevelWeight) => {
    if (!isNaN(educationLevelWeight)) {
      let updatedRequirementsTotalPercentage =
        this.state.requirementsStepTotalPercentage +
        educationLevelWeight -
        this.state.jobDescriptionData.weightLivelloIstruzione;
      this.setState((prevState) => ({
        jobDescriptionData: {
          ...prevState.jobDescriptionData,
          weightLivelloIstruzione: educationLevelWeight,
        },
        requirementsStepTotalPercentage: updatedRequirementsTotalPercentage,
      }));
    }
  };

  //Viene aggiornato, al cambiamento, il valore dell'area tematica, mantenendo invariato lo stato precedente.
  onThemeAreaChange = (themeAreaValue) => {
    if (isNaN(themeAreaValue)) {
      let tempCoursesIds = this.state.courses.slice();
      let tempCourses = this.state.jobDescriptionData.coursesList.slice();
      let tempCoursesNamed = this.state.coursesNamed.slice();
      //tempCourses = tempCourses.concat(themeAreaValue);
      let courseId = themeAreaValue.split(" ");
      tempCoursesIds = tempCoursesIds.concat(courseId[0]);
      tempCoursesNamed = tempCoursesNamed.concat(themeAreaValue);
      Axios.get(`/api/laurea/${courseId[0]}`)
        .then((response) => {
          tempCourses = tempCourses.concat(response.data);
          this.setState((prevState) => ({
            jobDescriptionData: {
              ...prevState.jobDescriptionData,
              coursesList: tempCourses,
            },
            courses: tempCoursesIds,
            coursesNamed: tempCoursesNamed,
          }));
        })
        .catch((error) => {
          //TODO Gestione errore
        });
    } else {
      this.setState({
        areDataLoading: true,
        spinText:
          localStorage.getItem("Language") === "it"
            ? "Caricamento dei corsi"
            : "Course loading",
      });
      Axios.get(`/api/laurea/search/findByAreaId?themeAreaId=${themeAreaValue}`)
        .then((response) => {
          let tempCourses = this.state.jobDescriptionData.coursesList.slice();
          tempCourses = tempCourses.concat(response.data._embedded.laureas);
          let tempCoursesNamed = this.state.coursesNamed.slice();
          tempCoursesNamed = response.data._embedded.laureas.map(
            (course) => `${course.id} ${course.nome}`
          );
          let tempThemeAreas = this.state.jobDescriptionData.theme_area.slice();
          tempThemeAreas = tempThemeAreas.concat(themeAreaValue);
          let tempCoursesIds = tempCourses.map((course) => {
            return course.id;
          });
          this.setState((prevState) => ({
            jobDescriptionData: {
              ...prevState.jobDescriptionData,
              theme_area: tempThemeAreas,
              coursesList: tempCourses,
            },
            areDataLoading: false,
            courses: tempCoursesIds,
            coursesNamed: tempCoursesNamed,
            //dbCourses: tempCourses,
            spinText:
              localStorage.getItem("Language") === "it"
                ? "Caricamento dati in corso"
                : "Data loading",
          }));
        })
        .catch((error) => {
          if (error.response) {
            errorRouter(error.response.status, this.props.history);
          }
        });
    }
  };

  onThemeAreaDeselect = (removedThemeArea) => {
    if (isNaN(removedThemeArea)) {
      let tempCoursesIds = this.state.courses.slice();
      let removedThemeAreaId = removedThemeArea.split(" ")[0];
      tempCoursesIds.splice(tempCoursesIds.indexOf(removedThemeAreaId), 1);
      let tempCourses = this.state.jobDescriptionData.coursesList.slice();
      let tempCoursesNamed = this.state.coursesNamed.slice();
      let removedCoursesNamedIndex = tempCoursesNamed.indexOf(removedThemeArea);
      let removedCourseIndex = tempCourses.indexOf(removedThemeArea);
      tempCourses.splice(removedCourseIndex, 1);
      tempCoursesNamed.splice(removedCoursesNamedIndex, 1);
      this.setState((prevState) => ({
        jobDescriptionData: {
          ...prevState.jobDescriptionData,
          coursesList: tempCourses,
        },
        courses: tempCoursesIds,
        coursesNamed: tempCoursesNamed,
      }));
    } else {
      let tempThemeAreas = this.state.jobDescriptionData.theme_area.slice();
      let removedThemeAreaIndex = tempThemeAreas.indexOf(removedThemeArea);
      tempThemeAreas.splice(removedThemeAreaIndex, 1);
      let tempCourses = this.state.jobDescriptionData.coursesList.slice();
      let tempCoursesNamed = this.state.coursesNamed.slice();
      let removedCoursesIndex = tempCourses.map((course, index) => {
        if (course._embedded.area.id === removedThemeArea) return index;
        else return -1;
      });
      for (let i = removedCoursesIndex.length - 1; i >= 0; i--) {
        if (removedCoursesIndex[i] !== -1) {
          tempCoursesNamed.splice(removedCoursesIndex[i], 1);
          tempCourses.splice(removedCoursesIndex[i], 1);
        }
      }
      let tempCoursesIds = tempCourses.map((course) => {
        return course.id;
      });
      this.setState((prevState) => ({
        jobDescriptionData: {
          ...prevState.jobDescriptionData,
          theme_area: tempThemeAreas,
          coursesList: tempCourses,
        },
        courses: tempCoursesIds,
        coursesNamed: tempCoursesNamed,
      }));
    }
  };

  //Viene aggiornato, al cambiamento, il peso dell'area tematica, mantenendo invariato lo stato precedente.
  onThemeAreaWeightChange = (themeAreaWeight) => {
    if (!isNaN(themeAreaWeight)) {
      let updatedRequirementsTotalPercentage =
        this.state.requirementsStepTotalPercentage +
        themeAreaWeight -
        this.state.jobDescriptionData.weightCorsoLaurea;
      this.setState((prevState) => ({
        jobDescriptionData: {
          ...prevState.jobDescriptionData,
          weightCorsoLaurea: themeAreaWeight,
        },
        requirementsStepTotalPercentage: updatedRequirementsTotalPercentage,
      }));
    }
  };

  //Al cambiamento di uno dei corsi di studio, nel secondo step, viene aggiornato il dato.
  onCourseChange = (courseIndex, courseValue) => {
    //Viene preso l'array contenente tutti i corsi
    let coursesArray = this.state.jobDescriptionData.coursesList.slice();
    //In base all'index passato dal sottocomponente, viene aggiornato il dato del corso in questione.
    coursesArray[courseIndex].nameValue = courseValue;
    //Lo stato viene aggiornato con il nuovo dato.
    this.setState((prevState) => ({
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        coursesList: coursesArray,
      },
    }));
  };

  onSkillsSearch = (searchValue) => {
    let skillsObj = [];
    /* this.state.skills.forEach((skill) => {
      skillsObj.push({
        category: skill.category,
        descriptions: skill.descriptions,
        id: skill.id_skill,
      });
    }); */
    Axios.get(
      `api/skillTk/search/findTop50ByDescriptionsContainingOrderByDescriptionsAsc?searchString=${searchValue}`
    )
      .then((response) => {
        this.setState({
          dbSkills: skillsObj.concat(response.data._embedded.skillTks),
        });
      })
      .catch((error) => {
        if (error.response) {
          errorRouter(error.response.status, this.props.history);
        }
      });
  };

  //Metodo lanciato al cambiamento delle skills selezionate, nel terzo step di creazione/modifica della job
  onSkillsChange = (selectedSkills) => {
    let found = false;
    let skills = this.state.skills.slice();
    if (skills.length > selectedSkills.length) {
      let removedElementIndex = 0;
      skills.forEach((skill, index) => {
        selectedSkills.forEach((selectedSkill) => {
          if (skill.id_skill === selectedSkill) {
            found = true;
          }
        });
        if (!found) {
          removedElementIndex = index;
        }
        found = false;
      });
      skills.splice(removedElementIndex, 1);
    } else {
      let dbSkills = this.state.dbSkills.slice();
      let selectedSkillName = "";
      let selectedSkillType = 0;
      dbSkills.forEach((skill) => {
        if (skill.id === selectedSkills[selectedSkills.length - 1]) {
          selectedSkillName = skill.descriptions;
          selectedSkillType = skill.category;
        }
      });
      let skillObj = {
        id_skill: selectedSkills[selectedSkills.length - 1],
        weight:
          skills.length === 0 &&
          this.state.skillsStepTotalPercentage !== 0 &&
          this.state.requirementsStepTotalPercentage !== 0
            ? this.state.skillsStepTotalPercentage
            : this.state.requirementsStepTotalPercentage === 0 &&
              this.state.skillsStepTotalPercentage === 0
            ? 100
            : 0,
        category: selectedSkillType,
        descriptions: selectedSkillName,
        mandatory: false,
      };
      skills = skills.concat(skillObj);
    }
    let updatedSkillsPercentage = 0;
    skills.forEach((skill) => {
      updatedSkillsPercentage += skill.weight;
    });
    this.setState((prevState) => ({
      ...prevState,
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
      },
      skillsStepTotalPercentage: updatedSkillsPercentage,
      skills: selectedSkills.length === 0 ? [] : skills,
    }));
  };

  //Lanciato al cambiamento del peso di una skill, nel terzo step di creazione/modifica della job description
  onSkillWeightChange = (modifiedWeight, idModifiedSkill) => {
    //Recupera l'array di tutte le skills selezionate, dallo stato
    let skills = this.state.skills.slice();
    let modifiedSkillIndex = 0;
    //Cerca l'index, nell'array, della skill modificata
    skills.map((skill, index) => {
      if (skill.id_skill === idModifiedSkill) {
        modifiedSkillIndex = index;
        return true;
      }
      return false;
    });
    //Modifica, in base all'index trovato, il peso della skill modificata
    skills[modifiedSkillIndex].weight = modifiedWeight;
    let updatedSkillsPercentage = 0;
    skills.forEach((skill) => {
      updatedSkillsPercentage += skill.weight;
    });
    //Aggiorna lo stato con il dato aggiornato
    this.setState((prevState) => ({
      ...prevState,
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
      },
      skillsStepTotalPercentage: updatedSkillsPercentage,
      skills: skills,
    }));
  };

  onSkillMandatoryChange = (mandatory) => {
    //Recupera l'array di tutte le skills selezionate, dallo stato
    let skills = this.state.skills.slice();
    let modifiedSkillIndex = 0;
    //Cerca l'index, nell'array, della skill modificata
    skills.map((skill, index) => {
      if (skill.id_skill === mandatory.value) {
        modifiedSkillIndex = index;
        return true;
      }
      return false;
    });
    //Modifica, in base all'index trovato, il peso della skill modificata
    skills[modifiedSkillIndex].mandatory = mandatory.checked;
    //Aggiorna lo stato con il dato aggiornato
    this.setState((prevState) => ({
      ...prevState,
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
      },
      skills: skills,
    }));
  };

  /**
   * Lanciato al click del tasto di aggiunta di un corso. Prende l'array con i corsi
   * e aggiunge un nuovo oggetto courseObject. Aggiorna lo stato con l'array contenente il
   * nuovo oggetto.
   */
  addCourse = () => {
    let coursesArray = this.state.jobDescriptionData.coursesList.slice();
    let courseObject = {
      nameValue: 0,
      weight: 0,
    };
    coursesArray.push(courseObject);
    this.setState((prevState) => ({
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        coursesList: coursesArray,
      },
    }));
  };

  //Al contrario del metodo precedente, prende l'array dei corsi e rimuove l'elemento all'index indicato
  removeCourse = (index) => {
    let coursesArray = this.state.jobDescriptionData.coursesList.slice();
    coursesArray.splice(index, 1);
    this.setState((prevState) => ({
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        coursesList: coursesArray,
      },
    }));
  };

  //E' lanciato al click del tasto "Analizza Descrizione"
  scanDescription = () => {
    this.setState({
      isScanningDescription: true,
    });
  };

  //Imposta tutti i dati del primo step di modo che, al salvataggio, possano essere sottoposti ai controlli specificati nel componente
  setJobDescriptionStepData = () => {
    this.props.form.setFieldsValue({
      title: this.state.jobDescriptionData.title,
      titleOption: this.state.jobDescriptionData.jobProfessionTitle,
      jobDescriptionCode: this.state.jobDescriptionData.jobDescriptionCode,
      view: this.state.jobDescriptionData.view,
      description: this.state.jobDescriptionData.description,
    });
  };

  //Lanciato al click del bottone di salvataggio
  onFormSubmit = async (e) => {
    this.event = e;

    if (this.state.jobDescriptionData.anniEsperienzaType !== 5) {
      this.setState((prevState) => ({
        jobDescriptionData: {
          ...prevState.jobDescriptionData,
          anniEsperienzaMax: 0,
        },
      }));
    }

    //Torna al primo step, in modo da poter caricare i dati richiesti della job, e controllare se ci sono errori
    await this.onStepChange(0);
    //Lancia il controllo degli errori, per fare il check dei dati.
    await this.formErrorHandler(this.event);
  };

  onStepsPercentageModalCancel = () => {
    this.setState({
      isStepsPercentageModalOpen: false,
    });
  };

  onStepsPercentageModalSubmit = async (requirementsWeight, skillsWeight) => {
    await this.setState((prevState) => ({
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        requirementsWeight,
        skillsWeight,
      },
    }));
    await this.saveJobDescriptionData();
  };

  stepAvanti = async (minYears, maxYears) => {
    //Torna al primo step, in modo da poter caricare i dati richiesti della job, e controllare se ci sono errori
    if (
      maxYears !== null &&
      minYears > maxYears &&
      this.state.jobDescriptionData.anniEsperienzaType === 5
    ) {
      this.showError(this.state.experienceYearsErrorMessage.message);
    } else {
      let current = this.state.currentStep;
      if (current === 1 && this.state.autoCompleteUsed) {
      }
      await this.onStepChange(current + 1);
    }
  };

  showError(msg) {
    this.toast.show({
      severity: "error",
      summary: "Errore",
      detail: msg,
      life: 3000,
    });
  }

  saveJobDescriptionData = async () => {
    if (this.props.isJobUpdating) {
      let tempCourses = [];
      if (this.state.courses) {
        this.state.courses.forEach((course) => {
          if (typeof course === "string") {
            tempCourses.push(parseInt(course));
          } else {
            tempCourses.push(course);
          }
        });
      }
      let jobDescriptionData = this.state.jobDescriptionData;
      let jobCodeProfession = [];
      let jobProfessionTitle =
        this.state.jobDescriptionData.jobProfessionTitle.slice();
      this.state.jobDescriptionData.jobCodeProfession.forEach((id, index) => {
        jobCodeProfession = jobCodeProfession.concat({
          jobCode: id,
          jobTitle: jobProfessionTitle[index],
        });
      });
      jobDescriptionData.jobProfessions = jobCodeProfession;
      jobDescriptionData.jobCodeProfession = 0;

      if (
        jobDescriptionData.jobDescriptionCode &&
        jobDescriptionData.jobDescriptionCode.trim().length === 0
      ) {
        jobDescriptionData.jobDescriptionCode = null;
      }

      Axios.post(`/api/jobDescription/edit/${this.props.idUpdatingJob}`, {
        jobFrontEnd: {
          jobDescription: jobDescriptionData,
          skills: this.state.skills,
          courses: tempCourses,
        },
      })
        .then(() => {
          this.props.history.push("/jobdescriptions");
        })
        .catch((error) => {
          notification.error({
            message:
              localStorage.getItem("Language") === "it"
                ? "Errore inserimento"
                : "Input error",
            description:
              localStorage.getItem("Language") === "it"
                ? "Errore inserimento posizione lavorativa"
                : "Job description entry error",
            placement: "bottomRight",
          });
          if (error.response) {
            errorRouter(error.response.status, this.props.history);
          }
        });
    } else {
      let jobDescriptionData = this.state.jobDescriptionData;

      let jobCodeProfession = [];
      let jobProfessionTitle =
        this.state.jobDescriptionData.jobProfessionTitle.slice();
      this.state.jobDescriptionData.jobCodeProfession.forEach((id, index) => {
        jobCodeProfession = jobCodeProfession.concat({
          jobCode: id,
          jobTitle: jobProfessionTitle[index],
        });
      });
      jobDescriptionData.jobCodeProfession = jobCodeProfession;
      jobDescriptionData.jobCodeProfession = 0;
      if (jobDescriptionData.jobDescriptionCode.trim().length === 0) {
        jobDescriptionData.jobDescriptionCode = null;
      }

      Axios.post("/api/jobDescription/add/", {
        jobFrontEnd: {
          jobDescription: jobDescriptionData,
          skills: this.state.skills,
          courses: this.state.courses,
        },
      })
        .then(() => {
          this.props.history.push("/jobdescriptions");
        })
        .catch((error) => {
          notification.error({
            message:
              localStorage.getItem("Language") === "it"
                ? "Errore inserimento"
                : "Input error",
            description:
              localStorage.getItem("Language") === "it"
                ? "Errore inserimento posizione lavorativa"
                : "Job description entry error",
            placement: "bottomRight",
          });
          if (error.response) {
            errorRouter(error.response.status, this.props.history);
          }
        });
    }
  };

  /**
   * Una volta che lo step è impostato al primo, controlla che i dati inseriti siano corretti.
   * In caso affermativo, viene effettuata la chiamata al backend e la job description salvata,
   * altrimenti viene impostato lo stato di errore, in modo che sia visualizzabile all'utente
   */
  formErrorHandler = (e) => {
    if (this.state.currentStep === 0) {
      let errorOnSkillWeight =
        this.state.skills.length === 0 && this.state.skillsStepTotalPercentage;
      e.preventDefault();
      this.props.form.validateFields((err) => {
        if (
          !err &&
          !errorOnSkillWeight &&
          this.state.requirementsStepTotalPercentage +
            this.state.skillsStepTotalPercentage ===
            100
        ) {
          /* //Apre il modal che permette di scegliere la percentuale di ogni sezione
          this.setState({
            isStepsPercentageModalOpen: true,
          }) */
          this.saveJobDescriptionData();
        } else {
          if (err) {
            this.setState({
              jobDescriptionStepStatus: "error",
              jobCodeStatus:
                this.state.jobCodeStatus === "warning" ? "warning" : "error",
              jobCodeHelpText:
                localStorage.getItem("Language") === "it"
                  ? "Inserire il codice della posizione lavorativa!"
                  : "Enter job description code!",
            });
          }
          if (
            this.state.requirementsStepTotalPercentage +
              this.state.skillsStepTotalPercentage !==
            100
          ) {
            this.setState({
              requirementsStepStatus: "error",
              skillsStepStatus: "error",
            });
            notification.open({
              message:
                localStorage.getItem("Language") === "it"
                  ? "Correggi i pesi"
                  : "Correct the weights",
              description:
                localStorage.getItem("Language") === "it"
                  ? 'I due step "Requisiti" e "Competenze" devono avere un peso totale pari a 100%, attualmente è di ' + (this.state.requirementsStepTotalPercentage + this.state.skillsStepTotalPercentage) + "%"
                  : 'Two steps "Requirements" and "Skills" must have a total weight of 100%, now is of ' + (this.state.requirementsStepTotalPercentage + this.state.skillsStepTotalPercentage) + "%",
              placement: "bottomLeft",
            });
          }
          if (errorOnSkillWeight) {
            this.setState({
              requirementsStepStatus: "error",
              skillsStepStatus: "error",
            });
            notification.open({
              message:
                localStorage.getItem("Language") === "it"
                  ? "Correggi le skill"
                  : "Correct the skills",
              description:
                localStorage.getItem("Language") === "it"
                  ? 'Lo step "Competenze" presenta un peso ma nessuna skill'
                  : 'The step "Skills" has a weight but no skill',
              placement: "bottomLeft",
            });
          }
        }
      });
    }
  };

  onWeightChangeForRequirements = (value) => {
    let tmpYearsWeight = 0;
    let tmpEducationalWeight = 0;
    let tmpThematicWeight = 0;

    if (
      this.state.jobDescriptionData.livelloIstruzione !== 3 &&
      this.state.jobDescriptionData.livelloIstruzione !== 4
    ) {
      if (value % 2 === 0) {
        tmpYearsWeight = value / 2;
        tmpEducationalWeight = value / 2;
      } else {
        tmpYearsWeight = Math.trunc(value / 2) + 1;
        tmpEducationalWeight = Math.trunc(value / 2);
      }
    } else {
      let remaining = value % 3;
      if (remaining === 0) {
        tmpYearsWeight = value / 3;
        tmpEducationalWeight = value / 3;
        tmpThematicWeight = value / 3;
      } else if (remaining === 1) {
        tmpYearsWeight = Math.trunc(value / 3) + 1;
        tmpEducationalWeight = Math.trunc(value / 3);
        tmpThematicWeight = Math.trunc(value / 3);
      } else {
        tmpYearsWeight = Math.trunc(value / 3) + 1;
        tmpEducationalWeight = Math.trunc(value / 3) + 1;
        tmpThematicWeight = Math.trunc(value / 3);
      }
    }

    this.setState((prevState) => ({
      jobDescriptionData: {
        ...prevState.jobDescriptionData,
        weightAnniEsperienza: tmpYearsWeight,
        weightLivelloIstruzione: tmpEducationalWeight,
        weightCorsoLaurea: tmpThematicWeight,
      },
    }));
  };

  onWeightChangeForSkills = (value) => {
    let length = this.state.skills.length;
    let remaining = value % length;
    let weight = value / length;
    let loopTimes = remaining === 0 ? 1 : 2;

    for (let i = 0; i < loopTimes; i++) {
      this.state.skills.forEach((skill) => {
        if (i === 0) {
          skill.weight = Math.trunc(weight);
        } else if (remaining !== 0) {
          skill.weight += 1;
          remaining -= 1;
        }
      });
    }
  };

  onTotalRequirementsWeightChange = (value) => {
    this.onWeightChangeForRequirements(value);

    this.setState((prevState) => ({
      requirementsStepTotalPercentage: value,
    }));
  };

  onTotalSkillWeightChange = (value) => {
    this.onWeightChangeForSkills(value);

    this.setState((prevState) => ({
      skillsStepTotalPercentage: value,
    }));
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Spin
        spinning={this.state.areDataLoading}
        tip={this.state.spinText}
        size="large"
      >
        <Toast ref={(el) => (this.toast = el)} />
        <Layout className="steps_header-container">
          <Header style={{ backgroundColor: "#f9f9fc" }}>
            <Steps
              current={this.state.currentStep}
              onChange={this.onStepChange}
              type="navigation"
              size="large"
            >
              <Step
                title={<Translate content="newJobDescription.importText" />}
                description={
                  <Translate content="newJobDescription.importDescriptionText" />
                }
                status={this.state.importStepStatus}
                disabled={this.state.autoCompleteUsed}
                icon={
                  this.state.importStepStatus === "error" ? (
                    <i
                      class="fa-regular fa-circle-xmark iconsCandidate"
                      style={{
                        fontSize: "1.2rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></i>
                  ) : this.state.autoCompleteUsed === true ? (
                    <i
                      class="fa-regular fa-circle-check iconsCandidate"
                      style={{
                        fontSize: "1.2rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></i>
                  ) : this.state.isScanningDescription ? (
                    <i
                      class="fa-regular fa-loader iconsCandidate"
                      style={{
                        fontSize: "1.2rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></i>
                  ) : (
                    <i
                      class="fa-regular fa-upload iconsCandidate"
                      style={{
                        fontSize: "1.2rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></i>
                  )
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
              <Step
                title={
                  <Translate content="newJobDescription.jobDescriptionText" />
                }
                description={
                  <Translate content="jobDescriptions.jobDescriptionDataText" />
                }
                status={this.state.jobDescriptionStepStatus}
                icon={
                  this.state.jobDescriptionStepStatus === "error" ? (
                    <i
                      class="fa-regular fa-circle-xmark iconsCandidate"
                      style={{
                        fontSize: "1.2rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></i>
                  ) : this.state.isScanningDescription ? (
                    <i
                      class="fa-regular fa-loader iconsCandidate"
                      style={{
                        fontSize: "1.2rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></i>
                  ) : (
                    <i
                      class="fa-regular fa-pen-line iconsCandidate"
                      style={{
                        fontSize: "1.2rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></i>
                  )
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
              <Step
                title={
                  <Translate content="newJobDescription.requirementText" />
                }
                subTitle={
                  <InputNumber
                    value={this.state.requirementsStepTotalPercentage}
                    min={0}
                    max={100}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace("%", "")}
                    onChange={this.onTotalRequirementsWeightChange}
                    style={{ maxWidth: "4.5rem" }}
                  />
                }
                description={
                  <Translate content="newJobDescription.requirementDescriptionText" />
                }
                status={this.state.requirementsStepStatus}
                icon={
                  this.state.requirementsStepStatus === "error" ? (
                    <i
                      class="fa-regular fa-circle-xmark iconsCandidate"
                      style={{
                        fontSize: "1.2rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></i>
                  ) : (
                    <i
                      class="fa-regular fa-rectangle-list iconsCandidate"
                      style={{
                        fontSize: "1.2rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></i>
                  )
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
              <Step
                disabled={this.state.experienceYearsErrorMessage.error}
                title={<Translate content="newJobDescription.skillText" />}
                subTitle={
                  <InputNumber
                    value={this.state.skillsStepTotalPercentage}
                    min={0}
                    max={100}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace("%", "")}
                    onChange={this.onTotalSkillWeightChange}
                    style={{ maxWidth: "4.5rem" }}
                  />
                }
                description={
                  <Translate content="newJobDescription.skillDescriptionText" />
                }
                status={this.state.skillsStepStatus}
                icon={
                  this.state.skillsStepStatus === "error" ? (
                    <i
                      class="fa-regular fa-circle-xmark iconsCandidate"
                      style={{
                        fontSize: "1.2rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></i>
                  ) : (
                    <i
                      class="fa-regular fa-address-card iconsCandidate"
                      style={{
                        fontSize: "1.2rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></i>
                  )
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            </Steps>
          </Header>
          <Content style={{ paddingTop: "inherit", paddingBottom: "70px" }}>
            <div className="steps-container">
              <div style={{ padding: "20px 40px 0px 40px" }}>
                <Row type="flex" align="middle" justify="center">
                  <Col
                    xs={{ span: 10 }}
                    sm={{ span: 10 }}
                    md={{ span: 10 }}
                    lg={{ span: 10 }}
                    xl={{ span: 10 }}
                    xxl={{ span: 10 }}
                  >
                    <Title level={2} type="secondary">
                      {this.state.jobDescriptionName}
                    </Title>
                  </Col>
                  <Col
                    xs={{ span: 3, offset: 9 }}
                    sm={{ span: 3, offset: 9 }}
                    md={{ span: 3, offset: 9 }}
                    lg={{ span: 3, offset: 9 }}
                    xl={{ span: 2, offset: 7 }}
                    xxl={{ span: 1, offset: 8 }}
                  >
                    <Progress
                      type="circle"
                      width={"3rem"}
                      status={
                        this.state.requirementsStepTotalPercentage +
                          this.state.skillsStepTotalPercentage >
                          100 ||
                        this.state.requirementsStepTotalPercentage +
                          this.state.skillsStepTotalPercentage <
                          0
                          ? "exception"
                          : this.state.requirementsStepTotalPercentage +
                              this.state.skillsStepTotalPercentage <
                            100
                          ? "active"
                          : this.state.requirementsStepTotalPercentage +
                              this.state.skillsStepTotalPercentage ===
                            100
                          ? "success"
                          : "active"
                      }
                      strokeColor={
                        this.state.requirementsStepTotalPercentage +
                          this.state.skillsStepTotalPercentage >
                          100 ||
                        this.state.requirementsStepTotalPercentage +
                          this.state.skillsStepTotalPercentage <
                          0
                          ? { "100%": "#2317b5" }
                          : {
                              "0%": "#E56842 ",
                              "61%": "#763a84",
                              "100%": "#2317b5",
                            }
                      }
                      percent={
                        this.state.requirementsStepTotalPercentage +
                        this.state.skillsStepTotalPercentage
                      }
                    />
                  </Col>
                  <Col
                    xs={{ span: 3 }}
                    sm={{ span: 3 }}
                    md={{ span: 3 }}
                    lg={{ span: 3 }}
                    xl={{ span: 3 }}
                    xxl={{ span: 3 }}
                  >
                    <Button
                      disabled={this.state.experienceYearsErrorMessage.error}
                      type="primary"
                      color="#3f88c5"
                      onClick={this.onFormSubmit}
                      onSubmit={this.onFormSubmit}
                    >
                      <Translate content="newJobDescription.saveButtonText" />
                    </Button>
                  </Col>
                </Row>
                <Divider style={{ marginBottom: 10 }} />
              </div>
              <div className="new_jobdescription">
                <Form>
                  {
                    //Carica il componente in base allo step cliccato
                    this.state.currentStep === 0 ? (
                      <ImportStep
                        onAnalizzaJob={this.onAnalizzaJob}
                        stepAvanti={this.stepAvanti}
                      />
                    ) : this.state.currentStep === 1 ? (
                      <JobDescriptionStep
                        isScanningDescription={this.state.isScanningDescription}
                        scanDescription={this.scanDescription}
                        onJobCodeChange={this.onJobCodeChange}
                        onJobTitleChange={this.onJobTitleChange}
                        onViewChange={this.onViewChange}
                        onJobDescriptionChange={this.onJobDescriptionChange}
                        onProfessionDescriptionSelect={
                          this.onProfessionDescriptionSelect
                        }
                        onProfessionDescriptionDeselect={
                          this.onProfessionDescriptionDeselect
                        }
                        setJobDescriptionStepData={
                          this.setJobDescriptionStepData
                        }
                        getFieldDecorator={getFieldDecorator}
                        totalPercentage={this.state.totalPercentage}
                        JobDescriptionExtended={
                          this.state.jobDescriptionData.description
                        }
                        jobCodeStatus={this.state.jobCodeStatus}
                        jobCodeHelpText={this.props.jobCodeHelpText}
                        jobProfessionCode={
                          this.state.jobDescriptionData.jobCodeProfession
                        }
                        jobProfessionTitle={
                          this.state.jobDescriptionData.jobProfessionTitle
                        }
                        stepAvanti={this.stepAvanti}
                      />
                    ) : this.state.currentStep === 2 ? (
                      <RequirementsStep
                        onYearsOfExperienceChange={
                          this.onYearsOfExperienceChange
                        }
                        onMaxYearsOfExperienceChange={
                          this.onMaxYearsOfExperienceChange
                        }
                        onYearsOfExperienceWeightChange={
                          this.onYearsOfExperienceWeightChange
                        }
                        onEducationLevelChange={this.onEducationLevelChange}
                        onEducationLevelWeightChange={
                          this.onEducationLevelWeightChange
                        }
                        onThemeAreaChange={this.onThemeAreaChange}
                        onThemeAreaDeselect={this.onThemeAreaDeselect}
                        onThemeAreaWeightChange={this.onThemeAreaWeightChange}
                        onCourseChange={this.onCourseChange}
                        addCourse={this.addCourse}
                        removeCourse={this.removeCourse}
                        jobDescriptionData={this.state.jobDescriptionData}
                        coursesList={this.state.courses}
                        coursesNamedList={this.state.coursesNamed}
                        getFieldDecorator={getFieldDecorator}
                        totalPercentage={
                          this.state.requirementsStepTotalPercentage
                        }
                        dbThemeAreas={this.state.dbThemeAreas}
                        dbCourses={this.state.dbCourses}
                        onAtLeastCheckboxChange={this.onAtLeastCheckboxChange}
                        onAziendaWorkCheckboxChange={
                          this.onAziendaWorkCheckboxChange
                        }
                        onDisabilityStatusCheckboxChange={
                          this.onDisabilityStatusCheckboxChange
                        }
                        stepAvanti={this.stepAvanti}
                        errorMessage={this.state.experienceYearsErrorMessage}
                        onExperienceYearsMandatory={
                          this.onExperienceYearsMandatory
                        }
                        onEducationLevelMandatory={
                          this.onEducationLevelMandatory
                        }
                        onThematicMandatory={this.onThematicMandatory}
                        activateMaxYearHandler={this.activateMaxYearHandler}
                        onOperationChange={this.onOperationChange}
                        educationLevelMapping={educationLevelMapping}
                      />
                    ) : (
                      <SkillsStep
                        getFieldDecorator={getFieldDecorator}
                        dbSkills={this.state.dbSkills}
                        onSkillsSearch={this.onSkillsSearch}
                        selectedSkills={this.state.skills}
                        onSkillsChange={this.onSkillsChange}
                        onSkillWeightChange={this.onSkillWeightChange}
                        onSkillMandatoryChange={this.onSkillMandatoryChange}
                        totalPercentage={this.state.skillsStepTotalPercentage}
                        isUpdatingJob={this.props.isJobUpdating}
                      />
                    )
                  }
                </Form>
              </div>
            </div>
          </Content>
        </Layout>
        {/*  <SaveJobModal
          onStepsPercentageModalCancel={this.onStepsPercentageModalCancel}
          onStepsPercentageModalSubmit={this.onStepsPercentageModalSubmit}
          isStepsPercentageModalOpen={this.state.isStepsPercentageModalOpen}
        /> */}
      </Spin>
    );
  }
}
NewJobDescription = Form.create({ name: "new-job_description" })(
  NewJobDescription
);
export default withRouter(NewJobDescription);
