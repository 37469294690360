/**
 * Funzione di gestione degli errori che prende in ingresso lo stato di errore e l'history di 
 * react-router. In baso allo status, decide a quale componente reindirizzare il browser, in modo 
 * da mostrare l'errore all'utente con lo specifico problema.
 */
export function errorRouter(errorStatus, history) {
  switch (errorStatus) {
    case 403:
      localStorage.setItem('Token', '')
      localStorage.setItem('Username', "");
      localStorage.setItem('userLogged', "");
      history.push('/forbiddenaccess');
      break;
    case 404:
      history.push('/notfound')
      break;
    case 503:
      history.push('/serviceunavailable')
      break;
    default:
      console.log("Errore non specificato"); //TODO ERRORE GENERICO
  }

  if (errorStatus >= 400 && errorStatus < 500) {
    if (errorStatus === 403) {
      localStorage.setItem('Token', '');
      localStorage.setItem('Username',"");
      history.push({
        pathname: '/forbiddenaccess',
        state: { statusCode: errorStatus }
      });
    }
  } else if (errorStatus >= 500 && errorStatus < 600) {

  }

  return true;
}