import React from "react";
//Componenti ant design utilizzati
import {
  Card,
  Row,
  Col,
  Badge,
  Typography,
  Tooltip,
  Button,
  notification,
  Popover,
  Spin,
  Icon,
  Menu,
  Modal,
  Input,
} from "antd";
//Componente utilizzato per la formattazione delle date
import Moment from "react-moment";
//Librerie per il multilingua
import Translate from "react-translate-component";

import { withRouter } from "react-router-dom";
import Axios from "axios";

import { errorRouter } from "../errors/ErrorRouter";

import infoCircle from "../../resources/icons/infoCircle.png";
import counterpart from "counterpart";

//Componenti di ant design presi da Typography
const { Title, Paragraph, Text } = Typography;

/**
 * Utilizza i props:
 * - jobDescription: si prende dal componente superiore l'intero oggetto contenente tutti i dati della job description che deve visualizzare
 * - onJobCardClick: si prende il metodo già spiegato in <JobDescriptions />, passato direttamente da <App />, che permette
 *   di salvare l'id della job description cliccata nello stato di <App />, in modo che possa poi essere passato in <SingleJobDescription />
 *   per preservare il dato di quale job description è stata selezionata, in modo da non perderlo neanche in caso di refresh della pagina
 *   da parte dell'utente (viene inserito l'id nell'URL)
 * - loadJobDescriptionsData: permette di ricaricare i dati ogni volta che vengono effettuate le modifiche ad una job description specifica,
 *   in modo che i dati della griglia siano sempre aggiornati
 */
class JobDescriptionCard extends React.Component {
  state = {
    isLoadingData: false,
    users: [],
    menu: <Menu></Menu>,
    isModalVisible: false,
    updateShare: false,
    isSearched: false,
    valueSearched: "",
  };

  //Permette di gestire l'apertura di una notifica alla volta. Se una notifica è già aperta, risulta true
  notificationIsOpen = false;

  /**
   * Nel momento del click della card lancia il metodo spiegato nel commento sopra, passando l'id della job description
   * specifica della card cliccata
   */
  onJobCardClick = (event) => {
    if (this.props.onJobCardClick !== undefined) {
      this.props.onJobCardClick(this.props.jobDescription.id);
      this.props.history.push(
        "/singleJobDescription/" + this.props.jobDescription.id
      );
    } else {
      //TODO gestire errore possibile di mancato click della card, o di click della card in <SingleCandidate />
    }
  };

  /**
   * Al click del bottone per l'eliminazione della job description, viene aperta la notifica di conferma.
   * Il controllo (!this.notificationIsOpen) fa in modo che venga aperta una sola notifica alla volta.
   * Le diverse notifiche sono identificate tramite una key creata in base all'istante ,
   * in modo da poterle gestire indistintamente l'una dall'altra
   */
  onDeleteClick = () => {
    if (!this.notificationIsOpen) {
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            notification.close(key);
            this.deleteJobDescription();
          }}
        >
          <Translate content="jobDescriptions.confirmButtonText" />
        </Button>
      );
      notification.open({
        message: <Translate content="jobDescriptions.deleteChooseText" />,
        description: (
          <Translate content="jobDescriptions.deleteDescriptionText" />
        ),
        btn,
        key,
        placement: "bottomLeft",
        duration: 0,
        onClose: () => {
          this.notificationIsOpen = false;
          this.setState({
            isLoadingData: false,
          });
        },
      });
      this.notificationIsOpen = true;
      this.setState({
        isLoadingData: true,
      });
    }
  };

  /**
   * Una volta aperta la notifica di eliminazione della job description, se l'utente conferma, allora la job description
   * viene eliminata e vengono ricaricati i dati della griglia
   */
  deleteJobDescription = () => {
    Axios.delete(`/api/job/${this.props.jobDescription.id}`)
      .then((response) => {
        if (this.props.history.location.pathname === "/jobdescriptions") {
          this.props.loadJobDescriptionsData();
        } else {
          this.props.history.push("/jobdescriptions");
        }
      })
      .catch((error) => {
        if (error.response) {
          errorRouter(error.response.status, this.props.history);
        }
      });
    this.notificationIsOpen = false;
    this.setState({
      isLoadingData: false,
    });
  };

  /**
   * Al click del bottone per l'archiviazione della job description, viene aperta la notifica di conferma.
   * Il controllo (!this.notificationIsOpen) fa in modo che venga aperta una sola notifica alla volta.
   * Le diverse notifiche sono identificate tramite una key creata in base all'istante,
   * in modo da poterle gestire indistintamente l'una dall'altra
   */
  onArchiveClick = () => {
    if (!this.notificationIsOpen) {
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            notification.close(key);
            this.archiveJobDescription();
          }}
        >
          <Translate content="jobDescriptions.confirmButtonText" />
        </Button>
      );
      notification.open({
        message: <Translate content="jobDescriptions.archiveChooseText" />,
        description: (
          <Translate content="jobDescriptions.archiveDescriptionText" />
        ),
        btn,
        key,
        placement: "bottomLeft",
        duration: 0,
        onClose: () => {
          this.notificationIsOpen = false;
          this.setState({
            isLoadingData: false,
          });
        },
      });
      this.setState({
        isLoadingData: true,
      });
    }
  };

  /**
   * Una volta aperta la notifica di archiviazione della job description, se l'utente conferma, allora la job
   * description viene archiviata e vengono ricaricati i dati della griglia/tabella
   */
  archiveJobDescription = () => {
    Axios.patch(`/api/job/${this.props.jobDescription.id}`, {
      bucket: 1,
    })
      .then((response) => {
        if (this.props.history.location.pathname === "/jobdescriptions") {
          this.props.loadJobDescriptionsData();
        } else {
          this.props.refreshJobDescriptionData();
        }
      })
      .catch((error) => {
        if (error.response) {
          errorRouter(error.response.status, this.props.history);
        }
      });
    this.notificationIsOpen = false;
    this.setState({
      isLoadingData: false,
    });
  };

  /**
   * Al click del bottone per il ripristino della job description, viene aperta la notifica di conferma.
   * Il controllo (!this.notificationIsOpen) fa in modo che venga aperta una sola notifica alla volta.
   * Le diverse notifiche sono identificate tramite una key creata in base all'istante,
   * in modo da poterle gestire indistintamente l'una dall'altra
   */
  onDearchiveClick = () => {
    if (!this.notificationIsOpen) {
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            notification.close(key);
            this.dearchiveJobDescription();
          }}
        >
          <Translate content='jobDescriptions.confirmButtonText' />
        </Button>
      );
      notification.open({
        message: <Translate content='jobDescriptions.restoreChooseText' />,
        description: <Translate content='jobDescriptions.restoreDescriptionText' />,
        btn,
        key,
        placement: "bottomLeft",
        duration: 0,
        onClose: () => {
          this.notificationIsOpen = false;
          this.setState({
            isLoadingData: false,
          });
        },
      });
      this.setState({
        isLoadingData: true,
      });
    }
  };

  /**
   * Una volta aperta la notifica di ripristino della job description, se l'utente conferma, allora la job
   * description viene ripristinata e vengono ricaricati i dati della griglia/tabella
   */
  dearchiveJobDescription = () => {
    Axios.patch(`/api/job/${this.props.jobDescription.id}`, {
      bucket: 0,
    })
      .then((response) => {
        if (this.props.history.location.pathname === "/jobdescriptions") {
          this.props.loadJobDescriptionsData();
        } else {
          this.props.refreshJobDescriptionData();
        }
      })
      .catch((error) => {
        if (error.response) {
          errorRouter(error.response.status, this.props.history);
        }
      });
    this.notificationIsOpen = false;
    this.setState({
      isLoadingData: false,
    });
  };

  /**
   * Al click del bottone per la chiusura della job description, viene aperta la notifica di conferma.
   * Il controllo (!this.notificationIsOpen) fa in modo che venga aperta una sola notifica alla volta.
   * Le diverse notifiche sono identificate tramite una key creata in base all'istante,
   * in modo da poterle gestire indistintamente l'una dall'altra
   */
  onCloseClick = () => {
    if (!this.notificationIsOpen) {
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            notification.close(key);
            this.closeJobDescription();
          }}
        >
          <Translate content='jobDescriptions.confirmButtonText' />
        </Button>
      );
      notification.open({
        message: <Translate content='jobDescriptions.closeChooseText' />,
        description: <Translate content='jobDescriptions.closeDescriptionText' />,
        btn,
        key,
        placement: "bottomLeft",
        duration: 0,
        onClose: () => {
          this.notificationIsOpen = false;
          this.setState({
            isLoadingData: false,
          });
        },
      });
      this.setState({
        isLoadingData: true,
      });
    }
  };

  /**
   * Una volta aperta la notifica di chiusura della job description, se l'utente conferma, allora la job
   * description viene chiusa e vengono ricaricati i dati della griglia/tabella
   */
  closeJobDescription = () => {
    Axios.patch(`/api/job/${this.props.jobDescription.id}`, {
      active: 0,
    })
      .then((response) => {
        if (this.props.history.location.pathname === "/jobdescriptions") {
          this.props.loadJobDescriptionsData();
        } else {
          this.props.refreshJobDescriptionData();
        }
      })
      .catch((error) => {
        if (error.response) {
          errorRouter(error.response.status, this.props.history);
        }
      });
    this.notificationIsOpen = false;
    this.setState({
      isLoadingData: false,
    });
  };

  /**
   * Al click del bottone per l'apertura della job description, viene aperta la notifica di conferma.
   * Il controllo (!this.notificationIsOpen) fa in modo che venga aperta una sola notifica alla volta.
   * Le diverse notifiche sono identificate tramite una key creata in base all'istante,
   * in modo da poterle gestire indistintamente l'una dall'altra
   */
  onOpenClick = () => {
    if (!this.notificationIsOpen) {
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            notification.close(key);
            this.openJobDescription();
          }}
        >
          <Translate content='jobDescriptions.confirmButtonText' />
        </Button>
      );
      notification.open({
        message: <Translate content='jobDescriptions.openChooseText' />,
        description: <Translate content='jobDescriptions.openDescriptionText' />,
        btn,
        key,
        placement: "bottomLeft",
        duration: 0,
        onClose: () => {
          this.notificationIsOpen = false;
          this.setState({
            isLoadingData: false,
          });
        },
      });
      this.setState({
        isLoadingData: true,
      });
    }
  };

  /**
   * Una volta aperta la notifica di apertura della job description, se l'utente conferma, allora la job
   * description viene chiusa e vengono ricaricati i dati della griglia/tabella
   */
  openJobDescription = () => {
    Axios.patch(`/api/job/${this.props.jobDescription.id}`, {
      active: 1,
    })
      .then((response) => {
        if (this.props.history.location.pathname === "/jobdescriptions") {
          this.props.loadJobDescriptionsData();
        } else {
          this.props.refreshJobDescriptionData();
        }
      })
      .catch((error) => {
        if (error.response) {
          errorRouter(error.response.status, this.props.history);
        }
      });
    this.notificationIsOpen = false;
    this.setState({
      isLoadingData: false,
    });
  };

  onJobEditClick = () => {
    this.props.onJobEditClick(this.props.jobDescription.id);
  };

  getJobUser = () => {
    return Axios.get(`/api/getJobUser/${this.props.jobDescription.id}`);
  };

  checkIsPresent = (usersSaml, usersJob) => {
    usersSaml.forEach((user) => {
      let found = false;

      usersJob.forEach((userJob) => {
        found === false
          ? userJob.matricola === undefined ||
            userJob.matricola == null ||
            userJob.matricola !== user.username
            ? (found = false)
            : (found = true)
          : (found = true);
      });

      user.shared = found;
    });

    return usersSaml;
  };

  handleOk = (e) => {
    e.stopPropagation();
    this.setState({
      isModalVisible: false,
    });
  };

  handleCancel = (e) => {
    e.stopPropagation();
    this.setState({
      isModalVisible: false,
    });
  };

  shareJob = (user) => {
    Axios.post(
      `/api/setJobUser/${this.props.jobDescription.id}/${user.username}`
    ).then(() => {
      user.shared = true;

      this.setState({
        updateShare: true,
      });
    });
  };

  stopShareJob = (user) => {
    Axios.post(
      `/api/deleteJobUser/${this.props.jobDescription.id}/${user.username}`
    ).then(() => {
      user.shared = false;

      this.setState({
        updateShare: false,
      });
    });
  };

  getSamlUsers = () => {
    this.setState({
      isModalVisible: true,
    });

    if (!this.state.isSearched) {
      let users;
      Axios.get("/api/getSamlUser").then((responseSamlUser) => {
        this.getJobUser().then((responseJobUser) => {
          users = this.checkIsPresent(
            responseSamlUser.data,
            responseJobUser.data
          );
          console.log(users);
          this.setState({
            users: users,
            isSearched: false,
          });
        });
      });
    }
  };

  searchName = (nome) => {
    let users;
    Axios.get(
      `/api/samlUser/search/findByNomeContainingOrCognomeContainingOrUsernameContaining?searchName=${nome}&searchSurname=${nome}&searchUsername=${nome}`
    ).then((response) => {
      this.getJobUser().then((responseJobUser) => {
        users = this.checkIsPresent(
          response.data._embedded.samlUsers,
          responseJobUser.data
        );
        console.log(users);
        this.setState({
          users: users,
          isSearched: true,
        });
      });
    });
  };

  changeSearch = (nome) => {
    console.log("ChangeSearch: " + nome);
  };

  render() {
    /**
     * Variabili contenenti il testo (nella lingua selezionata) dei placeholder dei campi del login. Il campo non accetta
     * l'oggetto <Translate /> utilizzato per le traduzioni, questo è il metodo alternativo per permette comunque l'utilizzo
     * delle traduzioni anche in casi del genere.
     */
    const isArchivedText = counterpart.translate(
      "jobDescriptions.isArchivedText"
    );
    const isOpenedText = counterpart.translate("jobDescriptions.isOpenedText");
    const isClosedText = counterpart.translate("jobDescriptions.isClosedText");

    /**
     * Variabili booleane che specificano se la job description è aperta/chiusa (isOpened), e anche
     * se archiviata (isArchived), in modo da poter visualizzare testo e colore differente in base alle tre
     * situazioni distinte:
     * - Aperta non archiviata
     * - Chiusa non archiviata
     * - Aperta/Chiusa archiviata
     */
    let isOpened = this.props.jobDescription.active;
    let isArchived = this.props.jobDescription.bucket;
    return (
      <Spin spinning={this.state.isLoadingData}>
        <Card hoverable style={{ height: "230px" }}>
          <Row gutter={[0, 8]} onClick={this.onJobCardClick}>
            <Col
              xs={{ span: 16 }}
              sm={{ span: 19 }}
              md={{ span: 16 }}
              lg={{ span: 18 }}
              xl={{ span: 16 }}
              xxl={{ span: 16 }}
            >
              <Title level={4} ellipsis>
                <Tooltip
                  title={
                    this.props.jobDescription.title.trim() === "" ||
                    this.props.jobDescription.title == null ||
                    this.props.jobDescription.title === undefined
                      ? "Nessun Titolo"
                      : this.props.jobDescription.title
                  }
                >
                  {this.props.jobDescription.title.trim() === "" ||
                  this.props.jobDescription.title == null ||
                  this.props.jobDescription.title === undefined
                    ? "Nessun Titolo"
                    : this.props.jobDescription.title}
                </Tooltip>
              </Title>
            </Col>
            <Col
              xs={{ span: 7, offset: 1 }}
              sm={{ span: 4, offset: 1 }}
              md={{ span: 7, offset: 1 }}
              lg={{ span: 5, offset: 1 }}
              xl={{ span: 7, offset: 1 }}
              xxl={{ span: 7, offset: 1 }}
              style={{ textAlign: "right" }}
            >
              <Tooltip title={isOpened ? isOpenedText : isClosedText}>
                <Badge
                  count={
                    isArchived
                      ? isArchivedText
                      : isOpened
                      ? isOpenedText
                      : isClosedText
                  }
                  style={{
                    backgroundColor: isArchived
                      ? "#FF9800"
                      : isOpened
                      ? "#52c41a"
                      : "#F44336",
                    width: "77px",
                    marginTop: "7px",
                    zIndex: 0,
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={[0, 8]}>
            <Col
              xs={{ span: 20 }}
              sm={{ span: 20 }}
              md={{ span: 20 }}
              lg={{ span: 20 }}
              xl={{ span: 20 }}
              xxl={{ span: 20 }}
              onClick={this.onJobCardClick}
            >
              <Text>
                <Translate content="jobDescriptions.codeText" />:{" "}
                {this.props.jobDescription.jobDescriptionCode}
              </Text>
            </Col>
            <Col
              xs={{ span: 2 }}
              sm={{ span: 2 }}
              md={{ span: 2 }}
              lg={{ span: 2 }}
              xl={{ span: 2 }}
              xxl={{ span: 2 }}
              style={{ textAlign: "center" }}
            >
              {this.props.jobDescription.categoriaProtetta ? (
                <Tooltip
                  title="Ricercata Categoria Protetta"
                  autoAdjustOverflow
                  placement="left"
                  overlayStyle={{ maxWidth: 800 }}
                >
                  <Icon
                    type="warning"
                    style={{ fontSize: 19, marginTop: 2, color: "red" }}
                  />
                </Tooltip>
              ) : null}
            </Col>
            <Col
              xs={{ span: 2 }}
              sm={{ span: 2 }}
              md={{ span: 2 }}
              lg={{ span: 2 }}
              xl={{ span: 2 }}
              xxl={{ span: 2 }}
              style={{ textAlign: "center" }}
            >
              <Popover
                title={this.props.jobDescription.title}
                content={
                  <Paragraph
                    ellipsis={{
                      rows: 7,
                      expandable: true,
                    }}
                  >
                    {this.props.jobDescription.description.length !== 0 ? (
                      this.props.jobDescription.description
                    ) : (
                      <Translate content="jobDescriptions.noDescriptionText" />
                    )}
                  </Paragraph>
                }
                autoAdjustOverflow
                placement="right"
                overlayStyle={{ maxWidth: 800 }}
              >
                <img src={infoCircle} alt="Info" style={{ height: 18 }} />
              </Popover>
            </Col>
          </Row>
          <Row gutter={[0, 8]} onClick={this.onJobCardClick}>
            <Col>
              <Text style={{ fontSize: "95%" }}>
                <Translate content="jobDescriptions.isCreatedByText" />{" "}
                <b>
                  {this.props.jobDescription.zcreationUser != null &&
                  this.props.jobDescription.zcreationUser !== undefined &&
                  this.props.jobDescription.zcreationUser.trim() !== ""
                    ? this.props.jobDescription.zcreationUser.split("@")[0]
                    : ""}
                </b>{" "}
                <Translate content="jobDescriptions.isCreatedAndModifiedByArticle" />{" "}
                <Moment
                  format={ localStorage.getItem("Language") === "it" ?  "DD/MM/YYYY" : "MM/DD/YYYY" }
                  date={this.props.jobDescription.zcreationDate}
                />
              </Text>
            </Col>
          </Row>
          <Row
            gutter={[0, 8]}
            type="flex"
            align="top"
            onClick={this.onJobCardClick}
            style={{ height: 85 }}
          >
            <Col>
              <Tooltip
                title={
                  <span>
                    <Translate content="jobDescriptions.isModifiedByProposition" />{" "}
                    <Moment
                      format="HH:MM"
                      date={this.props.jobDescription.zlastModifiedDate}
                    />
                  </span>
                }
                placement="bottomLeft"
              >
                <Text style={{ fontSize: "95%" }}>
                  <Translate content="jobDescriptions.isModifiedByText" />{" "}
                  <b>
                    {this.props.jobDescription.zlastModifiedUser != null &&
                    this.props.jobDescription.zlastModifiedUser !== undefined &&
                    this.props.jobDescription.zlastModifiedUser.trim() !== ""
                      ? this.props.jobDescription.zlastModifiedUser.split(
                          "@"
                        )[0]
                      : ""}
                  </b>{" "}
                  <Translate content="jobDescriptions.isCreatedAndModifiedByArticle" />{" "}
                  <Moment
                    format={ localStorage.getItem("Language") === "it" ?  "DD/MM/YYYY" : "MM/DD/YYYY" }
                    date={this.props.jobDescription.zlastModifiedDate}
                  />
                </Text>
              </Tooltip>
            </Col>
          </Row>
          <Row type="flex" align="middle">
            <Col span={12} style={{ textAlign: "start" }}>
              <Tooltip
                title={<Translate content="jobDescriptions.deleteText" />}
              >
                <i
                  onClick={this.onDeleteClick}
                  className="fa-regular fa-trash iconsCandidate"
                  style={{ marginRight: "1rem", fontSize: "1.1rem" }}
                ></i>
              </Tooltip>
              <Tooltip
                title={
                  !this.props.jobDescription.active ||
                  this.props.jobDescription.bucket ? (
                    <Translate content="jobDescriptions.enableModifiyText" />
                  ) : (
                    <Translate content="jobDescriptions.modifyText" />
                  )
                }
              >
                <i
                  onClick={
                    !this.props.jobDescription.active ||
                    this.props.jobDescription.bucket
                      ? null
                      : this.onJobEditClick
                  }
                  className="fa-regular fa-pen iconsCandidate"
                  style={
                    !this.props.jobDescription.active ||
                    this.props.jobDescription.bucket
                      ? {
                          marginRight: "1rem",
                          fontSize: "1.1rem",
                          opacity: "0.7",
                        }
                      : { marginRight: "1rem", fontSize: "1.1rem" }
                  }
                />
              </Tooltip>

              <Tooltip title={<Translate content="jobDescriptions.shareJob" />}>
                <i
                  onClick={this.getSamlUsers}
                  className="fa-regular fa-share-nodes iconsCandidate"
                  style={{ fontSize: "1.1rem" }}
                />
              </Tooltip>

              <Modal
                title={
                  this.props.jobDescription.title.trim() === "" ||
                  this.props.jobDescription.title == null ||
                  this.props.jobDescription.title === undefined
                    ? "Nessun Titolo"
                    : this.props.jobDescription.title
                }
                visible={this.state.isModalVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                cancelButtonProps={{ style: { display: "none" } }}
              >
                <Input.Search
                  onChange={this.changeSearch}
                  onSearch={this.searchName}
                  enterButton={<Translate content="drawer.searchText" />}
                  style={{ marginBottom: "0.5rem" }}
                  allowClear
                />

                {this.state.users.map((user) => (
                  <Row
                    gutter={8}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Col span={15}>
                      <Tooltip
                        title={user.nome + " " + user.cognome}
                        placement="left"
                      >
                        <Text>
                          {user.nome +
                            " " +
                            user.cognome +
                            " - " +
                            user.username}
                        </Text>
                      </Tooltip>
                    </Col>
                    <Col
                      span={10}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {user.shared !== undefined ? (
                        user.shared === false ? (
                          <Button
                            ghost
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px",
                              shape: "round",
                              color: "green",
                            }}
                            onClick={() => this.shareJob(user)}
                          >
                            <Translate content="jobDescriptions.shareText" />
                          </Button>
                        ) : (
                          <Button
                            ghost
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px",
                              shape: "round",
                              color: "red",
                            }}
                            onClick={() => this.stopShareJob(user)}
                          >
                            <Translate content="jobDescriptions.stopShareText" />
                          </Button>
                        )
                      ) : null}
                    </Col>
                  </Row>
                ))}
              </Modal>
            </Col>
            <Col span={12} style={{ textAlign: "end" }}>
              <Tooltip
                title={
                  this.props.jobDescription.bucket ? (
                    <Translate content="jobDescriptions.restoreText" />
                  ) : (
                    <Translate content="jobDescriptions.archiveText" />
                  )
                }
              >
                {this.props.jobDescription.bucket ? (
                  <i
                    className="fa-regular fa-inbox-in iconsCandidate"
                    alt="Reset"
                    style={{ marginRight: "1rem", fontSize: "1.1rem" }}
                    onClick={this.onDearchiveClick}
                  />
                ) : (
                  <i
                    className="fa-regular fa-inbox-out iconsCandidate"
                    alt="Archive"
                    style={{ marginRight: "1rem", fontSize: "1.1rem" }}
                    onClick={this.onArchiveClick}
                  />
                )}
              </Tooltip>
              {!this.props.jobDescription.bucket ? (
                <Tooltip
                  title={
                    this.props.jobDescription.active ? (
                      <Translate content="jobDescriptions.closeText" />
                    ) : (
                      <Translate content="jobDescriptions.openText" />
                    )
                  }
                >
                  {this.props.jobDescription.active ? (
                    <i
                      className="fa-regular fa-door-closed iconsCandidate"
                      alt="Close"
                      style={{ fontSize: "1.1rem" }}
                      onClick={this.onCloseClick}
                    />
                  ) : (
                    <i
                      className="fa-regular fa-door-open iconsCandidate"
                      alt="Open"
                      style={{ fontSize: "1.1rem" }}
                      onClick={this.onOpenClick}
                    />
                  )}
                </Tooltip>
              ) : null}
            </Col>
          </Row>
        </Card>
      </Spin>
    );
  }
}
export default withRouter(JobDescriptionCard);
