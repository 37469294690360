import React from 'react';
//Componenti ant design utilizzati
import {Card, Row, Col, Typography, Tooltip} from 'antd';
//Librerie per il multilingua
import Translate from 'react-translate-component';
import {withRouter} from 'react-router-dom';

//Componenti Title e Text di Ant Design, fanno parte della libreria Typography
const {Title, Text} = Typography;

/** TODO Da definire i props utilizzati dal componente */
class CityCard extends React.Component {

    city = ""

    render() {
        this.city = (localStorage.getItem("Language") === 'it' ? 'Città non inserita' : 'City not insert');
        //console.log(this.props.city)
        if (this.props.city && this.props.city !== null && this.props.city !== undefined) {
            this.city = this.props.city.nome;
        }
        return (
            <Tooltip title={this.city}>
                <Card id='personalCard' style={{height: '100%'}}>
                    <Row justify='center' align='middle'>

                        <Col
                            span={20}
                            style={{textAlign: 'left', height: '25px', justifyContent: 'start', alignItems: 'center'}}
                        >
                            <div>
                                <Row>
                                    <Title level={3} ellipsis>
                                        {
                                            this.city
                                        }
                                    </Title>
                                </Row>
                                <Row style={{marginTop: '-3px'}}>
                                    <Text style={{color: '#3f88c5', fontSize: 12}}>
                                        {this.props.isCandidate ? <Translate content='cards.cityCandidate'/> :
                                            <Translate content='cards.cityJob'/>}

                                    </Text>
                                </Row>
                            </div>


                        </Col>
                        <Col
                            span={4}
                            style={{textAlign: 'right'}}
                        >
                            <i className="fa-regular fa-city iconsCandidate"
                               style={{
                                   marginRight: '0.5rem',
                                   fontSize: '2rem',
                                   display: 'flex',
                                   justifyContent: 'center'
                               }}></i>
                        </Col>
                    </Row>
                </Card>

            </Tooltip>
        )
    }
}

export default withRouter(CityCard);


