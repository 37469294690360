import React from 'react';

import { InputNumber } from 'antd';
import { withRouter } from 'react-router-dom';

class InputNumberWithKey extends React.Component {
  
  //Al cambiamento dell'input number, lancia un metodo che aggiorna lo stato nel componente <NewJobDescription />
  onSkillWeightChange = (value) => {
    this.props.onSkillWeightChange(value, this.props.skillID)
  }

  render() {
    return (
      <InputNumber
        defaultValue={this.props.value ? this.props.value : 0}
        value={this.props.value}
        min={0}
        max={100}
        formatter={() => `${Math.round(this.props.value)}%`}//{() => `${(this.props.value).toFixed(2)}%`}
        parser={value => value.replace('%', '')}
        onChange={this.onSkillWeightChange}
        /* step={0.1}
        precision={2} */
      />
    )
  }
} export default withRouter(InputNumberWithKey);