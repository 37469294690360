import { Col, Row, Select, Spin } from 'antd';
import Axios from 'axios';
import Translate from 'react-translate-component'
import React from 'react';
import { withRouter } from 'react-router-dom';

class AddCvsJobConnection extends React.Component {

  state = {
    candidates: [],
    jobDescriptions: [],
    selectedJobDescriptionsId: localStorage.getItem("Language") === 'it' ? "Seleziona una posizione" : "Select a job",
    selectedCandidatesIds: [],
    filteredName: "",
    isLoadingData: true,
  }

  componentDidMount() {

    Axios.all([
      Axios.get(`/api/candidato`),
      Axios.get('/api/job')
    ])
      .then((response) => {
        let tempCandidates = [];
        response[0].data._embedded.candidatoes.forEach(candidate => {
          tempCandidates.push({
            idCandidate: candidate.id,
            candidateName:
              `${candidate.firstName ? candidate.firstName : ""} ${candidate.middleName ? candidate.middleName : ""} ${candidate.lastName ? candidate.lastName : ""}`
          })
        });
        this.setState({
          //candidates: tempCandidates,
          jobDescriptions: response[1].data._embedded.jobDescriptions,
          isLoadingData: false,
        })
      })
      .catch((error => {
        //TODO GESTIONE ERRORE
      }))
  }

  componentDidUpdate() {
    if (this.props.location.pathname.includes("singleJobDescription")) {
      var result = this.props.location.pathname.split("/")
      if (this.state.selectedJobDescriptionsId !== parseInt(result[2])) {
        this.setState({
          selectedJobDescriptionsId: parseInt(result[2]),
        })
        this.props.onJobSelectChange(parseInt(result[2]));
      }
      if (this.state.filteredName.length >= 3) {
        Axios.get(
          `/api/cvJob/search/findTop40ByJobDescriptionIdAndCandidateAndCvPersonalLastNameContainsIgnoreCaseOrderByCvPersonalLastNameAsc?id_job=${parseInt(result[2])}&candidate=false&name=${this.state.filteredName.length >= 3 ? this.state.filteredName : " "}`
        )
          .then((response) => {
            let tempCandidates = [];
            response.data._embedded.cvJobs.forEach(candidate => {
              tempCandidates.push({
                idCandidate: candidate.idCandidate,
                candidateName: candidate.candidateName
              })
            });
            if (JSON.stringify(this.state.candidates) !== JSON.stringify(tempCandidates)) {
              this.setState({
                candidates: tempCandidates,
              })
            }
          })
          .catch((error) => {
            console.log(error)
            //TODO GESTIONE ERRORE
          })
      }
    } else if (this.state.selectedJobDescriptionsId !== "Seleziona una posizione" && this.state.selectedJobDescriptionsId !== "Select a job") {
      Axios.get('/api/job')
        .then((response) => {
          let isPresent = response.data._embedded.jobDescriptions.map((jobDescription) => jobDescription.id === this.state.selectedJobDescriptionsId);
          if (!isPresent.includes(true)) {
            this.setState({
              selectedJobDescriptionsId: localStorage.getItem("Language") === 'it' ? "Seleziona una posizione" : "Select a job"
            })
          } else {
            if (this.state.filteredName.length >= 3) {
              Axios.get(`/api/cvJob/search/findTop40ByJobDescriptionIdAndCandidateAndCvPersonalLastNameContainsIgnoreCaseOrderByCvPersonalLastNameAsc?id_job=${this.state.selectedJobDescriptionsId}&candidate=false&name=${this.state.filteredName.length >= 3 ? this.state.filteredName : " "}`)
                .then((response) => {
                  let tempCandidates = [];
                  response.data._embedded.cvJobs.forEach(candidate => {
                    tempCandidates.push({
                      idCandidate: candidate.idCandidate,
                      candidateName: candidate.candidateName
                    })
                  });
                  if (JSON.stringify(this.state.candidates) !== JSON.stringify(tempCandidates)) {
                    this.setState({
                      candidates: tempCandidates,
                    })
                  }
                })
                .catch((error) => {
                  console.log(error)
                  //TODO GESTIONE ERRORE
                })
            }
          }
        })
        .catch((error) => {
          //TODO GESTIONE ERRORE
        })
    }
  }

  onCandidateSelect = (selectedCandidatesIds) => {
    this.setState({
      selectedCandidatesIds: selectedCandidatesIds
    })
    this.props.onCandidateSelectChange(selectedCandidatesIds)
  }

  onJobSelect = (selectedJobId) => {
    this.setState({
      selectedJobDescriptionsId: selectedJobId,
    });
    if (this.state.filteredName.length >= 3) {
      Axios.get(`/api/cvJob/search/findTop40ByJobDescriptionIdAndCandidateAndCvPersonalLastNameContainsIgnoreCaseOrderByCvPersonalLastNameAsc?id_job=${selectedJobId}&candidate=false&name=${this.state.filteredName.length >= 3 ? this.state.filteredName : " "}`)
        .then((response) => {
          let tempCandidates = [];
          response.data._embedded.cvJobs.forEach(candidate => {
            tempCandidates.push({
              idCandidate: candidate.idCandidate,
              candidateName: candidate.candidateName
            })
          });
          if (JSON.stringify(this.state.candidates) !== JSON.stringify(tempCandidates)) {
            this.setState({
              candidates: tempCandidates,
            })
          }
        })
        .catch((error) => {
          console.log(error)
          //TODO GESTIONE ERRORE
        })
    }
    this.props.onJobSelectChange(selectedJobId);
  }

  onCandidateSearch = (filteredName) => {
    this.setState({
      filteredName
    })
  }

  render() {
    return <div>
      <Spin spinning={this.state.isLoadingData}>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Select
              showSearch
              allowClear
              value={this.state.selectedJobDescriptionsId}
              onChange={this.onJobSelect}
              style={{ width: "inherit", border: '1px solid #d9d9d9', borderRadius: '5px' }}
              placeholder={localStorage.getItem("Language") === 'it' ? "Seleziona una posizione" : "Select a job"}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                this.state.jobDescriptions.map((jobDescription) => {
                  return (
                    <Select.Option
                      value={jobDescription.id}
                    >
                      {`${jobDescription.title} - ${jobDescription.jobDescriptionCode}`}
                    </Select.Option>
                  )
                })
              }
            </Select>
          </Col>
        </Row>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Select
              showSearch
              allowClear
              value={this.state.selectedCandidatesIds}
              onChange={this.onCandidateSelect}
              style={{ width: "inherit", border: '1px solid #d9d9d9', borderRadius: '5px' }}
              placeholder={localStorage.getItem("Language") === 'it'
                            ? "Seleziona i candidati per questa posizione"
                            : "Select candidates for this job"
                          }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSearch={this.onCandidateSearch}
              mode="multiple"
            >
              {
                this.state.candidates.map((candidate) => {
                  return (
                    <Select.Option
                      value={candidate.idCandidate}
                    >
                      {
                        candidate.candidateName && candidate.candidateName.trim().length !== 0
                          ? candidate.candidateName
                          : <Translate content='addCv.noNameText' />
                      }
                    </Select.Option>
                  )
                })
              }
            </Select>
          </Col>
        </Row>
      </Spin>
    </div>;
  }
} export default withRouter(AddCvsJobConnection);