import React from 'react';

//Libreria G2 di antv data visualization
import G2 from '@antv/g2';
import { Button, Icon, Result } from 'antd';

/**
 * Utilizza i props: 
 * - selectedCandidateId: id del candidato considerato.\
 * - selectedCandidateScore: score del candidato considerato, per la job description specifica
 * - jobSoftSkills: array contenente tutte le soft skills della job description presa in considerazione.
 * - jobHardSkills: array contenente tutte le hard skills della job description presa in considerazione.
*/
class PieChart extends React.Component {

  componentDidMount() {
    let candidateSkills = []
    if (this.props.candidateSoftSkills)
      candidateSkills = candidateSkills.concat(this.props.candidateSoftSkills);

    if (this.props.candidateHardSkills) {
      candidateSkills = candidateSkills.concat(this.props.candidateHardSkills);
    }

    if (this.props.candidateLanguages) {
      candidateSkills = candidateSkills.concat(this.props.candidateLanguages);
    }

    let pieChartData = [];
    //Viene fatto un matching tra skill del candidato e skill della job description, e i dati filtrati sono aggiunti in pieChartData
    if (candidateSkills.length !== 0) {
      this.props.jobSoftSkills.forEach((softSkill) => {
        let hasFound = false;
        candidateSkills.map((cvSkill) => { return cvSkill.code === softSkill.code_id ? hasFound = true : false })
        //Se non c'è matching, il dato viene settato isChecked=0 (grigio nella torta, quindi assente)
        if (!hasFound) {
          pieChartData.push({
            item: softSkill.name,
            percent: softSkill.duration / 100,
            type: localStorage.getItem("Language") === 'it' ? 'Requisito non trovato' : 'Requirement not found',//<Translate content='pieChart.notFoundText' />
            value: 0,
            count: softSkill.code_id
          })
        } else { //Altrimenti 1 in caso di matching, in modo che appaia primary color
          pieChartData.push({
            item: softSkill.name,
            percent: softSkill.duration / 100,
            type: localStorage.getItem("Language") === 'it' ? 'Requisito trovato' : 'Requirement found',
            tipo: 'softSkill',
            value: softSkill.duration,//(softSkill.duration).toFixed(2),
            count: softSkill.code_id
          })
        }
      })
      //Stesso ragionamento delle softskills, ma per le hard skills.
      this.props.jobHardSkills.forEach((hardSkill) => {
        let hasFound = false;
        candidateSkills.map((cvSkill) => { return cvSkill.code === hardSkill.code_id ? hasFound = true : false })
        if (!hasFound) {
          pieChartData.push({
            item: hardSkill.name,
            percent: hardSkill.duration / 100,
            type: localStorage.getItem("Language") === 'it' ? 'Requisito non trovato' : 'Requirement not found',
            value: 0,
            count: hardSkill.code_id
          })
        } else {
          pieChartData.push({
            item: hardSkill.name,
            percent: hardSkill.duration / 100,
            type: localStorage.getItem("Language") === 'it' ? 'Requisito trovato' : 'Requirement found',
            tipo: 'hardSkill',
            value: hardSkill.duration,//(hardSkill.duration).toFixed(2),
            count: hardSkill.code_id
          })
        }
      })

      //Stesso ragionamento di soft e hard skills, ma per le lingue
      this.props.jobLanguages.forEach((languageSkill) => {
        let hasFound = false;
        console.log(languageSkill)
        console.log(candidateSkills)
        candidateSkills.map((cvSkill) => { return cvSkill.languageCode !== undefined && (cvSkill.languageCode.toLowerCase() === languageSkill.code_id.toLowerCase() ? hasFound = true : false)})
        if (!hasFound) {
          pieChartData.push({
            item: languageSkill.languageCodeDescription,
            percent: languageSkill.proficiencyCodeDescription / 100,
            type: localStorage.getItem("Language") === 'it' ? 'Requisito non trovato' : 'Requirement not found',
            value: 0,
            count: languageSkill.code_id
          })
        } else {
          pieChartData.push({
            item: languageSkill.languageCodeDescription,
            percent: languageSkill.proficiencyCodeDescription / 100,
            type: localStorage.getItem("Language") === 'it' ? 'Requisito trovato' : 'Requirement found',
            tipo: 'languageSkill',
            value: languageSkill.proficiencyCodeDescription,//(languageSkill.proficiencyCodeDescription).toFixed(2),
            count: languageSkill.code_id
          })
        }
      })
    }
    /**
     *  //Nel caso in cui l'array delle skills del candidato fosse vuoto, allora le skills della 
     * torta sarebbero tutte settate a isChecked=false
    */
    else {
      this.props.jobSoftSkills.forEach((softSkill) => {
        pieChartData.push({
          item: softSkill.name,
          percent: softSkill.duration / 100,
          type: localStorage.getItem("Language") === 'it' ? 'Requisito non trovato' : 'Requirement not found',
          value: 0,
          count: softSkill.id
        })
      })
      this.props.jobHardSkills.forEach((hardSkill) => {
        pieChartData.push({
          item: hardSkill.name,
          percent: hardSkill.duration / 100,
          type: localStorage.getItem("Language") === 'it' ? 'Requisito non trovato' : 'Requirement not found',
          value: 0,
          count: hardSkill.id
        })
      })
      this.props.jobLanguages.forEach((languageSkill) => {
        pieChartData.push({
          item: languageSkill.name,
          percent: languageSkill.proficiencyCodeDescription / 100,
          type: localStorage.getItem("Language") === 'it' ? 'Requisito non trovato' : 'Requirement not found',
          value: 0,
          count: languageSkill.id
        })
      })
    }
    if ((this.props.jobYearsOfExperienceScore - this.props.candidateYearsOfExperienceScore) === 0) {
      pieChartData.push({
        item: "Anni di Esperienza",
        percent: this.props.jobYearsOfExperienceScore / 100,
        type: localStorage.getItem("Language") === 'it' ? 'Requisito trovato' : 'Requirement found',
        tipo: 'years',
        value: this.props.candidateYearsOfExperienceScore,//(this.props.candidateYearsOfExperienceScore).toFixed(2),
        text: this.props.candidateYearsOfExperienceExplanation ? this.props.candidateYearsOfExperienceExplanation : "",
        count: this.props.jobYearsOfExperienceScore,
      })
    } else if ((this.props.jobYearsOfExperienceScore - this.props.candidateYearsOfExperienceScore) === this.props.jobYearsOfExperienceScore) {
      pieChartData.push({
        item: "Anni di Esperienza",
        percent: this.props.jobYearsOfExperienceScore / 100,
        type: localStorage.getItem("Language") === 'it' ? 'Requisito non trovato' : 'Requirement not found',
        value: 0,
        text: this.props.candidateYearsOfExperienceExplanation ? this.props.candidateYearsOfExperienceExplanation : "",
        count: this.props.jobYearsOfExperienceScore
      })
    } else if ((this.props.jobYearsOfExperienceScore - this.props.candidateYearsOfExperienceScore) > 0) {
      pieChartData.push({
        item: `Anni di Esperienza`,
        percent: this.props.jobYearsOfExperienceScore / 100,
        type: localStorage.getItem("Language") === 'it' ? 'Requisito parziale' : 'Partial requirement',
        value: this.props.candidateYearsOfExperienceScore,//(this.props.candidateYearsOfExperienceScore).toFixed(2),
        text: this.props.candidateYearsOfExperienceExplanation ? this.props.candidateYearsOfExperienceExplanation : "",
        count: `Anni di esperienza considerati: ${this.props.candidateYearsOfExperienceScore}%`,
      })
    }

    if ((this.props.candidateEducationLevelScore - this.props.jobEducationLevelScore) === 0) {
      pieChartData.push({
        item: "Livello di Istruzione",
        percent: this.props.jobEducationLevelScore / 100,
        type: localStorage.getItem("Language") === 'it' ? 'Requisito trovato' : 'Requirement found',
        tipo: 'education',
        value: this.props.candidateEducationLevelScore,//(this.props.candidateEducationLevelScore).toFixed(2),
        count: this.props.jobEducationLevelScore
      })
    } else {
      pieChartData.push({
        item: "Livello di Istruzione",
        percent: this.props.jobEducationLevelScore / 100,
        type: localStorage.getItem("Language") === 'it' ? 'Requisito non trovato' : 'Requirement not found',
        value: this.props.candidateEducationLevelScore,//(this.props.candidateEducationLevelScore).toFixed(2),
        count: this.props.jobEducationLevelScore
      })
    }

    if (this.props.jobDegreesCoursesScore !== 0) {
      if ((this.props.candidateDegreesCoursesScore - this.props.jobDegreesCoursesScore) === 0) {
        pieChartData.push({
          item: "Corso d'Istruzione",
          percent: this.props.jobDegreesCoursesScore / 100,
          tipo: 'corso',
          type: localStorage.getItem("Language") === 'it' ? 'Requisito trovato' : 'Requirement found',
          value: this.props.candidateDegreesCoursesScore,//(this.props.candidateDegreesCoursesScore).toFixed(2),
          count: this.props.jobDegreesCoursesScore
        })
      } else {
        pieChartData.push({
          item: "Corso d'Istruzione",
          percent: this.props.jobDegreesCoursesScore / 100,
          type: localStorage.getItem("Language") === 'it' ? 'Requisito non trovato' : 'Requirement not found',
          value: this.props.candidateDegreesCoursesScore,//(this.props.candidateDegreesCoursesScore).toFixed(2),
          count: this.props.jobDegreesCoursesScore
        })
      }
    }

    //Viene creato il grafico.
    if (this.props.jobHardSkills.length !== 0 || this.props.jobSoftSkills.length !== 0
      || this.props.jobLanguages.length !== 0 || this.props.jobEducationLevelScore > 0
      || this.props.jobDegreesCoursesScore > 0 || this.props.jobYearsOfExperienceScore > 0)
      this.createChart(pieChartData);
  }


  createChart = (pieChartData) => {
    let chart = new G2.Chart({
      container: document.getElementById('pie_chart-container'),
      forceFit: true,
      height: 450,
      animate: true,
    });
    chart.source(pieChartData, {
      percent: {
        formatter: val => {
          val = (val * 100) + '%';
          return val;
        }
      }
    });
    chart.coord('theta', {
      radius: 0.75,
      innerRadius: 0.6
    });
    chart.tooltip({
      showTitle: false,
      itemTpl: '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
    });

    chart.legend({
      layout: 'vertical',
      position: 'left'
    });

    chart.guide().html({
      position: ['50%', '50%'],
      html: `<div style="color:#8c8c8c;font-size: 14px;text-align: center;width: 10em;">Score<br><span style="color:#8c8c8c;font-size:20px">${this.props.selectedCandidateScore}</span>%</div>`,
      alignX: 'middle',
      alignY: 'middle'
    });
    const interval = chart.intervalStack()
      .position('percent')
      .color('type*tipo', (type, tipo) => {
        if (type === 'Requisito non trovato' || type === 'Requirement not found') {
          return '#e8e8e8';
        } else if (type === "Requisito trovato" || type === 'Requirement found') {
          if(tipo === "hardSkill"){
            return '#43d187';
          }else if(tipo === "softSKill"){
            return '#43d1aa';
          }else if(tipo === "languageSkill"){
            return '#43d1ce';
          }else if(tipo === "years"){
            return '#43b1d1';
          }else if(tipo === "education"){
            return '#438dd1';
          }else if(tipo === "corso"){
            return '#4369d1';
          }
          return '#00a8b0';
        } else {
          //#e88537
          return '#4347d1';
        }
      })
      .label('item*type*value', (item, type, value) => {
        value = value + '%';
        if (item === 'Anni di Esperienza' && (type === 'Requisito Parziale' || type === 'Partial requirement')) {
          return localStorage.getItem("Language") === 'it' ? `Anni di esperienza considerati: ${value}` : `Years of experience considered: ${value}`;
        }
        return `${item}: ${value}`;
      })
      /* .tooltip(false) */
      .tooltip('text*item*value', (text, item, value) => {
        if (text) {
          if (text.length !== 0) {
            return {
              name: localStorage.getItem("Language") === 'it' ? `Spiegazione anni di esperienza considerati` : `Explanation years of experience considered`,
              value: text,
            };
          } else {
            return {
              name: item,
              value: value + '%'
            };
          }
        } else {
          return {
            name: item,
            value: value + '%'
          };
        }
      })
      .style({
        lineWidth: 1,
        stroke: '#fff',
      });
    chart.render();
    interval.setSelected(pieChartData[0]);
  }

  render() {
    return (
      <div>
        {
          this.props.jobHardSkills.length !== 0 || this.props.jobSoftSkills.length !== 0
            || this.props.jobLanguages.length !== 0 || this.props.jobEducationLevelScore > 0
            || this.props.jobDegreesCoursesScore > 0 || this.props.jobYearsOfExperienceScore > 0
            ? <div id='pie_chart-container' style={{ marginTop: "-35px" }}></div>
            : <Result style={{ height: 415 }}
              icon={<Icon type="frown" theme="twoTone" twoToneColor="#00a8b0" />}
              title="Peccato, non hai aggiunto informazioni a questa Job Description"
              extra={<Button type="primary">Modifica Job Description</Button>}
            />
        }
      </div>

    );
  }
} export default PieChart;
