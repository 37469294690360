import React, { useEffect, useState } from "react";

import { message } from "antd";
import { withRouter } from "react-router-dom";

import { Button } from "primereact/button";

import Axios from "axios";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import SettingsDropdown from "./SettingsDropdown";

const Settings = () => {
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [junior, setJunior] = useState({
    operation: 0,
    minYear: 0,
    maxYear: 0,
  });
  const [senior, setSenior] = useState({
    operation: 3,
    minYear: 0,
    maxYear: 0,
  });
  const [expert, setExpert] = useState({
    operation: 5,
    minYear: 0,
    maxYear: 0,
  });

  useEffect(() => {
    Axios.get("/api/getYearsSeniority").then((response) => {
      setJunior({
        operation: response.data.juniorType,
        minYear: response.data.juniorMin,
        maxYear: response.data.juniorMax,
      });
      setSenior({
        operation: response.data.seniorType,
        minYear: response.data.seniorMin,
        maxYear: response.data.seniorMax,
      });
      setExpert({
        operation: response.data.expertType,
        minYear: response.data.expertMin,
        maxYear: response.data.expertMax,
      });
    });
  }, []);

  const changeLanguageItalianHandler = () => {
    if (language !== "it") {
      setLanguage("it");
      counterpart.setLocale("it");
      localStorage.setItem("Language", "it");
    }
  };

  const changeLanguageEnglishHandler = () => {
    if (language !== "en") {
      setLanguage("en");
      counterpart.setLocale("en");
      localStorage.setItem("Language", "en");
    }
  };

  const onOperationChangeHandler = (operation, label) => {
    label === "Junior" &&
      setJunior((junior) => ({
        ...junior,
        operation: operation,
      }));

    label === "Senior" &&
      setSenior((senior) => ({
        ...senior,
        operation: operation,
      }));

    label === "Expert" &&
      setExpert((expert) => ({
        ...expert,
        operation: operation,
      }));
  };

  const onMinYearsChangeHandler = (value, label) => {
    label === "Junior" &&
      setJunior((junior) => ({
        ...junior,
        minYear: value,
      }));

    label === "Senior" &&
      setSenior((senior) => ({
        ...senior,
        minYear: value,
      }));

    label === "Expert" &&
      setExpert((expert) => ({
        ...expert,
        minYear: value,
      }));
  };

  const onMaxYearsChangeHandler = (value, label) => {
    label === "Junior" &&
      setJunior((junior) => ({
        ...junior,
        maxYear: value,
      }));

    label === "Senior" &&
      setSenior((senior) => ({
        ...senior,
        maxYear: value,
      }));

    label === "Expert" &&
      setExpert((expert) => ({
        ...expert,
        maxYear: value,
      }));
  };

  const handleDeletePreferences = () => {
    Axios.post(`/api/userpreference/delete`)
      .then(() => {
        message.success(<Translate content="settings.successClear" />);
      })
      .catch((error) => {
        if (error.response) {
          message.error(<Translate content="settings.errorClear" />);
        }
      });
  };

  const saveSettingsHandler = () => {
    Axios.post("/api/saveYearsSeniority", {
      juniorType: junior.operation,
      juniorMin: junior.minYear,
      juniorMax: junior.operation === 5 ? junior.maxYear : 0,

      seniorType: senior.operation,
      seniorMin: senior.minYear,
      seniorMax: senior.operation === 5 ? senior.maxYear : 0,

      expertType: expert.operation,
      expertMin: expert.minYear,
      expertMax: expert.operation === 5 ? expert.maxYear : 0,
    })
      .then(() => {
        message.success(<Translate content="settings.successSave" />);
      })
      .catch((error) => {
        if (error.response) {
          message.error(<Translate content="settings.errorSave" />);
        }
      });
  };

  const italianClasses =
    language === "it"
      ? "p-button-raised settings"
      : "p-button-raised p-button-text settings";
  const englishClasses =
    language !== "it"
      ? "p-button-raised settings"
      : "p-button-raised p-button-text settings";

  return (
    <div style={{ marginTop: "30px", marginLeft: "30px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h3 style={{ margin: 0 }}>
          <Translate content="settings.languageText" />
        </h3>
        <Button
          label={<Translate content="settings.italianText" />}
          onClick={changeLanguageItalianHandler}
          className={`p-button-sm ${italianClasses}`}
          style={{ padding: "1rem" }}
        />
        <Button
          label={<Translate content="settings.englishText" />}
          onClick={changeLanguageEnglishHandler}
          className={`p-button-sm ${englishClasses}`}
          style={{ padding: "1rem" }}
        />
      </div>
      <SettingsDropdown
        label="Junior"
        values={junior}
        onOperationChangeHandler={onOperationChangeHandler}
        onMinYearsChangeHandler={onMinYearsChangeHandler}
        onMaxYearsChangeHandler={onMaxYearsChangeHandler}
      />
      <SettingsDropdown
        label="Senior"
        values={senior}
        onOperationChangeHandler={onOperationChangeHandler}
        onMinYearsChangeHandler={onMinYearsChangeHandler}
        onMaxYearsChangeHandler={onMaxYearsChangeHandler}
      />
      <SettingsDropdown
        label="Expert"
        values={expert}
        onOperationChangeHandler={onOperationChangeHandler}
        onMinYearsChangeHandler={onMinYearsChangeHandler}
        onMaxYearsChangeHandler={onMaxYearsChangeHandler}
      />
      <Button
        label={<Translate content="settings.saveSettings" />}
        onClick={saveSettingsHandler}
        className="p-button-raised settings"
      />
      <Button
        label={<Translate content="settings.clearUserSetting" />}
        onClick={handleDeletePreferences}
        className="p-button-raised settings"
      />
    </div>
  );
};

export default withRouter(Settings);
