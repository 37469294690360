export default {
  login: {
    welcomeMessage: "Bentornato! Per favore effettua il login",
    usernamePlaceholder: "Username",
    passwordPlaceholder: "Password",
    usernameErrorMessage: "Per favore inserisci il tuo username!",
    passwordErrorMessage: "Per favore inserisci la tua password!",
    loginButtonText: "Accedi",
    loginButtonWithSSOText: "Accedi con SSO",
  },
  header: {
    jobDescriptionsText: "Posizioni lavorative",
    candidatesText: "Candidati",
    settingsText: "Impostazioni",
    userProfileText: "Profilo Utente",
    moreText: "Altro...",
    logoutButtonText: "Esci",
  },
  filters: {
    selectPresence: "Seleziona presenza",
    selectType: "Seleziona tipologia",
    selectGeographicArea: "Seleziona area geografica",
    selectRegion: "Seleziona regione",
    selectProvince: "Seleziona provincia",
  },
  dashboard: {
    welcomeMessage: "Benvenuto",
    logoutButton: "Esci",
    jobDescriptionsTotal: "Totale Posizioni",
    candidatesTotal: "Totale Candidati",
    last24Hours: "Nelle ultime 24H",
    newCandidates: "Nuovi Candidati Importati",
    newJobs: "Nuove Posizioni Importate",
    group: "Gruppo",
    jobAreasDistribution: "Distribuzione Aree Lavorative Candidato",
    noSkillPresent: "Nessuna Skill presente",
    topCities: "Top Città",
    noCityPresent: "Nessuna Città presente",
    noHobbyPresent: "Nessun Hobby presente",
  },
  settings: {
    languageText: "Lingua:",
    italianText: "Italiano",
    englishText: "Inglese",
    clearUserSetting: "Cancella impostazioni utente",
    saveSettings: "Salva impostazioni",
    successClear: "Impostazioni utente cancellate con successo",
    errorClear: "Si è verificato un errore! Riprova più tardi",
    successSave: "Impostazioni salvate con successo",
    errorSave: "Si è verificato un errore durante il salvataggio",
  },
  jobDescriptions: {
    jobDescriptionsText: "Posizioni Lavorative",
    noJobDescriptionsText: "Posizioni lavorative assenti",
    jobDescriptionData: "Dettaglio Posizione Lavorativa",
    isArchivedText: "Archiviata",
    isOpenedText: "Aperta",
    isClosedText: "Chiusa",
    isCreatedByText: "Creata da",
    creationDateText: "Creata il",
    isCreatedAndModifiedByArticle: "il",
    isModifiedByText: "Modificata da",
    modifiedDateText: "Modificata il",
    isModifiedByProposition: "alle",
    codeText: "Codice",
    nameText: "Nome",
    statusText: "Stato",
    archiveStatusText: "Archivio",
    //activeText: 'Attiva',
    protectedCategoryText: "Ricercata categoria protetta",
    noDescriptionText: "Nessuna descrizione presente",
    deleteText: "Elimina",
    deleteChooseText: "Eliminare la posizione lavorativa?",
    deleteDescriptionText: "La posizione lavorativa sarà eliminata",
    modifyText: "Modifica",
    enableModifiyText: "Abilita la posizione per modificare",
    archiveText: "Archivia",
    archiveChooseText: "Archiviare la posizione lavorativa?",
    archiveDescriptionText:
      "La posizione lavorativa sarà archiviata e potrà essere recuperata in qualunque momento",
    restoreText: "Ripristina",
    restoreChooseText: "Dearchiviare la posizione lavorativa?",
    restoreDescriptionText: "La posizione lavorativa sarà dearchiviata",
    openText: "Apri",
    openChooseText: "Aprire la posizione lavorativa?",
    openDescriptionText: "La posizione lavorativa sarà aperta",
    closeText: "Chiudi",
    closeChooseText: "Chiudere la posizione lavorativa?",
    closeDescriptionText: "La posizione lavorativa sarà chiusa",
    confirmButtonText: "Conferma",
    jobDescriptionDataText: "Dati posizione lavorativa",
    matchHistory: "Storico Matching",
    availableVersion: "Versioni disponibili",
    noHistoryFile: "Non sono presenti file relativi allo storico",
    shareJob: "Condividi Posizione",
    shareText: "Condividi",
    stopShareText: "Interrompi condivisione",
    exportText: "Esporta situazione corrente",
  },
  candidates: {
    candidatesText: "Candidati",
    noCandidatesText: "Candidati assenti",
    nameText: "Nome",
    ageText: "Età",
    bornDateText: "Data di nascita",
    phoneNumberText: "Numero di telefono",
    jobDescriptionCodeText: "Codice posizione lavorativa",
    jobDescriptionNameText: "Nome posizione lavorativa",
    applicationForText: "Applicato",
    isCandidateText: "Candidato",
    isNotCandidateText: "Non candidato",
    protectedText: "Categoria protetta",
    isProtectedPresentText: "Presente",
    isNotProtectedPresentText: "Non presente",
    protectedTypeText: "Tipo categoria protetta",
    entryUserText: "Inserito da",
    experienceText: "Esperienza rilevata",
    detectedText: "Rilevata",
    notDetectedText: "Non rilevata",
    insertionDateText: "Data d'inserimento",
    provenanceText: "Provenienza",
    statusText: "Stato candidato",
    scoreText: "Punteggio",
    deleteSearchText: "Eliminazione ricerca full text",
    deleteSearchDescriptionText:
      "La ricerca full text è stata eliminata con successo.",
    startSearchText: "Inizio ricerca full text",
    startSearchDescriptionText:
      "La ricerca full text con le parole chiave richieste è stata schedulata.",
    existSearchText: "Ricerca full text già esistente",
    existSearchDescriptionText:
      "La ricerca full text con le parole chiave richieste è già esistente, quindi è stata schedulata di nuovo.",
    noNameText: "Nome non trovato",
    companyText: "Ha già lavorato in Azienda",
    protectedBelongText: "Appartiene a una categoria protetta",
    deleteText: "Elimina",
    //deleteChooseText: 'Eliminare il CV di %(firstName) %(middleName) %(lastName)?',
    deleteDescriptionText: "Il curriculum sarà eliminato definitivamente",
    updateText: "Aggiorna",
    updateDataText: "Aggiorna i dati personali del candidato",
    firstNameText: "Primo nome",
    middleNameText: "Secondo nome",
    surnameText: "Cognome",
    downloadText: "Scarica CV",
    confirmButtonText: "Conferma",
    missingText: "Mancante",
  },
  fullTextSearchCandidate: {
    firstKeywordText: "Prima parola chiave",
    secondKeywordText: "Seconda parola chiave",
    searchStatusText: "Stato ricerca",
    completedText: "Completata",
    notCompletedText: "Non completata",
    actionText: "Azione",
  },
  buttons: {
    topBarFilter: "Filtra",
    downloadText: "Scarica",
  },
  cards: {
    candidateData: "Dati Candidato",
    educationLevelJob: "Livello d'Istruzione Richiesto",
    educationLevelCandidate: "Livello d'Istruzione Rilevato",
    yearsOfExperienceJob: "Anni di esperienza Richiesti",
    yearsOfExperienceCandidate: "Anni di esperienza Rilevati",
    languagesJob: "Lingue Richieste",
    languagesCandidate: "Lingue Rilevate",
    cityCandidate: "Città Rilevata",
    cityJob: "Città Richiesta",
    socialCandidate: "Social Media Rilevati",
    languages: "Lingue",
  },
  candidateData: {
    noAddress: "Nessun indirizzo presente",
    missingData: "Dato mancante",
    noEmailAddress: "Indirizzo email mancante",
    openLinkedin: "Apri profilo LinkedIn",
    candidateWebsite: "Sito web del candidato",
  },
  candidateState: {
    applicationText: "Candidatura",
    applicationDescriptionText: "Il profilo si è applicato alla posizione",
    progressText: "In progresso",
    outcomeText: "Esito",
    suitableText: "Idoneo",
    noSuitableText: "Non idoneo",
    infoText: "Clicca per visualizzare o inserire delle note",
    infoVisualizationText: "Visualizza note",
    //infoInsertText: 'Inserisci qui le tue note',
    saveButtonText: "Salva note",
    cancelButtonText: "Annulla",
    confirmationStatusText: "Confermi di voler cambiare stato al candidato?",
    clickProgressText:
      "Clicca per avanzare lo stato del candidato nel processo",
    progressButtonText: "Avanza",
    clickSuitableText: "Clicca per segnare il candidato come idoneo",
    clickNotSuitableText: "Clicca per segnare il candidato come non idoneo",
  },
  newJobDescription: {
    importText: "1) Importa posizione lavorativa",
    importDescriptionText: "Importa posizione lavorativa esistente",
    jobDescriptionText: "2) Posizione lavorativa",
    requirementText: "3) Requisiti",
    requirementDescriptionText: "Requisiti richiesti",
    skillText: "4) Competenze",
    skillDescriptionText: "Hard/Soft skill e lingue",
    saveButtonText: "Salva posizione lavorativa",
    courseText: "Corso",
    selectWeightText: "Scegli i pesi per requisiti e compotenze",
    weightRequirementsText: "Peso requisiti",
    weightSkillsText: "Peso competenze",
    proceedwithoutText: "Procedi senza analizzare",
    analyzeText: "Analizza",
    titleText: "Titolo",
    jobTitleText: "Titoli professionali",
    codeText: "Codice identificativo",
    usedCodeText: "Codice già utilizzato",
    noCodeText: "Inserire il codice!",
    isRequiredText: "Requisito necessario?",
    yearsOfExperienceText: "Anni d'esperienza:",
    weightText: "Peso:",
    educationLevelText: "Livello d'istruzione:",
    thematicText: "Area tematica:",
    thematicAreasText: "Aree tematiche",
    coursesText: "Corsi",
    notFoundText: "Cerca dlle skill",
    typeText: "Tipo",
    weightTableText: "Peso",
    continueButtonText: "Avanti",
    maxYearErrorMessage:
      "Gli anni inseriti nel secondo campo devono essere maggiori o uguali a quelli inseriti nel primo campo",
    requiredText: "Necessaria",
  },
  /*educationLevel: {
    anyText: 'Qualsiasi',
    diplomaText: 'Diploma',
    professionalTrainingText: 'Formazione professionale',
    bachelorDegreeText: 'Laurea triennale',
    masterDegreeText: 'Laurea magistrale',
    masterText: 'Master',
    phdText: 'Dottorato',
    associateDegreeText: 'Laurea associata'
  },*/
  drawer: {
    title: "Filtra",
    typeOfVisualization: "Visualizzazione: ",
    gridText: "Griglia",
    tableText: "Tabella",
    nameSearchText: "Cerca per nome",
    //searchPlaceholderJobDescription: 'Inserisci nome posizione lavorativa',
    //searchPlaceholderCandidate: 'Inserisci nome o cognome del candidato',
    searchText: "Cerca",
    resetSearchText: "Annulla ricerca",
    fullTextSearch: "Ricerca full text nei CV:",
    //firstKeywordText: 'Inserisci prima parola chiave',
    //secondKeywordText: 'Inserisci seconda parola chiave',
    startSearchText: "Avvia ricerca",
  },
  hobbies: {
    noHobbies: "Hobby mancanti",
  },
  educationCard: {
    titleText: "Educazione",
    completingText: "In corso",
    endedText: "Finito il",
    missingDateText: "Data di fine assente",
    noDataText: "Nessuna data",
    noDescriptionText: "Nessuna descrizione",
    weightText: "Peso assegnato",
  },
  experienceCard: {
    titleText: "Esperienze lavorative",
  },
  fabButton: {
    visualizationText: "Visualizza",
    titleText: "Gestione CV",
    saveLinkText: "Salva collegamento",
    addCVText: "Aggiungi CV",
    linkCVText: "Collega CV",
  },
  addCV: {
    searchText: "Cercando nuove posizioni...",
    descriptionText:
      "Aggiungi curricula candidati alla posizione lavorativa selezionata",
    activeOpenText: "Attive aperte",
    activeCloseText: "Attive chiuse",
    archivedText: "Archiviate",
    uploadText: "Carica",
    noNameText: "Nessun nome trovato",
  },
  errorMessages: {
    missingCandidateName: "Nome non trovato",
    missingCandidateBornDate: "Data non trovata",
    missingCandidatePhoneNumber: "Numero non trovato",
    notAllowed: "Accesso negato",
    notAllowedJobDescription: "Non hai accesso a questa posizione.",
    serviceUnavailable: "Servizio non disponibile",
    expiredDescription: "Scusa, la tua sessione è scaduta.",
    notFound: "Risorsa non trovata",
    notFoundDescription: "Hai cercato qualcosa che non è presente.",
    retry: "Riprova",
    backDashboard: "Torna alla dashboard",
    backLogin: "Torna al login",
  },
  infoMessages: {
    loadingJobDescriptionsData: "Caricando le Job Descriptions",
    loadingCandidatesData: "Caricando i candidati",
    loadingSingleJobDescriptionData: "Caricando la Job Description",
    loadingSingleCandidateData: "Caricando il candidato",
  },
  alertJob: {
    alertMessage: "Dati in fase di Calcolo",
    alertDescription:
      "La job description è appena stata modificata, i dati sono ancora in fase di calcolo. Riprova tra poco.",
  },
};
