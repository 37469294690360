import React from 'react';

import { Card, Badge, Empty } from 'antd';

import { withRouter } from 'react-router-dom';

import Translate from 'react-translate-component';

const { Meta } = Card;

export default withRouter(function HobbyCard(props) {
  return (
    <div style={{ paddingTop: 24 }}>
      <Card id="personalCard">
        <Meta title={'Hobby'} />
        <div style={{ marginTop: 13, textAlign: "center" }}>
          {
            props.hobbies
              ? (props.hobbies.length !== 0
                ? props.hobbies.map(element => {
                  return (
                    <Badge
                      key={element.id}
                      count={element.hobby.length > 30 ? element.hobby.slice(0, 31)+'...' : element.hobby}
                      style={{
                        backgroundColor: "#E0E0E0",
                        color: "#4D4F5C",
                        fontSize: 14,
                        height: 22,
                        marginRight: 5,
                        marginTop: 5
                      }}
                      title={element.hobby}
                    />
                  );
                })
                : <Empty description={<Translate content='hobbies.noHobbies' />} image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
              : <Empty description={<Translate content='hobbies.noHobbies' />} image={Empty.PRESENTED_IMAGE_SIMPLE} />
          }
        </div>
      </Card>
    </div>
  )
});
