import React from "react";
import { withRouter } from 'react-router-dom';

class DashboardButton extends React.Component {

  render() {
    return (
      <div class="dashboard-buttons">
        {/* <div>
          {
            this.props.title === "Job Descriptions"
              ? (
                <img
                  src='../../../resources/icons/briefcaseSolid.png'
                  alt='Zarathustra'
                  style={{ height: '15vh', color: '#F9F9FC' }}
                />
              )
              : this.props.title === "Candidati"
                ? <img
                  src='../../../resources/icons/usersSolid.png'
                  alt='Zarathustra'
                  style={{ height: '15vh', color: '#F9F9FC' }}
                />
                : this.props.title === "Impostazioni"
                  ? <img
                    src='../../../resources/icons/settingsSolid.png'
                    alt='Zarathustra'
                    style={{ height: '15vh', color: '#F9F9FC' }}
                  />
                  : <img
                    src='../../../resources/icons/userCircleSolid.png'
                    alt='Zarathustra'
                    style={{ height: '15vh', color: '#F9F9FC' }}
                  />
          }
        </div> */}
        <div style={{display:"flex", justifyContent: "center"}}>
          <h1 style={{fontSize: 20, fontWeight: "bold", color: "#fff", textAlign: "center", paddingTop: 20, paddingBottom:20, margin:0}}>{this.props.title}</h1>
        </div>
      </div>
    );
  }
} export default withRouter(DashboardButton);