import React from 'react';
import { Table, Badge } from 'antd';
import Moment from 'react-moment';
import Translate from 'react-translate-component';
import { withRouter } from 'react-router-dom';
import counterpart from 'counterpart';



class JobDescriptionsTable extends React.Component {

  render() {
    const isOpenedText = counterpart.translate('jobDescriptions.isOpenedText');
    const isClosedText = counterpart.translate('jobDescriptions.isClosedText');
    const isArchivedText = counterpart.translate('jobDescriptions.isArchivedText');
    return (
      <Table
        columns={
          [
            {
              title: <Translate content='jobDescriptions.codeText' />,
              dataIndex: 'jobDescriptionCode',
              key: 'jobDescriptionCode',
              align: 'center',
            },
            {
              title: <Translate content='jobDescriptions.nameText' />,
              dataIndex: 'title',
              key: 'title',
              ellipsis: true,
              align: 'center',
            },
            {
              title: <Translate content='jobDescriptions.statusText' />,
              dataIndex: 'active',
              key: 'active',
              ellipsis: true,
              align: 'center',
              render: isOpened =>
                <Badge
                  count={
                    isOpened
                      ? isOpenedText
                      : isClosedText
                  }
                  style={{ backgroundColor: isOpened ? '#52c41a' : '#F44336', width: '77px', marginTop: '7px', zIndex: 0 }}
                />
            },
            {
              title: <Translate content='jobDescriptions.archiveStatusText' />,
              dataIndex: 'bucket',
              key: 'bucket',
              ellipsis: true,
              align: 'center',
              render: isArchived =>
                <Badge
                  count={
                    isArchived
                      ? isArchivedText
                      : localStorage.getItem("Language") === 'it' ? "Attiva" : "Operating"
                  }
                  style={{ backgroundColor: isArchived ? '#FF9800' : '#52c41a', width: '77px', marginTop: '7px', zIndex: 0 }}
                />
            },
            {
              title: <Translate content='jobDescriptions.isCreatedByText' />,
              dataIndex: 'zcreationUser',
              key: 'zcreationUser',
              align: 'center',
            },
            {
              title: <Translate content='jobDescriptions.creationDateText' />,
              dataIndex: 'zcreationDate',
              key: 'zcreationDate',
              align: 'center',
              render: date => <Moment format='DD/MM/YYYY' date={date} />
            },
            {
              title: <Translate content='jobDescriptions.isModifiedByText' />,
              dataIndex: 'zlastModifiedUser',
              key: 'zlastModifiedUser',
              align: 'center',
            },
            {
              title: <Translate content='jobDescriptions.modifiedDateText' />,
              dataIndex: 'zlastModifiedDate',
              key: 'zlastModifiedDate',
              align: 'center',
              render: date => <Moment format='DD/MM/YYYY' date={date} />
            },
          ]
        }
        dataSource={this.props.cardsData}
        bordered
        loading={this.props.isDataLoading}
        pagination={false}
        tableLayout='fixed'
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              this.props.history.push('/singleJobDescription/' + record.id)
            }
          }
        }}
        scroll={{ y: 600 }}
      />
    );
  }
} export default withRouter(JobDescriptionsTable);