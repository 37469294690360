import React from "react";
import { Card, Typography, Spin, Tooltip, Row, Col, /* Icon, Modal, */ Form/* , Input */ } from "antd";
import Axios from "axios";
import { withRouter } from "react-router-dom";
import { errorRouter } from "../errors/ErrorRouter";

/* import homeSolid from "../../resources/icons/homeSolid.png";
import suitcaseSolid from "../../resources/icons/suitcaseSolid.png";
import envelopeSolid from "../../resources/icons/envelopeSolid.png";
import externalLinkAlt from "../../resources/icons/externalLinkAlt.png"; 
import { HomeFilled, HomeTwoTone, MailTwoTone, ScheduleTwoTone } from "@ant-design/icons";*/


import Translate from 'react-translate-component';

const { Meta } = Card;
const { Title, Text } = Typography;

/**
 * Props utilizzati: 
 * - candidateID: Contiene l'id del candidato considerato, in modo da poter recuperare tutti i suoi dati
 */
class CandidateInfoCard extends React.Component {

  state = {
    //Booleano che definisce se i dati stanno caricando o meno 
    isLoading: true,

    isUpdateModalVisibile: false,
  }

  //Viene utilizzato l'id del candidato per recuperare tutti i suoi dati personali
  componentDidMount() {
    this.loadCandidatePersonalData();
  }

  loadCandidatePersonalData = async () => {
    await this.setState({
      isLoading: false,
    })
    await this.props.form.setFieldsValue({
      cvs: [
        {
          'personal': {
            'address': {
              'streetName': this.props.candidateAddress.streetName,
              'streetNumberBase': this.props.candidateAddress.streetNumberBase,
              'city': this.props.candidateAddress.city,
              'postalCode': this.props.candidateAddress.postalCode,
              'region': {
                'codeDescription': this.props.candidateAddress.region.codeDescription
              },
              'country': {
                'codeDescription': this.props.candidateAddress.country.codeDescription
              }
            },
            'email': [
              {
                'email': this.props.candidateEmail
              }
            ],
            'socialMediaLink': [
              {
                'url': this.props.candidateWebsite
              },
            ]
          },
          'summary': {
            'currentJob': this.props.candidateLastJob,
            'currentEmployer': this.props.candidateLastEmployer
          },
        }
      ]
    })
  }

  openUpdateCandidateDataModal = () => {
    this.setState({
      isUpdateModalVisibile: true,
    })
    this.loadCandidatePersonalData();
  }

  handleUpdateModalOk = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        Axios.patch(`/api/candidato/${this.props.candidateID}`, values)
          .catch((error) => {
            errorRouter(error.response.status, this.props.history);
          })
      }
    });
  }

  handleUpdateModalCancel = () => {
    this.setState({
      isUpdateModalVisibile: false,
    })
  }

  getAddress = (address) => {
    let fullAddress = '';
    fullAddress = fullAddress.concat(address.streetName.length !== 0 ? `${address.streetName}` : '');
    fullAddress = fullAddress.concat(address.streetNumberBase.length !== 0 ? `, ${address.streetNumberBase}` : '');
    fullAddress = fullAddress.concat(address.postalCode.length !== 0 ? `, ${address.postalCode}` : '');
    fullAddress = fullAddress.concat(address.city.length !== 0 ? `, ${address.city}` : '');
    if (address.region) {
      fullAddress = fullAddress.concat(address.region.codeDescription.length !== 0 ? `, ${address.region.codeDescription}` : '')
    }
    if (address.country) {
      fullAddress = fullAddress.concat(address.country.codeDescription.length !== 0 ? `, ${address.country.codeDescription}` : '')
    }
    return fullAddress;
  }

  /* TODO Traduzioni per tutta la pagina */
  render() {
    //const { getFieldDecorator } = this.props.form;
    return (
      <Spin spinning={this.state.isLoading}>
        <Card id="personalCard" style={{ height: "213px" }} >
          <Meta
            title={
              <Row>
                <Col span={22}>
                  <Title level={4} style={{ color: "#3f88c5" }} >
                    <Translate content='cards.candidateData' />
                  </Title>
                </Col>
                {/*  <Col span={2}>
                  <Icon type="edit" theme="filled" style={{ fontSize: 18, color: '#4D4F5C' }} onClick={this.openUpdateCandidateDataModal} />
                </Col>*/}
              </Row>

            }
          />
          <div style={{ marginTop: 5, paddingLeft: 3 }}>
            <Tooltip
              title={
                this.props.candidateAddress
                  ? this.getAddress(this.props.candidateAddress)
                  : <Translate content='candidateData.noAddress' />
              }
            >
              <Row>
                <Col
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5}}
                >
                  <i className="fa-light fa-house-user iconsCandidate" style={{ marginRight: '0.5rem', fontSize: '1.1rem' }}></i>
                  <Text style={{ maxWidth: 200 }} ellipsis>
                    {
                      this.props.candidateAddress
                        ? this.getAddress(this.props.candidateAddress).length !== 0
                          ? this.getAddress(this.props.candidateAddress)
                          : <Translate content='candidateData.noAddress' />
                        : <Translate content='candidateData.noAddress' />
                    }
                  </Text>
                </Col>
              </Row>
            </Tooltip>
          </div>
          <div style={{ marginTop: 5, paddingLeft: 3 }}>
            <Tooltip
              title={
                this.props.candidateLastJob.length !== 0
                  ? this.props.candidateLastJob.concat(
                    this.props.candidateLastEmployer.length !== 0
                      ? localStorage.getItem("Language") === 'it'
                        ? ` presso ${this.props.candidateLastEmployer}`
                        : ` to ${this.props.candidateLastEmployer}`
                      : ''
                  )
                  : ''
              }
            >
              <Row>
                <Col 
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5}}
                >
                  <i className="fa-light fa-briefcase iconsCandidate" style={{ marginRight: '0.5rem', fontSize: '1.1rem' }}></i>
                  <Text style={{ maxWidth: 200 }} ellipsis>
                    {
                      this.props.candidateLastJob.length !== 0
                        ? this.props.candidateLastJob.concat(
                          this.props.candidateLastEmployer.length !== 0
                            ? localStorage.getItem("Language") === 'it'
                              ? ` presso ${this.props.candidateLastEmployer}`
                              : ` to ${this.props.candidateLastEmployer}`
                            : ''
                        )
                        : this.props.candidateLastEmployer.length !== 0
                          ? localStorage.getItem("Language") === 'it'
                            ? `Ruolo non specificato presso ${this.props.candidateLastEmployer}`
                            : `Unknown role to ${this.props.candidateLastEmployer}`
                          : <Translate content='candidateData.missingData' />
                    }
                  </Text>
                </Col>
              </Row>
            </Tooltip>
          </div>
          <div style={{ marginTop: 5, paddingLeft: 3 }}>
            <Tooltip
              title={
                this.props.candidateEmail.length !== 0
                  ? ` ${this.props.candidateEmail}`
                  : <Translate content='candidateData.noEmailAddress' />
              }
            >
              <Row>
                <Col 
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5}}
                >
                  <i className="fa-light fa-envelope iconsCandidate" style={{ marginRight: '0.5rem', fontSize: '1.1rem' }}></i>
                  <Text style={{ maxWidth: 200 }} ellipsis>
                    {
                      this.props.candidateEmail
                        ? this.props.candidateEmail
                        : <Translate content='candidateData.noEmailAddress' />
                    }
                  </Text>
                </Col>
              </Row>
            </Tooltip>
          </div>
          <div style={{ marginTop: 10, paddingLeft: 5 }}>
            <Row>
              <Col 
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5}}
              >
                <i className="fa-brands fa-linkedin iconsCandidate" style={{ marginRight: '0.5rem', fontSize: '1.1rem' }}></i>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={
                    this.props.candidateWebsite
                      ? this.props.candidateWebsite
                      : `https://www.linkedin.com/search/results/all/?keywords=${this.props.candidateName}&origin=GLOBAL_SEARCH_HEADER`}
                > 
                  {
                    this.props.candidateWebsite
                      ? this.props.candidateWebsite.toLowerCase().includes('linkedin')
                        ? <Translate content='candidateData.openLinkedin' />
                        : <Translate content='candidateData.candidateWebsite' />
                      : localStorage.getItem("Language") === 'it'
                        ? `Cerca ${this.props.candidateName} su LinkedIn`
                        : `Search ${this.props.candidateName} on LinkedIn`
                  }
                </a>
              </Col>
            </Row>     
          </div>
        </Card>
        {/* <Modal
          title="Modificati i dati personali del candidato"
          visible={this.state.isUpdateModalVisibile}
          onOk={this.handleUpdateModalOk}
          onCancel={this.handleUpdateModalCancel}
        >
          <Form layout="vertical" className="login-form">
            <Form.Item label="Via/Viale/Piazza">
              {getFieldDecorator('cvs[0].personal.address.streetName')(
                <Input
                  prefix={
                    <img
                      src={homeSolid}
                      alt="Città"
                      style={{ height: 12, color: '#3f88c5'}}
                    />
                  }
                  placeholder="Inserisci Via/Viale/Piazza"
                />,
              )}
            </Form.Item>
            <Form.Item label="Numero Civico">
              {getFieldDecorator('cvs[0].personal.address.streetNumberBase')(
                <Input
                  prefix={<Icon type="number" style={{ color: '#3f88c5' }} />}
                  placeholder="Inserisci Numero Civico"
                />,
              )}
            </Form.Item>
            <Form.Item label="Città">
              {getFieldDecorator('cvs[0].personal.address.city')(
                <Input
                  prefix={<Icon type="environment" theme='filled' style={{ color: '#3f88c5' }} />}
                  placeholder="Inserisci Città"
                />,
              )}
            </Form.Item>
            <Form.Item label="Regione">
              {getFieldDecorator('cvs[0].personal.address.region.codeDescription')(
                <Input
                  prefix={<Icon type="environment" theme='filled' style={{ color: '#3f88c5' }} />}
                  placeholder="Inserisci Regione"
                />,
              )}
            </Form.Item>
            <Form.Item label="Paese">
              {getFieldDecorator('cvs[0].personal.address.country.codeDescription')(
                <Input
                  prefix={<Icon type="environment" theme='filled' style={{ color: '#3f88c5' }} />}
                  placeholder="Inserisci Paese"
                />,
              )}
            </Form.Item>
            <Form.Item label="Ultimo Impiego">
              {getFieldDecorator('cvs[0].summary.currentJob')(
                <Input
                  prefix={
                    <img
                      src={suitcaseSolid}
                      alt="Ultimo Lavoro"
                      style={{ height: 12, }}
                    />
                  }
                  placeholder="Inserisci Ultimo Impiego"
                />,
              )}
            </Form.Item>
            <Form.Item label="Ultimo Datore di Lavoro">
              {getFieldDecorator('cvs[0].summary.currentEmployer')(
                <Input
                  prefix={
                    <img
                      src={suitcaseSolid}
                      alt="Ultimo Lavoro"
                      style={{ height: 12, }}
                    />
                  }
                  placeholder="Inserisci Ultimo Datore di Lavoro"
                />,
              )}
            </Form.Item>
            <Form.Item label="Email">
              {getFieldDecorator('cvs[0].personal.email[0].email')(
                <Input
                  prefix={
                    <img
                      src={envelopeSolid}
                      alt="Email"
                      style={{ height: 10,}}
                    />
                  }
                  placeholder="Inserisci Email"
                />,
              )}
            </Form.Item>
            <Form.Item label="Social Media">
              {getFieldDecorator('cvs[0].personal.socialMediaLink[0].url')(
                <Input
                  prefix={
                    <img
                      src={externalLinkAlt}
                      alt="Link"
                      style={{ height: 14,}}
                    />
                  }
                  placeholder="Inserisci Url Social Media"
                />,
              )}
            </Form.Item>
          </Form>
        </Modal> */}
      </Spin>
    );
  }
} const CandidateInfoCardForm = Form.create({ name: 'horizontal_login' })(CandidateInfoCard);
export default withRouter(CandidateInfoCardForm);
