import React from 'react';
//Librerie per il multilingua
import Translate from 'react-translate-component';
//Componenti ant design utilizzati
import { Drawer, Typography, Radio, Row, Col, Button, Input } from 'antd';
import { withRouter } from 'react-router-dom';
import Search from 'antd/lib/input/Search';
import counterpart from 'counterpart';

//Componente Text di Ant Design, fa parte della libreria Typography
const { Text } = Typography;

/**
 * Props utilizzati:
 * - isVisible: variabile booleana che indica se il drawer deve essere visibile o meno
 * - onClose: metodo lanciato per la chiusura del drawer (set di isVisible: false)
 * - onVisualizationSettingChange: metodo lanciato al cambiamento della selezione dei radio button del radio group
 * - tableVisualizationValue: valore selezionato nel radio group
*/
class FilterDrawer extends React.Component {

  state = {
    keyword1: "",
    keyword2: ""
  }

  onJobSearch = (searchingJob) => {
    this.props.onJobSearch(searchingJob);
  }

  onCandidateSearch = (searchingCandidate) => {
    this.props.onCandidateSearch(searchingCandidate);
  }

  onSearchCancel = () => {
    if (this.props.history.location.pathname === "/jobdescriptions")
      this.props.loadJobDescriptionsData();
    else
      this.props.loadCandidatesData();
  }

  onSearchStart = () => {
    this.props.fullTextSearch(this.state.keyword1, this.state.keyword2)
  }

  keyword1Select = (input) => {
    this.setState({
      keyword1: input.target.value
    })
  }

  keyword2Select = (input) => {
    this.setState({
      keyword2: input.target.value
    })
  }

  render() {
    let drawerTitle = counterpart.translate('drawer.title');
    return (
      <div>
        <Drawer
          title={drawerTitle}
          placement="right"
          closable={true}
          onClose={this.props.onClose}
          visible={this.props.isVisible}
          width={350}
        >
          <Row gutter={[8, 24]}>
            <Col>
              <Text
                strong
                style={{ fontSize: 16 }}
              >
                <Translate content='drawer.typeOfVisualization' />
              </Text>
            </Col>
          </Row>
          <Row gutter={[8, 24]}>
            <Col>
              <Radio.Group onChange={this.props.onVisualizationSettingChange} value={this.props.tableVisualizationValue}>
                <Radio value={'1'}><Translate content='drawer.gridText' /></Radio>
                <Radio value={'2'}><Translate content='drawer.tableText' /></Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col>
              <Text
                strong
                style={{ fontSize: 16 }}
              >
                <Translate content='drawer.nameSearchText' />
              </Text>
            </Col>
          </Row>
          <Row gutter={[8, 24]}>
            <Col>
              <Search
                //TODO sistemare Object[object]
                placeholder={counterpart.getLocale() === 'it'
                              ? this.props.history.location.pathname === "/jobdescriptions"
                                ? 'Inserisci nome posizione lavorativa' 
                                : 'Inserisci nome o cognome del candidato'
                              : this.props.history.location.pathname === "/jobdescriptions"
                                ? 'Insert job description\'s name' 
                                : 'Insert candidate\'s name or surname'
                            }
                enterButton={<Translate content='drawer.searchText' />}
                size="default"
                onSearch={this.props.history.location.pathname === "/jobdescriptions" ? this.onJobSearch : this.onCandidateSearch}
              />
            </Col>
          </Row>
          <Row gutter={[8, 24]}>
            <Col>
              <Button onClick={this.onSearchCancel}><Translate content='drawer.resetSearchText' /></Button>
            </Col>
          </Row>
          {
            this.props.history.location.pathname === "/candidates" ? 
            <><Row gutter={[8, 8]}>
            <Col>
              <Text
                strong
                style={{ fontSize: 16 }}
              >
                <Translate content='drawer.fullTextSearch' />
              </Text>
            </Col>
          </Row>
          <Row gutter={[8, 24]}>
            <Col>
              <Input
                //TODO sistemare [object Object]
                placeholder={counterpart.getLocale() === 'it'
                              ? 'Inserisci prima parola chiave'
                              : 'Insert first keyword'
                            }
                size="default"
                onChange={this.keyword1Select}
              />
            </Col>
          </Row>
          <Row gutter={[8, 24]}>
            <Col>
              <Input
                placeholder={counterpart.getLocale() === 'it'
                              ? 'Inserisci seconda parola chiave'
                              : 'Insert second keyword'
                            }
                size="default"
                onChange={this.keyword2Select}
              />
            </Col>
          </Row>
          <Row gutter={[8, 24]}>
            <Col>
              <Button onClick={this.onSearchStart}><Translate content='drawer.startSearchText' /></Button>
            </Col>
          </Row></> : null
          }
        </Drawer>
      </div>
    );
  }
} export default withRouter(FilterDrawer);