import React from 'react';
//Componenti ant design utilizzati
import { Icon } from 'antd';

//Il componente non riceve nessun props in ingresso, creato solo per evitare di duplicare il codice 
export default function PrevPageBar(props) {
  return (
    <Icon type='arrow-left' onClick={props.onPrevPageClick} style={{ fontSize: 25, paddingBottom: 20, color: '#3f88c5' }} />
  )
}
