import React from 'react'

import { Input, Row, Col, Form, Select, Button} from 'antd';
import Axios from 'axios';
import { errorRouter } from '../../errors/ErrorRouter';

import Translate from 'react-translate-component';

class JobDescriptionStep extends React.Component {

  state = {
    jobDescriptionTitleOptions: [],
    titlesMap: null,
  }

  //Lancia il caricamento dei dati dello step.
  componentDidMount() {
    this.props.setJobDescriptionStepData()
    if (this.props.jobProfessionCode.length !== 0 && this.props.jobProfessionTitle.length !== 0) {
      let tempTitlesMap = new Map();
      this.props.jobProfessionCode.forEach((profession, index) => {
        tempTitlesMap.set(profession.jobCode, this.props.jobProfessionTitle[index]);
      })
      this.setState({
        titlesMap: tempTitlesMap,
      })
    }
  }

  componentDidUpdate() {
    if (this.props.jobProfessionCode.length !== 0 && this.props.jobProfessionTitle.length !== 0 && this.state.titlesMap === null) {
      let tempTitlesMap = new Map();
      this.props.jobProfessionCode.forEach((profession, index) => {
        tempTitlesMap.set(profession.jobCode, this.props.jobProfessionTitle[index]);
      })
      this.setState({
        titlesMap: tempTitlesMap,
      })
    }
  }

  //Al cambiamento, aggiorna il dato contenente il titolo della job description
  onJobTitleChange = (title) => {
    this.props.onJobTitleChange(title.target.value);
  }

  onJobTitleSearch = (title) => {
    if (title || title.length !== 0)
      Axios.get(`/api/professionDescriptions/search/findTop50ByProfessionDescriptionContainingOrderByProfessionDescriptionAsc?searchString=${title}`)
        .then(response => {
          let jobDescriptionTitleOptionsArray = [];
          let tempTitlesMap = new Map();
          response.data._embedded.professionDescriptions.forEach(option => {
            jobDescriptionTitleOptionsArray.push(option.professionDescription);
            tempTitlesMap.set(option.professionDescription, option.professionCode)
          });
          this.setState({
            jobDescriptionTitleOptions: jobDescriptionTitleOptionsArray,
            titlesMap: tempTitlesMap,
          })
        })
        .catch(error => {
          if (error.response) {
            errorRouter(error.response.status, this.props.history);
          }
        })
    else
      this.setState({
        jobDescriptionTitleOptions: [],
      })
  }

  onProfessionDescriptionSelect = (title) => {
    this.props.onProfessionDescriptionSelect(title, this.state.titlesMap.get(title));
  }

  onProfessionDescriptionDeselect = (title) => {
    this.props.onProfessionDescriptionDeselect(title, this.state.titlesMap.get(title));
  }

  //Al cambiamento, aggiorna il dato contenente il codice della job description
  onJobCodeChange = (code) => {
    this.props.onJobCodeChange(code.target.value)
  }

  //Al cambiamento, aggiorna il dato contenente il tipo di visualizzazione dei candidati scelta
  onViewChange = (view) => {
    this.props.onViewChange(view.target.value)
  }

  //Al cambiamento, aggiorna il dato contenete la descrizione della job description
  onJobDescriptionChange = (jobDescription) => {
    this.props.onJobDescriptionChange(jobDescription.target.value)
  }

  render() {
    const getFieldDecorator = this.props.getFieldDecorator
    return (
      <Row gutter={[8, 8]}>
        <Row type='flex' justify='start' align='middle'>
          <Col span={24}>
            <Form.Item
              label={<Translate content='newJobDescription.titleText' />}
              labelCol={{
                xs: { span: 1, offset: 7 },
                sm: { span: 1, offset: 7 },
                md: { span: 2, offset: 6 },
                lg: { span: 2, offset: 6 },
                xl: { span: 2, offset: 6 },
                xxl: { span: 1, offset: 7 },
              }}
              wrapperCol={{
                xs: { span: 8 },
                sm: { span: 8 },
                md: { span: 12, offset: 6 },
                lg: { span: 8, offset: 0 },
                xl: { span: 8 },
                xxl: { span: 8 },
              }}
            >
              {getFieldDecorator('title',
                {
                  rules: [{
                    required: true,
                    message: localStorage.getItem("Language") === 'it'
                              ? 'Inserire il titolo!' 
                              : 'Enter the title!'
                  }],
                })(
                  <Input
                    placeholder={localStorage.getItem("Language") === 'it'
                                  ? 'Inserire titolo' 
                                  : 'Enter title'}
                    onChange={this.onJobTitleChange}
                  />
                )}

            </Form.Item>
          </Col>
        </Row>
        <Row type='flex' justify='start' align='middle'>
          <Col span={24}>
            <Form.Item
              label={<Translate content='newJobDescription.jobTitleText' />}
              labelCol={{
                xs: { span: 3, offset: 5 },
                sm: { span: 3, offset: 5 },
                md: { span: 6, offset: 5 },
                lg: { span: 5, offset: 3 },
                xl: { span: 4, offset: 4 },
                xxl: { span: 3, offset: 5 },
              }}
              wrapperCol={{
                xs: { span: 8 },
                sm: { span: 8 },
                md: { span: 12, offset: 6 },
                lg: { span: 8, offset: 0 },
                xl: { span: 8 },
                xxl: { span: 8 },
              }}
            >
              {getFieldDecorator('titleOption')(
                <Select
                  placeholder={localStorage.getItem("Language") === 'it'
                                ? 'Cerca un titolo' 
                                : 'Search title'}
                  mode='multiple'
                  onSearch={this.onJobTitleSearch}
                  onSelect={this.onProfessionDescriptionSelect}
                  onDeselect={this.onProfessionDescriptionDeselect}
                  style={{ width: '500', border: '1px solid #d9d9d9', borderRadius: '5px' }}
                >
                  {
                    this.props.jobProfessionTitle.length !== 0 && this.state.jobDescriptionTitleOptions.length === 0
                      ? this.props.jobProfessionTitle.map((option) => {
                        return (
                          <Select.Option value={option} key={option}>
                            {option}
                          </Select.Option>
                        )
                      })
                      : this.state.jobDescriptionTitleOptions.map((option) => {
                        return (
                          <Select.Option value={option} key={option}>
                            {option}
                          </Select.Option>
                        )
                      })
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex' justify='start' align='middle'>
          <Col span={24}>
            <Form.Item
              label={<Translate content='newJobDescription.codeText' />}
              labelCol={{
                xs: { span: 3, offset: 5 },
                sm: { span: 3, offset: 5 },
                md: { span: 6, offset: 5 },
                lg: { span: 5, offset: 3 },
                xl: { span: 4, offset: 4 },
                xxl: { span: 3, offset: 5 },
              }}
              wrapperCol={{
                xs: { span: 8 },
                sm: { span: 8 },
                md: { span: 12, offset: 6 },
                lg: { span: 8, offset: 0 },
                xl: { span: 8 },
                xxl: { span: 8 },
              }}
              validateStatus={this.props.jobCodeStatus}
              help={this.props.jobCodeStatus === "warning"
                      ? <Translate content='newJobDescription.usedCodeText' />
                      : this.props.jobCodeStatus === "error"
                        ? <Translate content='newJobDescription.noCodeText' />
                        : null
              }
            >
              {getFieldDecorator('jobDescriptionCode')(
                <Input
                  placeholder={localStorage.getItem("Language") === 'it'
                                ? 'Codice posizione lavorativa' 
                                : 'Job description code'}
                  allowClear
                  maxLength={255}
                  onChange={this.onJobCodeChange}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        {/* <Row type='flex' justify='center' align='middle' >
          <Col span={24}>
            <Form.Item
              label={
                <span>
                  Visualizzazione Candidati
                </span>
              }
              labelCol={{
                xs: { span: 3, offset: 5 },
                sm: { span: 3, offset: 5 },
                md: { span: 8, offset: 6 },
                lg: { span: 6, offset: 2 },
                xl: { span: 5, offset: 3 },
                xxl: { span: 3, offset: 5 },
              }}
              wrapperCol={{
                xs: { span: 16 },
                sm: { span: 16 },
                md: { span: 12, offset: 6 },
                lg: { span: 16, offset: 0 },
                xl: { span: 16 },
                xxl: { span: 16 },
              }}
            >
              {getFieldDecorator('view')(
                <Radio.Group onChange={this.onViewChange} >
                  <Radio value={0}>Junior</Radio>
                  <Radio value={1}>Senior</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </Row> */}
        {/* <Row type='flex' justify='start'>
          <Col span={24}>
            <Form.Item
              label={
                <span>
                  Job Description Estesa&nbsp;
                  <Tooltip title="Se è attivo il modulo di parsing semantico sarà possibile eseguire il matching in base all'immissione di testo libero" // TODO TESTO TOOLTIP MULTILINGUA >
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
              labelCol={{
                xs: { span: 3, offset: 5 },
                sm: { span: 3, offset: 5 },
                md: { span: 5, offset: 6 },
                lg: { span: 4, offset: 4 },
                xl: { span: 3, offset: 5 },
                xxl: { span: 3, offset: 5 },
              }}
              wrapperCol={{
                xs: { span: 8 },
                sm: { span: 8 },
                md: { span: 12, offset: 6 },
                lg: { span: 8, offset: 0 },
                xl: { span: 8 },
                xxl: { span: 8 },
              }}
            >
              {getFieldDecorator('description')(
                <Input.TextArea
                  placeholder="Descrizione Job Description"
                  allowClear
                  autoSize={{ minRows: 4, maxRows: 9 // Per gli xl, capire se si può mettere in base ai breakpoint  }}
                  onChange={this.onJobDescriptionChange}
                />

              )}
            </Form.Item>
            {/* <Row type='flex' justify='center' align='middle' style={{ paddingTop: 20 }} >
              <Tooltip title="Coming Soon - Permetterà di ottenere dati a partire dalla job description estesa">
                <Button
                  type='primary'
                  icon="poweroff"
                  loading={this.props.isScanningDescription}
                  onClick={this.props.scanDescription}
                  disabled={this.props.JobDescriptionExtended.length !== 0 ? false : true}
                >
                  Analizza Descrizione
                </Button>
              </Tooltip>
            </Row> }
          </Col>
        </Row> */}
        <Row type='flex' justify='center' align='middle' style={{marginTop: '2rem'}}>
          <Col span={22} style={{display: 'flex', justifyContent:'end'}}>
            <Button  type="primary" color='#3f88c5' onClick={this.props.stepAvanti}>
              <Translate content='newJobDescription.continueButtonText' />
            </Button>
          </Col>
        </Row>
      </Row >
    )
  }
} export default JobDescriptionStep;