import React from 'react';
//Utilizzate per le Rotte
import {
  Switch,
  Route,
  withRouter,
} from 'react-router-dom';
/** 
 * Viene utilizzato per studiare l'attività dell'utente all'interno dell'app, e per definire un
 * intervallo di tempo dopo il quale l'utente diventa inattivo, nel caso in cui non interagisce con l'app 
*/
import IdleTimer from 'react-idle-timer'
/** 
 * Componente utilizzato per modificare la scrollbar, in quanto quella originale modificava
 * la larghezza della pagina. Semplice scelta stilistica, componente non necessario al funzionamento
 * dell'applicazione 
*/
import { Scrollbars } from 'react-custom-scrollbars';
//Componenti Custom creati da noi
import ZarathustraHeader from './components/ZarathustraHeader';
import ZarathustraFooter from './components/ZarathustraFooter';
import Dashboard from './components/main/Dashboard';
import JobDescriptions from './components/main/jobdescriptions/JobDescriptions';
import SingleJobDescription from './components/main/jobdescriptions/SingleJobDescription';
import NewJobDescription from './components/new-job_description/NewJobDescription';
import Candidates from './components/main/candidates/Candidates';
import SingleCandidate from './components/main/candidates/SingleCandidate';
import Settings from './components/main/Settings';
import FabButton from './components/fab-buttons/FabButton';
import Axios from 'axios';

import { errorRouter } from './components/errors/ErrorRouter';
import Error403 from './components/errors/Error403';
import Error404 from './components/errors/Error404';
import Error503 from './components/errors/Error503';
import SessionCountdownModal from './components/modals/SessionCountdownModal';

class App extends React.Component {

  state = {
    /**
     * Salva l'id della job description cliccata nel componente <JobDescriptions />, in modo che possa 
     * essere passata tramite URL al componente <SingleJobDescription />. Ciò permette di evitare che il dato 
     * venga perso in caso di refresh della pagina, in quanto salvato nell'URL 
    */
    jobDescriptionID: 0,
    /**
     * Salva l'id del candidato cliccato nel componente <Candidates />, in modo che possa 
     * essere passato tramite URL al componente <SingleCandidate />. Ciò permette di evitare che il dato 
     * venga perso in caso di refresh della pagina, in quanto salvato nell'URL 
    */
    candidateID: 0,
    /**
     * sessionCountdownMinutes e sessionCountdownSeconds indicano rispettivamente minuti e secondi dalla scadenza del token, e 
     * sono visualizzati nel popup che si apre in caso di inattività da parte dell'utente. Il popup è aperto a 5 minuti dalla
     * scadenza del token.
     */
    sessionCountdownMinutes: 0,
    sessionCountdownSeconds: 0,
    /**
     * Il metodo setInterval(milliseconds) genera un timer che lancia una funziona ogni tot 'milliseconds', e restituisce l'id
     * del timer creato. Non ci sono timer concorrenti che vengono lanciati contemporaneamente, quindi sicuramente l'ultimo 
     * ID salvato in questo stato è l'id del timer attualmente attivo. Viene salvato per riuscire a cancellare, in un secondo momento e 
     * se necessario, il timer attivo. 
     */
    activeIntervalID: 0,
    /** 
     * Avvisa il componente <Modal /> di aprirsi nel momento in cui il timer raggiunge i 5 minuti dalla scadenza del token:
     * - true: il modal è visualizzato
     * - false: il modal viene nascosto
     */
    isSessionModalVisible: false,
    /** E' il timer di default che viene scalato dalla durata totale della scadenza del token. */
    defaultTimer: (1000 * 60 * 5),
    //Array contenente le varie preferenze dell'utente.
    userSettings: [],
    //E' utilizzato, in caso di eventuali errori restituiti dalle chiamate, per comunicare all'header di nascondere le icone del menù
    hasError: false,
    //Permette di visualizzare o meno il modal per l'aggiunta dei curriculum.
    isAddCvsVisible: false,

    candidatesDataAreRefreshing: false,
    authToken: '',
    isJobUpdating: false,
    idUpdatingJob: 0,
  }
  /** 
   * Salva l'istante preciso (timestamp) dell'ultimo momento in cui l'utente ha interagito con l'app
   * in modo da sapere precisamente se l'utente è stato attivo nell'intervallo di tempo necessario. Vedere
   * il funzionamento del refresh del token (a partire da onTimerStart()) per comprendere la necessita di 
   * questa variabile.
   */
  static lastUserInteraction = null;

  //Inizializzazione necessaria per il corretto funzionamento del timer di <IdleTimer />
  constructor(props) {
    super(props)
    this.idleTimer = null
    this.onIdle = this._onIdle.bind(this)
    this.onAction = this._onAction.bind(this)
    this.state = {
      jobDescriptionID: 0,
      candidateID: 0,
      sessionCountdownMinutes: 0,
      sessionCountdownSeconds: 0,
      activeIntervalID: 0,
      isSessionModalVisible: false,
      defaultTimer: (1000 * 60 * 5),
      userSettings: [],
      hasError: false,
      isAddCvsVisible: false,
      candidatesDataAreRefreshing: false,
      authToken: localStorage.getItem('Token'),
    }
  }

  /**
   * Nel momento in cui l'utente riesce ad effettuare il login con successo, viene lanciato il componente 
   * <App />, e successivamente al render viene salvata l'ultima interazione dell'utente come l'istante "preciso"
   * in cui ha effettuato il login, e viene richiesta al back end (in modo da averlo anche in caso di perdita di Duration
   * dal localStorage) il tempo rimasto alla scadenza del token.
   */
  componentDidMount() {
    this.lastUserInteraction = Date.now()
    Axios.get('/api/token/remaining')
      .then((response) => {
        /**
         * Una volta ottenuto il tempo rimanente alla scadenza del token, viene lanciato il timer che si occupa di capire in quale istante
         * il token sta per scadere.
         */
        this.setState({
          authToken: Axios.defaults.headers.common['Authorization']
        })
        this.onTimerStart(response.data.remaining)
      })
      .catch((error) => {
        localStorage.setItem("Token", "");
        localStorage.setItem('Username', "");
        localStorage.setItem('userLogged', "");
        this.props.history.push("/login")
        /* if (error.response) {
          errorRouter(error.response.status, this.props.history);
        } */
      })
    Axios.get('/api/userpreference')
      .then((response) => {
        this.setState({
          userSettings: response.data
        })
      })
      .catch((error) => {
        localStorage.setItem("Token", "");
        localStorage.setItem('Username', "");
        localStorage.setItem('userLogged', "");
        this.props.history.push("/login")
        /* if (error.response) {
          errorRouter(error.response.status, this.props.history);
        } */
      })
  }

  /** Nel momento in cui <App /> viene smontato (e quindi la schermata chiusa, o effettuato il logout), il timer attivo viene interrotto. */
  componentWillUnmount() {
    clearInterval(this.state.activeIntervalID)
  }

  /**
   * Questo metodo è lanciato ogni volta che viene salvata o modificata una nuova preferenza dell'utente, nei componenti sottostanti.
   * Nei componenti viene effettuata la chiamata per l'aggiornamento dei dati nel db, e qui invece viene aggiornato l'array in locale in modo 
   * che non debba essere effettuata di nuovo la lettura dei dati dal back end.
   */
  updateUserSettings = (setting, newValue) => {
    let settingsArray = this.state.userSettings.slice();
    let modifiedSettingIndex = settingsArray.map(element => element.name).indexOf(setting)
    if (modifiedSettingIndex !== -1) {
      settingsArray[modifiedSettingIndex].value = newValue;
      this.setState({
        userSettings: settingsArray
      })
    } else {
      let newSetting = {
        name: setting,
        value: newValue
      }
      settingsArray.push(newSetting);
      this.setState({
        userSettings: settingsArray
      })
    }
  }

  /**
   * Metodo lanciato dal componente <JobDescriptions /> in caso di click di una card. Permette di salvare nello stato l'id 
   * della job description cliccata
  */
  onJobCardClick = (jobDescriptionID) => {
    this.setState({
      jobDescriptionID: jobDescriptionID,
    })
  }
  /**
     * Metodo lanciato dal componente <Candidates /> in caso di click di una card. Permette di salvare nello stato l'id 
     * del candidato cliccato
  */
  onCandidateCardClick = (candidateCode) => {
    this.setState({
      candidateID: candidateCode,
    })
  }

  /**
   * Il timer lavora nel seguente modo: 
   * 1) Viene considerata, come sua durata, il tempo rimanente alla scadenza del token, in minuti (tokenDuration)
   * 2) A questa durata vengono sottratti 5 minuti (this.state.defaultTimer), in modo che a 5 minuti dalla scadenza 
   *    del token venga effettuato un controllo sull'attività dell'utente (vedere commento di IdleTimer sopra).
   * 3) Il timer viene lanciato con la nuova durata (tokenCheck = tokenDuration - this.state.defaultTimer)
  */
  onTimerStart = (tokenDuration) => {
    //Sottrae al tempo di scadenza del token 5 minuti
    let tokenCheck = (parseInt(tokenDuration, 10) - (this.state.defaultTimer)) / 1000;
    this.startTimer(tokenCheck);
  }

  /**
   * Viene usato il setInterval() in quanto il setTimeout() con tempi troppo lunghi non funziona correttamente. Il setInterval
   * è usato in modo che la nostra durata (duration) viene divisa in secondi, e ogni secondo il setInterval lancia la funzione che scala
   * un secondo alla durata. 
  */
  startTimer = (duration) => {
    var timer = duration, minutes, seconds;
    //Questo è l'intervalID del timer che viene lanciato, e che viene poi salvato nello stato (sotto), per essere utilizzato come indicato nel commento sopra
    let intervalID = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      /** 
       * Indica che il timer è terminato, e che quindi può essere lanciata la funziona di refresh del token. L'id del timer viene passato
       * per poterlo interrompere in tokenRefresh()
      */
      if (--timer < 0) {
        this.tokenRefresh(intervalID);
      }
    }.bind(this), 1000);
    // eslint-disable-next-line
    this.state.activeIntervalID = intervalID;
  }

  /**
   * Il refresh del token tiene in considerazione due possibilità: 
   * - L'utente è attivo (definito nel prossimo commento cosa si intende per utente attivo): il refresh viene effettuato automaticamente, l'utente non 
   *   ha nessun feedback a riguardo.
   * - L'utente è inattivo: il refresh non può essere effettuato automaticamente, quindi viene visualizzato un modal in cui si chiede a l'utente
   *   se vuole rinnovare il token, e viene lanciato un altro timer di 5 minuti. Dopo questi 5 minuti, se l'utente non accetta il refresh del token,
   *   viene automaticamente effettuato il logout. 
  */
  tokenRefresh = (intervalID) => {
    clearInterval(intervalID)
    /** 
    * L'utente risulta attivo nel momento in cui a 10 minuti dalla scadenza del token ha effettuato qualche azione. Per noi questo equivale
    * a dire che, al momento (Date.now()) della scadenza della durata del timer (durataToken- 5 minuti), se l'ultima interazione dell'utente (lastUserInteraction) 
    * rientra negli ultimi 5 minuti, allora l'utente è attivo. Successivamente è mostrato il salvataggio di "lastUserInteraction"
   */
    if (Date.now() - this.lastUserInteraction <= (this.state.defaultTimer)) {
      //Utente attivo: token refresh automatico.
      Axios.get('/api/token/refresh')
        .then((response) => {
          //Token settato nell'header di Axios, come visto in index.js
          Axios.defaults.headers.common['Authorization'] = response.headers.authorization;
          //Il token è salvato nel localStorage in modo che possa essere recuperato in caso di perdita.
          localStorage.setItem('Token', response.headers.authorization);

          localStorage.setItem('tokenDuration', response.data.Duration);
          //Viene lanciato un nuovo timer, con la durata del token.
          this.setState({
            authToken: response.headers.authorization
          })
          this.onTimerStart(response.data.Duration)
        })
        .catch((error) => {
          if (error.response) {
            errorRouter(error.response.status, this.props.history);
          }
        });
    } else {
      //Il modal è abilitato
      this.setState({
        isSessionModalVisible: true,
      })
    }
  }

  //Metodo di refresh del token lanciato nel momento in cui l'utente interagisce con il modal di refresh sopra descritto
  tokenRenew = () => {
    this.setState({
      isSessionModalVisible: false,
    })
    clearInterval(this.state.activeIntervalID);
    Axios.get('/api/token/refresh')
      .then((response) => {
        Axios.defaults.headers.common['Authorization'] = response.headers.authorization;
        //Il token è salvato nel localStorage in modo che possa essere recuperato in caso di perdita.
        localStorage.setItem('Token', response.headers.authorization);
        localStorage.setItem('tokenDuration', response.data.Duration);
        this.setState({
          authToken: response.headers.authorization
        })
        this.onTimerStart(response.data.Duration)
      })
      .catch((error) => {
        if (error.response) {
          errorRouter(error.response.status, this.props.history);
        }
      });
  }

  activeIntervalIDUpdate = (tempFinalIntervalID) => {
    //Anche qui viene settato l'id del timer.
    this.setState({
      activeIntervalID: tempFinalIntervalID
    })
  }

  onSessionModalHide = () => {
    this.setState({
      isSessionModalVisible: false,
    })
  }

  /**
   * I due metodi successivi vengono lanciati dal componente <IdleTimer />:
   * - _onIdle: lanciato nel momento in cui l'utente risulta inattivo, ossia dopo 5 minuti dall'ultima attività. E' il componente a gestire gli eventi 
   *   dell'utente per capire se è attivo o meno. Il tempo per renderlo inattivo è definito nel componente stesso ("timeout")
   * - _onAction: lanciato ogni volta che l'utente effettua un azione. Le azioni tengono conto di eventi del mouse e della tastiera.
  */
  _onIdle(e) {
    this.lastUserInteraction = this.idleTimer.getLastActiveTime();
  }
  //Se lanciato, l'utente ha interagito, e quindi viene salvato quell'istante come ultima interazione.
  _onAction(e) {
    this.lastUserInteraction = Date.now()
  }

  //Permette di attivare lo stato di errore dell'applicazione, che comunica all'header, tramite this.state.hasError, di nascondere le icone del menù
  errorHandling = () => {
    this.setState({
      hasError: !this.state.hasError,
    })
  }

  refreshCandidatesData = () => {
    this.setState({
      candidatesDataAreRefreshing: !this.state.candidatesDataAreRefreshing,
    })
  }

  onJobEditClick = (idUpdatingJob) => {
    if (idUpdatingJob === "saved") {
      this.setState({
        isJobUpdating: false,
        idUpdatingJob: 0,
      })
    } else {
      this.setState({
        isJobUpdating: true,
        idUpdatingJob,
      })
      this.props.history.push('/newjobdescription');
    }
  }

  render() {
    return (
      <div>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          debounce={250}
          onIdle={this.onIdle}
          onAction={this.onAction}
          timeout={this.state.defaultTimer}
        />
        <ZarathustraHeader hasError={this.state.hasError} />
        <Scrollbars style={{ height: '93vh' }} >
          <div>
            <Switch>
           
              <Route path='/dashboard'
                component={() =>
                  <Dashboard />
                }
              />
              <Route path={'/jobdescriptions'}
                component={() =>
                  <JobDescriptions
                    onJobCardClick={this.onJobCardClick}
                    userSettings={this.state.userSettings}
                    updateUserSettings={this.updateUserSettings}
                    onJobEditClick={this.onJobEditClick}
                  />
                }
              />
              {/** 
               * match.params permette di prelevare dall'url il parametro specificato (In questo caso jobDescriptionID) 
               * :jobDescriptionID sta ad indicare che nell'URL, il valore presente dopo 
               * /singlejobdescription/ è un parametro che prenderà il nome di jobDescriptionID, in modo che possa poi essere
               * recuperato con il match.params
              */}
              <Route path={'/singlejobdescription/:jobDescriptionID'}
                component={({ match }) =>
                  <SingleJobDescription
                    jobDescriptionID={match.params.jobDescriptionID}
                    onJobEditClick={this.onJobEditClick}
                  />
                }
              />
              <Route path={'/newjobdescription'}
                component={() =>
                  <NewJobDescription idUpdatingJob={this.state.idUpdatingJob} onJobEditClick={this.onJobEditClick} isJobUpdating={this.state.isJobUpdating} />
                }
              />
              <Route path={'/candidates'}
                component={() =>
                  <Candidates
                    onCandidateCardClick={this.onCandidateCardClick}
                    userSettings={this.state.userSettings}
                    updateUserSettings={this.updateUserSettings}
                    candidatesDataAreRefreshing={this.state.candidatesDataAreRefreshing}
                    refreshCandidatesData={this.refreshCandidatesData}
                  />
                }
              />
              <Route path={'/singlecandidate/:candidateID'}
                component={({ match }) =>
                  <SingleCandidate
                    candidateID={match.params.candidateID}
                    onJobEditClick={this.onJobEditClick}
                  />
                }
              />
              <Route path={'/settings'}
                component={() =>
                  <Settings />
                }
              />
              <Route path={'/forbiddenaccess'}
                component={() =>
                  <Error403 errorHandling={this.errorHandling} hasError={this.state.hasError} />
                }
              />
              <Route path={'/serviceunavailable'}
                component={() =>
                  <Error503 errorHandling={this.errorHandling} hasError={this.state.hasError} />
                }
              />
              <Route path='/' exact
                component={() =>
                  <Dashboard />
                }
              />
              <Route
                path='/login' component={Dashboard}
              />
              <Route
                component={() =>
                  <Error404 errorHandling={this.errorHandling} hasError={this.state.hasError} />
                }
              />
            </Switch>
          </div>
        </Scrollbars>
        {
          !this.state.hasError
            ? (
              <FabButton refreshCandidatesData={this.refreshCandidatesData} authToken={this.state.authToken} />
            )
            : null
        }
        <SessionCountdownModal
          sessionCountdownMinutes={this.state.sessionCountdownMinutes}
          sessionCountdownSeconds={this.state.sessionCountdownSeconds}
          isSessionModalVisible={this.state.isSessionModalVisible}
          tokenRenew={this.tokenRenew}
          activeIntervalIDUpdate={this.activeIntervalIDUpdate}
          onSessionModalHide={this.onSessionModalHide}
        />
        <ZarathustraFooter/>
      </div>
    );
  }
} export default withRouter(App);
