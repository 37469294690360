import React, { useEffect, useState } from "react";

import { InputNumber } from "antd";

import { Dropdown } from "primereact/dropdown";

const operations = [
  { operation: "<", value: 0 },
  { operation: "<=", value: 1 },
  { operation: "=", value: 2 },
  { operation: ">", value: 3 },
  { operation: ">=", value: 4 },
  { operation: "Range", value: 5 },
];

let onlyOperations = [];

const SettingsDropdown = (props) => {
  useEffect(() => {
    onlyOperations = operations.map((operation) => operation.operation);
  }, []);

  const onOperationChangeHandler = (event) => {
    event.preventDefault();
    console.log(event);
    props.onOperationChangeHandler(
      operations.findIndex((operation) => operation.operation === event.value),
      props.label
    );
  };

  const onMinYearsChangeHandler = (value) => {
    props.onMinYearsChangeHandler(value, props.label);
  };

  const onMaxYearsChangeHandler = (value) => {
    props.onMaxYearsChangeHandler(value, props.label);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "0.5rem",
      }}
    >
      <h3 style={{ margin: 0, paddingRight: "1rem" }}>{props.label}:</h3>
      <Dropdown
        className="experience"
        options={onlyOperations}
        value={onlyOperations[props.values.operation]}
        onChange={onOperationChangeHandler}
      />
      <InputNumber
        className="experience"
        defaultValue={0}
        value={props.values.minYear}
        min={0}
        max={100}
        onChange={onMinYearsChangeHandler}
      />
      {props.values.operation === 5 && (
        <InputNumber
          className="experience"
          value={props.values.maxYear}
          min={props.values.minYear + 1}
          max={100}
          onChange={onMaxYearsChangeHandler}
        />
      )}
    </div>
  );
};

export default SettingsDropdown;
