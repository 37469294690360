import React from 'react';

import { Table } from 'antd';
import Translate from 'react-translate-component';

import Moment from 'react-moment';
import { withRouter } from 'react-router-dom';

export default withRouter(function CandidatesTable(props) {
  return (
    <Table
      columns={
        [
          {
            title: <Translate content='candidates.nameText'/>,
            dataIndex: 'firstName',
            key: 'firstName',
            ellipsis: true,
            align: 'center',
            render: firstName => firstName ? firstName : "-"
          },
          {
            title: <Translate content='candidates.surnameText'/>,
            dataIndex: 'lastName',
            key: 'lastName',
            ellipsis: true,
            align: 'center',
            render: lastName => lastName ? lastName : "-"
          },
          {
            title: <Translate content='candidates.bornDateText'/>,
            dataIndex: 'dateOfBirth',
            key: 'born_date',
            ellipsis: true,
            align: 'center',
            render: date => date ? <Moment format='DD/MM/YYYY' date={date} /> : "-"
          },
          {
            title: <Translate content='candidates.phoneNumberText'/>,
            dataIndex: 'phone',
            key: 'phone_number',
            ellipsis: true,
            align: 'center',
          },
        ]
      }
      dataSource={props.cardsData}
      bordered
      loading={props.isDataLoading}
      pagination={false}
      tableLayout='fixed'
      onRow={(record) => {
        return {
          onClick: () => {
            props.history.push('/singlecandidate/' + record.id)
          }
        }
      }}
    />
  );
})