import React from "react";
import { Select, Row, Col, Table /* , Button */ } from "antd";

import InputNumberWithKey from "../InputNumberWithKey";
import { withRouter } from "react-router-dom";

import Translate from "react-translate-component";

import { Checkbox } from "primereact/checkbox";
import { remove } from "lodash";

const { Option, OptGroup } = Select;

class SkillsStep extends React.Component {
  state = {
    //Contiene gli id di tutte le skill selezionate
    idSelectedSkills: [],
    //Contiene tutte le skills selezionate
    selectedSkills: [],
    dbSkills: [],
    //booleano che permette di capire se le skills devono ancora essere caricate
    isSkillsLoading: true,
  };

  //Permette, prima del rendering, di recuperare tutti i dati sulle skill selezionate
  constructor(props) {
    super(props);
    this.onSelectedSkillsLoading();
  }

  componentDidUpdate() {
    this.onSelectedSkillsLoading();
  }

  onSelectedSkillsLoading = () => {
    let selectedSkillsId = [];

    this.props.selectedSkills.forEach((selectedSkill) => {
      selectedSkillsId = selectedSkillsId.concat(selectedSkill.id_skill);
    });
    // eslint-disable-next-line
    this.state = {
      idSelectedSkills: selectedSkillsId,
    };
  };

  onSkillsSearch = (searchValue) => {
    this.props.onSkillsSearch(searchValue);
  };

  /**
   * Lanciato alla selezione di una skill dalla select. Aggiunge a tempSelectedSkills
   * tutte le skills di dbSkills che hanno id corrispondenti con quelli presenti in skillIDs
   * Dopo di che aggiorna lo stato col nuovo dato
   */
  onSkillsSelection = (skillIDs) => {
    //Passa le nuove skill selezionate a <NewJobDescription /> in modo che lo stato possa essere aggiornato
    this.setState({
      idSelectedSkills: skillIDs,
    });
    this.props.onSkillsChange(skillIDs);
  };

  //Lanciato al cambiamento del peso di una skill
  onSkillWeightChange = (weight, id) => {
    this.props.onSkillWeightChange(weight, id);
  };

  onSkillMandatoryChange = (mandatory) => {
    this.props.onSkillMandatoryChange(mandatory);
  };

  onDeleteSkill = (skillId) => {
    remove(this.state.idSelectedSkills, (id) => skillId === id);
    this.onSkillsSelection(this.state.idSelectedSkills);
  };

  render() {
    return (
      <div>
        <Row type="flex" justify="center" align="top" gutter={[0, 48]}>
          <Col
            xs={{ span: 23 }}
            sm={{ span: 23 }}
            md={{ span: 23 }}
            lg={{ span: 23 }}
            xl={{ span: 23 }}
            xxl={{ span: 23 }}
            style={{ textAlign: "center" }}
          >
            <Select
              notFoundContent={
                <Translate content="newJobDescription.notFoundText" />
              }
              showArrow={false}
              filterOption={false}
              defaultActiveFirstOption={false}
              allowClear
              value={
                this.state.idSelectedSkills.length !== 0
                  ? this.state.idSelectedSkills
                  : []
              }
              defaultValue={
                this.state.idSelectedSkills.length !== 0
                  ? this.state.idSelectedSkills
                  : []
              }
              mode="multiple"
              placeholder={
                localStorage.getItem("Language") === "it"
                  ? "Seleziona le skill necessarie"
                  : "Select necessary skills"
              }
              onSearch={this.onSkillsSearch}
              onChange={this.onSkillsSelection}
              style={{
                width: "inherit",
                border: "1px solid #d9d9d9",
                borderRadius: "5px",
              }}
            >
              <OptGroup label="Soft Skills" key="SoftSkills">
                {this.props.dbSkills
                  ? this.props.dbSkills.map((skill) => {
                      if (skill.category === 1) {
                        return (
                          <Option key={skill.id} value={skill.id}>
                            {skill.descriptions}
                          </Option>
                        );
                      } else return false;
                    })
                  : false}
              </OptGroup>
              <OptGroup label="Hard Skills" key="HardSkills">
                {this.props.dbSkills
                  ? this.props.dbSkills.map((skill) => {
                      if (skill.category === 2 || skill.category === 3) {
                        return (
                          <Option key={skill.id} value={skill.id}>
                            {skill.descriptions}
                          </Option>
                        );
                      } else return false;
                    })
                  : false}
              </OptGroup>
              <OptGroup
                label={<Translate content="cards.languages" />}
                key="LanguagesSkills"
              >
                {this.props.dbSkills
                  ? this.props.dbSkills.map((skill) => {
                      if (skill.category === 4) {
                        return (
                          <Option key={skill.id} value={skill.id}>
                            {skill.descriptions}
                          </Option>
                        );
                      } else return false;
                    })
                  : false}
              </OptGroup>
            </Select>
          </Col>
        </Row>
        <Row type="flex" justify="center" align="middle" gutter={[0, 48]}>
          <Col
            xs={{ span: 23 }}
            sm={{ span: 23 }}
            md={{ span: 23 }}
            lg={{ span: 23 }}
            xl={{ span: 23 }}
            xxl={{ span: 23 }}
          >
            <Table
              columns={[
                {
                  title: <Translate content="candidates.nameText" />,
                  dataIndex: "descriptions",
                  key: "descriptions",
                  align: "center",
                },
                {
                  title: <Translate content="newJobDescription.typeText" />,
                  dataIndex: "category",
                  key: "category",
                  align: "center",
                  render: (text) => {
                    return text === 1
                      ? "Soft Skill"
                      : text === 4
                      ? "Lingua"
                      : "Hard Skill";
                  },
                },
                {
                  title: (
                    <Translate content="newJobDescription.weightTableText" />
                  ),
                  dataIndex: "weight",
                  key: "weight",
                  align: "center",
                  render: (value, record) => {
                    return (
                      <InputNumberWithKey
                        value={value}
                        totalPercentage={this.props.totalPercentage}
                        onSkillWeightChange={this.onSkillWeightChange}
                        skillID={record.id_skill}
                      />
                    );
                  },
                },
                {
                  title: (
                    <Translate content="newJobDescription.requiredText" />
                  ),
                  dataIndex: "mandatory",
                  key: "mandatory",
                  align: "center",
                  render: (value, record) => {
                    return (
                      <Checkbox
                        onChange={this.onSkillMandatoryChange}
                        checked={value}
                        value={record.id_skill}
                      />
                    );
                  },
                },
                {
                  title: <Translate content="jobDescriptions.deleteText" />,
                  dataIndex: "delete",
                  key: "delete",
                  align: "center",
                  render: (value, record) => {
                    return (
                      <i
                        onClick={() => this.onDeleteSkill(record.id_skill)}
                        className="fa-regular fa-xmark iconsDelete"
                        style={{ marginRight: "0.5rem", fontSize: "1.1rem" }}
                      ></i>
                    );
                  },
                },
              ]}
              dataSource={this.props.selectedSkills}
              bordered
              tableLayout="fixed"
              scroll={{ y: 400 }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
export default withRouter(SkillsStep);
