import React from 'react';

import { Modal } from 'antd';
import { withRouter } from 'react-router-dom';

class SessionCountdownModal extends React.Component {

  state = {
    sessionCountdownMinutes: 0,
    sessionCountdownSeconds: 0,
  }
  
  tempFinalIntervalID = null
  
  componentDidUpdate() {
    if (this.props.isSessionModalVisible && this.state.sessionCountdownMinutes === 0 && this.state.sessionCountdownSeconds === 0 && this.tempFinalIntervalID === null) {
      //Utente inattivo: viene lanciato un altro timer di 5 minuti durante i quali il modal che richiede il rinnovo del token blocca l'utilizzo dell'app
      var timer = ((60 * 1000 * 5) / 1000), minutes, seconds;
      //Stesso concetto del timer visto sopra, in "startTimer()"
      this.tempFinalIntervalID = setInterval(async function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);
        //Minuti e secondi vengono salvati nello stato per poterli visualizzare nel modal, aggiornati in tempo reale.
        await this.setState({
          sessionCountdownMinutes: minutes < 10 ? "0" + minutes : minutes,
          sessionCountdownSeconds: seconds < 10 ? "0" + seconds : seconds,
        })
        //Se il timer scade, l'utente non ha confermato il refresh, viene interrotto il timer, ed effettuato il logout
        if (--timer < 0) {
          clearInterval(this.tempFinalIntervalID);
          this.props.onSessionModalHide()
          localStorage.setItem('Token', '')
          localStorage.setItem('Username', "");
          localStorage.setItem('tokenDuration', 0);
          localStorage.setItem('userLogged', "");
          this.props.history.push('/forbiddenaccess');
        }
      }.bind(this), 1000);
      this.props.activeIntervalIDUpdate(this.tempFinalIntervalID)
    }
  }

  tokenRenew = () => {
    this.setState({
      sessionCountdownMinutes: 0,
      sessionCountdownSeconds: 0,
    })
    this.tempFinalIntervalID = null;
    this.props.tokenRenew()
  }

  render() {
    return (
      <Modal
        title={'La tua sessione scadrà tra ' + this.state.sessionCountdownMinutes + ':' + this.state.sessionCountdownSeconds + 'minuti. Vuoi rinnovarla?'}
        maskClosable={false}
        closable={false}
        onOk={this.tokenRenew}
        visible={this.props.isSessionModalVisible}
      >
        Rinnovare la sessione se necessario, altrimenti verrà effettuato il logout
      </Modal>
    )
  }
} export default withRouter(SessionCountdownModal);