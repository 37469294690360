import React from 'react';
//Componenti ant design utilizzati
import {Card, Row, Col, Typography, Modal, Timeline, Comment, Icon, Tooltip} from 'antd';
//Librerie per il multilingua
import Translate from 'react-translate-component';
import Moment from 'react-moment';
import {withRouter} from 'react-router-dom';

//Componenti Title e Text di Ant Design, fanno parte della libreria Typography
const {Title, Text} = Typography;

/**
 * Props utilizzati:
 * - onYearsOfExperienceCardClick: metodo lanciato al click della card,
 * - yearsOfExperience:
 * - isYearsOfExperienceModalVisible:
 * - handleCancelYearsOfExperienceModal:
 * - experience:
 * - isCandidate:
 */
class YearsOfExperienceCard extends React.Component {

    onYearsOfExperienceChange = (updatedYearsOfExperience) => {
        this.props.onYearsOfExperienceChange(updatedYearsOfExperience);
    }

    render() {
        return (
            <div>
                <Card
                    hoverable
                    id='personalCard'
                    style={{height: '100%'}}
                >
                    {!this.props.isCandidate
                        ? (
                            <Tooltip
                                title={
                                    this.props.yearsOfExperienceScore
                                        ? this.props.yearsOfExperienceScore + '%'
                                        : localStorage.getItem("Language") === 'it' ? 'Peso non indicato' : 'Weight not indicated'
                                }
                            >
                                <Row justify='center' align='middle'>
                                    <Col
                                        span={20}
                                        style={{
                                            textAlign: 'left',
                                            height: '25px',
                                            justifyContent: 'start',
                                            alignItems: 'center'
                                        }}
                                        onClick={this.props.employmentHistory ? this.props.onYearsOfExperienceCardClick : null}
                                    > <Row>

                                        <Title
                                            level={3}
                                            editable={this.props.isCandidate ? {onChange: this.onYearsOfExperienceChange} : false}
                                            className="ant-editable-icon"
                                        >
                                            {this.props.yearsOfExperience ? (this.props.yearsOfExperience.length !== 0 ? this.props.yearsOfExperience : 0) : 0}
                                        </Title>
                                    </Row>
                                        <Row style={{marginTop: '-3px'}}>
                                            <Text style={{color: '#3f88c5', textAlign: 'center', fontSize: 12}}>
                                                <Translate content='cards.yearsOfExperienceJob'/>
                                            </Text>
                                        </Row>
                                    </Col>
                                    <Col
                                        span={4}
                                        style={{textAlign: 'right'}}
                                        onClick={this.props.employmentHistory ? this.props.onYearsOfExperienceCardClick : null}
                                    >
                                        <i className="fa-regular fa-business-time iconsCandidate" style={{
                                            marginRight: '0.5rem',
                                            fontSize: '2rem',
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}></i>
                                    </Col>

                                </Row>
                            </Tooltip>
                        )
                        : (
                            <Row justify='center' align='middle'>
                                <Col
                                    span={20}
                                    style={{
                                        textAlign: 'left',
                                        height: '25px',
                                        justifyContent: 'start',
                                        alignItems: 'center'
                                    }}
                                    onClick={this.props.employmentHistory ? this.props.onYearsOfExperienceCardClick : null}
                                ><Row>

                                    <Title
                                        level={3}
                                        className="ant-editable-icon"
                                    >
                                        {this.props.yearsOfExperience ? (this.props.yearsOfExperience.length !== 0 ? this.props.yearsOfExperience : 0) : 0}
                                    </Title>
                                </Row>
                                    <Row style={{marginTop: '-3px'}}>
                                        <Text style={{color: '#3f88c5', textAlign: 'center', fontSize: 12}}>
                                            <Translate content='cards.yearsOfExperienceCandidate'/>
                                        </Text>
                                    </Row>
                                </Col>

                                <Col
                                    span={4}
                                    style={{textAlign: 'right'}}
                                    onClick={this.props.employmentHistory ? this.props.onYearsOfExperienceCardClick : null}
                                >
                                    <i className="fa-regular fa-business-time iconsCandidate" style={{
                                        marginRight: '0.5rem',
                                        fontSize: '2rem',
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}></i>
                                </Col>
                            </Row>
                        )
                    }
                </Card>
                {
                    this.props.employmentHistory ?
                        (
                            <Modal
                                title={<Translate content='experienceCard.titleText'/>}
                                visible={this.props.isYearsOfExperienceModalVisible}
                                onCancel={this.props.handleCancelYearsOfExperienceModal}
                                onOk={this.props.handleCancelYearsOfExperienceModal}
                                width='70vw'
                            >
                                <Timeline mode='left'>
                                    {
                                        this.props.employmentHistory.map((workExperience) => {
                                            return (
                                                <Timeline.Item
                                                    dot={
                                                        workExperience.endDate === 'present'
                                                            ?
                                                            <Tooltip title={<Translate
                                                                content='educationCard.completingText'/>}>
                                                                <i className="fa-regular fa-business-time iconsCandidate"
                                                                   style={{
                                                                       marginRight: '0.5rem',
                                                                       fontSize: '2rem'
                                                                   }}></i>
                                                                {/* <Icon type="clock-circle-o" style={{ fontSize: '16px' }} /> */}
                                                            </Tooltip>
                                                            :
                                                            <Tooltip
                                                                title={
                                                                    workExperience.endDate
                                                                        ? <span><Translate
                                                                            content='educationCard.endedText'/> <Moment
                                                                            format='DD/MM/YYYY' parse='YYYY/MM/DD'
                                                                            date={workExperience.endDate}/></span>
                                                                        : <Translate
                                                                            content='educationCard.missingDateText'/>
                                                                }
                                                            >
                                                                <Icon type="check-circle-o" style={{fontSize: '16px'}}/>
                                                            </Tooltip>
                                                    }
                                                    key={workExperience.id}
                                                >
                                                    <Comment
                                                        author={
                                                            (
                                                                workExperience.jobCodeDescription.length !== 0 && (workExperience.employerName.length !== 0 || workExperience.employerPlace.length !== 0)
                                                                    ? `${workExperience.jobCodeDescription},`
                                                                    : `${workExperience.jobCodeDescription}`
                                                            )
                                                                .concat(
                                                                    workExperience.employerName.length !== 0 && workExperience.employerPlace.length !== 0
                                                                        ? ` ${workExperience.employerName},`
                                                                        : ` ${workExperience.employerName}`
                                                                )
                                                                .concat(
                                                                    workExperience.employerPlace.length !== 0
                                                                        ? ` ${workExperience.employerPlace}`
                                                                        : ''
                                                                )
                                                        }
                                                        datetime={workExperience.startDate ?
                                                            <Moment format='DD/MM/YYYY' parse='YYYY/MM/DD'
                                                                    date={workExperience.startDate}/> :
                                                            <Translate content='educationCard.noDataText'/>}
                                                        content={workExperience.description ? workExperience.description :
                                                            <Translate content='educationCard.noDescriptionText'/>}
                                                    />
                                                </Timeline.Item>
                                            )
                                        })
                                    }
                                </Timeline>
                            </Modal>
                        ) : null
                }
            </div>
        )
    }
}

export default withRouter(YearsOfExperienceCard);
