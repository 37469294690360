import React from "react";
//Utilizzate per le Rotte
import { Redirect } from "react-router-dom";
//Utilizzato per poter ricavare i dati dall'URL
import { withRouter } from "react-router";
//Componenti ant design utilizzati
import { Row, Col, Layout, Spin, Progress, Card } from "antd";
//Axios per le chiamate alle API Back End
import Axios from "axios";

//Componenti Custom creati da noi
import PrevPageBar from "../../topbars/PrevPageBar";
import SingleCandidateCard from "../../Cards/SingleCandidateCard";
import HardSoftSkillsCard from "../../Cards/HardSoftSkillsCard";
import CandidateInfoCard from "../../Cards/CandidateInfoCard";
import EducationLevelCard from "../../Cards/EducationLevelCard";
import LanguagesCard from "../../Cards/LanguagesCard";
import YearsOfExperienceCard from "../../Cards/YearsOfExperienceCard";
import JobDescriptionPerCandidate from "../jobdescriptions/JobDescriptionPerCandidate";

import Translate from "react-translate-component";
import Moment from "react-moment";
import SocialCard from "../../Cards/SocialCard";
import HobbyCard from "../../Cards/HobbyCard";
import { errorRouter } from "../../errors/ErrorRouter";
import Title from "antd/lib/typography/Title";

import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Slider } from "primereact/slider";
import { Dropdown } from "primereact/dropdown";
import counterpart from "counterpart";
import { uniqBy } from "lodash";

let application = [];
let multiUtility = [];
let disability = [];

let applied;
let notApplied;
let detected;
let notDetected;
let all;

//Componenti di ant design presi dal componente <Layout />
const { Content, Sider } = Layout;

class SingleCandidate extends React.Component {
  nameStateJob = `dt-singleCandidate-state-session-${this.props.candidateID}`;
  state = {
    /**
     * Contiene il link alla pagina che ha chiamato questo componente, in modo da poter tornare,
     * con il bottone indietro, alla pagina precedente
     */
    prevPage: "/candidates",
    //Contiene l'oggetto della job description che si deve visualizzare a schermo
    candidate: undefined,
    //Larghezza del sider di ant design, per definire il lato sinistro del layout
    siderWidth: 290,
    //Array contenente tutte le job descriptions relazionate al candidato specifico
    cvJobs: [],
    //Booleano di controllo del caricamento dei dati, in modo da gestire uno spinner di caricamento dati
    isLoadingData: true,
    /**
     * Booleano che permette di passare dalla visualizzazione della tabella con tutte le job descriptions alla visualizzazione
     * della singola job description in caso di click di una delle righe della tabella. Se false, visualizza la tabella, se true,
     * la singola job description cliccata nella tabella
     */
    isJobDescriptionVisible: false,
    //Oggetto con tutti i dati della job description cliccata nella tabella con tutte le job descriptions relazionate al candidato
    selectedJobDescription: {},
    //I due arrivi contengono tutte le soft e hard skills trovate nel curriculum del candidato
    softSkills: [],
    hardSkills: [],
    languages: [],
    //Indica il numero totale di job descriptions trovate in relazione al candidato visualizzato.
    totalData: 0,
    /**
     * Oggetto di paginazione della tabella contenente tutte le job descriptions in relazione al candidato:
     * - total: Numero totale di elementi
     * - defaultPageSize: Numero totale di elementi per pagina
     */
    pagination: {
      total: 0,
      defaultPageSize: 9,
      currentFirst: 0,
    },
    sortField: null,
    sortOrder: null,
    filters: {
      candidate: {
        operator: "and",
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      "_embedded.cv.disability": {
        operator: "and",
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      aziendaMultiUtility: {
        operator: "and",
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      distanzaDalComune: {
        operator: "and",
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      score: {
        operator: "and",
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      jobDescriptionName: {
        operator: "and",
        constraints: [{ value: null, matchMode: "startsWith" }],
      },
      zcreationDate: {
        operator: "and",
        constraints: [{ value: null, matchMode: "startsWith" }],
      },
    },

    candidateData: {},

    candidatePersonalData: {},

    isYearsOfExperienceModalVisible: false,

    isEducationLevelModalVisible: false,
    loading: true,
  };

  constructor(props) {
    super(props);

    applied = localStorage.getItem("Language") === "it" ? "Applicato" : "Applied";
    notApplied =
      localStorage.getItem("Language") === "it" ? "Non Applicato" : "Not Applied";
    detected = localStorage.getItem("Language") === "it" ? "Rilevata" : "Detected";
    notDetected =
      localStorage.getItem("Language") === "it" ? "Non Rilevata" : "Not Detected";
    all = localStorage.getItem("Language") === "it" ? "Tutti" : "All";

    application = [
      { label: applied, value: 1 },
      { label: notApplied, value: 0 },
      { label: all, value: null },
    ];
    multiUtility = [
      { label: detected, value: 1 },
      { label: notDetected, value: 0 },
      { label: all, value: null },
    ];
    disability = [
      { label: detected, value: 1 },
      { label: notDetected, value: 0 },
      { label: all, value: null },
    ];
  }

  jobDescriptionNameBodyTemplate(rowData) {
    return rowData._embedded.jobDescription.title &&
      rowData._embedded.jobDescription.title.trim().length !== 0 ? (
      rowData._embedded.jobDescription.title
    ) : (
      <Translate content="candidates.noNameText" />
    );
  }

  jobDescriptionCodeBodyTemplate(rowData) {
    console.log(rowData);
    console.log(rowData._embedded.jobDescription.jobDescriptionCode);
    return rowData._embedded.jobDescription.jobDescriptionCode &&
      rowData._embedded.jobDescription.jobDescriptionCode.trim().length !== 0
      ? rowData._embedded.jobDescription.jobDescriptionCode
      : "-";
  }

  candidateBodyTemplate(rowData) {
    return rowData.candidate ? (
      <span
        className={`customer-badge status-applicato`}
        style={{ display: "flex", justifyContent: "center" }}
      >
        {applied}
      </span>
    ) : (
      <span
        className={`customer-badge status-nonapplicato`}
        style={{ display: "flex", justifyContent: "center" }}
      >
        {notApplied}
      </span>
    );
  }

  candidateFilterTemplate(options) {
    return (
      <Dropdown
        value={options.value}
        options={application}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={this.candidateItemTemplate}
        placeholder={<Translate content="filters.selectType" />}
        className="p-column-filter"
      />
    );
  }

  candidateItemTemplate = (option) => {
    return (
      <span
        className={`customer-badge status-${option.label
          .replace(" ", "")
          .toLowerCase()}`}
      >
        {option.label}
      </span>
    );
  };

  etaFilterTemplate(options) {
    return (
      <React.Fragment>
        <div style={{ padding: "1rem 1rem 0rem 1rem" }}>
          <Slider
            value={options.value}
            onChange={(e) => options.filterCallback(e.value)}
            range
            className="m-3"
          ></Slider>
          <div
            className="flex align-items-center justify-content-between px-2"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>{options.value ? options.value[0] : 0}</span>
            <span>{options.value ? options.value[1] : 100}</span>
          </div>
        </div>
      </React.Fragment>
    );
  }

  scoreFilterTemplate(options) {
    return (
      <React.Fragment>
        <div style={{ padding: "1rem 1rem 0rem 1rem" }}>
          <Slider
            value={options.value}
            onChange={(e) => options.filterCallback(e.value)}
            range
            className="m-3"
          ></Slider>
          <div
            className="flex align-items-center justify-content-between px-2"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>{options.value ? options.value[0] : 0}</span>
            <span>{options.value ? options.value[1] : 100}</span>
          </div>
        </div>
      </React.Fragment>
    );
  }

  categoryTypeBodyTemplate(rowData) {
    return rowData._embedded.cv.disability
      ? rowData._embedded.cv.disability
      : "-";
  }

  categoryTypeFilterTemplate(options) {
    return (
      <Dropdown
        value={options.value}
        options={disability}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={this.multiUtilityItemTemplate}
        placeholder={<Translate content="filters.selectPresence" />}
        className="p-column-filter"
      />
    );
  }

  categoryTypeItemTemplate = (option) => {
    return (
      <span
        className={`customer-badge status-${option.label
          .replace(" ", "")
          .toLowerCase()}`}
      >
        {option.label}
      </span>
    );
  };

  multiUtilityBodyTemplate(rowData) {
    return rowData.aziendaMultiUtility ? (
      <span
        className={`customer-badge status-rilevata`}
        style={{ display: "flex", justifyContent: "center" }}
      >
        {detected}
      </span>
    ) : (
      <span
        className={`customer-badge status-nonrilevata`}
        style={{ display: "flex", justifyContent: "center" }}
      >
        {notDetected}
      </span>
    );
  }

  multiUtilityFilterTemplate(options) {
    return (
      <Dropdown
        value={options.value}
        options={multiUtility}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={this.multiUtilityItemTemplate}
        placeholder={<Translate content="filters.selectPresence" />}
        className="p-column-filter"
      />
    );
  }

  multiUtilityItemTemplate = (option) => {
    return (
      <span
        className={`customer-badge status-${option.label
          .replace(" ", "")
          .toLowerCase()}`}
      >
        {option.label}
      </span>
    );
  };

  dateBodyTemplate(rowData) {
    return <Moment format="DD/MM/YYYY" date={rowData.zcreationDate} />;
  }

  distanceFilterTemplate = (options) => {
    return (
      <React.Fragment>
        <div style={{ padding: "1rem 1rem 0rem 1rem" }}>
          <Slider
            value={options.value}
            onChange={(e) => options.filterCallback(e.value)}
            className="m-3"
            max={600}
            step={10}
          ></Slider>
          <div
            className="flex align-items-center justify-content-between px-2"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>0</span>
            <span>{options.value ? options.value : ""}</span>
          </div>
        </div>
      </React.Fragment>
    );
  };

  scoreBodyTemplate(rowData) {
    return (
      <span>
        <Progress steps={3} percent={rowData.score} size="medium" />
      </span>
    );
  }

  resetStore() {
    sessionStorage.removeItem(this.nameStateJob);
    let obj = {
      first: 0,
      rows: 9,
      multiSortMeta: [],
      filters: {
        candidate: {
          operator: "and",
          constraints: [{ value: null, matchMode: "equals" }],
        },
        "_embedded.cv.eta": {
          operator: "and",
          constraints: [{ value: null, matchMode: "equals" }],
        },
        "_embedded.cv.disability": {
          operator: "and",
          constraints: [{ value: null, matchMode: "equals" }],
        },
        aziendaMultiUtility: {
          operator: "and",
          constraints: [{ value: null, matchMode: "equals" }],
        },
        distanzaDalComune: {
          operator: "and",
          constraints: [{ value: null, matchMode: "equals" }],
        },
        score: {
          operator: "and",
          constraints: [{ value: null, matchMode: "equals" }],
        },
        jobDescriptionName: {
          operator: "and",
          constraints: [{ value: null, matchMode: "startsWith" }],
        },
        zcreationDate: {
          operator: "and",
          constraints: [{ value: null, matchMode: "startsWith" }],
        },
      },
    };
    sessionStorage.setItem(this.nameStateJob, JSON.stringify(obj));
  }

  setInitStore() {
    let obj = {
      first: 0,
      rows: 9,
      multiSortMeta: [],
      filters: {
        candidate: {
          operator: "and",
          constraints: [{ value: null, matchMode: "equals" }],
        },
        "_embedded.cv.eta": {
          operator: "and",
          constraints: [{ value: null, matchMode: "equals" }],
        },
        "_embedded.cv.disability": {
          operator: "and",
          constraints: [{ value: null, matchMode: "equals" }],
        },
        aziendaMultiUtility: {
          operator: "and",
          constraints: [{ value: null, matchMode: "equals" }],
        },
        distanzaDalComune: {
          operator: "and",
          constraints: [{ value: null, matchMode: "equals" }],
        },
        score: {
          operator: "and",
          constraints: [{ value: null, matchMode: "equals" }],
        },
        jobDescriptionName: {
          operator: "and",
          constraints: [{ value: null, matchMode: "startsWith" }],
        },
        zcreationDate: {
          operator: "and",
          constraints: [{ value: null, matchMode: "startsWith" }],
        },
      },
    };
    sessionStorage.setItem(this.nameStateJob, JSON.stringify(obj));
    return obj;
  }

  onFilter(event) {
    console.log("ON FILTER top EVENT");
    this.getPagedFilteredCvJob(event, false, false, true);
  }

  onFilterClick(event) {
    console.log("ON FILTER EVENT");
    //this.getPagedFilteredCvJob(event, false, false,false)
  }

  onPageChange(event) {
    console.log("ON PAGE EVENT");
    this.getPagedFilteredCvJob(event, true, false, false);
  }

  onClean(event) {
    console.log("ON CLEAN EVENT");
    //this.getPagedFilteredCvJob(event, true, true,false)
  }

  onSort(event) {
    console.log("ON SORT EVENT");

    this.getPagedFilteredCvJob(event, false, true, false);
    //console.log(event)
  }

  //Vengono raccolti i dati del cv selezionato e di tutte le job descriptions ad esso collegate
  componentDidMount() {
    this.refreshCandidateData();
  }

  getPagedFilteredCvJob(event, changePage, sortCall, filterTotal) {
    this.setState({
      loading: true,
    });

    const store = sessionStorage.getItem(this.nameStateJob);
    let local = JSON.parse(store);
    let candidate = this.state.candidate;

    if (candidate === undefined || candidate === null) {
      return;
    }
    if (local === null) {
      local = this.setInitStore();
    }
    let sortQuery = "";
    let arrayFilters = [];
    let url = "";

    if (filterTotal) {
      local.filters = event.filters;
    }

    if (event != null) {
      if (sortCall) {
        local.sortOrder = event.sortOrder;
        local.sortField = event.sortField;
      }
      if (event.sortField != null) {
        local.sortField = event.sortField;
        if (event.sortOrder != null) {
          local.sortOrder = event.sortOrder;
          if (local.sortField !== undefined) {
            sortQuery += `&sort=${local.sortField
              .replace("_embedded", "")
              .replace(".cv", "")
              .replace(".", "")},${local.sortOrder === 1 ? "asc" : "desc"}`;
          }
        }
      } else if (event.field != null) {
        local.filters[event.field].constraints = event.constraints.constraints;
      }
      if (event.first != null && changePage) {
        local.first = event.first;
      }
    }
    if (
      local != null &&
      local.sortField !== undefined &&
      local.sortField !== null
    ) {
      sortQuery += `&sort=${local.sortField
        .replace("_embedded", "")
        .replace(".cv", "")
        .replace(".", "")},${local.sortOrder === 1 ? "asc" : "desc"}`;
    }

    sortQuery += `&sort=score,desc&sort=id,asc`;
    let currentPage = 0;

    if ((event != null && event === undefined) || local == null) {
      arrayFilters = local == null ? [] : { ...local.filters };
      url = `/api/cvJob/search/findByCvIdAndCandidateOrderByScoreDesc?idCv=${candidate.cvs[0].profile.id}`;
      local = {};
      local.sortField = "";
      local.sortOrder = "";
      local.first = 0;
    } else {
      if (local.filters.candidate != null) {
        arrayFilters = { ...local.filters };
        if (local.filters.candidate.constraints[0].value == null) {
          local.filters.candidate.constraints[0].value = null;
        } else if (local.filters.candidate.constraints[0].value != null) {
        }
      }
      url =
        `/api/cvJob/search/findPagedFilteredSortedCandidate?idCv=${candidate.cvs[0].profile.id}` +
        `&candidate=${
          local.filters.candidate.constraints[0].value === null
            ? ""
            : local.filters.candidate.constraints[0].value
        }` +
        `&disability=${
          local.filters["_embedded.cv.disability"].constraints[0].value === null
            ? ""
            : local.filters["_embedded.cv.disability"].constraints[0].value
        }` +
        (local.filters["score"].constraints[0].value === null
          ? ""
          : `&scoreMin=${local.filters["score"].constraints[0].value[0]}`) +
        (local.filters["score"].constraints[0].value === null
          ? ""
          : `&scoreMax=${local.filters["score"].constraints[0].value[1]}`) +
        (local.filters.distanzaDalComune.constraints[0].value === null
          ? ""
          : `&distanzaMax=${local.filters.distanzaDalComune.constraints[0].value}`);
      currentPage = local.first / local.rows;
    }

    url += sortQuery + `&size=9&page=${currentPage}`;
    Axios.get(url)
      .then((response) => {
        if (
          response.data.page.totalElements !== 0 &&
          response.data._embedded.cvJobs.length === 0
        ) {
          let url =
            `/api/cvJob/search/findPagedFilteredSortedCandidate?idCv=${candidate.cvs[0].profile.id}` +
            `&candidate=${
              local.filters.candidate.constraints[0].value === null
                ? ""
                : local.filters.candidate.constraints[0].value
            }` +
            `&disability=${
              local.filters["_embedded.cv.disability"].constraints[0].value ===
              null
                ? ""
                : local.filters["_embedded.cv.disability"].constraints[0].value
            }` +
            (local.filters.distanzaDalComune.constraints[0].value === null
              ? ""
              : `&distanzaMax=${local.filters.distanzaDalComune.constraints[0].value}`) +
            sortQuery +
            `&size=9&page=0`;
          Axios.get(url)
            .then((response2) => {
              this.setState((prevState) => ({
                cvJobs: response2.data._embedded.cvJobs,
                pagination: {
                  total: response2.data.page.totalElements,
                  currentFirst: 0,
                },
                sortField: local.sortField,
                sortOrder: local.sortOrder,
                loading: false,
                filters:
                  Object.entries(arrayFilters).length !== 0
                    ? arrayFilters
                    : { ...prevState },
              }));
            })
            .catch((error) => {
              if (error.response && error.response.status === 500)
                this.resetStore();
            });
        } else {
          this.setState((prevState) => ({
            cvJobs: response.data._embedded.cvJobs,
            pagination: {
              total: response.data.page.totalElements,
              currentFirst: local.first,
            },
            sortField: local.sortField,
            sortOrder: local.sortOrder,
            loading: false,
            filters:
              Object.entries(arrayFilters).length !== 0
                ? arrayFilters
                : { ...prevState },
          }));
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) this.resetStore();
      });
  }
  refreshCandidateData = async () => {
    await this.setState({
      isLoadingData: true,
    });
    await Axios.get("/api/candidato/" + this.props.candidateID)
      .then((response) => {
        let tempHardSkills = [];
        if (response.data.cvs[0].profile.skills.computerSkill)
          tempHardSkills = tempHardSkills.concat(
            response.data.cvs[0].profile.skills.computerSkill
          );
        if (response.data.cvs[0].profile.skills.otherSkill)
          tempHardSkills = tempHardSkills.concat(
            response.data.cvs[0].profile.skills.otherSkill
          );
        this.setState({
          candidate: response.data,
          softSkills: uniqBy(response.data.cvs[0].profile.skills.softSkill, "code"),
          languages: response.data.cvs[0].profile.skills.languageSkill,
          hardSkills: uniqBy(tempHardSkills, "code"),
          isLoadingData: false,
        });
        this.getPagedFilteredCvJob(null, false, false, false);
        /* Axios.get(`/api/cvJob/search/findByCvIdAndCandidateOrderByScoreDesc?id_cv=${response.data.cvs[0].profile.id}&candidate=true&size=9&page=0`)
                    .then((response) => {
                        this.setState({
                            cvJobs: response.data._embedded.cvJobs,
                            totalData: response.data.page.totalElements
                        })
                        //Gli elementi per pagina nella tabella sono di default a 9, cambia solo il totale in base al tipo di filtratura
                        this.setState((prevState) => ({
                            ...prevState,
                            pagination: {
                                ...prevState.pagination,
                                total: response.data.page.totalElements,
                            },
                            isLoadingData: false,
                        }))
                    })
                    .catch((error) => {
                        console.log(error)
                    })*/
      })
      .catch((error) => {
        return <Redirect to="/candidates" />;
      });
  };

  /**
   * Permette la gestione del sider della pagina. Il sider è costituito dalla colonna laterale che contiene card candidato,
   * card soft skills e card hard skills. Nel momento in cui si passa ad una dimensione inferiore al BREAKPOINT LG, questo metodo
   * viene lanciato e il layout cambia completamente il suo stile.
   */
  onSiderCollapse = (collapsed, type) => {
    if (collapsed && type === "responsive") {
      this.setState({
        siderWidth: "100%",
      });
    } else if (!collapsed && type === "responsive") {
      this.setState({
        siderWidth: "290",
      });
    }
  };

  /**
   * Lanciato nel momento in cui viene cliccata una qualunque delle righe della tabella, in modo da visualizzare, al posto della tabella,
   * la singola job description cliccata.
   */
  onJobDescriptionRowClick = (record) => {
    console.log(record);
    this.setState({
      selectedJobDescription: record.data,
      isJobDescriptionVisible: true,
    });
  };

  /**
   * Una volta aperta la singola job descriptions rispetto al candidato, sostituendo quindi alla tabella con tutte le job descriptions il singolo
   * record, è possibile tornare alla visualizzazione della tabella con la frecca indietro. Cliccando la freccia, viene lanciato questo metodo, che
   * inverte di nuovo la visualizzazione: viene rimossa la singola job description, e visualizzata di nuovo la tabella
   */
  onJobDescriptionViewBack = () => {
    this.setState({
      selectedJobDescription: {},
      isJobDescriptionVisible: false,
    });
  };

  /**
   * Metodo lanciato quando viene filtrata la colonna che mostra la candidatura o meno del candidato alla job description
   */
  onCandidateColumnFilter = (pagination, filters) => {
    //Entra nell'if se c'è filtratura, e filtra i candidati in base alla scelta dell'utente.
    Axios.get(
      `/api/cvJob/search/findPagedFilteredSorted?idCv=${this.props.candidateID}` +
        (filters.candidate !== undefined &&
        filters.candidate.length !== 0 &&
        filters.candidate[0] !== undefined &&
        filters.candidate[0] !== null
          ? `&candidate=${filters.candidate[0]}`
          : ``) +
        (filters.disabilityStatus !== undefined &&
        filters.disabilityStatus.length !== 0 &&
        filters.disabilityStatus[0] !== undefined &&
        filters.disabilityStatus[0] !== null
          ? `&disability=${filters.disabilityStatus[0]}`
          : ``) +
        `&sort=score,desc` +
        `&size=9&page=${pagination.current - 1}`
    )
      .then((response) => {
        this.setState({
          cvJobs: response.data._embedded.cvJobs,
          totalData: response.data.page.totalElements,
        });
        this.setState((prevState) => ({
          pagination: {
            ...prevState.pagination,
            total: response.data.page.totalElements,
          },
        }));
      })
      .catch((error) => {
        if (error.response) {
          errorRouter(error.response.status, this.props.history);
        }
      });
  };

  onEducationLevelCardClick = () => {
    this.setState({
      isEducationLevelModalVisible: true,
    });
  };

  handleCancelEducationLevelModal = () => {
    this.setState({
      isEducationLevelModalVisible: false,
    });
  };

  onYearsOfExperienceCardClick = () => {
    this.setState({
      isYearsOfExperienceModalVisible: true,
    });
  };

  handleCancelYearsOfExperienceModal = () => {
    this.setState({
      isYearsOfExperienceModalVisible: false,
    });
  };

  onYearsOfExperienceChange = (updatedYearsOfExperience) => {
    Axios.get(
      `/api/cv/${this.state.candidate._embedded.cvs[0].id}/profile`
    ).then((response) => {
      Axios.patch(`/api/summary/${response.data._embedded.summary.id}`, {
        totalExperienceYears: updatedYearsOfExperience,
      })
        .then(() => {
          if (updatedYearsOfExperience >= 0) {
            this.setState((prevState) => ({
              ...prevState,
              candidatePersonalData: {
                ...prevState.candidatePersonalData,
                Summury: {
                  ...prevState.candidatePersonalData.Summury,
                  totalExperienceYears: updatedYearsOfExperience,
                },
              },
            }));
            this.refreshCandidateData();
          } else {
            //TODO notifica dell'errore
          }
        })
        .catch((error) => {
          errorRouter(error.response.status, this.props.history);
        });
    });
  };

  onEducationLevelChange = (updatedEducationLevel) => {
    let values = {
      Summary: {
        highestEducationLevelCodeDescription: updatedEducationLevel,
      },
    };

    this.setState((prevState) => ({
      ...prevState,
      candidatePersonalData: {
        ...prevState.candidatePersonalData,
        Summury: {
          ...prevState.candidatePersonalData.Summury,
          highestEducationLevelCodeDescription: updatedEducationLevel,
        },
      },
    }));

    Axios.patch(`/api/cv/${this.props.candidateID}/personal`, { values })
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          candidatePersonalData: {
            ...prevState.candidatePersonalData,
            Summury: {
              ...prevState.candidatePersonalData.Summury,
              highestEducationLevelCodeDescription: updatedEducationLevel,
            },
          },
        }));
      })
      .catch((error) => {
        errorRouter(error.response.status, this.props.history);
      });
  };

  onPrevPageClick = () => {
    this.props.history.goBack();
  };

  render() {
    const loadingMessage = counterpart.translate(
      "infoMessages.loadingSingleCandidateData"
    );
    return !this.state.isLoadingData ? (
      <div style={{ padding: "30px 5vw 40px 5vw" }}>
        <Row>
          <Col xl={{ span: 2 }} xxl={{ span: 1 }} style={{ marginTop: 5 }}>
            {/* eslint-disable-next-line */}
            <a
              href="javascript:void(0)"
              style={{ padding: "0px 20px 0px 20px" }}
              onClick={this.onPrevPageClick}
            >
              <PrevPageBar />
            </a>
          </Col>
          <Col span={22}>
            <Title level={3}>
              {<Translate content="cards.candidateData" />}
            </Title>
          </Col>
        </Row>
        <Layout>
          <Sider
            width={this.state.siderWidth}
            breakpoint="lg"
            collapsedWidth="0"
            onCollapse={this.onSiderCollapse}
            theme="dark"
            style={{
              /* backgroundColor: 'rgb(0 168 176 / 8%)', */ padding: 10,
            }}
          >
            <Row>
              <SingleCandidateCard
                candidate={this.state.candidate}
                refreshCandidateData={this.refreshCandidateData}
              />
            </Row>
            <Row>
              <HardSoftSkillsCard
                isSoftSkill={true}
                skills={this.state.softSkills}
                isSingleCandidate={true}
              />
            </Row>
            <Row>
              <HardSoftSkillsCard
                isSoftSkill={false}
                skills={this.state.hardSkills}
                isSingleCandidate={true}
              />
            </Row>
            <Row>
              <HobbyCard
                hobbies={
                  this.state.candidate.cvs[0].profile.other.hobbies
                    ? this.state.candidate.cvs[0].profile.other.hobbies
                    : []
                }
              />
            </Row>
          </Sider>
          <Content style={{ backgroundColor: "#F9F9FC", paddingLeft: "0.4vw" }}>
            <Row
              gutter={[
                {
                  xs: 8,
                  sm: 8,
                  md: 8,
                  lg: 8,
                  xl: 8,
                  xxl: 32,
                },
                32,
              ]}
              style={{
                //backgroundColor: 'rgb(0 168 176 / 8%)',
                padding: 10,
                marginLeft: "-6px",
                borderRadius: "0% 3% 3% 0%",
              }}
            >
              <Col xl={{ span: 8 }} xxl={{ span: 6 }}>
                <CandidateInfoCard
                  candidateEmail={
                    this.state.candidate.email ? this.state.candidate.email : ""
                  }
                  candidateWebsite={
                    this.state.candidate.cvs[0].profile.personal.socialMediaLink
                      ? this.state.candidate.cvs[0].profile.personal
                          .socialMediaLink[0]
                        ? this.state.candidate.cvs[0].profile.personal
                            .socialMediaLink[0].url
                          ? this.state.candidate.cvs[0].profile.personal
                              .socialMediaLink[0].url
                          : ""
                        : ""
                      : ""
                  }
                  candidateAddress={
                    this.state.candidate.cvs[0].profile.personal.address
                      ? this.state.candidate.cvs[0].profile.personal.address
                      : ""
                  }
                  candidateLastJob={
                    this.state.candidate.cvs[0].profile.summary.currentJob
                      ? this.state.candidate.cvs[0].profile.summary.currentJob
                      : ""
                  }
                  candidateLastEmployer={
                    this.state.candidate.cvs[0].profile.summary.currentEmployer
                      ? this.state.candidate.cvs[0].profile.summary
                          .currentEmployer
                      : ""
                  }
                  jobDescriptionName={
                    this.state.candidate.firstName +
                    " " +
                    this.state.candidate.middleName +
                    " " +
                    this.state.candidate.lastName
                  }
                  candidateID={this.props.candidateID}
                  refreshCandidateData={this.refreshCandidateData}
                />
              </Col>
              <Col xl={{ span: 16 }} xxl={{ span: 18 }}>
                <Row
                  gutter={[
                    {
                      xs: 8,
                      sm: 8,
                      md: 8,
                      lg: 8,
                      xl: 8,
                      xxl: 32,
                    },
                    32,
                  ]}
                >
                  <Col
                    xs={{ span: 8 }}
                    sm={{ span: 8 }}
                    md={{ span: 8 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <LanguagesCard
                      languages={
                        this.state.candidate.cvs[0].profile.skills.languageSkill
                          ? this.state.candidate.cvs[0].profile.skills
                              .languageSkill
                          : []
                      }
                      isCandidate={true}
                    />
                  </Col>
                  <Col
                    xs={{ span: 8 }}
                    sm={{ span: 8 }}
                    md={{ span: 8 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <EducationLevelCard
                      educationHistory={
                        this.state.candidate.cvs[0].profile.educationHistory
                          ? this.state.candidate.cvs[0].profile.educationHistory
                          : []
                      }
                      highestEducationLevel={
                        this.state.candidate.cvs[0].profile.summary
                          ? this.state.candidate.cvs[0].profile.summary
                              .highestEducation
                            ? this.state.candidate.cvs[0].profile.summary
                                .highestEducation
                            : undefined
                          : undefined
                      }
                      isEducationLevelModalVisible={
                        this.state.isEducationLevelModalVisible
                      }
                      onEducationLevelCardClick={this.onEducationLevelCardClick}
                      handleCancelEducationLevelModal={
                        this.handleCancelEducationLevelModal
                      }
                      isCandidate={true}
                      onEducationLevelChange={this.onEducationLevelChange}
                    />
                  </Col>
                  <Col
                    xs={{ span: 8 }}
                    sm={{ span: 8 }}
                    md={{ span: 8 }}
                    lg={{ span: 24 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <YearsOfExperienceCard
                      employmentHistory={
                        this.state.candidate.cvs[0].profile.employmentHistory
                          ? this.state.candidate.cvs[0].profile
                              .employmentHistory
                          : []
                      }
                      yearsOfExperience={
                        this.state.candidate.cvs[0].profile.summary
                          ? this.state.candidate.cvs[0].profile.summary
                              .totalExperienceYears
                          : 0
                      }
                      isYearsOfExperienceModalVisible={
                        this.state.isYearsOfExperienceModalVisible
                      }
                      onYearsOfExperienceCardClick={
                        this.onYearsOfExperienceCardClick
                      }
                      handleCancelYearsOfExperienceModal={
                        this.handleCancelYearsOfExperienceModal
                      }
                      isCandidate={true}
                      onYearsOfExperienceChange={this.onYearsOfExperienceChange}
                    />
                  </Col>
                  <Col
                    xs={{ span: 8 }}
                    sm={{ span: 8 }}
                    md={{ span: 8 }}
                    lg={{ span: 24 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <SocialCard
                      socialsList={
                        this.state.candidate.cvs[0].profile.personal
                          .socialMediaLink
                          ? this.state.candidate.cvs[0].profile.personal
                              .personalWebsite
                            ? this.state.candidate.cvs[0].profile.personal.socialMediaLink.concat(
                                this.state.candidate.cvs[0].profile.personal
                                  .personalWebsite
                              )
                            : this.state.candidate.cvs[0].profile.personal
                                .socialMediaLink
                          : this.state.candidate.cvs[0].profile.personal
                              .personalWebsite
                          ? this.state.candidate.cvs[0].profile.personal
                              .personalWebsite
                          : []
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              {!this.state.isJobDescriptionVisible ? (
                <div className="datatable-doc-demo">
                  <Card
                    className="tables-container"
                    style={{
                      marginTop: "1rem",
                      height: "auto",
                      borderRadius: "0%",
                      backgroundColor: "rgb(232 225 0 / 9%)",
                    }}
                  >
                    <DataTable
                      value={this.state.cvJobs}
                      paginator
                      removableSort
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                      className="p-datatable-customers"
                      rows={9}
                      emptyMessage={
                        <Translate content="candidates.noCandidatesText" />
                      }
                      responsiveLayout="scroll"
                      totalRecords={this.state.pagination.total}
                      currentPageReportTemplate={
                        localStorage.getItem("Language") === "it"
                          ? "Job description {first} di {last} su {totalRecords} job description"
                          : "Job description from {first} to {last} of {totalRecords} job descriptions"
                      }
                      lazy="true"
                      stateStorage="session"
                      stateKey={this.nameStateJob}
                      onPage={this.onPageChange.bind(this)}
                      first={this.state.pagination.currentFirst}
                      onSort={this.onSort.bind(this)}
                      sortField={this.state.sortField}
                      sortOrder={this.state.sortOrder}
                      onRowClick={this.onJobDescriptionRowClick.bind(this)}
                      loading={this.state.loading}
                      filters={this.state.filters}
                      onFilter={this.onFilter.bind(this)}
                      filterDelay="1"
                    >
                      <Column
                        field="_embedded.jobDescription.title"
                        header={
                          <Translate content="jobDescriptions.nameText" />
                        }
                        body={this.jobDescriptionNameBodyTemplate}
                        style={{ minWidth: "11rem" }}
                      ></Column>
                      <Column
                        field="_embedded.jobDescription.jobDescriptionCode"
                        header={
                          <Translate content="jobDescriptions.codeText" />
                        }
                        body={this.jobDescriptionCodeBodyTemplate}
                        style={{ minWidth: "11rem" }}
                      ></Column>
                      <Column
                        field="candidate"
                        header={
                          <Translate content="candidates.applicationForText" />
                        }
                        style={{ minWidth: "1rem" }}
                        body={this.candidateBodyTemplate}
                        filter
                        filterElement={this.candidateFilterTemplate.bind(this)}
                        filterMatchMode="equals"
                        showFilterMenuOptions={false}
                        onFilterApplyClick={this.onFilterClick.bind(this)}
                        onFilterClear={this.onClean.bind(this)}
                      ></Column>
                      <Column
                        field="_embedded.cv.disability"
                        header={
                          <Translate content="candidates.protectedText" />
                        }
                        style={{ minWidth: "7rem" }}
                        body={this.categoryTypeBodyTemplate}
                        filter
                        filterElement={this.categoryTypeFilterTemplate.bind(
                          this
                        )}
                        filterMatchMode="contains"
                        showFilterMenuOptions={false}
                        onFilterApplyClick={this.onFilterClick.bind(this)}
                        onFilterClear={this.onClean.bind(this)}
                      ></Column>
                      {/* <Column
                                                field='aziendaMultiUtility'
                                                header="Azienda MultiUtility"
                                                style={{minWidth: '1rem'}}
                                                body={this.multiUtilityBodyTemplate} filter
                                                filterElement={this.multiUtilityFilterTemplate.bind(this)}
                                                filterMatchMode='contains' showFilterMenuOptions={false}
                                                onFilterApplyClick={this.onFilterClick.bind(this)}
                                                onFilterClear={this.onClean.bind(this)}>
                                            </Column> */}
                      <Column
                        field="zcreationDate"
                        header={
                          <Translate content="candidates.insertionDateText" />
                        }
                        style={{ minWidth: "5rem" }}
                        body={this.dateBodyTemplate}
                      ></Column>
                      <Column
                        field="score"
                        header={<Translate content="candidates.scoreText" />}
                        style={{ width: "15rem" }}
                        body={this.scoreBodyTemplate}
                        filterElement={this.scoreFilterTemplate}
                        filter
                        filterMatchMode="contains"
                        showFilterMenuOptions={false}
                        onFilterApplyClick={this.onFilterClick.bind(this)}
                        onFilterClear={this.onClean.bind(this)}
                      ></Column>
                    </DataTable>
                  </Card>
                </div>
              ) : (
                <Card
                  className="tables-container"
                  style={{
                    marginTop: "1rem",
                    height: "auto",
                    borderRadius: "0%",
                    backgroundColor: "rgb(232 225 0 / 9%)",
                  }}
                >
                  <div className="details-container">
                    <Row>
                      <Col
                        xl={{ span: 2 }}
                        xxl={{ span: 1 }}
                        style={{ marginTop: 5 }}
                      >
                        {/* eslint-disable-next-line */}
                        <a
                          href="javascript:void(0)"
                          style={{ padding: "0px 20px 0px 20px" }}
                          onClick={this.onJobDescriptionViewBack}
                        >
                          <PrevPageBar />
                        </a>
                      </Col>
                      <Col span={22}>
                        <Title level={3}>
                          {
                            <Translate content="jobDescriptions.jobDescriptionData" />
                          }
                        </Title>
                      </Col>
                    </Row>

                    <JobDescriptionPerCandidate
                      softSkills={this.state.softSkills}
                      hardSkills={this.state.hardSkills}
                      languageSkills={this.state.languages}
                      selectedJobDescription={this.state.selectedJobDescription}
                      referrerCandidateID={this.props.candidateID}
                      onJobEditClick={this.props.onJobEditClick}
                    />
                  </div>
                </Card>
              )}
            </Row>
          </Content>
        </Layout>
      </div>
    ) : (
      <Spin
        spinning={this.state.isLoadingData}
        size="large"
        tip={loadingMessage}
        style={{ paddingTop: "40vh", paddingLeft: "45vw" }}
      ></Spin>
    );
  }
}
export default withRouter(SingleCandidate);
