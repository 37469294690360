import { Button, Col, Row, Spin, Table, Tag } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import React from 'react';
import { withRouter } from 'react-router';
import Translate from 'react-translate-component';
import CandidatesGrid from './CandidatesGrid';


class FullTextSearchCandidates extends React.Component {
  state = {
    selectedSearch: 0,
  }

  onSearchSelect = (id) => {
    //TODO Gestione selezione ricerca
    this.props.onSearchSelect(id.target.value)
  }

  onSearchDelete = (id) => {
    //TODO Delete Ricerca
    this.props.onSearchDelete(id.target.value)
  }

  onPageChange = (page) => {
    console.log(page.current)
    this.props.onFullTextSearchTablePageChange(page.current)
  }



  render() {
    let pagination = {
      total: this.props.searchTableTotalElements,
      current: this.props.searchTablePage
    }
    console.log(this.props.cardsData)
    const candidates = <Col span={16}>
      {
        this.props.cardsData
          ?
          <CandidatesGrid
            cardsData={this.props.cardsData}
            onCandidateCardClick={this.props.onCandidateCardClick}
            loadCandidatesData={this.props.loadCandidatesData}
            isSearchFullText={true}
          />
          : null
      }
    </Col>
    return (
      <Spin spinning={this.props.isFullTextLoading}>
        {
          this.props.searchFullTextData
            ? <Row>
              <Col
                xl={{ span: 8 }}
                xll={{ span: 6 }}
              >
                <Table columns={
                  [
                    {
                      title: <Translate content='fullTextSearchCandidate.firstKeywordText' />,
                      dataIndex: 'keyword1',
                      key: 'keyword1',
                    },
                    {
                      title: <Translate content='fullTextSearchCandidate.secondKeywordText' />,
                      dataIndex: 'keyword2',
                      key: 'keyword2',
                    },
                    {
                      title: <Translate content='fullTextSearchCandidate.searchStatusText' />,
                      key: 'done',
                      dataIndex: 'done',
                      render: (done) => (
                        <span>
                          {
                            done
                              ? (
                                <Tag color="green" key={Math.random()}>
                                  <Translate content='fullTextSearchCandidate.completedText' />
                                </Tag>
                              )
                              : (
                                <Tag color="volcano" key={Math.random()}>
                                  <Translate content='fullTextSearchCandidate.notCompletedText' />
                                </Tag>
                              )
                          }
                        </span>
                      ),
                    },
                    {
                      title: <Translate content='fullTextSearchCandidate.actionText' />,
                      key: 'id',
                      dataIndex: 'id',
                      width: 120,
                      render: (id) => (
                        <ButtonGroup>
                          <Button value={id} icon="eye" onClick={this.onSearchSelect}></Button>
                          <Button value={id} icon="delete" onClick={this.onSearchDelete}></Button>
                        </ButtonGroup>
                      ),
                    },
                  ]
                } dataSource={this.props.searchFullTextData} bordered pagination={pagination}
                  onChange={this.onPageChange} />
              </Col>
              {candidates}
            </Row>
            : null
        }
      </Spin>
    );
  }
} export default withRouter(FullTextSearchCandidates);