import { Card, Typography, Row, Col, Avatar } from "antd";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import DashboardButton from "./dashboard_buttons/DashboardButton";
import Translate from "react-translate-component";
import { Tag, Button } from "antd";
import PieChartDistribution from "../Charts/PieChartDistribution";
import Axios from "axios";

const { Title } = Typography;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    let userLogged = {
      username: "",
      lastName: "",
      firstName: "",
      email: "",
      userGroup: "",
      id: 1,
    };
    if (
      localStorage.getItem("userLogged", null) != null &&
      localStorage.getItem("userLogged", null).length !== 0
    ) {
      userLogged = JSON.parse(localStorage.getItem("userLogged", null));
    }
    this.state = {
      username: localStorage.getItem("Username"),
      userLogged: userLogged,
    };
  }

  state = {
    totalJob: 0,
    openJob: 0,
    closedJob: 0,
    aziendaWork: 0,
    notAziendaWork: 0,
    job24Ore: 0,
    candidati24Ore: 0,
    countAndDistribution: undefined,
    topSkill: [],
    topAddress: [],
  };

  componentDidMount() {
    this.getOpenJob();
    this.getCloseJob();
    this.getAziendaCandidate();
    this.getNotAziendaCandidate();
    this.getCandidati24();
    this.getJob24();
    this.getCandidateDistributionForJob();
    this.getTopSkills();
    this.getTopCity();
  }

  onButtonLogout = () => {
    /**
     * Distingue il bottone di logout dagli altri presenti nel menù, cancella il token dagli headers di axios, e viene effettuato il redirect a /login
     * tramite il componente <Link />
     */
    Axios.defaults.headers.common["Authorization"] = null;
    localStorage.setItem("Token", "");
    localStorage.setItem("Username", "");
    localStorage.setItem("userLogged", "");
    localStorage.getItem("Type") === "Form"
      ? this.props.history.push("/login")
      : document.location.replace("/saml/logout_saml");
    localStorage.setItem("Type", "");
  };

  getTopSkills = () => {
    Axios.get("api/getTopSkill").then((response) =>
      this.setState({
        topSkill: response.data,
      })
    );
  };
  getOpenJob = () => {
    Axios.get("/api/job/search/countByActiveIsTrue").then((response) =>
      this.setState({
        openJob: response.data,
      })
    );
  };

  getCloseJob = () => {
    Axios.get("/api/job/search/countByActiveIsFalse").then((response) =>
      this.setState({
        closedJob: response.data,
      })
    );
  };

  getAziendaCandidate = () => {
    Axios.get("/api/candidato/search/countByAceaWorkIsTrue").then((response) =>
      this.setState({
        aziendaWork: response.data,
      })
    );
  };

  getNotAziendaCandidate = () => {
    Axios.get("/api/candidato/search/countByAceaWorkIsFalse").then((response) =>
      this.setState({
        notAziendaWork: response.data,
      })
    );
  };

  getCandidati24 = () => {
    Axios.get("/api/candidato/search/ultime24Ore").then((response) =>
      this.setState({
        candidati24Ore: response.data,
      })
    );
  };

  getJob24 = () => {
    Axios.get("/api/job/search/ultime24Ore").then((response) =>
      this.setState({
        job24Ore: response.data,
      })
    );
  };
  getCandidateDistributionForJob = () => {
    Axios.get("/api/employmenthistory/search/countAndDistribution").then(
      (response) =>
        this.setState({
          countAndDistribution: response.data,
        })
    );
  };
  getTopCity = () => {
    Axios.get("/api/address/search/top").then((response) => {
      this.setState({
        topAddress: response.data,
      });
    });
  };

  render() {
    return (
      <div style={{ paddingTop: 50, paddingRight: 100, paddingLeft: 100 }}>
        <Row style={{}} gutter={32}>
          <Col span={6}>
            <Card id="personalCard" style={{ height: "150px" }}>
              <Row
                type="flex"
                justify="center"
                align="end"
                style={{ margin: 15 }}
              >
                <Col span={20}>
                  <Tag color="#d5614b">
                    <i
                      class="fa-solid fa-angle-up"
                      style={{ marginRight: "3px" }}
                    ></i>
                    15%
                  </Tag>
                </Col>
                <Col span={4}></Col>
              </Row>
              <Row type="flex" justify="center" style={{ margin: 15 }}>
                <Col span={20}>
                  <Row>{<Translate content="dashboard.candidatesTotal" />}</Row>
                  <Row>
                    <Title level={3}>
                      {this.state.aziendaWork + this.state.notAziendaWork}
                    </Title>
                  </Row>
                </Col>
                <Col
                  span={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <i
                    className="fa-solid fa-users iconsHeader"
                    style={{ fontSize: "2.2rem", color: "#d5614b" }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={6}>
            <Card id="personalCard" style={{ height: "150px" }}>
              <Row
                type="flex"
                justify="center"
                align="end"
                style={{ margin: 15 }}
              >
                <Col span={20}>
                  <Tag color="#974d76">
                    <i
                      class="fa-solid fa-angle-up"
                      style={{ marginRight: "3px" }}
                    ></i>{" "}
                    20%
                  </Tag>
                </Col>
                <Col span={4}></Col>
              </Row>
              <Row type="flex" justify="center" style={{ margin: 15 }}>
                <Col span={20}>
                  <Row>
                    {<Translate content="dashboard.jobDescriptionsTotal" />}
                  </Row>
                  <Row>
                    <Title level={3}>
                      {this.state.openJob + this.state.closedJob}
                    </Title>
                  </Row>
                </Col>
                <Col
                  span={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <i
                    className="fa-solid fa-briefcase  iconsHeader"
                    style={{ fontSize: "2.2rem", color: "#974d76" }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={6}>
            <Card id="personalCard" style={{ height: "150px" }}>
              <Row
                type="flex"
                justify="center"
                align="end"
                style={{ margin: 15 }}
              >
                <Col span={20}>
                  <Tag color="#5e3092">
                    {" "}
                    <i
                      class="fa-solid fa-timer"
                      style={{ marginRight: "3px" }}
                    ></i>{" "}
                    {<Translate content="dashboard.last24Hours" />}
                  </Tag>
                </Col>
                <Col span={4}></Col>
              </Row>
              <Row type="flex" justify="center" style={{ margin: 15 }}>
                <Col span={20}>
                  <Row>{<Translate content="dashboard.newCandidates" />}</Row>
                  <Row>
                    <Title level={3}>{this.state.candidati24Ore}</Title>
                  </Row>
                </Col>
                <Col
                  span={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <i
                    className="fa-solid fa-user-plus iconsHeader"
                    style={{ fontSize: "2.2rem", color: "#5e3092" }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={6}>
            <Card id="personalCard" style={{ height: "150px" }}>
              <Row
                type="flex"
                justify="center"
                align="end"
                style={{ margin: 15 }}
              >
                <Col span={20}>
                  <Tag color="#0d0185">
                    <i
                      class="fa-solid fa-timer"
                      style={{ marginRight: "3px" }}
                    ></i>{" "}
                    {<Translate content="dashboard.last24Hours" />}
                  </Tag>
                </Col>
                <Col span={4}></Col>
              </Row>
              <Row type="flex" justify="center" style={{ margin: 15 }}>
                <Col span={20}>
                  <Row>{<Translate content="dashboard.newJobs" />}</Row>
                  <Row>
                    <Title level={3}>{this.state.job24Ore}</Title>
                  </Row>
                </Col>
                <Col
                  span={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <i
                    className="fa-solid fa-file-plus iconsHeader"
                    style={{ fontSize: "2.2rem", color: "#0d0185" }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row style={{ marginTop: 32 }} gutter={32}>
          <Col span={12}>
            <Card id="personalCard" style={{ height: "370px" }}>
              <Row
                type="flex"
                justify="center"
                align="end"
                style={{ margin: 15, height: "300px" }}
              >
                <Col
                  span={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{ maxHeight: "200px" }}
                    src="/resources/icons/avatar.png"
                    alt="avatar"
                  />
                </Col>
                <Col
                  span={12}
                  type="flex"
                  justify="center"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div>
                    <Row>
                      <Title level={4}>
                        {<Translate content="dashboard.welcomeMessage" />},{" "}
                        <b>
                          {" "}
                          {this.state.userLogged.firstName == null ||
                          this.state.userLogged.firstName === undefined
                            ? this.state.userLogged.nome
                            : this.state.userLogged.firstName}{" "}
                          {this.state.userLogged.lastName == null ||
                          this.state.userLogged.lastName === undefined
                            ? this.state.userLogged.cognome
                            : this.state.userLogged.lastName}
                          !
                        </b>
                      </Title>
                    </Row>

                    <Row>
                      <p style={{ marginBottom: "0" }}>
                        Email: <b>{this.state.userLogged.email}</b>
                      </p>
                    </Row>
                    <Row>
                      <p>
                        {<Translate content="dashboard.group" />}:{" "}
                        <b>{this.state.userLogged.userGroup}</b>
                      </p>
                    </Row>
                    <Row>
                      <Button
                        onClick={() => this.onButtonLogout()}
                        type="primary"
                      >
                        {" "}
                        <i
                          class="fa-solid fa-arrow-right-from-bracket"
                          style={{ marginRight: "2px" }}
                        ></i>{" "}
                        {<Translate content="dashboard.logoutButton" />}
                      </Button>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card id="personalCard" style={{ height: "370px" }}>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Title level={4}>
                  {<Translate content="dashboard.jobAreasDistribution" />}
                </Title>
              </Row>
              <Row>
                <div
                  class="chart-container"
                  style={{
                    position: "relative",
                    height: "300px",
                    width: "auto",
                  }}
                >
                  {this.state.countAndDistribution === undefined ? (
                    <div></div>
                  ) : (
                    <PieChartDistribution
                      employment={this.state.countAndDistribution}
                    />
                  )}
                </div>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: 32 }} gutter={32}>
          <Col span={8}>
            <Card id="personalCard" style={{ height: "200px" }}>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Title level={4}>Top Skills</Title>
              </Row>
              <Row
                style={{
                  marginTop: 15,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  {(() => {
                    if (this.state.topSkill === undefined) {
                      return (
                        <div>
                          {<Translate content="dashboard.noSkillPresent" />}
                        </div>
                      );
                    } else {
                      return this.state.topSkill.map((element) => {
                        return (
                          <Tag
                            style={{
                              background: "#3f88c5",
                              color: "white",
                              padding: "6px",
                              paddingLeft: "12px",
                              paddingRight: "12px",
                              marginBottom: "12px",
                            }}
                          >
                            {element.name}
                          </Tag>
                        );
                      });
                    }
                  })()}
                </div>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card id="personalCard" style={{ height: "200px" }}>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Title level={4}>
                  {<Translate content="dashboard.topCities" />}
                </Title>
              </Row>
              <Row style={{ marginTop: 15 }}>
                <div>
                  {(() => {
                    if (this.state.topAddress === undefined) {
                      return (
                        <div>
                          {<Translate content="dashboard.noCityPresent" />}
                        </div>
                      );
                    } else {
                      return this.state.topAddress.map((element) => {
                        return (
                          <Tag
                            style={{
                              background: "#e16644",
                              color: "white",
                              padding: "6px",
                              paddingLeft: "12px",
                              paddingRight: "12px",
                              marginBottom: "12px",
                            }}
                          >
                            {element.city}
                          </Tag>
                        );
                      });
                    }
                  })()}
                </div>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card id="personalCard" style={{ height: "200px" }}>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Title level={4}>Top Hobby</Title>
              </Row>
              <Row style={{ marginTop: 15 }}>
                <div>
                  {(() => {
                    return (
                      <div>
                        {<Translate content="dashboard.noHobbyPresent" />}
                      </div>
                    );
                  })()}
                </div>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row style={{ display: "none" }} gutter={32}>
          <Col span={6}>
            <Card id="personalCard" style={{ height: "400px" }}>
              <Row type="flex" justify="center" style={{ margin: 15 }}>
                <Avatar size={100} />
              </Row>
              <Row type="flex" justify="center">
                <Title level={3} style={{ color: "#3f88c5" }}>
                  <Translate content="dashboard.welcomeMessage" />{" "}
                  {this.state.username}
                </Title>
              </Row>
              <Row
                type="flex"
                justify="center"
                align="middle"
                gutter={[5, 5]}
                style={{
                  marginTop: 20,
                  height: 100,
                  position: "absolute",
                  right: 0,
                  left: 0,
                  bottom: "80px",
                }}
              >
                <Col span={22}>
                  <Link to="/login" onClick={() => this.onButtonLogout()}>
                    <DashboardButton
                      title={<Translate content="dashboard.logoutButton" />}
                    />
                  </Link>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={6}>
            <Card id="personalCard" style={{ height: "400px" }}>
              <Row
                type="flex"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Title level={2} style={{ margin: 15 }}>
                  <Translate content="dashboard.jobDescriptionsText" />
                </Title>
              </Row>
              <Row type="flex" style={{ margin: 15 }}>
                <Col span={20}>
                  <Title level={4}>
                    <Translate content="dashboard.jobDescriptionsOpen" />
                  </Title>
                </Col>
                <Col type="flex" justify="end" span={4}>
                  <Title level={4}>{this.state.openJob}</Title>
                </Col>
              </Row>
              <Row type="flex" style={{ margin: 15 }}>
                <Col span={20}>
                  <Title level={4}>
                    <Translate content="dashboard.jobDescriptionsClose" />
                  </Title>
                </Col>
                <Col type="flex" justify="end" span={4}>
                  <Title level={4}>{this.state.closedJob}</Title>
                </Col>
              </Row>
              <Row type="flex" style={{ margin: 15 }}>
                <Col span={20}>
                  <Title level={4}>
                    <Translate content="dashboard.jobDescriptionsTotal" />
                  </Title>
                </Col>
                <Col type="flex" justify="end" span={4}>
                  <Title level={4}>
                    {this.state.openJob + this.state.closedJob}
                  </Title>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="center"
                align="middle"
                gutter={[5, 5]}
                style={{
                  marginTop: 20,
                  height: 100,
                  position: "absolute",
                  right: 0,
                  left: 0,
                  bottom: "80px",
                }}
              >
                <Col span={22}>
                  <Link to={"/jobdescriptions"}>
                    <DashboardButton
                      title={
                        <Translate content="dashboard.jobDescriptionsButton" />
                      }
                    />
                  </Link>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6}>
            <Card id="personalCard" style={{ height: "400px" }}>
              <Row
                type="flex"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Title level={2} style={{ margin: 15 }}>
                  <Translate content="dashboard.jobDescriptionsText" />
                </Title>
              </Row>
              <Row type="flex" style={{ margin: 15 }}>
                <Col span={20}>
                  <Title level={4}>
                    <Translate content="dashboard.jobDescriptionsOpen" />
                  </Title>
                </Col>
                <Col type="flex" justify="end" span={4}>
                  <Title level={4}>{this.state.openJob}</Title>
                </Col>
              </Row>
              <Row type="flex" style={{ margin: 15 }}>
                <Col span={20}>
                  <Title level={4}>
                    <Translate content="dashboard.jobDescriptionsClose" />
                  </Title>
                </Col>
                <Col type="flex" justify="end" span={4}>
                  <Title level={4}>{this.state.closedJob}</Title>
                </Col>
              </Row>
              <Row type="flex" style={{ margin: 15 }}>
                <Col span={20}>
                  <Title level={4}>
                    <Translate content="dashboard.jobDescriptionsTotal" />
                  </Title>
                </Col>
                <Col type="flex" justify="end" span={4}>
                  <Title level={4}>
                    {this.state.openJob + this.state.closedJob}
                  </Title>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="center"
                align="middle"
                gutter={[5, 5]}
                style={{
                  marginTop: 20,
                  height: 100,
                  position: "absolute",
                  right: 0,
                  left: 0,
                  bottom: "80px",
                }}
              >
                <Col span={22}>
                  <Link to={"/jobdescriptions"}>
                    <DashboardButton
                      title={
                        <Translate content="dashboard.jobDescriptionsButton" />
                      }
                    />
                  </Link>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6}>
            <Card id="personalCard" style={{ height: "400px" }}>
              <Row
                type="flex"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Title level={2} style={{ margin: 15 }}>
                  <Translate content="dashboard.candidatesText" />
                </Title>
              </Row>
              <Row type="flex" style={{ margin: 15 }}>
                <Col span={20}>
                  <Title level={4}>
                    Hanno già lavorato per Azienda
                    {/* <Translate content='dashboard.candidatesAge' /> */}
                  </Title>
                </Col>
                <Col type="flex" justify="end" span={4}>
                  <Title level={4}>{this.state.aziendaWork}</Title>
                </Col>
              </Row>
              <Row type="flex" style={{ margin: 15 }}>
                <Col span={20}>
                  <Title level={4}>
                    Non hanno già lavorato per Azienda
                    {/* <Translate content='dashboard.candidatesSpontaneous' /> */}
                  </Title>
                </Col>
                <Col type="flex" justify="end" span={4}>
                  <Title level={4}>{this.state.notAziendaWork}</Title>
                </Col>
              </Row>
              <Row type="flex" style={{ margin: 15 }}>
                <Col span={20}>
                  <Title level={4}>
                    <Translate content="dashboard.candidatesTotal" />
                  </Title>
                </Col>
                <Col type="flex" justify="end" span={4}>
                  <Title level={4}>
                    {this.state.aziendaWork + this.state.notAziendaWork}
                  </Title>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="center"
                align="middle"
                gutter={[5, 5]}
                style={{
                  marginTop: 20,
                  height: 100,
                  position: "absolute",
                  right: 0,
                  left: 0,
                  bottom: "80px",
                }}
              >
                <Col span={22}>
                  <Link to={"/candidates"}>
                    <DashboardButton
                      title={<Translate content="dashboard.candidatesButton" />}
                    />
                  </Link>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
export default withRouter(Dashboard);
