import React from 'react';

import { Row, Col, Button, Upload, Icon, Select, Spin, Empty, Tooltip, message } from 'antd';

import Axios from 'axios';
import { withRouter } from 'react-router-dom';
import { errorRouter } from '../errors/ErrorRouter';
import Paragraph from 'antd/lib/typography/Paragraph';
import Translate from 'react-translate-component'

let props = {
  action: '/api/uploadFile',
  headers: {
    Authorization: localStorage.getItem('Token')
  },
  data: {
    position: 0,
  },
  //Viene effettuato il controllo per avere il token di autenticazione aggiornato
  beforeUpload({ file, fileList }) {
    if (props.headers.Authorization !== localStorage.getItem('Token')) {
      props.headers.Authorization = localStorage.getItem('Token')
    }
    props.headers.Authorization = Axios.defaults.headers.common['Authorization'];
  },
  multiple: true,
};

const { Option } = Select;

class AddCvs extends React.Component {

  state = {
    fileList: [],
    jobDescriptions: [],
    selectedJobDescriptionsId: [],
    activeOpenJobDescriptions: [],
    activeClosedJobDescriptions: [],
    archivedJobDescriptions: [],
    isLoadingJobs: true,
    messageKey: 'warningMessage'
  };

  componentDidMount() {
    this.onJobDescriptionLoad();

  }

  componentDidUpdate() {
    if (this.props.location.pathname.includes("singleJobDescription")) {
      var result = this.props.location.pathname.split("/")
      if (this.state.selectedJobDescriptionsId !== parseInt(result[2]))
        this.setState({
          selectedJobDescriptionsId: parseInt(result[2]),
        })
    } else if (this.state.selectedJobDescriptionsId !== null) {
      Axios.get('/api/job')
        .then((response) => {
          let isPresent = response.data._embedded.jobDescriptions.map((jobDescription) => jobDescription.id === this.state.selectedJobDescriptionsId);
          if (!isPresent.includes(true)) {
            this.setState({
              selectedJobDescriptionsId: null
            })
          }
        })
        .catch((error) => {
          //TODO GESTIONE ERRORE
        })
    }
  }

  /*componentDidUpdate() {
    console.log(this.state.selectedJobDescriptionsId)
    if(this.state.selectedJobDescriptionsId !== null) {
      Axios.get('/api/job')
        .then((response) => {
          let isPresent = response.data._embedded.jobDescriptions.map((jobDescription) => jobDescription.id === this.state.selectedJobDescriptionsId);
          if(!isPresent.includes(true)) {
            this.setState({
              selectedJobDescriptionsId: null
            })
          }
        })
        .catch((error) => {
          //TODO GESTIONE ERRORE
        })
    }
  }*/

  handleChange = info => {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-15);

    // 2. Read from response and show file link
    fileList = fileList.map(file => {
      if (info.file.response) {
        // Component will show file.url as link
        file.response = info.file.response.message;
      }
      return file;
    });

    this.setState({ fileList });
  };

  onJobDescriptionSelect = (jobDescriptionsId) => {
    this.setState({
      selectedJobDescriptionsId: jobDescriptionsId,
    })
  }

  onJobDescriptionLoad = () => {
    this.setState({
      isLoadingJobs: true,
    })
    let tempActiveOpenJobDescriptions = [];
    let tempActiveClosedJobDescriptions = [];
    let tempArchivedJobDescriptions = [];

    Axios.get('/api/job')
      .then((response) => {
        response.data._embedded.jobDescriptions.forEach((jobDescription) => {
          if (jobDescription.active && !jobDescription.bucket) {
            tempActiveOpenJobDescriptions.push(
              <Option
                key={jobDescription.id}
                value={jobDescription.id}
              >
                <Tooltip title={jobDescription.description} placement='right'>
                  {jobDescription.title} - {jobDescription.jobDescriptionCode}
                </Tooltip>
              </Option>
            )
          } else if (!jobDescription.active && !jobDescription.bucket) {
            tempActiveClosedJobDescriptions.push(
              <Option
                key={jobDescription.id}
                value={jobDescription.id}
              >
                <Tooltip title={jobDescription.description} placement='right'>
                  {jobDescription.title} - {jobDescription.jobDescriptionCode}
                </Tooltip>
              </Option>
            )
          } else {
            tempArchivedJobDescriptions.push(
              <Option
                key={jobDescription.id}
                value={jobDescription.id}
              >
                <Tooltip title={jobDescription.description} placement='right'>
                  {jobDescription.title} - {jobDescription.jobDescriptionCode}
                </Tooltip>
              </Option>
            )
          }
        })


        this.setState({
          jobDescriptions: response.data._embedded.jobDescriptions,
          activeOpenJobDescriptions: tempActiveOpenJobDescriptions,
          activeClosedJobDescriptions: tempActiveClosedJobDescriptions,
          archivedJobDescriptions: tempArchivedJobDescriptions,
          isLoadingJobs: false,
        })
      })
      .catch((error) => {
        if (error.response) {
          errorRouter(error.response.status, this.props.history);
        }
      })
  }

  render() {
    let authToken = this.props.authToken;
    if (this.props.isAddCvsVisible)
      message.warning({
        content: 
          localStorage.getItem("Language") === 'it'
            ? "Ricordati di inserire le posizioni lavorative di candidatura, prima dei cv."
            : 'Remember to insert job description of application, before CVs',
        key: this.state.messageKey,
        duration: 0
      });
    else
      message.destroy();

    return (
      <div>
        <Row gutter={[0, 32]}>
          <Paragraph strong style={{ fontSize: 18, paddingTop: 10 }}><Translate content='addCV.descriptionText' /></Paragraph>
        </Row>
        <Row
          gutter={[0, 32]}
        >
          <Col span={24}>
            <Select
              defaultValue={this.state.selectedJobDescriptionsId}
              value={this.state.selectedJobDescriptionsId ? this.state.selectedJobDescriptionsId : null}
              onChange={this.onJobDescriptionSelect}
              onFocus={this.onJobDescriptionLoad}
              notFoundContent={
                this.state.isLoadingJobs
                  ? <Spin tip={<Translate content='addCV.searchText' />} />
                  : Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ width: 'inherit', border: '1px solid #d9d9d9', borderRadius: '5px' }}
            >
              <Select.OptGroup label={<Translate content='addCV.activeOpenText' />}>
                {
                  this.state.activeOpenJobDescriptions.map((optionElement) => {
                    return optionElement;
                  })
                }
              </Select.OptGroup>
              <Select.OptGroup label={<Translate content='addCV.activeCloseText' />}>
                {
                  this.state.activeClosedJobDescriptions.map((optionElement) => {
                    return optionElement;
                  })
                }
              </Select.OptGroup>
              <Select.OptGroup label={<Translate content='addCV.archivedText' />}>
                {
                  this.state.archivedJobDescriptions.map((optionElement) => {
                    return optionElement;
                  })
                }
              </Select.OptGroup>
            </Select>
          </Col>
        </Row>
        <Row
          gutter={[0, 16]}
        >
          <Col span={24}>
            <Upload
              action='/api/uploadFile'
              headers={{
                Authorization: authToken,
              }}
              data={{
                position: this.state.selectedJobDescriptionsId
              }}
              onChange={this.handleChange}
              fileList={this.state.fileList}
              multiple
            >
              <Button disabled={this.state.selectedJobDescriptionsId !== null ? false : true}>
                <Icon type="upload" /> <Translate content='addCV.uploadText' />
            </Button>
            </Upload>
          </Col>
        </Row >
      </div>
    )
  }

} export default withRouter(AddCvs);