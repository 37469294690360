import React from 'react';
//Componenti ant design utilizzati
import { Row, Col } from 'antd';

export default function ZarathustraFooter() {

  return (
    <div>
      <Row type='flex' justify='center' align='middle'>
        <Col
          xs={{ span: 14 }}
          sm={{ span: 7 }}
          md={{ span: 9 }}
          lg={{ span: 4 }}
          xl={{ span: 4 }}
          xxl={{ span: 3 }}
          style={{ textAlign: 'center' }}
        >
          Zarathustra | Created by ELIS
        </Col>
      </Row>
    </div>
  )
}