import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Modal, notification, Tabs } from 'antd';

import AddCvs from '../addcvs/AddCvs';
import AddCvsJobConnection from '../addcvs/AddCvsJobConnection';
import Axios from 'axios';
import Translate from 'react-translate-component';

const { TabPane } = Tabs;

class FabButton extends React.Component {

  state = {
    //Permette di aprire o chiudere il modal di caricamento dei CVs
    isAddCvsVisible: false,
    tabKey: "1",
    connectionCandidates: [],
    connectionJobDescription: 0,
  }


  //Viene lanciato al click del bottone del fab che da accesso al modal per il caricamento dei CVs
  openAddCVs = () => {
    //Il modal diventa visibile
    this.setState({
      isAddCvsVisible: true,
    })
  }

  //Viene lanciato al tentativo di chiusura del modal per l'inserimento dei CVs
  closeAddCVs = () => {
    //Il modal diventa invisibile
    this.setState({
      isAddCvsVisible: false,
    })
  }

  onTabChange = (key) => {
    this.setState({
      tabKey: key,
    })
  }



  saveCvsConnection = () => {
    this.state.connectionCandidates.forEach((selectedCandidateId) => {
      Axios.post(`/api/candidatoToJob/${selectedCandidateId}/${this.state.connectionJobDescription}`)
        .then((response) => {
          this.closeAddCVs();
          const btn = (
            <Button type="primary" size="small"
              onClick={() => {
                notification.close(this.state.connectionJobDescription)
                this.props.history.push(`/singleJobDescription/${this.state.connectionJobDescription}`)
              }}
            >
              <Translate content='fabButton.visualizationText' />
            </Button>
          )
          const key = this.state.connectionJobDescription;
          notification.open({
            message: localStorage.getItem("Language") === 'it' ? 'Collegamento candidati' : 'Connecting candidates',
            description:
              localStorage.getItem("Language") === 'it'
                ? 'Il collegamento è stato effettuato correttamente, visualizza i dati premendo il bottone'
                : 'The connection has been done properly, displays data by pushing the button',
            btn,
            key,
            placement: 'bottomRight'
          });
        })
        .catch((error) => {
          notification.error({
            message: localStorage.getItem("Language") === 'it' ? 'Errore inserimento' : 'Input error',
            description:
              localStorage.getItem("Language") === 'it'
              ? 'Il candidato è già associato a questa posizione'
              : 'Candidate is already associated with this job',
            placement: 'bottomRight'
          });

        })
    })
  }

  onCandidateSelectChange = (candidateIds) => {
    this.setState({
      connectionCandidates: candidateIds,
    })
  }

  onJobSelectChange = (jobId) => {
    this.setState({
      connectionJobDescription: jobId,
    })
  }

  render() {
    return (
      <div className="fab">
        <span className="fab-action-button" style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <i className="fa-solid fa-plus iconsHeader" style={{ fontSize: '2rem' }}></i>
        </span>
        <ul className="fab-buttons">
          <li className="fab-buttons__item" onClick={this.openAddCVs}>
            {/* eslint-disable-next-line */}
            <a href='javascript:void(0);'
               className="fab-buttons__link"
               data-tooltip={localStorage.getItem("Language") === 'it' ? "Candidato" : "Candidate"}>
              <i className='fa-solid fa-users iconsHeader' style={{ fontSize: '1.1rem' }} />
            </a>
          </li>
          {

            this.props.history.location.pathname.includes('singleJobDescription')
              ? null
              : (
                <li className="fab-buttons__item">
                  <Link
                    to={{
                      pathname: '/newjobdescription'
                    }}
                    className="fab-buttons__link"
                    data-tooltip={localStorage.getItem("Language") === 'it' ? "Posizione lavorativa" : "Job description"}
                  >
                      <i className='fa-solid fa-briefcase iconsHeader' style={{ fontSize: '1.1rem' }} />
                  </Link>
                </li>
              )
          }
        </ul>
        <Modal
          title={<Translate content='fabButton.titleText' />}
          onCancel={this.closeAddCVs}
          onOk={this.state.tabKey === "1" ? this.closeAddCVs : this.saveCvsConnection}
          visible={this.state.isAddCvsVisible}
          style={{ height: 60 }}
          footer={[
            <Button key="back" onClick={this.closeAddCVs}>
              <Translate content='jobDescriptions.closeText' />
            </Button>,
            this.state.tabKey === "2"
              ? <Button key="submit" type="primary" onClick={this.saveCvsConnection}>
                  <Translate content='fabButton.saveLinkText' />
                </Button>
              : null,
          ]}
        >
          <Tabs defaultActiveKey="1" onChange={this.onTabChange}>
            <TabPane tab={<Translate content='fabButton.addCVText' />} key="1">
              <AddCvs
                authToken={this.props.authToken}
                isAddCvsVisible={this.state.isAddCvsVisible}
              />
            </TabPane>
            <TabPane tab={<Translate content='fabButton.linkCVText' />} key="2">
              <AddCvsJobConnection onCandidateSelectChange={this.onCandidateSelectChange} onJobSelectChange={this.onJobSelectChange} />
            </TabPane>
          </Tabs>
        </Modal>
      </div>
    );
  }
} export default withRouter(FabButton);