import React from 'react';

import { Row, Col, Empty } from 'antd';

import JobDescriptionCard from '../../Cards/JobDescriptionCard';
import { withRouter } from 'react-router-dom';
import Translate from 'react-translate-component';

export default withRouter(function JobDescriptionsGrid(props) {
  
  return (
    <Row gutter={[32, 24]} >
      {
        props.cardsData.length !== 0 ?
          /** 
           * Viene ciclato l'array contenente tutte le Job Descriptions ricevute dal back end in modo da poter
           * passare il singolo elemento ad ogni card
          */
          props.cardsData.map((singleJobDescription) => {
            return (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 8 }}
                xxl={{ span: 6 }}
                key={singleJobDescription.id}
              >
                {/** 
                   * In questo link viene passato l'id della job description tramite URL in modo che possa esserci persistenza
                   * della card cliccata anche in caso di refresh della pagina. In 'query' viene passato direttamente l'intero 
                   * oggetto (jobdescription: element), in modo che il componente <SingleJobDescription /> possa averlo senza dover
                   * effettuare di nuovo la richiesta al back end. Viene passato anche 'prevPage', che contiene la pagina di provenienza,
                   * in modo che da <SingleJobDescription /> si possa tornare indietro da dove si è arrivati.
                  */}
                <JobDescriptionCard
                  jobDescription={singleJobDescription}
                  onJobCardClick={props.onJobCardClick}
                  loadJobDescriptionsData={props.loadJobDescriptionsData}
                  onJobEditClick={props.onJobEditClick}  
                />
              </Col>
            )
          })
          : <Empty
            description={<Translate content='jobDescriptions.noJobDescriptionsText' />}
            image={Empty.PRESENTED_IMAGE_DEFAULT}
            style={{ paddingTop: '30vh' }}
          />
      }
    </Row>
  );
})
