import React from 'react';

//Componenti ant design utilizzati
import {Card, Row, Col, Typography, Popover, List, Avatar/* , Icon */} from 'antd';
import Translate from 'react-translate-component';

//Componenti Title e Text di Ant Design, fanno parte della libreria Typography
const {Title, Text} = Typography;

/** TODO Da definire i props utilizzati dal componente */
export default function SocialCard(props) {

    let data = [];
    props.socialsList
        ? props.socialsList.forEach((social) => {
            if (social.url.length !== 0 && social.type)
                data.push({
                    title:
                        `${social.url.length !== 0
                            ? social.url
                            : localStorage.getItem("Language") === 'it' ? 'URL mancante' : 'Missing URL'}`,
                    type:
                        `${social.type ? (social.type.length !== 0 ? social.type : 'error') : "error"}`
                })
            else if (social.url.length !== 0 && !social.type)
                data.push({
                    title:
                        `${social.url.length !== 0
                            ? social.url
                            : localStorage.getItem("Language") === 'it' ? 'URL mancante' : 'Missing URL'}`,
                    type:
                        `${social.type ? (social.type.length !== 0 ? social.type : 'error') : "error"}`
                })
        })
        : data = []
    return (
        <Popover
            content={
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={item.type.length !== 'error'
                                    ? <Avatar icon={item.type} style={{backgroundColor: '#3f88c5'}} shape='square'
                                              size='small'/>
                                    : <Avatar icon='question' style={{backgroundColor: '#3f88c5'}} shape='square'
                                              size='small'/>}
                                title={<a href={item.title}>{item.title}</a>}
                            />
                        </List.Item>
                    )}
                />
            }
        >
            <Card id='personalCard' style={{height: '100%'}}>
                <Row justify='center' align='middle'>
                    <Col
                        span={20}
                        style={{textAlign: 'left', height: '25px', justifyContent: 'start', alignItems: 'center'}}
                    >
                        <Row>
                            <Title level={3}>
                                {
                                    data
                                        ? data.length
                                        : 0
                                }
                            </Title>
                        </Row>
                        <Row>
                            <Text style={{color: '#3f88c5 ', fontSize: 12}}>
                                <Translate content='cards.socialCandidate' />
                            </Text>
                        </Row>
                    </Col>
                    <Col
                        span={4}
                        style={{textAlign: 'right'}}
                    >
                        <i className="fa-regular fa-globe iconsCandidate"
                           style={{marginRight: '0.5rem', fontSize: '2rem'}}></i>
                    </Col>
                </Row>
            </Card>
        </Popover>
    )
}
