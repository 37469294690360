import React from "react";
import { /* Card, Typography, */ Spin, Tooltip, Row, Col, /* Icon, */ Modal, Form, Input, Steps, Popconfirm, Button } from "antd";
import Axios from "axios";
import { withRouter } from "react-router-dom";
//import { errorRouter } from "../errors/ErrorRouter";

import Translate from 'react-translate-component';
const { TextArea } = Input;

const { Step } = Steps;

/* const { Meta } = Card;
const { Title, Text } = Typography; */

/**
 * Props utilizzati: 
 * - candidateID: Contiene l'id del candidato considerato, in modo da poter recuperare tutti i suoi dati
 */
class CandidateInfoCard extends React.Component {

  state = {
    //Booleano che definisce se i dati stanno caricando o meno 
    isLoading: true,
    current: 0,
    isUpdateModalVisibile: false,
    disabledStep0: false,
    disabledStep1: false,
    disabledStep2: false,
    visible: false,
    setVisible: false,
    confirmLoading: false,
    setConfirmLoading: false,
    esitoFinale: false,
    note: '',
    loadingButtonModal:false

  }

  //Viene utilizzato l'id del candidato per recuperare tutti i suoi dati personali
  componentDidMount() {
    this.loadCandidatePersonalData();
  }

  loadCandidatePersonalData = async () => {
    await this.setState({
      isLoading: false,
    })
    Axios.get(`/api/getStato/${this.props.selectedCandidateId}/${this.props.idPosizione}`).then((response) => {
      this.updateCurrentStatus(response.data.currentStato,response.data.esitoFinale, response.data.note)
    })
  }
  updateCurrentStatus = (current, esitoFinale, note) => {
    this.setState({
      current: current,
      esitoFinale: esitoFinale,
      note:note
    })
  }
  openUpdateCandidateDataModal = () => {
    this.loadCandidatePersonalData().then(
      this.setState({
        isUpdateModalVisibile: true,
      })
    )

  }



  handleUpdateModalCancel = () => {
    this.setState({
      isUpdateModalVisibile: false,
    })
  }

  showPopconfirm = (stato) => {
    if(this.state.current < 2){
      this.setState({
        visible: true,
        esitoFinale:stato
      })
    }
    
  };

  handleOk = () => {
      this.setState({
        confirmLoading: true
      })

      let current = this.state.current +1;
      Axios.post(`/api/cambioStatoCandidato/${this.props.selectedCandidateId}/${this.props.idPosizione}/${current}/${this.state.esitoFinale}`).then((response) => {
        this.updateCurrentStatus(response.data.currentStato,response.data.esitoFinale, response.data.note)
        this.setState({
          confirmLoading: false,
          visible: false
        })

      })

    
  };

  handleCancel = () => {
    console.log('Clicked cancel button');
    this.setState({
      visible: false
    })
  };


  handleUpdateModalOk = () => {
    let body = {note:this.state.note}
    this.setState({
      loadingButtonModal: true
      
    })
    Axios.post(`/api/inserisciNotaStato/${this.props.selectedCandidateId}/${this.props.idPosizione}`,body).then((response) => {
      this.updateCurrentStatus(response.data.currentStato,response.data.esitoFinale)
      this.setState({
        loadingButtonModal: false,
        isUpdateModalVisibile: false,
        
      })
    })
  }

  onChange = current => {
    this.showPopconfirm(false)
  
  };
  positivo = current => {
    this.showPopconfirm(true)
  
  };
  negativo = current => {
    this.showPopconfirm(false)
  
  };
  onChangeModal = ({ target: { value } }) => {
    this.setState({ note:value });
  };
 
  /* TODO Traduzioni per tutta la pagina */
  render() {
    //const { getFieldDecorator } = this.props.form;
    const { note } = this.state;
    return (
      <Spin spinning={this.state.isLoading}>
        <Row>

       
        <Modal
          title={<Translate content='candidateState.infoVisualizationText' />}
          visible={this.state.isUpdateModalVisibile}
          onOk={this.handleUpdateModalOk}
          okText={<Translate content='candidateState.saveButtonText' />}
          cancelText={<Translate content='candidateState.cancelButtonText' />}
          confirmLoading={this.state.loadingButtonModal}
          onCancel={this.handleUpdateModalCancel}
        >
           <TextArea
            value={note}
            onChange={this.onChangeModal}
            placeholder={localStorage.getItem("Language") === 'it'
                          ? 'Inserisci qui le tue note'
                          : 'Enter here your notes'
                        }
            autoSize={{ minRows: 3, maxRows: 5 }}
        />
        </Modal>
        <Col span={19}>
        <Tooltip title={<Translate content='candidateState.infoText' />}>
            <Steps  progressDot current={this.state.current}
                 className="site-navigation-steps"
                 type="default"
                 onClick={this.openUpdateCandidateDataModal}
            >
              <Step status="" title={<Translate content='candidateState.applicationText' />} description={<Translate content='candidateState.applicationDescriptionText' />} />
              <Step title={<Translate content='candidateState.progressText' />}/>
              <Step status={this.state.current === 2 ? this.state.esitoFinale ? "finish" : "error" : "process"} title={<Translate content='candidateState.outcomeText' />} description={this.state.current === 2 ? this.state.esitoFinale ? <Translate content='candidateState.suitableText' /> : <Translate content='candidateState.noSuitableText' /> : ""} />
            </Steps>
          </Tooltip>
         
         </Col>
         <Col span={5} style={{display:'flex'}}>

          <Popconfirm
              title={<Translate content='candidateState.confirmationStatusText' />}
              visible={this.state.visible}
              onConfirm={this.handleOk}
              cancelText={<Translate content='candidateState.cancelButtonText' />}
              okButtonProps={{ loading: this.state.confirmLoading }}
              onCancel={this.handleCancel}
            >
             
              {this.state.current === 0 &&
               <Tooltip title={<Translate content='candidateState.clickProgressText' />}>
                <Button onClick={this.onChange}  type="primary"><Translate content='candidateState.progressButtonText' /></Button> 
              </Tooltip>
              }
              {this.state.current === 1 &&
               <Tooltip title={<Translate content='candidateState.clickSuitableText' />}>
               <Button  onClick={this.positivo}  type="primary"><Translate content='candidateState.suitableText' /></Button> 
             </Tooltip>
          }
              {this.state.current === 1 &&
              <Tooltip title={<Translate content='candidateState.clickNotSuitableText' />}>
              <Button  onClick={this.onChange}  type="danger"><Translate content='candidateState.noSuitableText' /></Button> 
            </Tooltip>}
          </Popconfirm>
        </Col>

     
        </Row>
      

      </Spin>
    );
  }
} const CandidateInfoCardForm = Form.create({ name: 'horizontal_login' })(CandidateInfoCard);
export default withRouter(CandidateInfoCardForm);
