import React from 'react';

import { Link } from 'react-router-dom';

import { Row, Col, Spin, } from 'antd';

import SingleCandidateCard from '../../Cards/SingleCandidateCard';
import CandidateInfoCard from '../../Cards/CandidateInfoCard';
import LanguagesCard from '../../Cards/LanguagesCard';
import EducationLevelCard from '../../Cards/EducationLevelCard';
import YearsOfExperienceCard from '../../Cards/YearsOfExperienceCard';
import PieChart from '../../Charts/PieChart';
import Axios from 'axios';
import { errorRouter } from '../../errors/ErrorRouter';
import CandidateState from '../../Cards/CandidateState';

/**
 * Utilizza i props: 
 * - selectedCandidate: contiene tutti i dati del candidato selezionato
 * - referrerJobDescriptionID: ID della job description a cui si riferisce il candidato selezionato.
 * - onCandidateCardClick: metodo che lancia l'evento del click della card del candidato, effettuando il redirect a 
 *   <SingleCandidate /> con i dati del candidato stesso.
 * - softSkills: array con le soft skills della job description relazionata al candidato selezionato
 * - hardSkills: array con le hard skills della job description relazionata al candidato selezionato
 */
class CandidatePerJobDescription extends React.Component {

  state = {
    isLoadingData: true,
    softSkills: [],
    hardSkills: [],
    languageSkills: [],
    educationLevelScore: 0,
    yearsOfExperienceExplanation: "",
    yearsOfExperienceScore: 0,
    candidate: {},
    isEducationLevelModalVisible: false,
    isYearsOfExperienceModalVisible: false,
  }

  componentDidMount() {
    Axios.get(`/api/candidato/${this.props.selectedCandidate.idCandidate}`)
      .then((response) => {
        let tempHardSkills = [];
        if (response.data.cvs[0].profile.skills.computerSkill)
          tempHardSkills = tempHardSkills.concat(response.data.cvs[0].profile.skills.computerSkill);
        if (response.data.cvs[0].profile.skills.otherSkill)
          tempHardSkills = tempHardSkills.concat(response.data.cvs[0].profile.skills.otherSkill);

        this.setState({
          isLoadingData: false,
          candidate: response.data,
          softSkills: response.data.cvs[0].profile.skills.softSkill,
          hardSkills: tempHardSkills,
          languageSkills: response.data.cvs[0].profile.skills.languageSkill,
          educationLevelScore: this.props.selectedCandidate.scoreLivelloIstruzione,
          yearsOfExperienceScore: this.props.selectedCandidate.scoreAnniEsperienza,
          yearsOfExperienceExplanation: this.props.selectedCandidate.yearsOfExperienceExplanation,
          degreesCoursesScore: this.props.selectedCandidate.scoreCorsoLaurea,
        })
      })
      .catch((error) => {
        if (error.response) {
          errorRouter(error.response.status, this.props.history);
        }
      })
  }

  onEducationLevelCardClick = () => {
    this.setState({
      isEducationLevelModalVisible: true,
    })
  }

  handleCancelEducationLevelModal = () => {
    this.setState({
      isEducationLevelModalVisible: false,
    })
  }

  onYearsOfExperienceCardClick = () => {
    this.setState({
      isYearsOfExperienceModalVisible: true,
    })
  }

  handleCancelYearsOfExperienceModal = () => {
    this.setState({
      isYearsOfExperienceModalVisible: false,
    })
  }

  render() {

    if (this.props.selectedCandidate !== undefined && !this.state.isLoadingData) {
      return (
        <Row gutter={[16, 16]} style={{ /* backgroundColor: 'rgb(0, 168, 176, 0.5)', */ padding: 10, marginBottom: 20 }}>
          <Col
            xl={{ span: 8 }}
            xxl={{ span: 6 }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Link
                  to={{
                    pathname: `/singlecandidate/${this.props.selectedCandidate.idCandidate}`,
                    query: { candidate: this.props.selectedCandidate, prevPage: `/singlejobdescription/${this.props.referrerJobDescriptionID}` }
                  }}
                >
                  <SingleCandidateCard
                    candidate={this.state.candidate}
                    onCandidateCardClick={this.props.onCandidateCardClick}
                  />
                </Link>
              </Col>
              <Col span={24}>
                <CandidateInfoCard
                  candidateEmail={
                    this.state.candidate.email
                      ? this.state.candidate.email
                      : ''
                  }
                  candidateWebsite={
                    this.state.candidate.cvs[0].profile.personal.socialMediaLink
                      ? this.state.candidate.cvs[0].profile.personal.socialMediaLink[0]
                        ? this.state.candidate.cvs[0].profile.personal.socialMediaLink[0].url
                          ? this.state.candidate.cvs[0].profile.personal.socialMediaLink[0].url
                          : ''
                        : ''
                      : ''
                  }
                  candidateAddress={
                    this.state.candidate.cvs[0].profile.personal.address
                      ? this.state.candidate.cvs[0].profile.personal.address
                      : ''
                  }
                  candidateLastJob={
                    this.state.candidate.cvs[0].profile.summary.currentJob
                      ? this.state.candidate.cvs[0].profile.summary.currentJob
                      : ''
                  }
                  candidateLastEmployer={
                    this.state.candidate.cvs[0].profile.summary.currentEmployer
                      ? this.state.candidate.cvs[0].profile.summary.currentEmployer
                      : ''
                  }
                  candidateName={
                    this.state.candidate.firstName + ' ' + this.state.candidate.middleName + ' ' + this.state.candidate.lastName
                  }
                  candidateID={this.props.selectedCandidate._embedded.cv.id}
                />
                </Col>
             
            </Row>
          </Col>
          <Col
            xl={{ span: 16 }}
            xxl={{ span: 18 }}
          >
            {/* <Row>
              <Col span={4}>
                <label>Anni di esperienza:</label>
              </Col>
              <Col span={10}>
                <Progress percent={30} showInfo={false}/>
              </Col>
            </Row> */}
            {/* <Row>
              <Col offset={4} span={2}>
                3
              </Col>
              <Col offset={3} span={2}>6</Col>
              <Col offset={3} span={2}>9</Col>
            </Row> */}
            <Row style={{ marginBottom: 25}}>

            <CandidateState
                  idPosizione={this.props.referrerJobDescriptionID}
                  selectedCandidateId={this.props.selectedCandidate.idCandidate}
                  candidateStatus={{stato1:'Candidato Interessante'}}
                />
            </Row>
            <Row>

              <Col
                span={24}
                style={{ /* marginTop: "6vh" */ }}
              >
                <PieChart
                  jobSoftSkills={this.props.softSkills}
                  jobHardSkills={this.props.hardSkills}
                  jobLanguages={this.props.languages}
                  jobEducationLevelScore={this.props.educationLevelScore}
                  jobYearsOfExperienceScore={this.props.yearsOfExperienceScore}
                  jobDegreesCoursesScore={this.props.degreesCoursesScore}
                  candidateSoftSkills={this.state.softSkills}
                  candidateHardSkills={this.state.hardSkills}
                  candidateLanguages={this.state.languageSkills}
                  candidateYearsOfExperienceScore={this.state.yearsOfExperienceScore}
                  candidateDegreesCoursesScore={this.state.degreesCoursesScore}
                  candidateEducationLevelScore={this.state.educationLevelScore}
                  candidateYearsOfExperienceExplanation={this.state.yearsOfExperienceExplanation}
                  selectedCandidateId={this.props.selectedCandidate._embedded.cv.id}
                  selectedCandidateScore={this.props.selectedCandidate.score}
                />
              </Col>
            </Row>
            <Row
              gutter={[
                {
                  xs: 8,
                  sm: 8,
                  md: 8,
                  lg: 8,
                  xl: 8,
                  xxl: 32
                },
                16
              ]}
            >
              <Col
                xs={{ span: 8 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 8 }}
              >
                <LanguagesCard
                 isCandidate={true}
                  languages={
                    this.state.candidate.cvs[0].profile.skills.languageSkill
                      ? this.state.candidate.cvs[0].profile.skills.languageSkill
                      : []
                  }
                />
              </Col>
              <Col
                xs={{ span: 8 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 8 }}
              >
                <EducationLevelCard
                  educationHistory={
                    this.state.candidate.cvs[0].profile.educationHistory
                      ? this.state.candidate.cvs[0].profile.educationHistory
                      : []
                  }
                  highestEducationLevel={
                    this.state.candidate.cvs[0].profile.summary
                      ? this.state.candidate.cvs[0].profile.summary.highestEducation
                        ? this.state.candidate.cvs[0].profile.summary.highestEducation
                        : undefined
                      : undefined
                  }
                  isEducationLevelModalVisible={this.state.isEducationLevelModalVisible}
                  onEducationLevelCardClick={this.onEducationLevelCardClick}
                  handleCancelEducationLevelModal={this.handleCancelEducationLevelModal}
                  isCandidate={true}
                  onEducationLevelChange={this.onEducationLevelChange}
                />
              </Col>
              <Col
                xs={{ span: 8 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
                xxl={{ span: 8 }}
              >
                <YearsOfExperienceCard
                  employmentHistory={
                    this.state.candidate.cvs[0].profile.employmentHistory
                      ? this.state.candidate.cvs[0].profile.employmentHistory
                      : []
                  }
                  yearsOfExperience={
                    this.state.candidate.cvs[0].profile.summary
                      ? this.state.candidate.cvs[0].profile.summary.totalExperienceYears
                      : 0
                  }
                  isYearsOfExperienceModalVisible={this.state.isYearsOfExperienceModalVisible}
                  onYearsOfExperienceCardClick={this.onYearsOfExperienceCardClick}
                  handleCancelYearsOfExperienceModal={this.handleCancelYearsOfExperienceModal}
                  isCandidate={true}
                  onYearsOfExperienceChange={this.onYearsOfExperienceChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )
    } else {
      return (
        <Spin />
      )
    }
  }
} export default CandidatePerJobDescription;