export default {
  login: {
    welcomeMessage: "Welcome! Please login",
    usernamePlaceholder: "Username",
    passwordPlaceholder: "Password",
    usernameErrorMessage: "Please enter your username!",
    passwordErrorMessage: "Please enter your password!",
    loginButtonText: "Login",
    loginButtonWithSSOText: "Login with SSO",
  },
  header: {
    jobDescriptionsText: "Job description",
    candidatesText: "Candidates",
    settingsText: "Settings",
    userProfileText: "User Profile",
    moreText: "More...",
    logoutButtonText: "Logout",
  },
  filters: {
    selectPresence: "Select presence",
    selectType: "Select type",
    selectGeographicArea: "Seleziona geographic area",
    selectRegion: "Seleziona region",
    selectProvince: "Seleziona province",
  },
  dashboard: {
    welcomeMessage: "Welcome",
    logoutButton: "Logout",
    jobDescriptionsTotal: "Total Jobs",
    candidatesTotal: "Total Candidates",
    last24Hours: "In the last 24H",
    newCandidates: "New Imported Candidates",
    newJobs: "New Imported Jobs",
    group: "Group",
    jobAreasDistribution: "Candidate's Job Area Distribution",
    noSkillPresent: "No Skill present",
    topCities: "Top Cities",
    noCityPresent: "No City present",
    noHobbyPresent: "No Hobby present",
  },
  settings: {
    languageText: "Language:",
    italianText: "Italian",
    englishText: "English",
    clearUserSetting: "Clear user's settings",
    saveSettings: "Save settings",
    successClear: "User's settings successfully deleted",
    errorClear: "An error occurred! Please try again later",
    successSave: "Settings successfully saved",
    errorSave: "An error occured during save",
  },
  jobDescriptions: {
    jobDescriptionsText: "Job Descriptions",
    noJobDescriptionsText: "No job description present",
    jobDescriptionData: "Job Description's Detail",
    isArchivedText: "Archived",
    isOpenedText: "Opened",
    isClosedText: "Closed",
    isCreatedByText: "Created by",
    creationDateText: "Created on",
    isCreatedAndModifiedByArticle: "on",
    isModifiedByText: "Modified by",
    modifiedDateText: "Modified on",
    isModifiedByProposition: "at",
    codeText: "Code",
    nameText: "Name",
    statusText: "Status",
    archiveStatusText: "Archive",
    //activeText: 'Operating',
    protectedCategoryText: "Protected category searched",
    noDescriptionText: "No description present",
    deleteText: "Delete",
    deleteChooseText: "Delete job description?",
    deleteDescriptionText: "This job description will be deleted",
    modifyText: "Modify",
    enableModifiyText: "Enable position to modify",
    archiveText: "Archive",
    archiveChooseText: "Archive job description?",
    archiveDescriptionText:
      "This job description will be archived and it could be restored",
    restoreText: "Restore",
    restoreChooseText: "Restore job description?",
    restoreDescriptionText: "This job description will be restored",
    openText: "Open",
    openChooseText: "Open job description?",
    openDescriptionText: "This job description will be opened",
    closeText: "Close",
    closeChooseText: "Close job description?",
    closeDescriptionText: "This job description will be closed",
    confirmButtonText: "Confirm",
    jobDescriptionDataText: "Job description data",
    matchHistory: "Historical Matchings",
    availableVersion: "Available Versions",
    noHistoryFile: "There are no historical files",
    shareJob: "Share Job",
    shareText: "Share",
    stopShareText: "Stop sharing",
    exportText: "Export current situation",
  },
  candidates: {
    candidatesText: "Candidates",
    noCandidatesText: "No candidate present",
    nameText: "Name",
    ageText: "Age",
    bornDateText: "Date of birth",
    phoneNumberText: "Phone number",
    jobDescriptionCodeText: "Job description code",
    jobDescriptionNameText: "Job description name",
    applicationForText: "Applied",
    isCandidateText: "Applied",
    isNotCandidateText: "Not applied",
    protectedText: "Protected category",
    isProtectedPresentText: "Present",
    isNotProtectedPresentText: "Not present",
    protectedTypeText: "Protected category type",
    entryUserText: "Entered by",
    experienceText: "Experience detected",
    detectedText: "Detected",
    notDetectedText: "Undetected",
    insertionDateText: "Entry date",
    provenanceText: "Provenance",
    statusText: "Candidate's state",
    scoreText: "Score",
    deleteSearchText: "Deleting full text search",
    deleteSearchDescriptionText:
      "Full text search has been successfully deleted.",
    startSearchText: "Starting full text search",
    startSearchDescriptionText:
      "Full text search with the required keywords has been scheduled.",
    existSearchText: "Full text search already existing",
    existSearchDescriptionText:
      "Full text search with the required keywords already exists, so it has been scheduled again.",
    noNameText: "Name not found",
    companyText: "Has already worked in Company",
    protectedBelongText: "Belongs to a protected category",
    deleteText: "Delete",
    //deleteChooseText: 'Delete %(firstName) %(middleName) %(lastName)\'s CV?',
    deleteDescriptionText: "Curriculum will be permanently deleted",
    updateText: "Update",
    updateDataText: "Update candidate's personal data",
    firstNameText: "First name",
    middleNameText: "Middle name",
    surnameText: "Surname",
    downloadText: "Download CV",
    confirmButtonText: "Confirm",
    missingText: "Missing",
  },
  fullTextSearchCandidate: {
    firstKeywordText: "First keyword",
    secondKeywordText: "Second keyword",
    searchStatusText: "Search status",
    completedText: "Finished",
    notCompletedText: "Not finished",
    actionText: "Action",
  },
  buttons: {
    topBarFilter: "Filter",
    downloadText: "Download",
  },
  cards: {
    candidateData: "Candidate's Data",
    educationLevelJob: "Education Level detected",
    educationLevelCandidate: "Education Level Required",
    yearsOfExperienceJob: "Years of Experience Required",
    yearsOfExperienceCandidate: "Years of Experience detected",
    languagesJob: "Required Languages",
    languagesCandidate: "Detected Languages",
    cityCandidate: "Detected City",
    cityJob: "Required City",
    socialCandidate: "Detected Social Media",
    languages: "Languages",
  },
  candidateData: {
    noAddress: "No address present",
    missingData: "Missing data",
    noEmailAddress: "No Email address present",
    openLinkedin: "Open LinkedIn profile",
    candidateWebsite: "Candidate's website",
  },
  candidateState: {
    applicationText: "Application",
    applicationDescriptionText: "Profile applied to position",
    progressText: "In progress",
    outcomeText: "Outcome",
    suitableText: "Suitable",
    noSuitableText: "Not suitable",
    infoText: "Click to view or insert notes",
    infoVisualizationText: "View notes",
    //infoInsertText: 'Enter here your notes',
    saveButtonText: "Save notes",
    cancelButtonText: "Cancel",
    confirmationStatusText:
      "Do you want to change the status of the candidate?",
    clickProgressText: "Click to advance candidate status in process",
    progressButtonText: "Progress",
    clickSuitableText: "Click to mark the candidate as eligible",
    clickNotSuitableText: "Click to mark the candidate as ineligible",
  },
  newJobDescription: {
    importText: "1) Import job description",
    importDescriptionText: "Import existing job description",
    jobDescriptionText: "2) Job description",
    requirementText: "3) Requirements",
    requirementDescriptionText: "Needed requirements",
    skillText: "4) Skills",
    skillDescriptionText: "Hard/Soft skills and languages",
    saveButtonText: "Save job description",
    courseText: "Course",
    selectWeightText: "Select weight for requirements and skills",
    weightRequirementsText: "Weight of requirements",
    weightSkillsText: "Weight of skills",
    proceedwithoutText: "Proceed without analyzing",
    analyzeText: "Analyze",
    titleText: "Title",
    jobTitleText: "Job titles",
    codeText: "Identification code",
    usedCodeText: "Code already used",
    noCodeText: "Enter the code!",
    isRequiredText: "Is required?",
    yearsOfExperienceText: "Years of experience:",
    weightText: "Weight:",
    educationLevelText: "Education level:",
    thematicText: "Thematic area:",
    thematicAreasText: "Thematic areas",
    coursesText: "Courses",
    notFoundText: "Search some skills",
    typeText: "Type",
    weightTableText: "Weight",
    continueButtonText: "Forward",
    maxYearErrorMessage:
      "Years entered in the second field must be greater than or equal to those entered in the first field",
    requiredText: "Required",
  },
  /*educationLevel: {
    anyText: 'Qualsiasi',
    diplomaText: 'Diploma',
    professionalTrainingText: 'Formazione professionale',
    bachelorDegreeText: 'Laurea triennale',
    masterDegreeText: 'Laurea magistrale',
    masterText: 'Master',
    phdText: 'Dottorato',
    associateDegreeText: 'Laurea associata'
  },*/
  drawer: {
    title: "Filter",
    typeOfVisualization: "Visualization: ",
    gridText: "Grid",
    tableText: "Table",
    nameSearchText: "Search by name",
    //searchPlaceholderJobDescription: 'Insert job description name',
    //searchPlaceholderCandidate: 'Insert candidate name or surname',
    searchText: "Search",
    resetSearchText: "Reset search",
    fullTextSearch: "Full text search in CV:",
    //firstKeywordText: 'Insert first keyword',
    //secondKeywordText: 'Insert second keyword',
    startSearchText: "Start search",
  },
  hobbies: {
    noHobbies: "No hobby present",
  },
  educationCard: {
    titleText: "Education",
    completingText: "Ongoing",
    endendText: "Finished on",
    missingDateText: "Absent end date",
    noDataText: "No data present",
    noDescriptionText: "No description present",
    weightText: "Weight assigned",
  },
  experienceCard: {
    titleText: "Work experience",
  },
  fabButton: {
    visualizationText: "View",
    titleText: "CV management",
    saveLinkText: "Save link",
    addCVText: "Add CV",
    linkCVText: "Link CV",
  },
  addCV: {
    searchText: "Searching new jobs...",
    descriptionText: "Add candidate curricula to selected job description",
    activeOpenText: "Active and open",
    activeCloseText: "Active and close",
    archivedText: "Archived",
    uploadText: "Upload",
    noNameText: "No name found",
  },
  errorMessages: {
    missingCandidateName: "Name not found",
    missingCandidateBornDate: "Date not found",
    missingCandidatePhoneNumber: "Number not found",
    notAllowed: "Access denied",
    notAllowedJobDescription: "You do not have access to this job.",
    serviceUnavailable: "Service unavailable",
    expiredDescription: "Sorry, your session has expired.",
    notFound: "Resource not found",
    notFoundDescription: "You've been looking for something that isn't there.",
    retry: "Try again",
    backDashboard: "Back to dashboard",
    backLogin: "Back to login",
  },
  infoMessages: {
    loadingJobDescriptionsData: "Loading Job Descriptions",
    loadingCandidatesData: "Loading Candidates",
    loadingSingleJobDescriptionData: "Loading Job Description",
    loadingSingleCandidateData: "Loading Candidate",
  },
  alertJob: {
    alertMessage: "Data during the computing phase",
    alertDescription:
      "The job description has just been modified, data is still being calculated. Please try again soon.",
  },
};
