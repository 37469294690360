import React from 'react'

import { /* Input, */ Row, Col, /* Form, Select, */ Button } from 'antd';
import Axios from 'axios';
/* import { errorRouter } from '../../errors/ErrorRouter';
import Quill from 'quill'; */
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Translate from 'react-translate-component';

class ImportStep extends React.Component {

    state = {
        jobDescriptionTitleOptions: [],
        titlesMap: null,
        buttonLoading: false,
        oldValue: this.props.testoJob
    }

    constructor (props) {
        super(props);
        this.quillRef = null;
        this.reactQuillRef = null;
    }

    componentDidMount () { 
        this.attachQuillRefs()
       
    }
  
    componentDidUpdate () {
      
        this.attachQuillRefs()
    }
    
    attachQuillRefs = () => {
        if (typeof this.reactQuillRef.getEditor !== 'function') return;
        this.quillRef = this.reactQuillRef.getEditor();
    }

    procedi = () => {
      
       this.props.stepAvanti();
    }
    onAnalizzaJob = (job) => {
        this.props.onAnalizzaJob(job)
    }


    analizza = () => {
        const editor = this.quillRef;
        this.setState({
            buttonLoading: true
        })
        
        const unprivilegedEditor = this.reactQuillRef.makeUnprivilegedEditor(editor);
        let request = {"text": unprivilegedEditor.getHTML()}
        //console.log("RESPONSE: " + response.data);
        /* console.log("REQUEST: " + JSON.stringify(request));
        console.log("EDITOR: " + JSON.stringify(unprivilegedEditor))
        console.log("REACTQUILL: " + JSON.stringify(this.reactQuillRef)); */
        // You may now use the unprivilegedEditor proxy methods
        Axios.post('api/getInfoJob', request)
        .then((response) => {
            this.setState({
                buttonLoading: false,
            })
            this.onAnalizzaJob(response.data);
            this.procedi();
            
    
        })
        /*.catch((error) => {
            this.setState({
                buttonLoading: false
            })
            
            if(error.response && error.response.status === 503)
              this.props.history.push('/loginserviceunavailable')
            else
                alert("Errore")
          })*/
    }

    render() {
        //const getFieldDecorator = this.props.getFieldDecorator
        return (
            <Row gutter={[8, 8]}>
                <Row type='flex' justify='center' align='middle'>
                    <Col span={22}>
                        <ReactQuill theme='snow'
                            ref={(el) => { this.reactQuillRef = el }}>
                        </ReactQuill>
                    </Col>
                </Row>
                <Row type='flex' justify='center' align='middle' style={{marginTop: '2rem'}}>
                    <Col span={22} style={{display: 'flex', justifyContent:'end'}}>
                        <Button  color='#3f88c5' onClick={this.procedi} style={{marginRight: "0.5rem"}}>
                            <Translate content='newJobDescription.proceedwithoutText' />
                        </Button>

                        <Button  loading={this.state.buttonLoading} type="primary" color='#3f88c5' onClick={this.analizza}>
                            <Translate content='newJobDescription.analyzeText' />
                        </Button>
                    </Col>
                </Row>
            </Row >
        )
    }
} export default ImportStep;