import React from "react";
//Utilizzata per linkare le icone dell'header alle pagine specifiche
import { Link, withRouter } from "react-router-dom";
//Componenti ant design utilizzati
import { Row, Col, Affix, Dropdown, Menu, Tooltip } from "antd";

import logoWhiteZarathustra from "../resources/logo/logo-white.png";
/* import briefCaseSolid from '../resources/icons/briefcaseSolid.png';
import userSolid from '../resources/icons/usersSolid.png';
import settingsSolid from '../resources/icons/settingsSolid.png';
import userCircleSolid from '../resources/icons/userCircleSolid.png'; */

/**
 * Librerie per il multilingua, il componente prende come props il content, che indica a quale
 * coppia chiave-valore del json delle lingue deve riferirsi, in modo da stampare il testo specifico.
 */
import Translate from "react-translate-component";
//Axios per le chiamate alle API Back End
import Axios from "axios";

class ZarathustraHeader extends React.Component {
  state = {
    //Utilizzato nel componente Affix di ant design per specificare a che distanza dal margine superiore deve essere fissato l'header
    topPadding: 0,
  };

  //Il componente <Menu /> chiama questo metodo in caso di click di uno dei suoi Item, passando la key indicata nel props 'key' di <Menu.Item />
  onMenuClick = ({ key }) => {
    /**
     * Distingue il bottone di logout dagli altri presenti nel menù, cancella il token dagli headers di axios, e viene effettuato il redirect a /login
     * tramite il componente <Link />
     */
    if (key === "3") {
      Axios.defaults.headers.common["Authorization"] = null;
      localStorage.setItem("Token", "");
      localStorage.setItem("Username", "");
      localStorage.setItem("userLogged", "");
      localStorage.getItem("Type") === "Form" ? this.props.history.push("/login") : document.location.replace("/saml/logout_saml");
      localStorage.setItem("Type", "");
    }
  };

  render() {
    /**
     * Le due righe di codice settano il baseURL e il parametro Authenticate degli headers
     * per poter avere la configurazione di axios configurata anche in caso di mancato passaggio per il
     * login (possibile in caso di autenticazione già effettuata in precedenza). E' stato scelto l'header
     * in quanto è un componente che verrà caricato sicuramente, a prescindere dalla pagina in cui ci si trova.
     */
    /*  Axios.defaults.baseURL = '/zarathustra'; */
    Axios.defaults.headers.common["Authorization"] =
      localStorage.getItem("Token");
    /**
     * Ogni icona dell'header ha un link che effettua il redirect alla rotta specificata
     * nel props 'to'.
     */
    return (
      <Affix offsetTop={this.state.topPadding}>
        {!this.props.hasError ? (
          <Row type="flex" align="middle" className="header-row">
            <Col
              span={12}
              style={{
                justifyContent: "start",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Link to={"/dashboard"}>
                <img
                  src={logoWhiteZarathustra}
                  alt="Zarathustra"
                  style={{ height: "2.5rem" }}
                />
              </Link>
            </Col>
            <Col
              span={12}
              style={{
                justifyContent: "end",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tooltip
                title={<Translate content="header.jobDescriptionsText" />}
                placement="bottomRight"
              >
                <Link to={"/jobdescriptions"}>
                  <i
                    className="fa-solid fa-briefcase iconsHeader"
                    style={{ fontSize: "1.1rem" }}
                  />
                </Link>
              </Tooltip>

              <Tooltip
                title={<Translate content="header.candidatesText" />}
                placement="bottomRight"
              >
                <Link to={"/candidates"}>
                  <i
                    className="fa-solid fa-users iconsHeader"
                    style={{ fontSize: "1.1rem", marginLeft: "2rem" }}
                  />
                </Link>
              </Tooltip>
              <Tooltip
                title={<Translate content="header.settingsText" />}
                placement="bottomRight"
              >
                <Link to={"/settings"}>
                  <i
                    className="fa-solid fa-gear iconsHeader"
                    style={{ fontSize: "1.1rem", marginLeft: "2rem" }}
                  />
                </Link>
              </Tooltip>

              <Tooltip
                title={<Translate content="header.userProfileText" />}
                placement="bottomLeft"
              >
                <Dropdown
                  trigger={["click"]}
                  placement="bottomRight"
                  style={{ marginTop: 5 }}
                  overlay={
                    <Menu style={{ marginTop: 15 }} onClick={this.onMenuClick}>
                      <Menu.Item key="1">
                        <Translate content="header.userProfileText" />
                      </Menu.Item>
                      <Menu.Item key="2">
                        <Translate content="header.moreText" />
                      </Menu.Item>
                      <Menu.Item key="3">
                        <Link to="/login">
                          <Translate content="header.logoutButtonText" />
                        </Link>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  {/* eslint-disable-next-line */}
                  <i
                    className="fa-solid fa-circle-user iconsHeader"
                    style={{
                      fontSize: "2rem",
                      marginLeft: "2rem",
                      marginRight: "5rem",
                    }}
                  />
                </Dropdown>
              </Tooltip>
            </Col>
          </Row>
        ) : (
          <Row
            type="flex"
            align="middle"
            justify="start"
            className="header-row"
          >
            <Col span={12}>
              <Link to={"/dashboard"}>
                <img
                  src={logoWhiteZarathustra}
                  alt="Zarathustra"
                  style={{ height: "2.5rem" }}
                />
              </Link>
            </Col>
          </Row>
        )}
      </Affix>
    );
  }
}
export default withRouter(ZarathustraHeader);
