import React from 'react';
//Componenti ant design utilizzati
import { Card, Row, Col, Typography, Popover, List } from 'antd';
//Librerie per il multilingua
import Translate from 'react-translate-component';

//import languageSolid from '../../resources/icons/languageSolid.png';

//Componenti Title e Text di Ant Design, fanno parte della libreria Typography
const { Title, Text } = Typography;

/** TODO Da definire i props utilizzati dal componente */
export default function LanguagesCard(props) {

  let data = [];
  props.languages
    ? props.languages.forEach((language) => {
      data.push({
        title:
          `${language.languageCodeDescription.length !== 0 
            ? `${language.languageCodeDescription}`
            : localStorage.getItem("Language") === 'it' ? 'Lingua non identificata' : 'Unidentified language'} 
            ${language.proficiencyCodeDescription.length !== 0
              ? props.isCandidate
                ? `, ${language.proficiencyCodeDescription}`
                : `, ${language.proficiencyCodeDescription}%`
              : localStorage.getItem("Language") === 'it' ? ', Livello non trovato' : ', Level not found'}`
      })
    })
    : data = []
  return (
    <Popover
      content={
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                title={item.title}
              />
            </List.Item>
          )}
        />
      }
    >
      <Card id='personalCard' style={{ height: '100%' }}>
        <Row  justify='center' align='middle'>

          <Col
            span={20}
            style={{ textAlign: 'left', height: '25px',  justifyContent: 'start', alignItems: 'center' }}
          ><div>
                  <Row>
                  <Title level={3}>
                    {
                      props.languages
                        ? props.languages.length
                        : 0
                    }
                  </Title>
                </Row>
                <Row style={{marginTop: '-6px'}}>
                <Text style={{ color: '#3f88c5', fontSize: 12 }}>
                      {props.isCandidate ? <Translate content='cards.languagesCandidate' /> : <Translate content='cards.languagesJob'/>} 
                    
                  </Text>
                </Row>
            </div>
           
            
          </Col>
          <Col
            span={4}
            style={{ textAlign: 'right' }}
          >
            <i className="fa-regular fa-language iconsCandidate" style={{ marginRight: '0.5rem', fontSize: '2rem', display: 'flex', justifyContent: 'center' }}></i>
          </Col>
        </Row>
      </Card>
    </Popover >
  )
}
