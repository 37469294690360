import React from 'react';

import TopBar from '../../topbars/TopBar';
import JobDescriptionsGrid from './JobDescriptionsGrid';
import JobDescriptionsTable from './JobDescriptionsTable';
import FilterDrawer from '../../Drawers/FilterDrawer';
import Axios from 'axios';

import { Spin } from 'antd';

import { errorRouter } from '../../errors/ErrorRouter';
import { withRouter } from 'react-router-dom';
import counterpart from 'counterpart';

/**
 * Questo componente prende come unico props this.props.onCardClick. Questo props
 * contiene una reference al metodo onCardClick passato da <App />, in modo che nel momento
 * del click di una card, il componente <JobDescriptionCard /> possa lanciare l'evento e passare il parametro
 * jobDescriptionID. App riuscirà così a ricevere l'id della job description cliccata in modo da poterlo passare 
 * a <SingleJobDescription /> tramite URL (e come props), per preservare il dato anche in caso di refresh da parte
 * dell'utente
*/
class JobDescriptions extends React.Component {

  state = {
    //Rende visibile o meno il drawer con le possibili filtrature
    drawerIsVisible: false,
    //Il tipo di visualizzazione scelta dall'utente: 1 - Griglia, 2 - Tabella
    tableVisualizationValue: '1',
    //Permette di capire se i dati devono ancora essere caricati o meno, in modo da visualizzare uno spinner
    isDataLoading: true,
    //Array contenente tutte le job descriptions
    jobDescriptionData: [],
    //Numero totale delle job descriptions per la pagination di spring
    totalJobDescriptions: 0,
    //Pagina corrente visualizzazione per la pagination di spring
    currentPage: 1,
    //Numero di elementi per pagina per la pagination di spring
    pageSize: 12,
  }

  /**
   * Nel costruttore sono impostati i vari filtri presenti, in base alla risposta mandata dal back end.
   * Se non sono presenti filtri, allora da dei valori di default specifici, altrimenti imposta il dato ricevuto
   */ 
  constructor(props) {
    super(props);
    if (this.props.userSettings.length !== 0) {
      let tableVisualizationValue = this.props.userSettings.filter((item) => { return item.name === 'jobDescriptionsVisualization' });
      this.state = {
        tableVisualizationValue: tableVisualizationValue[0] ? tableVisualizationValue[0].value : '1',
        drawerIsVisible: false,
        isDataLoading: true,
        jobDescriptionData: [],
        totalJobDescriptions: 0,
        currentPage: 1,
        pageSize: 12,
      }
    } else {
      this.state = {
        tableVisualizationValue: '1',
        drawerIsVisible: false,
        isDataLoading: true,
        jobDescriptionData: [],
        totalJobDescriptions: 0,
        currentPage: 1,
        pageSize: 12,
      }
    }
  }

  //Quando il componente viene montato, sono caricati tutti i dati della prima pagina, con una pagination di default di 12
  componentDidMount() {
    this.loadJobDescriptionsData(1, 12)
  }

  /**
   * API che prende tutte le job descriptions dal back end, imposta l'array con le job descriptions, il numero totale di 
   * elementi per la pagination, e comunica che i dati sono stati caricati, in modo da interrompere lo spin 
   */
  loadJobDescriptionsData = async () => {
    await this.setState({
      isDataLoading: true,
    })
    await Axios.get(`/api/job?size=${this.state.pageSize}&page=${this.state.currentPage - 1}`)
      .then((response) => {
        this.setState({
          jobDescriptionData: response.data._embedded.jobDescriptions,
          totalJobDescriptions: response.data.page.totalElements,
          isDataLoading: false
        })
      })
      .catch((error) => {
        if (error.response) {
          errorRouter(error.response.status, this.props.history);
        }
      })
  }

  onJobSearch = (searchingJob) => {
    Axios.get(`/api/job/search/findByTitleContaining?title=${searchingJob}&size=${this.state.pageSize}&page=${this.state.currentPage - 1}`)
      .then((response) => {
        this.setState({
          jobDescriptionData: response.data._embedded.jobDescriptions,
          totalJobDescriptions: response.data.page.totalElements,
          isDataLoading: false
        })
      })
  }

  //Viene lanciato al cambiamento del numero di elementi per pagina, ricaricando tutti i dati per la nuova visualizzazione
  onShowSizeChange = async (currentPage, pageSize) => {
    await this.setState({
      isDataLoading: !this.state.isDataLoading,
      currentPage,
      pageSize,
    })
    await this.loadJobDescriptionsData(currentPage, pageSize)
  }

  //Viene lanciato al cambiamento del numero di pagina, ricaricando tutti i dati per la nuova visualizzazione
  onPageChange = async (currentPage, pageSize) => {
      await this.setState({
      isDataLoading: !this.state.isDataLoading,
      currentPage,
      pageSize,
    })
    await this.loadJobDescriptionsData(currentPage, pageSize)
  }

  //Gestisce l'apertura del drawer per i filtri 
  showDrawer = () => {
    this.setState({
      drawerIsVisible: true,
    });
  };

  //Gestisce la chiusura del drawer per i filtri
  onDrawerClose = () => {
    this.setState({
      drawerIsVisible: false,
    });
  };

  /**
   * Metodo lanciato al cambiamento della visualizzazione griglia/tabella: permette il salvataggio della nuova
   * impostazione, e lancia un metodo che cambia in modo dinamico il dato anche in locale (updateUserSettings) 
   */
  onVisualizationSettingChange = (e) => {
    Axios.post(`/api/userpreference/add/jobDescriptionsVisualization/${e.target.value}`)
      .then(() => {
        this.setState({
          tableVisualizationValue: e.target.value,
        })
      })
      .catch((error) => {
        if (error.response) {
          errorRouter(error.response.status, this.props.history);
        }
      })
    this.props.updateUserSettings('jobDescriptionsVisualization', e.target.value);
  }

  render() {
    const loadingMessage = counterpart.translate('infoMessages.loadingJobDescriptionsData')
    return (
      <div style={{ padding: '40px 5vw 40px 5vw' }} >
        <Spin
          spinning={this.state.isDataLoading}
          size='large'
          tip={loadingMessage}
          style={{ paddingTop: '70vh' }}
        >
          <TopBar
            isJobDescriptions={true}
            totalData={this.state.totalJobDescriptions}
            onShowSizeChange={this.onShowSizeChange}
            onPageChange={this.onPageChange}
            showDrawer={this.showDrawer}
          />
          {
            this.state.tableVisualizationValue === '1'
              ? <JobDescriptionsGrid
                cardsData={this.state.jobDescriptionData}
                onJobCardClick={this.props.onJobCardClick}
                loadJobDescriptionsData={this.loadJobDescriptionsData}
                onJobEditClick={this.props.onJobEditClick}  
              />
              : <JobDescriptionsTable
                cardsData={this.state.jobDescriptionData}
                onJobCardClick={this.props.onJobCardClick}
                isDataLoading={this.state.isDataLoading}
              />
          }
          <FilterDrawer
            isVisible={this.state.drawerIsVisible}
            onClose={this.onDrawerClose}
            onVisualizationSettingChange={this.onVisualizationSettingChange}
            tableVisualizationValue={this.state.tableVisualizationValue}
            onJobSearch={this.onJobSearch}
            loadJobDescriptionsData={this.loadJobDescriptionsData}
          />
        </Spin>
      </div>
    );
  }
} export default withRouter(JobDescriptions);