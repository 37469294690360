import React from 'react';
//Componenti ant design utilizzati
import { Card, Row, Col, Avatar, Typography, Tooltip, Button, notification, Spin, Icon, Form, Input, Modal, DatePicker } from 'antd';
//Utilizzata per la gestione del formato delle date
import Moment from 'react-moment';
import moment from 'moment';

import Axios from 'axios';

import Translate from 'react-translate-component';
import { withRouter } from 'react-router-dom';

import { errorRouter } from '../errors/ErrorRouter';

/* import calendarDaySolid from '../../resources/icons/calendarDaySolid.png';
import phoneSolid from '../../resources/icons/phoneSolid.png';
import trashSolid from '../../resources/icons/trashSolid.png';
import fileDownloadSolid from '../../resources/icons/fileDownloadSolid.png'; */
import fileCvSolid from '../../resources/icons/fileCvSolid.png';
/* import envelopeSolid from "../../resources/icons/envelopeSolid.png";
import { CalendarTwoTone, DeleteTwoTone, EditTwoTone, PhoneTwoTone } from '@ant-design/icons'; */


//Componenti Title e Text di Ant Design, fanno parte della libreria Typography
const { Title, Text } = Typography;


/**
 * Props utilizzati: 
 * - candidate: l'oggetto contenente i dati del candidato che la card deve mostrare.
 * - onCandidateCardClick: metodo lanciato al momento del click della card, utilizzato per salvare l'id del candidato cliccato
 * - loadCandidatesData: metodo lanciato quando viene effettuata una qualunque operazione sulla card, in modo da aggiornare i dati della griglia ed avere sempre 
 *   le informazioni aggiornate
*/
class SingleCandidateCard extends React.Component {

  state = {
    candidate: {},
    isLoading: true,
    isUpdateModalOpen: false,
  }

  //Permette di gestire l'apertura di una notifica alla volta. Se una notifica è già aperta, risulta true
  notificationIsOpen = false

  componentDidMount() {
    this.setState({
      candidate: this.props.candidate,
      isLoading: false,
    })
  }

  /**
   * Nel caso del componente <SingleCandidate /> la card non deve lanciare nessun evento con il click in quanto già ci si trova 
   * nella schermata del candidato, quindi il prop "onCandidateCardClick" non viene passato dal componente superiore. L'if gestisce
   * l'assenza di questo prop, e quindi il click viene ignorato nel componente sopra specificato.
  */
  onCandidateCardClick = () => {
    if (this.props.onCandidateCardClick !== undefined) {
      this.props.onCandidateCardClick(this.state.candidate.id)
      //this.props.history.push( '/singleCandidate/' + this.state.candidate.id )
      this.props.history.push({
        pathname: '/singleCandidate/' + this.state.candidate.id,
        state: { currentPage: this.props.currentPage }
      })
      console.log(this.props.currentPage)
    } else {
      //TODO gestire errore possibile di mancato click della card, o di click della card in <SingleCandidate />
    }
  }

  /**
   * Al click del bottone per l'eliminazione del candidato, viene aperta la notifica di conferma.
   * Il controllo (!this.notificationIsOpen) fa in modo che venga aperta una sola notifica alla volta.
   * Le diverse notifiche sono identificate tramite una key creata in base all'istante , 
   * in modo da poterle gestire indistintamente l'una dall'altra
   */
  onDeleteClick = () => {
    if (!this.notificationIsOpen) {
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            notification.close(key);
            this.deleteCandidate();
            this.notificationIsOpen = false;
          }}
        >
          <Translate content='candidates.confirmButtonText' />
        </Button>
      );
      notification.open({
        message: `${localStorage.getItem("Language") === 'it' 
                    ? 'Eliminare il CV di ' + this.props.candidate.firstName + ' ' + this.props.candidate.middleName + ' ' + this.props.candidate.lastName + '?' 
                    : 'Delete ' + this.props.candidate.firstName + ' ' + this.props.candidate.middleName + ' ' + this.props.candidate.lastName + '\'s CV?'}`,
        description:
          <Translate content='candidates.deleteDescriptionText' />,
        btn,
        key,
        placement: "bottomLeft",
        onClose: () => {
          this.setState({
            isLoading: false,
          })
          this.notificationIsOpen = false
        }
      });
      this.notificationIsOpen = true;
    }
    this.setState({
      isLoading: true,
    })
  }

  /**
   * Una volta aperta la notifica di eliminazione del candidato, se l'utente conferma, allora il candidato
   * viene eliminato e vengono ricaricati i dati della griglia
   */
  deleteCandidate = async () => {
    await Axios.delete(`/api/candidato/${this.state.candidate.id}`)
      .then((response) => {
        this.props.loadCandidatesData()
      })
      .catch((error) => {
        if (error.response) {
          errorRouter(error.response.status, this.props.history);
        }
      })
    this.notificationIsOpen = false;
    this.setState({
      isLoading: false,
    })
    if (this.props.history.location.pathname !== '/candidates') {
      await this.props.history.push('/candidates')
    }
  }

  //Permette di scaricare, direttamente dal server, il cv collegato al candidato specifico della card
  onDownloadCvClick = () => {
    //Viene impostata la configurazione di axios, in modo che possa accettare i file
    var axiosconfig =
    {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf'
      }
    };

    /**
     * Viene lanciato il download del file in base al nome del file indicato dal back end, con la configurazione soprastante, aprendo
     * una finestra di conferma del download con il nome del curriculum
     */
    Axios.get(`/api/download/${this.state.candidate.id}`, axiosconfig)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.props.candidate.firstName + this.props.candidate.middleName + this.props.candidate.lastName}.pdf`);
        document.body.appendChild(link);
        link.click();
      });
  }

  onUpdateCandidateClick = async () => {
    await this.props.form.setFieldsValue({
      'firstName': this.props.candidate ? this.props.candidate.firstName : '',
      'middleName': this.props.candidate ? this.props.candidate.middleName : '',
      'lastName': this.props.candidate ? this.props.candidate.lastName : '',
      'email': this.props.candidate ? this.props.candidate.email : '',
      'dateOfBirth': this.props.candidate ? moment(this.props.candidate.dateOfBirth, 'YYYY-MM-DD') : moment(),
      'phone': this.props.candidate ? this.props.candidate.phone : ''
    })
    await this.setState({
      isUpdateModalOpen: true,
    })
  }
  handleUpdateModalOk = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        Axios.patch(`/api/candidato/${this.props.candidate.id}`, {
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          email: values.email,
          dateOfBirth:
            `${values.dateOfBirth.toDate().getFullYear()}-${values.dateOfBirth.toDate().getMonth() + 1}-${values.dateOfBirth.toDate().getDate()}`,
          phone: values.phone,
        })
          .then(() => {
            this.setState({
              isUpdateModalOpen: false,
            })
            this.props.refreshCandidateData
              ? this.props.refreshCandidateData()
              : this.props.loadCandidatesData()
          })
          .catch((error) => {
            errorRouter(error.response.status, this.props.history);
          })
      }
    });
  }

  handleUpdateModalCancel = () => {
    this.setState({
      isUpdateModalOpen: false,
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const hasDisability = false;
    return (
      <Spin spinning={this.state.isLoading}>
        <Card
          hoverable
          style={{ height: '290px' }}
        >
          <div style={{ textAlign: 'center' }}>
            <Row type='flex' justify='center' align='middle' gutter={[0, 24]} onClick={this.onCandidateCardClick}>
              <Col
                span={24}
              >
                <Avatar
                  size={120}
                  icon="user"
                  src={`data:image/jpeg;base64,${this.props.candidate ? this.props.candidate.photo : ''}`}
                />
              </Col>
            </Row>
            <Row type='flex' justify='center' align='middle' gutter={[0, 24]} onClick={this.onCandidateCardClick}>
              <Col
                span={
                  this.props.candidate
                    ? this.props.candidate.aziendaWork ? 21 : 24
                    : null
                }
              >
                <Tooltip
                  title={
                    this.props.candidate.firstName !== undefined && this.props.candidate.firstName !== null && this.props.candidate.firstName !== ''
                    && this.props.candidate.lastName !== undefined && this.props.candidate.lastName !== null && this.props.candidate.lastName !== ''
                      ? `${this.props.candidate.firstName} ${this.props.candidate.middleName} ${this.props.candidate.lastName}`
                      : <Translate content= 'candidates.noNameText' />
                  }
                >
                  <Title level={4} ellipsis style={{ color: '#3f88c5' }}>
                    {
                      this.props.candidate ?
                        this.props.candidate.firstName || this.props.candidate.middleName || this.props.candidate.lastName
                          ? this.props.candidate.firstName.length !== 0 || this.props.candidate.middleName.length !== 0 || this.props.candidate.lastName.length !== 0
                            ? `${this.props.candidate.firstName} ${this.props.candidate.middleName} ${this.props.candidate.lastName}`
                            : <Translate content='errorMessages.missingCandidateName' />
                          : <Translate content='errorMessages.missingCandidateName' />
                        : <Translate content='errorMessages.missingCandidateName' />
                    }
                  </Title>
                </Tooltip>
              </Col>
              {
                this.props.candidatetrue
                  ? this.props.candidate.aziendaWork
                    ? <Tooltip title={<Translate content='candidates.companyText'/>}>
                      <Col span={3}>
                        <Icon type="trophy" theme="filled" style={{ fontSize: 18, color: "rgb(0, 168, 176)" }} />
                      </Col>
                    </Tooltip>
                    : null
                  : null
              }

            </Row>
            <Row type='flex' justify='center' align='middle' gutter={[24, 0]} style={{ marginTop: '0.5rem' }} onClick={this.onCandidateCardClick}>
              <Col 
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                xs={{ span: 8 }}
                sm={{ span: 5 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                xl={{ span: 7 }}
                xxl={{ span: 14 }}
              >
                <i className="fa-light fa-calendar-star iconsCandidate" style={{ marginRight: '0.5rem', fontSize: '1rem' }}></i>
                <Text >
                  {
                    this.props.candidate
                      ? this.props.candidate.dateOfBirth
                        ? <Moment format='DD/MM/YYYY' date={this.props.candidate.dateOfBirth} />
                        : '-'
                      : '-'
                  }
                </Text>
              </Col>
            </Row>
            <Row type='flex' justify='center' align='top' style={{ height: 25, marginTop: '0.3rem' }} onClick={this.onCandidateCardClick}>
              <Col
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                xs={{ span: 8 }}
                sm={{ span: 5 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                xl={{ span: 7 }}
                xxl={{ span: 14 }}
              >
                <i className="fa-light fa-phone iconsCandidate" style={{ marginRight: '0.5rem', fontSize: '1rem' }}></i>
                <Text>
                  {
                    this.props.candidate
                      ? this.props.candidate.phone
                        ? this.props.candidate.phone
                        : <Translate content='candidates.missingText'/>
                      : <Translate content='candidates.missingText'/>
                  }
                </Text>
              </Col>
            </Row>
            <Text style={{ color: "red", fontSize: 13 }}>
              {
                hasDisability
                  ? <Translate content='candidates.protectedBelongText'/>
                  : ""
              }
              {/* {
                    this.props.candidate
                      ? this.props.candidate.phone
                        ? this.props.candidate.phone
                        : 'Mancante'
                      : 'Mancante'
                  } */}
            </Text>
            <Row type='flex' align='middle' style={hasDisability ? { marginTop: '0px' } : { marginTop: '10px' }}>
              <Col
                span={12}
                style={{ textAlign: 'start' }}
              >
                <Tooltip title={<Translate content='candidates.deleteText' />}>
                <i onClick={this.onDeleteClick} className="fa-regular fa-trash iconsCandidate" style={{ marginRight: '0.5rem', fontSize: '1.1rem' }}></i>
                </Tooltip>
           
              
                <Tooltip title={<Translate content='candidates.updateText' />}>
                <i onClick={this.onUpdateCandidateClick} className="fa-regular fa-user-pen iconsCandidate" style={{ marginRight: '0.5rem', fontSize: '1.1rem', marginLeft: '0.5rem' }}></i>
                </Tooltip>
              </Col>
            
              
              <Col
                span={12}
                style={{ textAlign: 'end' }}
              >
                <Tooltip title={<Translate content='candidates.downloadText' />}>
                  <img
                    src={fileCvSolid}
                    alt="DownloadCV"
                    style={{ height: 18 }}
                    onClick={this.onDownloadCvClick}
                  />
                </Tooltip>
              </Col>
            </Row>
          </div>
        </Card >
        <Modal
          title={<Translate content='candidates.updateDataText' />}
          visible={this.state.isUpdateModalOpen}
          onOk={this.handleUpdateModalOk}
          onCancel={this.handleUpdateModalCancel}
        >
          <Form onSubmit={this.handleSubmit} layout="vertical" className="login-form">
            <Form.Item label={<Translate content='candidates.firstNameText' />}>
              {getFieldDecorator('firstName')(
                <Input
                  prefix={<i className="fa-regular fa-user iconsCandidate" style={{ marginRight: '0.3rem', fontSize: '0.9rem' }}/>}
                  placeholder={localStorage.getItem("Language") === 'it'
                                ? 'Inserisci primo nome'
                                : 'Insert first name'
                              }
                />,
              )}
            </Form.Item>
            <Form.Item label={<Translate content='candidates.middleNameText' />}>
              {getFieldDecorator('middleName')(
                <Input
                  prefix={<i className="fa-regular fa-user iconsCandidate" style={{ marginRight: '0.3rem', fontSize: '0.9rem' }}/>}
                  placeholder={localStorage.getItem("Language") === 'it'
                                ? 'Inserisci secondo nome'
                                : 'Insert middle name'
                              }
                />,
              )}
            </Form.Item>
            <Form.Item label={<Translate content='candidates.surnameText' />}>
              {getFieldDecorator('lastName')(
                <Input
                  prefix={<i className="fa-regular fa-user iconsCandidate" style={{ marginRight: '0.3rem', fontSize: '0.9rem' }}/>}
                  placeholder={localStorage.getItem("Language") === 'it'
                                ? 'Inserisci cognome'
                                : 'Insert surname'
                              }
                />,
              )}
            </Form.Item>
            <Form.Item label="Email">
              {getFieldDecorator('email')(
                <Input
                  prefix={<i className="fa-regular fa-envelope iconsCandidate" style={{ marginRight: '0.3rem', fontSize: '0.9rem' }}/>}
                  placeholder={localStorage.getItem("Language") === 'it'
                                ? 'Inserisci Email'
                                : 'Insert Email'
                              }
                />,
              )}
            </Form.Item>
            <Form.Item label={<Translate content='candidates.bornDateText' />}>
              {getFieldDecorator('dateOfBirth')(
                <DatePicker
                  placeholder={localStorage.getItem("Language") === 'it'
                                ? 'Inserisci data di nascita'
                                : 'Insert date of birth'
                              }
                  format="DD/MM/YYYY"
                  suffixIcon={<i className="fa-regular fa-calendar-star iconsCandidate" style={{ fontSize: '0.9rem' }}/>}
                />,
              )}
            </Form.Item>
            <Form.Item label={<Translate content='candidates.phoneNumberText' />}>
              {getFieldDecorator('phone')(
                <Input
                  type='number'
                  prefix={<i className="fa-regular fa-phone iconsCandidate" style={{ marginRight: '0.3rem', fontSize: '0.9rem' }}/>}
                  placeholder={localStorage.getItem("Language") === 'it'
                                ? 'Inserisci numero di telefono'
                                : 'Insert phone number'
                              }
                />,
              )}
            </Form.Item>
          </Form>
        </Modal>
      </Spin >
    );
  }
} const SingleCandidateCardForm = Form.create({ name: 'horizontal_login' })(SingleCandidateCard);
export default withRouter(SingleCandidateCardForm);
