import React from 'react';

import { Result, Button } from 'antd';
import { Link, withRouter } from 'react-router-dom';

import Translate from 'react-translate-component';

/**
 * Utilizza i props: 
 * - hasError: utilizzato dall'app per rilevare eventuali errori
 * - errorHandling: metodo lanciato per notificare all'app l'avvenuto (o risolto) error
 */
class Error404 extends React.Component {

  /**
   * All'apertura della pagina d'errore, se this.props.hasError risulta false, viene lanciato l'errore in modo che 
   * l'app sia in grado di rilevarlo.
   */
  componentDidMount() {
    if (!this.props.hasError && this.props.errorHandling) {
      this.props.errorHandling();
    }
  }

  /**
   * Nel passaggio dalla schermata di errore a quella di dashboard, la segnalazione d'errore viene annullata e l'app torna
   * al suo funzionamento standard.
   */
  onBackToDashboardClick = () => {
    if (this.props.errorHandling)
      this.props.errorHandling();
  }

  render() {
    return (
      <Result
        status="404"
        title={<Translate content='errorMessages.notFound' />}
        subTitle={<Translate content='errorMessages.notFoundDescription' />}
        extra={
          <Link to="/dashboard">
            <Button type="primary" onClick={this.onBackToDashboardClick}>
              <Translate content='errorMessages.backDashboard' />
            </Button>
          </Link>
        }
      />
    )
  }

} export default withRouter(Error404); 